import { useCallback } from "react";
import { useNotify } from "react-admin";
import { MutationOptions } from "ra-core/esm/dataProvider/useMutation";
export function useSaveWithErrors(mutate: any, type: "created" | "updated") {
  const notify = useNotify();

  return useCallback(
    async (data, options?: MutationOptions | undefined) => {
      try {
        await mutate(
          { payload: { data } },
          { returnPromise: true, ...(options ? options : {}) }
        );
        const messages = {
          created: "Elemento creato",
          updated: "Elemento modificato!",
        };
        notify(messages[type], "info");

        // if (type === "created") return response;
      } catch (error: any) {
        if (error.response.data) {
          try {
            if ("detail" in error.response.data) {
              notify(error.response.data.detail, "error");
              return;
            }
          } catch (error) {
            notify("Error", "error");
            return;
          }
          let errorDict: Record<string, string> = {};
          Object.keys(error.response.data).forEach((key) => {
            errorDict[key] = error.response.data[key].join(". ");
          });
          return errorDict;
        }
      }
    },
    [mutate, notify, type]
  );
}
