import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";

export const ReasonStatusForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"row"}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Grid item container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Motivazioni stati
                      </Typography>
                    </Grid>
                  </Grid>


                  <Grid item>
                    <SelectInput
                      {...initInput(
                        "status",
                        props.fieldOptions,
                        props.canEdit,
                        "array"
                      )}
                      allowEmpty
                      choices={props.fieldOptions.status.choices}
                    />
                  </Grid>
                  <Grid item>
                    <TextInput
                      {...initInput(
                        "description",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <BooleanInput
                      {...initInput(
                        "fault",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              //handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />
  );
};
