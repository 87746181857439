import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteInput,
  BooleanInput,
  FormWithRedirect,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { ReferenceInputChartAccounts } from "../../components/reference-inputs/ReferenceInputChartAccounts";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";

export const ChartAccountsTagsForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"row"}
                spacing={2}
              >

                <Grid item xs={4}>
                  <TextInput
                    {...initInput("tag", props.fieldOptions, props.canEdit)}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInputChartAccounts
                    {...initInput(
                      "chart_accounts",
                      props.fieldOptions,
                      props.canEdit
                    )}
                    filter={{}}
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInputSubject
                    {...initInput(
                      "supplier",
                      props.fieldOptions,
                      props.canEdit
                    )}
                    filter={{ type: "supplier" }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <BooleanInput
                    {...initInput(
                      "equal",
                      props.fieldOptions,
                      props.canEdit
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <BooleanInput
                    {...initInput(
                      "contain",
                      props.fieldOptions,
                      props.canEdit
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <BooleanInput
                    {...initInput(
                      "similar",
                      props.fieldOptions,
                      props.canEdit
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <BooleanInput
                    {...initInput(
                      "default",
                      props.fieldOptions,
                      props.canEdit
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BooleanInput
                    {...initInput(
                      "active",
                      props.fieldOptions,
                      props.canEdit
                    )}
                    defaultValue={true}
                  />
                </Grid>

              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card >
      )}
    />
  );
};
