import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import { useEffect, useRef } from "react";
import "leaflet-routing-machine";
import { CREATE_ROUTE_API } from "../../constants";
import { useNotify } from "react-admin";

interface Props {
  points: L.LatLng[];
  onRoutesFound?: (distance: number, timeInSeconds: number) => void;
  color?: string;
  onRouteClick?: () => void;
}

export function Routing({ points, onRoutesFound, color, onRouteClick }: Props) {
  const context = useLeafletContext();
  const routingControl = useRef<any>();
  const notify = useNotify();
  useEffect(() => {
    const map = context.map;

    routingControl.current = L.Routing.control({
      routeLine: function (route) {
        const line = L.routing.line(route, {
          styles: [
            {
              color: color ? color : "black",
              opacity: 1,
              weight: 5,
            },
          ],
          extendToWaypoints: false,
          missingRouteTolerance: 1,
          addWaypoints: false,
        });
        line.eachLayer(function (l) {
          l.on("click", function (e) {
            onRouteClick && onRouteClick();
          });
        });
        return line;
      },
      plan: new L.Routing.Plan([], {
        createMarker: () =>
          new L.Marker([0, 0], {
            icon: new L.DivIcon({ className: "d-none" }),
          }),
      }),
      fitSelectedRoutes: false,
      waypoints: [],
      alternativeClassName: "d-none",
      itineraryClassName: "d-none",
      router: L.Routing.osrmv1({
        language: "it",
        requestParameters: { continue_straight: "false" },
        serviceUrl: CREATE_ROUTE_API,
        useHints: false,
      }),
    })
      .on("routesfound", function (e) {
        const routes = e.routes;
        const summary = routes[0].summary;

        onRoutesFound &&
          onRoutesFound(
            Math.round(summary.totalDistance / 1000),
            summary.totalTime
          );
      })
      .on("routingerror", function (e) {
        notify("Errore nel calcolo del tracciato", "warning");
      });

    map.addControl(routingControl.current);

    return () => {
      routingControl.current && routingControl.current.setWaypoints([]);
      routingControl.current &&
        map &&
        map.hasLayer(routingControl.current) &&
        map.removeControl(routingControl.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.map]);

  useEffect(() => {
    routingControl && routingControl.current.setWaypoints(points);
  }, [points]);

  return null;
}
