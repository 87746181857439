import { Chip, ChipProps, makeStyles, Theme } from "@material-ui/core";

import { Record } from "react-admin";

const useStyles = makeStyles<Theme, { record: Record }>((theme) => ({
  outlined: {
    borderColor: ({ record }) =>
      record.status === "I"
        ? theme.palette.error.main
        : record.status === "G"
        ? theme.palette.success.main
        : theme.palette.warning.main,
    color: ({ record }) =>
      record.status === "I"
        ? theme.palette.error.main
        : record.status === "G"
        ? theme.palette.success.main
        : theme.palette.warning.main,
  },
}));
export const DeliveryStatusBadge = (props: ChipProps & { record: Record }) => {
  const { record, ...rest } = props;
  const classes = useStyles({ record });

  return <Chip {...rest} classes={classes} size="small" variant="outlined" />;
};
