import { Record } from "react-admin";
import { DeliveryStatusChoice } from "../../shared/types";

export const getPossibleNextStatusDelivery = (record: Record) => {
  const filteredChoices: any = {

    [DeliveryStatusChoice.TO_PLAN]: [
      { display_name: "In giacenza", value: DeliveryStatusChoice.STOCKED },
      { display_name: "Sospesa", value: DeliveryStatusChoice.SUSPENDED },
      { display_name: "Annullata", value: DeliveryStatusChoice.CANCELED },
    ],
    [DeliveryStatusChoice.PLANNED]: [
      { display_name: "In giacenza", value: DeliveryStatusChoice.STOCKED },
      //{ display_name: "Caricate", value: DeliveryStatusChoice.LOAD },
      { display_name: "Sospesa", value: DeliveryStatusChoice.SUSPENDED },
      { display_name: "Annullata", value: DeliveryStatusChoice.CANCELED },
    ],
    [DeliveryStatusChoice.TRANSITING]: [
      { display_name: "Consegnata", value: DeliveryStatusChoice.DELIVERED },
      { display_name: "Sospesa", value: DeliveryStatusChoice.SUSPENDED },
      { display_name: "Annullata", value: DeliveryStatusChoice.CANCELED },
    ],
    [DeliveryStatusChoice.STOCKED]: [
      { display_name: "Fine giacenza", value: DeliveryStatusChoice.STOCKED_END },
      { display_name: "Consegnata", value: DeliveryStatusChoice.DELIVERED },
    ],
    [DeliveryStatusChoice.STOCKED_END]: [
      { display_name: "In giacenza", value: DeliveryStatusChoice.STOCKED },
      { display_name: "Sospesa", value: DeliveryStatusChoice.SUSPENDED },
      { display_name: "Annullata", value: DeliveryStatusChoice.CANCELED },
    ],
    [DeliveryStatusChoice.LOAD]: [
      { display_name: "In consegna", value: DeliveryStatusChoice.DELIVERING },
      { display_name: "In giacenza", value: DeliveryStatusChoice.STOCKED },
      { display_name: "Sospesa", value: DeliveryStatusChoice.SUSPENDED },
      { display_name: "Annullata", value: DeliveryStatusChoice.CANCELED },
    ],
    [DeliveryStatusChoice.DELIVERING]: [
      { display_name: "Consegnata", value: DeliveryStatusChoice.DELIVERED },
      { display_name: "In giacenza", value: DeliveryStatusChoice.STOCKED },
      { display_name: "Sospesa", value: DeliveryStatusChoice.SUSPENDED },
      { display_name: "Annullata", value: DeliveryStatusChoice.CANCELED },
    ],
    [DeliveryStatusChoice.SUSPENDED]: [
      { display_name: "In giacenza", value: DeliveryStatusChoice.STOCKED },
      { display_name: "Sospesa", value: DeliveryStatusChoice.SUSPENDED },
      //{ display_name: "Caricate", value: DeliveryStatusChoice.LOAD },
      //{ display_name: "In consegna", value: DeliveryStatusChoice.DELIVERING },
      { display_name: "Consegnata", value: DeliveryStatusChoice.DELIVERED },
      { display_name: "Annullata", value: DeliveryStatusChoice.CANCELED },
    ],
    [DeliveryStatusChoice.DELIVERED]: [],
    [DeliveryStatusChoice.CANCELED]: [],
  };
  return filteredChoices[record.status];
};

export const transformDelivery = (data: any) =>
  data.is_recurrent === false
    ? { ...data, recurrence_conf: undefined }
    : { ...data };

export const chargeTypeDisplay = (charge_type: string, short: boolean = false) => {

  const type_map: { [key: string]: string } = {
    "NO": 'Consegna',
    "RC": "Riconsegna",
    "RI": 'Ritiro',
    "BA": 'Reso',
    "CO": "Contrassegno",
    "TR": "Trazione",
    "SD": "Same day",
    "AS": "Assicurazione",
    "GI": "Giacenze",
    "FU": "Fuel",
    "SE": "Servizi"
  }
  let ret = type_map[charge_type];
  if (short === true) {
    ret = ret.substring(0, 3)
  }
  return ret;
}