import {
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  FunctionField,
} from "react-admin";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";
import { DeliveryField } from "../../components/DeliveryField";
import { IdentifierField } from "../../components/IdentifierField";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { CustomFileField } from "../../components/CustomFIleField";

export const CustomerImportFileDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <CustomFileField
        {...initField("file_info", options)}
        title="name"
        src="url"
      />
      <BooleanField {...initField("correct", options)} />
    </Datagrid>
  );
};
