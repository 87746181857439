import {
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  Record,
  FunctionField,
  SelectField,
  ArrayField,
  ChipField,
  SingleFieldList,
  ReferenceArrayField,
  SelectInput,
  Button,
  Identifier,
} from "react-admin";

import { DatagridPropsWithOptions } from "../../shared/types";
import { getFileNameFromContentDisposition, initField } from "../../shared/utils";
import { GoToDeliveriesButton } from "../../components/GoToDeliveriesButton";
import { DownloadFileButton } from "../../components/DownloadFileButton";
import { IdentifierField } from "../../components/IdentifierField";
import React from "react";

import { clientNoJson } from "../../dataProvider";
import { url } from "../../constants";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloseIcon from '@material-ui/icons/Close';
import { SubjectField } from "../../components/SubjectField";

let green = "rgb(76, 175, 80)";
let yellow = "rgb(255, 152, 0)";
let red = "#f44336";

export const downloadNote = async (routeId: Identifier, resource?: string) => {
  if (!resource) {
    resource = "notes"
  }
  let res = null;
  try {
    res = await clientNoJson(`${url}/${`${resource}/${routeId}/download/`}`, {
      responseType: "arraybuffer",
      method: "GET",
    });
  } catch (err) {
    return;
  }
  return res;
};

export const saveNoteToFile = async (routeId: Identifier) => {
  const res = await downloadNote(routeId, "delivery-groups");
  if (res) {
    const data = res.data;
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  }
};

export const DeliveryGroupDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {

  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />

      <FunctionField
        {...initField("file", options)}
        label={"Borderaux firmato"}
        render={(tableRecord: any) => {
          if (tableRecord.file) {
            return (
              <Button onClick={() => {
                saveNoteToFile(tableRecord.id)
              }} >
                <AssignmentTurnedInIcon style={{ color: green[500] }} />
              </Button>);

          }
          return (<CloseIcon style={{ color: red[500] }} />);
        }}
      />


      <SubjectField
        {...initField("subject_object", options)}

      />

      <SelectField choices={options.type.choices} {...initField("type", options, "array")} />

      <DateField showTime {...initField("created_date", options)} />

      <GoToDeliveriesButton filterAttribute={"delivery_group"} label="Miss." />

      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <DownloadFileButton
              title="Borderau pdf"
              urlDownload={`delivery-groups/${record.id}/get_pdf_borderau/`}
            />
          ) : null
        }
      />
    </Datagrid>
  );
};
