// botton crea riconsegna
import { Dialog, DialogContent, DialogActions, Typography, Grid } from "@material-ui/core";
import { useState } from "react";
import {
  useRecordContext,
  Button,
  FormWithRedirect,
  SaveButton,
  useNotify,
  useRedirect,
} from "react-admin";
import { useToggler } from "../../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";
import { client } from "../../dataProvider";

export const NewCachetBoxButton = (props: any) => {
  const { value, setTrue, setFalse } = useToggler();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const onClick = (values: any) => {
    setLoading(true);
    client(`cachet-boxes/new`, {
      method: "GET",
      data: values,
    })
      .then((data) => {
        notify("Nuova cassa creata", "success")
        redirect(window.location.href)
      })
      .catch(() => {
        setLoading(false);
        notify("Errore nella creazione!", "error");
      });
  };
  const [loading, setLoading] = useState(false);

  const [lastCD, setLastCD] = useState("");

  function rememberNewCachetBox(c_d: string) {
    if (c_d !== "" && lastCD !== c_d && value === false) {
      setLastCD(c_d);
      let diff = new Date().getTime() - new Date(c_d).getTime();
      if (diff / 3600000 > 9) {
        setTrue();
      }
    }

  }

  return (
    <>
      {
        <Grid container style={{ marginTop: "-5px", paddingTop: "0px", paddingBottom: "0px", maxHeight: "40px" }}>
          <Grid item xs={6} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <Typography style={{ textAlign: "center" }}>
              Cassa
            </Typography>
          </Grid>
          {
            props.cachetBox != null ? <Grid item xs={6} style={{ paddingTop: "0px", paddingBottom: "0px", maxHeight: "25px" }}>
              <Typography style={{ textAlign: "center" }}
                title={`Cassa ID:${props.cachetBox["id"]}/User:${props.cachetBox["user"]}`}>
                {props.cachetBox["id"]}/{props.cachetBox["user"]}
              </Typography>
            </Grid>
              : null
          }
          <Grid item xs={6} style={{ paddingTop: "0px", paddingBottom: "0px", maxHeight: "20px", textAlign: "center" }}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setTrue();
              }}
              label={"Crea nuova"}
              style={{ margin: "-5px" }}
            ></Button>
          </Grid>
          {
            props.cachetBox != null ? <Grid item xs={6} style={{ paddingTop: "0px", paddingBottom: "0px", maxHeight: "20px" }}>
              <Typography style={{ textAlign: "center" }} title={`${props.cachetBox["created_date"].slice(0, 10)} ${props.cachetBox["created_date"].slice(11, 19)}`}>
                {
                  `${props.cachetBox["created_date"].slice(8, 10)}/${props.cachetBox["created_date"].slice(5, 7)} ${props.cachetBox["created_date"].slice(11, 16)}`
                }
                {
                  rememberNewCachetBox(props.cachetBox["created_date"])
                }
              </Typography>
            </Grid>
              : null
          }

        </Grid>
      }

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          save={onClick}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <Typography>Sicuro di voler creare una nuova cassa?</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
