import {
  AutocompleteInput,
  DateInput,
  ListProps,
  ReferenceInput,
  SelectArrayInput,
} from "ra-ui-materialui";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField } from "../../shared/utils";
import { CalculateChargesButton } from "./components/CalculateChargesButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { BulkActions } from "./components/BulkActions";
import { ChargesList } from "../components/ChargesList";
import { TariffType } from "../../shared/types";
import { PurchaseChargesList } from "../components/ChargesList/PurchaseChargesList";

export const CalculatePurchaseChargesList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsTariff } = useOptions("tariffs", "GET");

  const filters =
    options && optionsTariff
      ? [
        <DateInput
          source="charge_date_after"
          label="Data borderaux dal"
          alwaysOn
          required
        />,
        <DateInput
          source="charge_date_before"
          label="Data borderaux al"
          alwaysOn
          required
        />,
        <ReferenceInput
          {...initField("delivery", options)}
          reference="autocomplete/delivery"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceInputSubject
          {...initField("supplier", options)}
          filter={{ type: "supplier" }}
        />,
        <ReferenceInputSubject
          {...initField("client", options)}
          filter={{ type: "client" }}
        />,
        <SelectArrayInput
          style={{ minWidth: "155px" }}
          choices={optionsTariff.charge_type.choices}
          {...initField("charge_type", optionsTariff, "array")}
        />,
      ]
      : [];

  return (
    <PurchaseChargesList
      {...props}
      Actions={BulkActions}
      filters={filters}
      filter={{
        verified: false,
        no_invoice: true,
        no_page: true,
        tariff_type: TariffType.PURCHASE
      }}
    />
  );
};
