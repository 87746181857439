import { TableBody } from "@material-ui/core";
import classnames from "classnames";
import { cloneElement } from "react";
import { DatagridBodyProps, Identifier } from "react-admin";
import {
  DragDropContext,
  Droppable,
  DragDropContextProps,
} from "react-beautiful-dnd";
import { DndDatagridRow } from "./DndDatagridRow";

const defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [] as Identifier[],
  row: <DndDatagridRow />,
};

export type DndDatagridBodyProps = Omit<
  DatagridBodyProps,
  "onDragStart" | "onDragEnd"
> &
  Omit<DragDropContextProps, "children">;

export const DndDatagridBody = ({
  onBeforeCapture,
  onBeforeDragStart,
  onDragStart,
  onDragUpdate,
  onDragEnd,
  dragHandleUsageInstructions,
  nonce,
  enableDefaultSensors,
  sensors,
  ...rest
}: DndDatagridBodyProps & typeof defaultProps) => {
  const {
    basePath,
    children,
    classes,
    className,
    data,
    expand,
    hasBulkActions = false,
    hover,
    ids,
    onToggleItem,
    resource,
    row,
    rowClick,
    rowStyle,
    selectedIds,
    isRowSelectable,
    ...restTable
  } = rest;
  const propsDnd = {
    onBeforeCapture,
    onBeforeDragStart,
    onDragStart,
    onDragUpdate,
    onDragEnd,
    dragHandleUsageInstructions,
    nonce,
    enableDefaultSensors,
    sensors,
  };
  return (
    <DragDropContext {...propsDnd}>
      <Droppable droppableId="table">
        {(provided) => (
          <TableBody
            className={classnames("datagrid-body", className)}
            {...restTable}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {ids.map((id, rowIndex) =>
              cloneElement(
                row,
                {
                  basePath,
                  classes,
                  className: classnames(classes?.row, {
                    [classes!.rowEven]: rowIndex % 2 === 0,
                    [classes!.rowOdd]: rowIndex % 2 !== 0,
                    [classes!.clickableRow]: rowClick,
                  }),
                  expand,
                  hasBulkActions: hasBulkActions && !!selectedIds,
                  hover,
                  id,
                  key: id,
                  onToggleItem,
                  record: data[id],
                  resource,
                  rowClick,
                  selectable: !isRowSelectable || isRowSelectable(data[id]),
                  selected: selectedIds?.includes(id),
                  style: rowStyle ? rowStyle(data[id], rowIndex) : null,
                  index: rowIndex,
                },
                children
              )
            )}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DndDatagridBody.defaultProps = defaultProps;
