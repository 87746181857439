import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { NoteForm } from "./NoteForm";

export const NoteCreate = (props: CreateProps) => {
  return (
    <CustomCreate component="div" {...props}>
      <NoteForm />
    </CustomCreate>
  );
};
