import { Button, ButtonProps, useListContext, useNotify } from "react-admin";
import DescriptionIcon from "@material-ui/icons/Description";
import { client, clientNoJson } from "../../dataProvider";
import { useState } from "react";
import { url as URL } from "../../constants";
import { CircularProgress } from "@material-ui/core";
import { getUser } from "../../authProvider";
import { generateError } from "../../shared/utils";

export const ResetCachetBulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);

    client(`cachets/reset`, {
      method: "POST",
      data: { ids: selectedIds }
    }).then((response) => {
      notify("Contrassegni azzerati");
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
      })

      ;
    refetch();

  };

  return (
    getUser().is_admin === true ?
      <Button
        {...props}
        disabled={loading || selectedIds.length <= 0}
        label="Azzera contrassegni"
        title="Azzera contrassegni"
        onClick={handleSubmit}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <DescriptionIcon />}
      </Button> : <></>
  );
};
