import { Grid, Typography, Paper, Box } from "@material-ui/core";
import React from "react";

interface StatCardProps {
  label: string | React.ReactElement;
  subtitle: string;
  icon: React.ReactElement;
  color?: string;
}
export const StatCard = (props: StatCardProps) => {
  const { label, subtitle, icon, color } = props;
  return (
    <Paper style={{
      height: "40px", marginBottom: "2px", marginRight: "2px"
    }} title={subtitle} >
      <Box padding={1} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <Grid container alignContent="space-between" alignItems="center">
          <Grid item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            {React.cloneElement(icon,
              {
                style: {
                  fontSize: "2em",
                  color: color ? color : null
                }
              })}
          </Grid>
          <Grid item xs container direction="column" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <Typography align="right" variant="h6">
              {label}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper >
  );
};
