import { Show, ShowProps } from "ra-ui-materialui";
import React from "react";
import { InvoiceItem } from "./InvoiceItem";

export const InvoiceShow = (props: ShowProps) => {
  return (
    <Show {...props} component="div">
      <InvoiceItem />
    </Show>
  );
};
