import * as React from "react";
import { Children, cloneElement, ReactElement } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { EditControllerProps, useEditContext } from "ra-core";
import {
  EditActions as DefaultActions,
  TitleForRecord,
  EditProps,
} from "react-admin";
import { Portal } from "@material-ui/core";

export const CustomEditView = (props: EditViewProps) => {
  const {
    actions,
    aside,
    children,
    classes: classesOverride,
    className,
    component: Content = Card,
    title,
    undoable,
    mutationMode,
    canDelete,
    canEdit,
    fieldOptions = {},
    ...rest
  } = props;

  const classes = useStyles(props);

  const {
    basePath,
    defaultTitle,
    hasList,
    hasShow,
    record,
    resource,
    save,
    saving,
    version,
  } = useEditContext(props);

  const finalActions =
    typeof actions === "undefined" && hasShow ? <DefaultActions /> : actions;
  if (!children) {
    return null;
  }
  return (
    <div
      className={classnames("edit-page", classes.root, className)}
      {...sanitizeRestProps(rest)}
    >
      <TitleForRecord
        title={title}
        record={record}
        defaultTitle={defaultTitle}
      />
      {finalActions && (
        <Portal container={document.getElementById("secondary-app-bar")}>
          {cloneElement(finalActions, {
            basePath,
            data: record,
            hasShow,
            hasList,
            resource,
            //  Ensure we don't override any user provided props
            ...finalActions.props,

            style: {
              backgroundColor: "white",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            },
          })}
        </Portal>
      )}
      <div className={classnames(classes.main)}>
        <Content className={classes.card}>
          {record ? (
            cloneElement(Children.only(children), {
              basePath,
              record,
              redirect:
                typeof children.props.redirect === "undefined"
                  ? "edit"
                  : children.props.redirect,
              resource,
              save:
                typeof children.props.save === "undefined"
                  ? save
                  : children.props.save,
              saving,
              undoable,
              mutationMode,
              version,
              canDelete,
              canEdit,
              fieldOptions,
            })
          ) : (
            <CardContent>&nbsp;</CardContent>
          )}
        </Content>
        {aside &&
          React.cloneElement(aside, {
            basePath,
            record,
            resource,
            version,
            save:
              typeof children.props.save === "undefined"
                ? save
                : children.props.save,
            saving,
          })}
      </div>
    </div>
  );
};

interface EditViewProps
  extends EditProps,
  Omit<EditControllerProps, "resource"> {
  children: ReactElement;
  canDelete: boolean;
  canEdit: boolean;
  fieldOptions: any;
}

CustomEditView.defaultProps = {
  classes: {},
  component: Card,
};

const useStyles = makeStyles(
  {
    root: {},
    main: {
      display: "flex",
    },
    noActions: {
      marginTop: "1em",
    },
    card: {
      flex: "1 1 auto",
    },
  },
  { name: "RaEdit" }
);

const sanitizeRestProps = ({
  basePath = null,
  defaultTitle = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  history = null,
  id = null,
  loaded = null,
  loading = null,
  location = null,
  match = null,
  onFailure = null,
  onFailureRef = null,
  onSuccess = null,
  onSuccessRef = null,
  options = null,
  permissions = null,
  refetch = null,
  save = null,
  saving = null,
  setOnFailure = null,
  setOnSuccess = null,
  setTransform = null,
  successMessage = null,
  transform = null,
  transformRef = null,
  ...rest
}: any) => rest;
