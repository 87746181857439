import {
  Box,
  Card,
  CardHeader,
  Grid,
  Typography,
  CardContent,
  Divider,
  makeStyles,
  CardActions,
  Theme,
} from "@material-ui/core";
import { linkToRecord, useRecordContext, useShowContext } from "ra-core";
import {
  Link,
  TextField,
  ChipField,
  NumberField,
  DateField,
  ReferenceArrayField,
  EditButton,
  ReferenceField,
  FunctionField,
  DeleteWithConfirmButton,
} from "ra-ui-materialui";
import { ReferenceManyField, SingleFieldList } from "react-admin";
import { InvoiceChartAccountsDatagrid } from "../../ChartAccounts/InvoiceChartAccounts";
import { AddInvoiceChartAccountsForm } from "../../ChartAccounts/InvoiceChartAccounts/AddInvoiceChartAccountsForm";
import { useOptions } from "../../shared/hooks/useOptions";
import { TariffType } from "../../shared/types";
import { initField } from "../../shared/utils";
import { AddInvoiceLineForm } from "./AddInvoiceLineForm";
import { DownloadXmlButton } from "./DownloadXmlButton";
import { InvoiceLineDatagrid } from "./InvoiceLineDatagrid/InvoiceLineDatagrid";

const useStyles = makeStyles<Theme, { invoiceType: string }>((theme) => ({
  mainAccordion: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  noEvents: {
    pointerEvents: "none",
  },
  withEvents: {
    pointerEvents: "auto",
  },
  accordionDetails: {
    maxHeight: "400px",
    overflow: "auto",
    flexDirection: "column",
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeader: {
    backgroundColor: (props) =>
      props.invoiceType === "FT"
        ? theme.palette.primary.light
        : theme.palette.secondary.light,
    textTransform: "uppercase",
    fontWeight: 600,
    color: (props) =>
      props.invoiceType === "FT"
        ? theme.palette.primary.main
        : theme.palette.secondary.contrastText,
  },
  chip: {
    color: (props) =>
      props.invoiceType === "FT"
        ? theme.palette.primary.main
        : theme.palette.secondary.contrastText,
    borderColor: (props) =>
      props.invoiceType === "FT"
        ? theme.palette.primary.main
        : theme.palette.secondary.contrastText,
  },
  mainTitle: {
    color: (props) =>
      props.invoiceType === "FT"
        ? theme.palette.primary.main
        : theme.palette.secondary.contrastText,
  },
}));

export const InvoiceItem = () => {
  const { refetch } = useShowContext();
  const record = useRecordContext();

  const classes = useStyles({ invoiceType: record.type });
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const { data: optionsinvoiceLines } = useOptions("invoice-lines", "GET");
  const { data: options } = useOptions("invoices", "GET");
  return options && optionsTariff && optionsinvoiceLines ? (
    <Box mb={"16px"}>
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: "inherit" }}
          title={
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs>
                <Link to={linkToRecord("/subjects", record.client_id, "edit")}>
                  <TextField
                    record={record.client}
                    source="business_name"
                    variant="inherit"
                    className={classes.mainTitle}
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems="center"
                justifyContent="flex-end"
              >
                <ChipField
                  record={{
                    id: options.status.choices.find(
                      (choice: any) => choice.value === record.status
                    ).display_name,
                  }}
                  source="id"
                  variant="outlined"
                  className={classes.chip}
                />
                {record.sequence_number && (
                  <Box ml={2}>
                    <Typography
                      variant={"inherit"}
                    >{`N° ${record.type}-${record.sequence_number}`}</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          }
          className={classes.cardHeader}
        />
        <CardContent className={classes.mainAccordion}>
          <Box padding={2}>
            <Grid container spacing={2} direction="column">
              <Grid container item>
                <Grid item xs container spacing={2}>
                  <Grid item>
                    <Typography>ID:</Typography>
                  </Grid>
                  <Grid item>
                    <NumberField {...initField("id", options)} />
                  </Grid>
                </Grid>
                <Grid item xs container spacing={2}>
                  <Grid item>
                    <Typography>Azienda:</Typography>
                  </Grid>
                  <Grid item>
                    <FunctionField
                      label="Azienda"
                      source="company_object"
                      render={(record: any) => {
                        if (record && record.company_object) {
                          return (
                            <Link to={linkToRecord("/subjects", record.company_object.company.id, "edit")}>
                              <TextField
                                record={record.company_object}
                                source="name"
                                variant="inherit"

                              />
                            </Link>
                          );
                        }
                        return null;
                      }}
                    />
                  </Grid>
                </Grid>

                {record.type === "FT" && (
                  <Grid item xs container spacing={2}>
                    <Grid item>
                      <Typography>Documenti collegati:</Typography>
                    </Grid>
                    <Grid item>
                      <ReferenceManyField
                        label="Documenti collegati"
                        reference="invoices"
                        target="invoice_reference"
                      >
                        <SingleFieldList linkType="show">
                          <FunctionField
                            render={(record: any) => (
                              <ChipField
                                record={{
                                  id: `N° ${record.type}-${record.sequence_number}`,
                                }}
                                source="id"
                              />
                            )}
                          />
                        </SingleFieldList>
                      </ReferenceManyField>
                    </Grid>
                  </Grid>
                )}
                {record.type === "NC" && (
                  <Grid item xs container spacing={2}>
                    <Grid item>
                      <Typography>Fattura di rierimento:</Typography>
                    </Grid>
                    <Grid item>
                      <ReferenceField
                        {...initField("invoice_reference", options)}
                        reference="invoices"
                      >
                        <FunctionField
                          render={(record: any) =>
                            `N° ${record.type}-${record.sequence_number}`
                          }
                        />
                      </ReferenceField>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid container item>
                <Grid item xs container spacing={2}>
                  <Grid item>
                    <Typography>Data fattura:</Typography>
                  </Grid>
                  <Grid item>
                    <DateField {...initField("invoice_date", options)} />
                  </Grid>
                </Grid>
                <Grid item xs container spacing={2}>
                  <Grid item>
                    <Typography>Data scadenza:</Typography>
                  </Grid>
                  <Grid item>
                    <DateField {...initField("expiration_date", options)} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {record.status === "C" ? null : <AddInvoiceLineForm />}

          <ReferenceArrayField
            label="Righe"
            reference="invoice-lines"
            source="invoice_lines"
          >
            <InvoiceLineDatagrid />
          </ReferenceArrayField>

          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="flex-end"
            p={"16px"}
          >
            <Typography variant="subtitle1">TOTALE:</Typography>
            <Box ml={2}>
              <NumberField
                variant="h6"
                {...initField("amount", options)}
                options={{
                  style: "currency",
                  currency: "EUR",
                }}
              />
            </Box>
          </Box>
        </CardContent>

        <Divider />
        <CardActions>
          <Grid container justifyContent="space-between">
            <Grid item xs>
              {record.invoice_type !== TariffType.PURCHASE ?
                (<DownloadXmlButton onSuccess={refetch!} />) :
                null
              }

            </Grid>

            <Grid item xs container justifyContent="flex-end">
              {record.status === "C" ? null : (
                <DeleteWithConfirmButton
                  mutationMode="pessimistic"
                  record={record}
                />
              )}
              <EditButton record={record} />
            </Grid>
          </Grid>
        </CardActions>


        <AddInvoiceChartAccountsForm />

        <ReferenceArrayField
          label="Piano dei conti"
          reference="invoicechartaccounts"
          source="invoice_chart_accounts"
        >
          <InvoiceChartAccountsDatagrid />
        </ReferenceArrayField>

      </Card>
    </Box>
  ) : null;
};
