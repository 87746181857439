import { Datagrid, NumberField, SelectField, TextField } from "react-admin";
import { DriverField } from "../components/DriverField";
import { IdentifierField } from "../components/IdentifierField";
import { DatagridPropsWithOptions } from "../shared/types";
import { initField } from "../shared/utils";

export const VehicleDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <TextField {...initField("plate", options)} />
      <SelectField
        {...initField("status", options, "array")}
        choices={options.status.choices}
      />
      <TextField {...initField("internal_code", options)} />
      <NumberField {...initField("max_weigth", options)} />
      <NumberField {...initField("max_items", options)} />
      <TextField {...initField("description", options)} />
      <DriverField {...initField("default_driver", options)} />
    </Datagrid>
  );
};
