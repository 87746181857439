import { Button, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { FormApi } from "final-form";
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Record,
  TextField,
} from "react-admin";
import { DeliveryField } from "../../components/DeliveryField";
import { DriverField } from "../../components/DriverField";
import { EditableCheckboxField } from "../../components/EditableCheckboxField";
import { EditableDatagrid } from "../../components/EditableDatagrid";
import { EditableDateField } from "../../components/EditableDatagrid/EditableDateField";
import { EditableNumberField } from "../../components/EditableDatagrid/EditableNumberField";
import { EditableTextField } from "../../components/EditableDatagrid/EditableTextField";
import { IdentifierField } from "../../components/IdentifierField";
import { SubjectField } from "../../components/SubjectField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { getResidue, initField } from "../../shared/utils";
import { SaveButtonDatagrid } from "./SaveButtonDatagrid";
import { DeliveryTypeField } from "../../components/DeliveryTypeField";

export const CachetDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  const getInputProps = (source: string, copy: boolean = true) => {
    return (
      record: Record,
      form: FormApi<
        globalThis.Record<string, any>,
        Partial<globalThis.Record<string, any>>
      >
    ) => ({
      style: { minWidth: "11em" },
      endAdornment: copy ? (
        <>
          <Button
            style={{ minWidth: "auto" }}
            color="primary"
            title="Incolla e salva"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.change(source, record.total);
              form.submit();
            }}
          >
            <FileCopyIcon />
          </Button>
          <SaveButtonDatagrid />
        </>
      ) : (<><SaveButtonDatagrid /></>),
    });
  };

  return (
    <EditableDatagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <EditableCheckboxField
        disabled={(record) => getResidue(record) !== 0}
        {...initField("verified", options)}
      />

      <FunctionField
        label="Incassato"
        render={(record: any) => {
          const value = getResidue(record) === 0;
          const color = value ? "green" : "red";
          return (
            <div
              style={{
                backgroundColor: color,
                width: "30px",
                height: "30px",
                borderRadius: "100%",
              }}
            />
          );
        }}
      />
      <DeliveryField
        deliverySource={"delivery.id"}
        {...initField("delivery", options)}
        noTitle={true}
      />
      <DeliveryTypeField
        source="delivery_type" sortBy="cachet__delivery__delivery_type" options={options} label={"Tipo"} />
      <TextField
        {...initField("delivery_status_display", options)}>
      </TextField>
      <ArrayField source="delivery.routes" label="Autista/Corriere -&gt; Giro" sortable={false}>
        <Datagrid header={<></>}>
          <FunctionField
            render={(record: any) => {
              return record && record.route ? (
                <Typography variant="body2">{
                  record.driver ?
                    (record.driver.surname + " " + record.driver.name) :
                    record.carrier
                } -&gt; {record.route} </Typography>
              ) : null
            }
            }
          />
        </Datagrid>
      </ArrayField>
      <TextField
        {...initField("delivery.document_number", options)}
        label="N. documento"
        sortBy="delivery__document_number"
      />
      <DateField
        {...initField("delivery.document_date", options)}
        label="Data documento"
        sortBy="delivery__document_date"
      />
      <SubjectField source="delivery.client" label="Cliente" sortBy="delivery__client__business_name" />
      <SubjectField source="delivery.receiver" label="Destinatario" sortBy="delivery__receiver__business_name" />
      <TextField {...initField("total", options)} />
      <FunctionField
        label="Residuo"
        render={(record: Record | undefined) => (
          <>{record ? getResidue(record).toFixed(2) : null}</>
        )}
        sortable={false}
      />
      <EditableNumberField
        alwaysEditable
        getInputProps={getInputProps("cash")}
        {...initField("cash", options)}
        sortable={false}
      />
      <EditableNumberField
        alwaysEditable
        getInputProps={getInputProps("check")}
        {...initField("check", options)}
        sortable={false}
      />
      <EditableNumberField
        alwaysEditable
        getInputProps={getInputProps("rebate")}
        {...initField("rebate", options)}
        sortable={false}
      />
      {/* <EditableNumberField
        alwaysEditable
        getInputProps={getInputProps("credit_card")}
        {...initField("credit_card", options)}
      /> */}
      <EditableTextField
        alwaysEditable
        getInputProps={getInputProps("note", false)}
        {...initField("note", options)}
      />
      <EditableDateField {...initField("collection_date", options)} />
    </EditableDatagrid>
  );
};
