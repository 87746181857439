import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { PaymentStatusForm } from "./PaymentStatusForm";

export const PaymentStatusEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component="div">
      <PaymentStatusForm />
    </DefaultEdit>
  );
};
