import { Badge, Button, Dialog, Grid, Table, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { client } from "../dataProvider";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import QueueIcon from "@material-ui/icons/Queue";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import TimelineIcon from "@material-ui/icons/Timeline";
import FeedbackIcon from '@material-ui/icons/Feedback';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import { useState } from "react";

export const NotificationIcons = (props: { isOpen: boolean }) => {

  const { isLoading, data } = useQuery(
    [],
    () =>
      client("notifications", {
        method: "GET",
      }),
    {
      initialData: {
        "note_without_delivery": 0,
        "delivery_stocked": 0,
        "delivery_suspended": 0,
        "route_not_left": 0,
        "customer_order_to_schedule": 0,
        "missing_subjects": 0,
        "missing_client_subjects": 0,
      }
    }
  );

  const [openDialog, setOpenDialog] = useState(false)

  const handleClose = (event: any, reason: any) => {
    if (reason && reason == "backdropClick")
      setOpenDialog(false);;

  }

  return (
    <>
      <Dialog open={openDialog} fullWidth maxWidth={'md'}
        onClose={handleClose}
      >
        <h2 style={{ textAlign: "center" }}>Notifiche</h2>
        <Table >
          <TableHead>
            <TableCell>Tipo</TableCell>
            <TableCell>Num</TableCell>
            <TableCell>Link</TableCell>
          </TableHead>
          {
            data["note_without_delivery"] !== 0 ?
              <TableRow>
                <TableCell>Bolle non assegnate</TableCell>
                <TableCell>
                  <Typography color="error">
                    {data["note_without_delivery"]}
                  </Typography>
                </TableCell>
                <TableCell><a target="_blank" href="/notes/not-assigned">Vai</a></TableCell>
              </TableRow>
              : null
          }
          {
            data["delivery_stocked"] !== 0 ?
              <TableRow>
                <TableCell>Giacenze</TableCell>
                <TableCell>
                  <Typography color="error">
                    {data["delivery_stocked"]}
                  </Typography>
                </TableCell>
                <TableCell><a target="_blank" href="/deliveries?displayedFilters=%7B%7D&filter=%7B%22delivery_states%22%3A%5B%22S%22%5D%7D&order=DESC&sort=id">Vai</a></TableCell>
              </TableRow>
              : null
          }
          {
            data["delivery_suspended"] !== 0 ?
              <TableRow>
                <TableCell>Sospese</TableCell>
                <TableCell>
                  <Typography color="error">
                    {data["delivery_suspended"]}
                  </Typography>
                </TableCell>
                <TableCell><a target="_blank" href="/deliveries?displayedFilters=%7B%7D&filter=%7B%22delivery_states%22%3A%5B%22SU%22%5D%7D&order=DESC&sort=id">Vai</a></TableCell>
              </TableRow>
              : null
          }
          {
            data["route_not_left"] !== 0 ?
              <TableRow>
                <TableCell>Giri non partiti</TableCell>
                <TableCell>
                  <Typography color="error">
                    {data["route_not_left"]}
                  </Typography>
                </TableCell>
                <TableCell><a target="_blank" href="/board_routes">Vai</a></TableCell>
              </TableRow>
              : null
          }
          {
            data["customer_order_to_schedule"] !== 0 ?
              <TableRow>
                <TableCell>Ordini in sospeso</TableCell>
                <TableCell>
                  <Typography color="error">
                    {data["customer_order_to_schedule"]}
                  </Typography>
                </TableCell>
                <TableCell><a target="_blank" href="/customer_orders">Vai</a></TableCell>
              </TableRow>
              : null
          }
          {
            data["missing_subjects"] !== 0 ?
              <TableRow>
                <TableCell>Soggetti non risolti</TableCell>
                <TableCell>
                  <Typography color="error">
                    {data["missing_subjects"]}
                  </Typography>
                </TableCell>
                <TableCell><a target="_blank" href="/subjects-solver">Vai</a></TableCell>
              </TableRow>
              : null
          }
          {
            data["missing_client_subjects"] !== 0 ?
              <TableRow>
                <TableCell>Soggetti ordini non risolti</TableCell>
                <TableCell>
                  <Typography color="error">
                    {data["missing_client_subjects"]}
                  </Typography>
                </TableCell>
                <TableCell><a target="_blank" href="/subjects-solver">Vai</a></TableCell>
              </TableRow>
              : null
          }
        </Table>
        <br />
        <Button onClick={() => setOpenDialog(false)}>Chiudi</Button>
      </Dialog>
      {props.isOpen ?
        <Grid container spacing={0} style={{ marginLeft: "1px" }}
          onClick={() => setOpenDialog(true)} >
          {
            (data["note_without_delivery"] !== 0 ?
              < Grid item xs={3} title="Bolle non assegnate">
                <Badge badgeContent={data["note_without_delivery"]} color="primary">
                  <InsertDriveFileIcon />
                </Badge>
              </Grid> : null
            )
          }
          {
            (data["delivery_stocked"] !== 0 ?
              < Grid item xs={3} title="Giacenze">
                <Badge badgeContent={"G:" + data["delivery_stocked"]} color="primary">
                  <QueueIcon />
                </Badge>
              </Grid> : null
            )
          }
          {
            (data["delivery_suspended"] !== 0 ?
              < Grid item xs={3} title="Sospese">
                <Badge badgeContent={"S:" + data["delivery_suspended"]} color="primary">
                  <QueueIcon />
                </Badge>
              </Grid> : null
            )
          }
          {
            (data["route_not_left"] !== 0 ?
              < Grid item xs={3} title="Giri non partiti">
                <Badge badgeContent={data["route_not_left"]} color="primary">
                  <TimelineIcon />
                </Badge>
              </Grid> : null
            )
          }
          {
            (data["customer_order_to_schedule"] !== 0 ?
              < Grid item xs={3} title="Ordini in sospeso">
                <Badge badgeContent={data["customer_order_to_schedule"]} color="primary">
                  <QueueIcon />
                </Badge>
              </Grid> : null
            )
          }
          {
            (data["missing_subjects"] !== 0 ?
              < Grid item xs={3} title="Risolvi soggetti">
                <Badge badgeContent={data["missing_subjects"]} color="primary">
                  <GroupAddIcon />
                </Badge>
              </Grid> : null
            )
          }
          {
            (data["missing_client_subjects"] !== 0 ?
              < Grid item xs={3} title="Risolvi soggetti ordini">
                <Badge badgeContent={data["missing_client_subjects"]} color="primary">
                  <PermContactCalendarIcon />
                </Badge>
              </Grid> : null
            )
          }
        </Grid> :
        < Grid title="Notifiche" onClick={() => setOpenDialog(true)} >
          {
            (
              data["note_without_delivery"] !== 0 ||
              data["delivery_stocked"] !== 0 ||
              data["delivery_suspended"] !== 0 ||
              data["route_not_left"] !== 0 ||
              data["customer_order_to_schedule"] !== 0 ||
              data["missing_subjects"] !== 0 ||
              data["missing_client_subjects"] !== 0
            )
              ?
              <Badge badgeContent={
                data["note_without_delivery"] +
                data["delivery_stocked"] +
                data["delivery_suspended"] +
                data["route_not_left"] +
                data["customer_order_to_schedule"] +
                data["missing_subjects"] +
                data["missing_client_subjects"]
              } color="primary">
                <FeedbackIcon />
              </Badge> : null
          }
        </Grid>
      }
    </>

  );
};
