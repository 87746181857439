import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function useCopyData<T>(
  initialData: T,
  dataToCopy: T
): [T, Dispatch<SetStateAction<T>>] {
  const [actualData, setActualData] = useState<T>(initialData);

  useEffect(() => {
    if (dataToCopy) setActualData(dataToCopy);
  }, [dataToCopy]);

  return [actualData, setActualData];
}
