import {
  ListProps,
  TextInput,
  DateInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../../shared/utils";
import { CustomerImportFileDatagrid } from "./CustomerImportFileDatagrid";

export const CustomerImportFileList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput {...initField("id", options)} />,
    ]
    : [];
  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      //filters={filters}
      {...getDefaultListProps(props)}
    >
      <CustomerImportFileDatagrid options={options} />
    </CustomList>
  ) : null;
};
