import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  DateTimeInput,
  FileField,
  FileInput,
  FormDataConsumer,
  FormWithRedirect,
  FunctionField,
  Identifier,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReferenceInputDriver } from "../../components/reference-inputs/ReferenceInputDriver";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { ReferenceInputVehicle } from "../../components/reference-inputs/ReferenceInputVehicle";
import { FormProps } from "../../shared/types";
import { getFileNameFromContentDisposition, initField } from "../../shared/utils";
import { initInput } from "../../shared/utils";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloseIcon from '@material-ui/icons/Close';
import { SubjectField } from "../../components/SubjectField";
import { clientNoJson } from "../../dataProvider";

let green = "rgb(76, 175, 80)";
let yellow = "rgb(255, 152, 0)";
let red = "#f44336";

export const downloadNote = async (routeId: Identifier, resource?: string) => {
  if (!resource) {
    resource = "notes"
  }
  let res = null;
  try {
    res = await clientNoJson(`/api/delivery-groups/${routeId}/download/`, {
      responseType: "arraybuffer",
      method: "GET",
    });
  } catch (err) {
    return;
  }
  return res;
};

export const saveNoteToFile = async (routeId: Identifier) => {
  const res = await downloadNote(routeId, "delivery-groups");
  if (res) {
    const data = res.data;
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  }
};

export const DeliveryGroupForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"row"}
                spacing={2}
              >
                <Grid item container xs={12} spacing={2}>
                  <Grid item container justifyContent="space-between">
                    <Grid item xs={5}>
                      <Typography variant="h6" gutterBottom>
                        Info principali
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      {
                        props.record.file && (
                          <FunctionField
                            {...initField("file", props.fieldOptions)}
                            label={"Borderaux firmato"}
                            render={(tableRecord: any) => {
                              if (tableRecord.file) {
                                return (
                                  <Button onClick={() => {
                                    saveNoteToFile(tableRecord.id)
                                  }} >
                                    <AssignmentTurnedInIcon style={{ color: green[500] }} />
                                  </Button>);

                              }
                              return (<CloseIcon style={{ color: red[500] }} />);
                            }}
                          />


                        )
                      }

                    </Grid>

                  </Grid>

                  <Grid item xs={6}>
                    <DateTimeInput
                      {...initInput(
                        "created_date",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectInput
                      {...initInput(
                        "type",
                        props.fieldOptions,
                        props.canEdit,
                        "array"
                      )}
                      allowEmpty
                      choices={props.fieldOptions.type.choices}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <ReferenceInputSubject
                      {...initInput(
                        "subject",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      reference="autocomplete/subject"
                      label="Soggetto"
                      allowEmpty
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextInput
                      {...initInput(
                        "notes",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />


                  </Grid>


                </Grid>
              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              //handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card >
      )}
    />
  );
};
