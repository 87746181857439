import { Datagrid, ListProps, TextField, TextInput } from "react-admin";
import { CustomList } from "../components/CustomList";
import { IdentifierField } from "../components/IdentifierField";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../shared/utils";

export const CategoryList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");


  const filters = options
    ? [
      <TextInput
        {...initField("code", options)}
        label="Codice"
      />,

      <TextInput
        {...initField("description", options)}
        label="Descrizione"
      />,
    ]
    : [];

  return options ? (
    <CustomList {...getDefaultListProps(props)}
      filters={filters}
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        <TextField {...initField("code", options)} label="Codice" />
        <TextField {...initField("description", options)} label="Descrizione" />
      </Datagrid>
    </CustomList>
  ) : null;
};
