import { AutocompleteArrayInput, AutocompleteInput, BooleanField, BooleanInput, Button, Datagrid, DateField, DateInput, DateTimeInput, FormWithRedirect, FunctionField, ListProps, NullableBooleanInput, NumberField, NumberInput, ReferenceArrayInput, ReferenceField, ReferenceInput, SaveButton, SelectArrayInput, SelectField, SelectInput, TextField, TextInput, useNotify, useRecordContext, useRefresh } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import LocalAtm from "@material-ui/icons/LocalAtm";
import Euro from "@material-ui/icons/Euro";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Create from "@material-ui/icons/Create";
import IconCancel from "@material-ui/icons/Cancel";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../shared/hooks/useOptions";
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { DeliveryTrackingNumberField } from "../../components/DeliveryTrackingNumberField";
import { DeliveryTrackingField } from "../../components/DeliveryTrackingField";
import { client } from "../../dataProvider";
import { Dialog, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { useToggler } from "../../shared/hooks/useToggler";
import { CarrierAddDeliveryStatusButton } from "./CarrierAddDeliveryStatusButton";
import { CarrierAddDeliveredDeliveryStatusButton } from "./CarrierAddDeliveredDeliveryStatusButton";
import { DeliveryStatusChoice } from "../../shared/types";
import { PrintDocumentsButton } from "../../Distribution/Delivery/PrintDocuments";
import { AdditionalActions } from "./AdditionalActions";
import { getUser } from "../../authProvider";


interface ManualCachetButtonProps {
  refresh: any;
  record: any;
}
export const ManualCachetDeliveryButton = (props: ManualCachetButtonProps) => {
  const { value, setTrue, setFalse } = useToggler();
  const record = useRecordContext();
  const notify = useNotify();
  const onClick = (values: any) => {
    setLoading(true);
    let payments = [];
    let total = 0;
    if (values.total_cash !== null && values.total_cash > 0) {
      payments.push(
        { "payment_type": "CO", "total": values.total_cash, "note": values.note }
      );
      total += values.total_cash;
    }

    if (values.total_check !== null && values.total_check > 0) {
      payments.push(
        { "payment_type": "AS", "total": values.total_check, "note": values.note }
      );
      total += values.total_check;
      if (values.note === undefined || values.note === null || values.note === "") {
        notify("Inserire nota per incassare l'assegno", "error")
        setLoading(false);
        return;
      }
    }

    if (Math.abs(total - record.cachet) >= 0.01) {
      notify("Il totale incassato non corrisponde al contrassegno", "error")
      setLoading(false);
      return;
    }

    client(`carrier_deliveries/${record.id}/set_carrier_cachet_payment`, {
      method: "POST",
      data: {
        "payments": payments,
      },
    })
      .then((data) => {
        props.refresh()

      })
      .catch((error) => {
      }).finally(() => {
        setLoading(false);
      });
  };
  const [loading, setLoading] = useState(false);
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { data: options } = useOptions("deliveries", "GET");

  return record && options && optionsTariffs ? (
    <>
      {
        <Button
          style={{ minWidth: "auto" }}
          color="primary"
          title="Incassa manualmente"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setTrue();
          }}
        >

          <Create />
        </Button>
      }

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="p"
          save={onClick}
          render={({ handleSubmitWithRedirect, form, saving }) => (
            <>
              <DialogContent>
                <Typography align="center"> Consegna {record.id} ({record.document_number}) </Typography>
                <Typography align="center"> Totale contrassegno: {record.cachet} €</Typography>
                <NumberInput source="total_cash" label="Totale contanti" fullWidth />
                <NumberInput source="total_check" label="Totale assegno" fullWidth />
                <TextInput
                  source="note"
                  label="Note"
                  fullWidth
                />


              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};

const rowStyle = (record: any, index: any) => ({
  minHeight: "40px",
  height: "40px",
});

export const CarrierDeliveryList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsDeliveryStates } = useOptions("carrier_deliverystates", "GET");

  const notify = useNotify();
  const refresh = useRefresh();

  const filters = options && optionsDeliveryStates
    ? [
      <TextInput alwaysOn {...initField("document_number", options)}
        className="filter-field-small"
        label="DDT/ID/QR" />,
      <SelectArrayInput
        {...initField("delivery_states", optionsDeliveryStates, "array")}
        choices={optionsDeliveryStates.status.choices}
        alwaysOn
        label="Stato"
      />,
      <NullableBooleanInput {...initField("has_cachet", options)} label="Contrassegno" alwaysOn />,
      <NullableBooleanInput {...initField("has_cachet_completed", options)} label="Contrassegno incassato" alwaysOn />,
      <SelectInput
        {...initField("note_filter", options, "array")}
        choices={[
          { value: "1", display_name: "NESSUNA" },
          { value: "2", display_name: "FINALE E INIZIALE" },
          { value: "3", display_name: "INIZIALE PRESENTE" },
          { value: "4", display_name: "FINALE PRESENTE" },
          { value: "5", display_name: "SOLO FINALE" },
          { value: "6", display_name: "SOLO INIZIALE" },
          { value: "7", display_name: "ALMENO UNA" },
          { value: "8", display_name: "MANCA INIZIALE" },
          { value: "9", display_name: "MANCA FINALE" },
        ]}
        alwaysOn
        className="filter-field-small"
        label="Bolle"
      />,
      <TextInput {...initField("id", options)} />,
      <SelectInput
        {...initField("delivery_type", options, "array")}
        choices={options.delivery_type.choices}
      />,
      <TextInput
        {...initField("starting_point_on_delivery", options)}
        label="Partenza"
      />,
      <TextInput
        {...initField("receiver", options)}
        label="Destinazione"
      />,
      <TextInput
        {...initField("client_business_name", options)}
        label="Cliente (Rag. sociale)"
      />,
      <DateTimeInput
        {...initField("delivery_date_after", options)}
        label="Data missione dal"
      />,
      <DateTimeInput
        {...initField("delivery_date_before", options)}
        label="Data missione fino al"
      />,
      <DateInput
        {...initField("document_date_after", options)}
        label="Data documento dal"
      />,
      <DateInput
        {...initField("document_date_before", options)}
        label="Data documento fino al"
      />,
      <TextInput
        {...initField("province", options)}
        label="Provincia"
      />,
      <TextInput
        {...initField("cash_box", options)}
        label="Cassa"
      />,
      <SelectInput
        {...initField("cachet_type", options, "array")}
        choices={[
          { value: "CO", display_name: "Contanti" },
          { value: "AS", display_name: "Assegno" },
        ]}
        label={"Tipo incasso contrassegno"}
      />,
    ]
    : [];
  if (options && filters && props &&
    props.permissions && props.permissions.includes("distribution.change_delivery")) {
    filters.push(
      <ReferenceInput
        {...initField("carrier", options)}
        filter={{ type: "carrier" }}
        reference="autocomplete/subject"
        label="Vettore"
        className="filter-field-small"
        alwaysOn
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>
    );
  }


  if (options &&
    props &&
    props.permissions &&
    props.permissions.includes('registry.view_subject')) {
    filters.push(
      <ReferenceInputSubject
        {...initField("client", options)}
        filter={{ type: "client" }}
        label="Cliente"
      />
    )
  }


  return options && optionsDeliveryStates ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      shouldExport={getUser().is_admin}
      filterDefaultValues={{
        delivery_states: [DeliveryStatusChoice.DELIVERING]
      }}
      {...getDefaultListProps(props)}
      bulkActionButtons={false}
      additionalActions={<AdditionalActions />}
    >
      <Datagrid rowStyle={rowStyle}>
        {
          props.permissions.includes("distribution.change_delivery") ?
            <IdentifierField
              {...initField("id", options)}
              basePath="deliveries"
            /> :
            <TextField {...initField("id", options)} />
        }
        <FunctionField
          label="Bolle"
          source="note_set"
          render={(tableRecord: any | undefined) => {
            if (tableRecord && tableRecord.note_set) {
              return tableRecord.note_set.map((note: any) => (
                <DownloadNoteFileButton
                  key={note.id}
                  title={note.file}
                  noteId={note.id}
                  final={note.final}
                  resource={"carrier_notes"}
                />
              ));
            }
            return null;
          }}
        />

        <PrintDocumentsButton label="Stampa" />
        <DeliveryTypeField source="delivery_type" options={options} label={"Tipo"} />

        <DateField source="delivery_date" options={options} label={"Data"} />
        <DateField source="document_date" options={options} label={"Data DDT"} />


        <TextField {...initField("document_number", options)} label={"N. DDT"} />
        <SelectField
          {...initField("status", optionsDeliveryStates, "array")}
          choices={optionsDeliveryStates.status.choices}
          sortBy="delivery_last_status__status"
        />
        <CarrierAddDeliveredDeliveryStatusButton />
        <CarrierAddDeliveryStatusButton />
        {/*
        <DeliveryTrackingField
          {...initField("tracking_number", options)} deliverySource="tracking_number"
          label={"Tracking"}
        />
        <FunctionField
          label="Bolle"
          source="note_set"
          render={(tableRecord: any | undefined) => {
            if (tableRecord && tableRecord.note_set) {
              return tableRecord.note_set.map((note: any) => (
                <DownloadNoteFileButton
                  key={note.id}
                  title={note.file}
                  noteId={note.id}
                  final={note.final}
                  resource={"carrier_notes"}
                />
              ));
            }
            return null;
          }}
        />
        */}
        <NumberField {...initField("weight", options)} />
        <NumberField {...initField("packages_number", options)} />
        <NumberField {...initField("cachet", options)} label="Contrass." sortable={false} />
        {
          <FunctionField
            label="Incassi"
            source="cachet"
            render={(tableRecord: any | undefined) => {
              if (tableRecord && tableRecord.cachet) {
                if (tableRecord.cachet_payments !== null && tableRecord.cachet_payments.length > 0) {

                  return (
                    <Grid container style={{ minWidth: "100px" }}>
                      <Grid item sm={4}>
                        <Button
                          style={{ minWidth: "auto" }}
                          color="primary"
                          title="Annulla incassi"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            client("carrier_deliveries/" + tableRecord.id + "/set_carrier_cachet_payment", {
                              method: "POST",
                              data: {
                                "payments": []
                              },
                            })
                              .then(() => {
                                refresh()
                              })
                              .catch((response) =>
                                notify(
                                  "Errore!",
                                  "error"
                                )
                              )
                              .finally();
                          }}
                        >
                          <HighlightOff />
                        </Button>
                      </Grid>
                      <Grid item sm={8}>
                        {
                          tableRecord.cachet_payments.map((payment: any) => (
                            <>
                              <Typography variant="body2" title={payment.note}>
                                {payment.type === "CO" ? "C" : "A"}{payment.note === null || payment.note === "" ? "" : "*"}:{payment.total}
                              </Typography>

                            </>
                          ))
                        }
                      </Grid>
                    </Grid>
                  );
                }
                else {
                  return (<>
                    <Button
                      style={{ minWidth: "auto" }}
                      color="primary"
                      title="Incassa in contanti"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        client("carrier_deliveries/" + tableRecord.id + "/set_carrier_cachet_payment", {
                          method: "POST",
                          data: {
                            "payments": [
                              { "payment_type": "CO", "total": tableRecord.cachet, "note": "" }
                            ]
                          },
                        })
                          .then(() => {
                            refresh()
                          })
                          .catch((response) =>
                            notify(
                              "Errore!",
                              "error"
                            )
                          )
                          .finally();
                      }}
                    >
                      <Euro />
                    </Button>
                    <Button
                      style={{ minWidth: "auto" }}
                      color="primary"
                      title="Incassa con assegno"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        let note = prompt("Inserisci nota con numero assegno");
                        if (note === null) {
                          notify("Inserisci il numero dell'assegno", "error")
                          return;
                        }
                        client("carrier_deliveries/" + tableRecord.id + "/set_carrier_cachet_payment", {
                          method: "POST",
                          data: {
                            "payments": [
                              { "payment_type": "AS", "total": tableRecord.cachet, "note": note }
                            ]
                          },
                        })
                          .then(() => {
                            refresh()
                          })
                          .catch((response) =>
                            notify(
                              "Errore!",
                              "error"
                            )
                          )
                          .finally();
                      }}
                    >
                      <LocalAtm />
                    </Button>
                    <ManualCachetDeliveryButton refresh={refresh} record={tableRecord} />
                  </>);
                }

              }
              return null;
            }}
          />
        }
        {
          <FunctionField
            label="Cassa"
            source="cachet"
            render={(tableRecord: any | undefined) => {
              if (tableRecord && tableRecord.cachet && tableRecord.cachet_payments !== null && tableRecord.cachet_payments.length > 0) {
                return (
                  <Grid container style={{ minWidth: "100px" }}>
                    <Grid item sm={12}>
                      {
                        tableRecord.cachet_payments.map((payment: any) => (
                          <>
                            <Typography variant="body2">
                              {payment.cash_box_id}
                            </Typography>

                          </>
                        ))
                      }
                    </Grid>
                  </Grid>
                );
              }
              return null;
            }}
          />
        }

        {
          /*
          <TextField
            {...initField("starting_point_on_delivery.label", options)}
            label={"Partenza"}
          />
          */
        }
        <TextField {...initField("province", options)}
          label={"Pr. Arrivo"} />
        <TextField
          {...initField("receiver.label", options)}
          label={"Arrivo"}
          sortBy={"receiver__business_name"}
        />

        <TextField
          {...initField("client__business_name", options)}
          label={"Cliente"}
        />

      </Datagrid>
    </CustomList>
  ) : null;
};
