import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { ReasonStatusForm } from "./ReasonStatusForm";

export const ReasonStatusCreate = (props: CreateProps) => {
  return (
    <CustomCreate component="div" {...props}>
      <ReasonStatusForm />
    </CustomCreate>
  );
};
