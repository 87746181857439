import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from "ra-ui-materialui";
import React from "react";

type ReferenceInputChartAccountsProps = Omit<
  ReferenceInputProps,
  "children" | "reference" | "sort"
>;

export const ReferenceInputChartAccounts = (props: ReferenceInputChartAccountsProps) => {
  return (
    <ReferenceInput
      {...(props as ReferenceInputProps)}
      reference="autocomplete/chartaccounts"
      sort={{ field: "code", order: "ASC" }}
      allowEmpty={true}
    >
      <AutocompleteInput optionText="label" />
    </ReferenceInput>
  );
};
