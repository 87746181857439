import {
  ArrayField,
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  NumberField,
  Record,
  ReferenceInput,
  SelectField,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { DialogSelectInput } from "../components/DialogSelectInput";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { SubjectField } from "../components/SubjectField";
import { useOptions } from "../shared/hooks/useOptions";
import { FormProps } from "../shared/types";
import { initField, initInput } from "../shared/utils";
import { NumberInput } from "../components/NumberInput";
import { TariffClientDatagrid } from "../components/TariffClientDatagrid";
export const ChargeForm = (props: FormProps) => {
  const { data: optionsTariffs } = useOptions("tariffs", "GET");

  return optionsTariffs ? (
    <SimpleForm {...props} redirect={false}>
      <BooleanInput
        {...initInput("not_to_be_invoiced", props.fieldOptions, props.canEdit)}
      />
      <DateInput
        {...initInput("charge_date", props.fieldOptions, props.canEdit)}
      />

      <ReferenceInput
        {...initInput("delivery", props.fieldOptions, props.canEdit)}
        reference="autocomplete/delivery"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>

      <ReferenceInputSubject
        {...initInput("client_id", props.fieldOptions, props.canEdit)}
        filter={{ type: "client" }}
      />

      <ReferenceInput
        {...initInput("tariff", props.fieldOptions, props.canEdit)}
        reference="tariffs-client"
      >
        <DialogSelectInput optionText="label">
          <TariffClientDatagrid options={optionsTariffs} />
        </DialogSelectInput>
      </ReferenceInput>
      <NumberInput
        {...initInput("quantity", props.fieldOptions, props.canEdit)}
      />
      <NumberInput
        {...initInput("taxable", props.fieldOptions, props.canEdit)}
      />
      <TextInput {...initInput("causal", props.fieldOptions, props.canEdit)} />
      <TextInput {...initInput("notes", props.fieldOptions, props.canEdit)} />
      {/*disabled only if no permissions*/}

      <BooleanInput
        {...initInput("verified", props.fieldOptions, props.canEdit)}
      />
    </SimpleForm>
  ) : null;
};
