import React from "react";
import { NumberInput as RaNumberInput, NumberInputProps } from "react-admin";
export const NumberInput = (props: NumberInputProps) => {
  return (
    <RaNumberInput
      {...props}
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
    />
  );
};
