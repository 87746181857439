import { Card } from "@material-ui/core";
import { CreateProps } from "react-admin";
import { CustomCreate } from "../components/CustomCreate";
import { ItemForm } from "./ItemForm";

export const ItemCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props} component={Card}>
      <ItemForm />
    </CustomCreate>
  );
};
