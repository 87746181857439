import { BooleanField, BooleanInput, Button, Datagrid, DateField, DateInput, FunctionField, ListProps, NullableBooleanInput, NumberField, ReferenceField, SelectField, SelectInput, TextField, TextInput, useNotify, useRecordContext, useRefresh } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { CustomerSubjectField } from "../../components/CustomerSubjectField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";
import { client } from "../../dataProvider";
import { ItemField } from "../../components/ItemField";
import { SubjectField } from "../../components/SubjectField";
import { CustomFileField } from "../../components/CustomFIleField";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { AcceptOrderBulkButton } from "./AcceptOrderBulkButton";
import { RefuseOrderBulkButton } from "./RefuseOrderBulkButton";
import { PrintPackagesMarkersBulkButton } from "./PrintPackagesMarkersBulkButton";
import { PrintDeliveryPdfBulkButton } from "./PrintDeliveryPdfBulkButton";
import { PrintAllPdfBulkButton } from "./PrintAllPdfBulkButton";
import { PrintBorderauxPdfBulkButton } from "./PrintBorderauxPdfBulkButton";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { PrintPackagesMarkers10x10BulkButton } from "./PrintPackagesMarkers10x10BulkButton";


const CreateDeliveryButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const createDelivery = () =>
    client("customer_orders/order_to_delivery", {
      method: "POST",
      data: {
        customer_order: record.id,
      },
    })
      .then(() => {
        notify("Fatto!");
        window.location.reload();
      })
      .catch((e) => {
        if (e.response.data["detail"])
          notify(e.response.data["detail"], { type: "error" });
        else
          notify("Errore", { type: "error" });
      });
  return <>
    {
      record.status == "C" ?
        <Button label="Crea missione" onClick={createDelivery} /> :
        null
    }
  </>
    ;
};

const RefuseDeliveryButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refuseOrder = () =>
    client("customer_orders/refuse_order", {
      method: "POST",
      data: {
        customer_order: record.id,
      },
    })
      .then(() => {
        notify("Fatto!");
        window.location.reload();
      })
      .catch((e) => {
        if (e.response.data["detail"])
          notify(e.response.data["detail"], { type: "error" });
        else {
          notify("Errore", { type: "error" });
        }
      });
  return <>
    {
      record.status == "C" ?
        <Button label="Rifiuta ordine" onClick={refuseOrder} /> :
        null
    }
  </>
};


export const CustomerOrderList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");

  const filters = options
    ? [
      <TextInput {...initField("id", options)} />,
      <SelectInput
        {...initField("delivery_type", options, "array")}
        choices={options.delivery_type.choices}
      />,
      <SelectInput
        {...initField("status", options, "array")}
        choices={options.status.choices}
      />,
      <NullableBooleanInput
        {...initField("printed_all", options)}
        label="Stampato tutto"
      />,
      <TextInput {...initField("document_number", options)} />,
      <DateInput
        {...initField("delivery_date_after", options)}
        label="Data missione dal"
      />,
      <DateInput
        {...initField("delivery_date_before", options)}
        label="Data missione fino al"
      />,
      <TextInput
        {...initField("province_start", options)}
        label="Province partenza"
      />,
      <TextInput
        {...initField("province", options)}
        label="Province arrivo"
      />,
      <NullableBooleanInput
        {...initField("printed_borderaux", options)}
        label="Stampato borderaux"
      />,
      <NullableBooleanInput
        {...initField("printed_ddt", options)}
        label="Stampato DDT"
      />,
      <NullableBooleanInput
        {...initField("printed_markers", options)}
        label="Stampati Segnacolli"
      />,
      <DateInput
        {...initField("document_date_exact", options)}
        label="Data documento"
      />,
    ]
    : [];

  if (options &&
    props &&
    props.permissions &&
    props.permissions.includes('registry.view_subject')) {
    filters.push(
      <ReferenceInputSubject
        {...initField("client", options)}
        filter={{ type: "client" }}
        label="Cliente"
      />
    )
  }

  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      shouldExport={false}
      filterDefaultValues={
        props.permissions.includes("distribution.change_delivery") && { status: "C" }
        //props.permissions.includes("distribution.change_delivery") && { status: "C", printed_all: false }
      }
      {...getDefaultListProps(props)}
      bulkActionButtons={
        <>
          {
            props.permissions.includes("distribution.change_delivery") &&
            <AcceptOrderBulkButton />
          }
          {
            props.permissions.includes("distribution.change_delivery") &&
            <RefuseOrderBulkButton />
          }
          <PrintPackagesMarkers10x10BulkButton />
          <PrintPackagesMarkersBulkButton />
          <PrintDeliveryPdfBulkButton />
          <PrintBorderauxPdfBulkButton />
          <PrintAllPdfBulkButton />
        </>
      }
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />

        <CustomFileField
          {...initField("file_info", options)}
          title="name"
          src="url"
        />
        {
          /*
          props.permissions.includes("distribution.change_delivery") &&
          <CreateDeliveryButton />
          */
        }
        {
          /*
          props.permissions.includes("distribution.change_delivery") &&
          <RefuseDeliveryButton />
          */
        }
        {
          props.permissions.includes("distribution.change_delivery") &&
          <SubjectField
            {...initField("client_object", options)}
          />
        }
        <TextField {...initField("delivery_status", options)} />
        <TextField {...initField("document_number", options)} />
        <DateField {...initField("delivery_date", options)} label="Data documento" />
        <FunctionField
          label="Tracking"
          source="delivery_id"
          render={(tableRecord: any | undefined) => {
            if (tableRecord && tableRecord.delivery_id) {
              //open tracking page
              return (
                <a href={"/customer_deliveries?displayedFilters={\"id\"%3Atrue}&filter={\"id\"%3A\"" + tableRecord.delivery_id + "\"}"} target="_blank">
                  <LocalShippingIcon />
                </a>
              );
            }
            return null;
          }}
        />
        <TextField {...initField("province_start", options)} />
        <TextField {...initField("province", options)} />
        <CustomerSubjectField
          {...initField("starting_point_on_delivery_object", options)}
        />
        <CustomerSubjectField
          {...initField("receiver_object", options)}
        />
        <NumberField {...initField("weight", options)} />
        <NumberField {...initField("packages_number", options)} />
        <BooleanField {...initField("printed_borderaux", options)} />
        <BooleanField {...initField("printed_ddt", options)} />
        <BooleanField {...initField("printed_markers", options)} />

      </Datagrid>
    </CustomList>
  ) : null;
};
