import { FilledInputProps, OutlinedInputProps } from "@material-ui/core";
import {
  DateField,
  DateInput,
  FieldProps,
  FunctionField,
  InputProps,
  Record,
  SelectInput,
} from "react-admin";
import { useStore } from "./store";
import { useForm } from "react-final-form";
import { FormApi } from "final-form";

export const EditableSelectField = (
  props: FieldProps & {
    source: string;
    choices: any;
    getInputProps?: (
      record: Record,
      form: FormApi<
        globalThis.Record<string, any>,
        Partial<globalThis.Record<string, any>>
      >
    ) =>
      | Partial<InputProps>
      | Partial<FilledInputProps>
      | Partial<OutlinedInputProps>
      | undefined;
  }
) => {
  const { editable } = useStore();
  const form = useForm();
  const { getInputProps, ...rest } = props;
  return editable ? (
    <FunctionField
      {...rest}
      render={(record: Record | undefined) => (
        <SelectInput
          {...rest}
          onClick={(e) => e.stopPropagation()}
          choices={rest.choices}
          className="filter-field-small"
          label=""
          InputProps={
            getInputProps && record ? getInputProps(record, form) : undefined
          }
        />
      )}
    />
  ) : (
    <SelectInput {...rest} disabled />
  );
};
