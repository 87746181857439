import { Grid, Typography } from "@material-ui/core";
import { DatagridProps, NumberField, useListContext, useResourceContext } from "react-admin";
import { useQuery } from "react-query";
import { client, getRealResource } from "../../dataProvider";
import { useListQueryParams } from "../../shared/hooks/useListQueryParams";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import MoneyIcon from "@material-ui/icons/Money";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { StatCard } from "../../components/StatCard";
import { NewCachetBoxButton } from "./NewCachetBoxButton";
import { useEffect, useMemo, useState } from "react";

export const CachetListHeader = (props: DatagridProps) => {
  const resource = useResourceContext();
  const url = `${getRealResource(resource)}/statistics`;
  const { selectedIds, data, filterValues, filter, ids } = useListContext(props);
  const [stats2, setStats2] = useState({
    total_selected_cashed: 0
  } as any);

  useEffect(() => {
    if (data) {
      let tempsManualStats = {
        total_selected_cashed: 0
      }
      for (let sId of selectedIds) {
        tempsManualStats.total_selected_cashed += data[sId].total;
      }
      setStats2(tempsManualStats);
    }
  },
    [selectedIds])

  const query = useListQueryParams();

  const { data: stats } = useQuery(
    [url, query, filterValues, filter],
    () =>
      client(url, {
        method: "GET",
        params: {
          ...query,
          ...filter,
          ...filterValues,
        },
        paramsSerializer: function handleQuery(q: any) {
          return Object.entries(q).map(([key, value], i) => Array.isArray(value) ? `${key}=${value.join('&' + key + '=')}` : `${key}=${value}`).join('&');
        }
      }),
    {
      initialData: {
        total_cashed: 0,
        total_cash: 0,
        total_check: 0,
        total_rebate: 0,
        total_to_cash_in: 0,
        cachet_box: {
          "created_date": "",
          "id": "",
          "user": ""
        }
      },
    }
  )

  return stats && stats.cachet_box ? (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <NewCachetBoxButton cachetBox={{ ...stats.cachet_box }} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="total_cashed"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
          }
          icon={<AttachMoneyIcon />}
          subtitle="Totale incassato"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="total_cash"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
          }
          icon={<MoneyIcon />}
          subtitle="Incassato in contanti"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="total_check"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
          }
          icon={<AccountBalanceIcon />}
          subtitle="Incassato con assegni"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="total_rebate"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
          }
          icon={<AccountBalanceIcon />}
          subtitle="Totale Abbuono"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="total_to_cash_in"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
          }
          icon={<MoneyOffIcon />}
          subtitle="Totale da incassare"
        />
      </Grid>

      {stats2.total_selected_cashed ? (
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <StatCard
              label={
                <NumberField record={{
                  id: 0,
                  total_selected_cashed: stats2.total_selected_cashed
                }}
                  source="total_selected_cashed"
                  variant="inherit"
                  options={{
                    style: "currency",
                    currency: "EUR",
                  }}
                />
              }
              icon={<AttachMoneyIcon />}
              subtitle="Totale incassato della selezione"
            />
          </Grid>

        </Grid>
      ) : null
      }

    </Grid>
  ) : null;
};
