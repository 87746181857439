// botton crea riconsegna
import { Dialog, DialogContent, DialogActions, Typography } from "@material-ui/core";
import { useState } from "react";
import {
  useRecordContext,
  Button,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  useNotify,
  useRedirect,
} from "react-admin";
import { useToggler } from "../../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";
import { useOptions } from "../../shared/hooks/useOptions";
import MapIcon from '@material-ui/icons/Map';
import { client } from "../../dataProvider";
import { generateError } from "../../shared/utils";
import { DeliveryType } from "../../shared/types";


export const NewToPlanDeliveryStatusButton = () => {
  const { value, setTrue, setFalse } = useToggler();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const onClick = (values: any) => {
    setLoading(true);

    client(`deliveries/${record.id}/new_to_plan`, {
      method: "POST",
      data: values,
    })
      .then((data) => {
        if (data.new_delivery) {
          const url = `/deliveries/${data.new_delivery}`;
          window.open(url);
          window.location.reload();
        }
        else {
          notify("Errore!", "error");
        }
        setFalse();

      })
      .catch((error) => {
        setLoading(false);
        generateError(error, notify);
      });
  };
  const [loading, setLoading] = useState(false);
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { data: options } = useOptions("deliveries", "GET");

  return options && record ? (
    <>
      {
        record.status && (
          record.status === "S" || (
            record.status === "SU" && record.delivery_type === DeliveryType.WITHDRAW
          ))
        &&
        <Button
          disabled={!!!record?.client}
          onClick={(e) => {
            e.stopPropagation();
            setTrue();
          }}
          label={"Ripianifica"}
        ><MapIcon /></Button>
      }

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          initialValues={{}}
          save={onClick}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <Typography>Sicuro di voler {record.status === "S" ? "terminare la giacenza e " : ""} ripianificare la missione?</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
