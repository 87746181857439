import {
  Button,
  ButtonProps,
  useListContext,
  useNotify,
  useRecordContext,
} from "react-admin";
import PublishIcon from "@material-ui/icons/Print";
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition } from "../../shared/utils";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { getUser } from "../../authProvider";

export const PrintDeliveryFinalNotesBulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    for (let i = 0; i < (Math.floor(selectedIds.length / 50) + 1); i++) {
      let res = null;
      try {
        res = await clientNoJson(`${URL}/print_delivery_final_notes/`, {
          method: "POST",
          data: {
            ids: selectedIds,
            mission_max_page: i
          },
          responseType: "arraybuffer",
        });
      } catch (err) {
        notify("ra.message.error", "error");
        setLoading(false);
        return;
      }
      if (i === Math.floor(selectedIds.length / 50)) {
        setLoading(false);
      }
      const data = res.data; // or res.blob() if using blob responses

      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: res.headers["content-type"],
        })
      );
      const actualFileName = getFileNameFromContentDisposition(
        res.headers["content-disposition"]
      );

      // uses the download attribute on a temporary anchor to trigger the browser
      // download behavior. if you need wider compatibility, you can replace this
      // part with a library such as filesaver.js
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", actualFileName);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) link.parentNode.removeChild(link);
      try {
        refetch();
      }
      catch (e) {

      }

    }


  };

  return (
    <Button
      {...props}
      disabled={loading}
      label="Pdf firme"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <PublishIcon />}
    </Button>
  );
};
