import React, { useEffect, useState } from "react";
import { SelectField } from "react-admin";
import { DeliveryType } from "../shared/types";
import { initField } from "../shared/utils";

export const DeliveryStatusSelectField = (props: any) => {


  const choicesNormal = [
    { value: "I", display_name: "Da pianificare" },
    { value: "G", display_name: "Pianificata" },
    { value: "T", display_name: "In Transito" },
    { value: "S", display_name: "In giacenza" },
    { value: "SE", display_name: "Fine giacenza" },
    { value: "L", display_name: "Caricata" },
    { value: "ID", display_name: "In Consegna" },
    { value: "D", display_name: "Consegnata" },
    { value: "SU", display_name: "Sospesa" },
    { value: "CA", display_name: "Annullata" }]


  const choicesWithdraw = [
    { value: "I", display_name: "Da pianificare" },
    { value: "G", display_name: "Pianificata" },
    { value: "T", display_name: "In Transito" },
    { value: "S", display_name: "In giacenza" },
    { value: "SE", display_name: "Fine giacenza" },
    { value: "L", display_name: "Caricata" },
    { value: "ID", display_name: "In ritiro" },
    { value: "D", display_name: "Ritirato" },
    { value: "SU", display_name: "Sospesa" },
    { value: "CA", display_name: "Annullata" }]


  const [choices, setChoices] = useState(choicesNormal);



  useEffect(() => {
    if (props.record) {
      if (props.record.delivery_type === DeliveryType.WITHDRAW ||
        props.record.delivery_type === DeliveryType.BACK) {
        setChoices(choicesWithdraw)
      }
      else {
        setChoices(choicesNormal)
      }
    }
  }, [props.record])

  return (
    props.optionsDeliveryStates ?
      <SelectField
        {...initField(
          props.status_field,
          props.optionsDeliveryStates,
          "array"
        )}
        choices={choices}
      /> : null
  );
};
