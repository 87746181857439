import { Datagrid, DateField, ListProps, TextField } from "react-admin";
import { CustomList } from "../components/CustomList";
import { SubjectField } from "../components/SubjectField";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../shared/utils";
import { UndoSplitAndDeleteButton } from "./UndoSplitAndDelete";

export const SplitSegmentList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");

  return options ? (
    <CustomList {...getDefaultListProps(props)}
      shouldExport={false}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField {...initField("id", options)} />
        <SubjectField {...initField("point", options)} label={"Punto di split"} />
        < DateField showTime {...initField("created_date", options)} label={"Data creazione"} />
        <UndoSplitAndDeleteButton />
      </Datagrid>
    </CustomList>
  ) : null;
};
