import { EditProps } from "react-admin";
import { DefaultEdit } from "../components/DefaultEdit";
import { VehicleForm } from "./VehicleForm";

export const VehicleEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component="div">
      <VehicleForm />
    </DefaultEdit>
  );
};
