import {
  useRecordContext,
  useListContext,
  Identifier,
  ListBase,
  Pagination,
  FilterButton,
  ListToolbar,
  TopToolbar,
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SelectArrayInput,
} from "react-admin";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";
import { ChargeDatagrid } from "./ChargeDatagrid";

interface ExpandedInvoiceLineProps {
  selectedCharges: Identifier[];
  onToggleCharge: (id: Identifier) => void;
  onSelectCharge: (ids: Identifier[], allCharges: Identifier[]) => void;
  chargesDatagridHasBulkActions: boolean;
}
export const ExpandedInvoiceLine = (props: ExpandedInvoiceLineProps) => {
  const record = useRecordContext();
  const { data: options } = useOptions("charges", "GET");

  //dont show nothing if loading in background, useful when deleting a reference resource
  const { loading } = useListContext();

  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const filters =
    options && optionsTariff
      ? [
          <DateInput source="charge_date_after" label="Dal" />,
          <DateInput source="charge_date_before" label="Al" />,
          <ReferenceInput
            {...initField("delivery", options)}
            reference="autocomplete/delivery"
          >
            <AutocompleteInput optionText="label" />
          </ReferenceInput>,
          <SelectArrayInput
            style={{ minWidth: "155px" }}
            choices={optionsTariff.charge_type.choices}
            {...initField("charge_type", optionsTariff, "array")}
          />,
        ]
      : [];
  return options && !loading ? (
    <ListBase
      resource="charges"
      syncWithLocation={false}
      basePath="/charges"
      filterDefaultValues={{ invoice_line: record.id }}
      perPage={25}
    >
      <ListToolbar
        filters={filters}
        actions={
          <TopToolbar>
            <FilterButton />
          </TopToolbar>
        }
      />

      <ChargeDatagrid
        hasBulkActions={props.chargesDatagridHasBulkActions}
        selectedCharges={props.selectedCharges}
        onToggleCharge={props.onToggleCharge}
        onSelectCharge={props.onSelectCharge}
      />
      <Pagination />
    </ListBase>
  ) : null;
};
