import { makeStyles, useTheme } from "@material-ui/core";

import {
  Identifier,
  ListContextProvider,
  useList,
  useListContext,
} from "ra-core";
import { useEffect, useMemo, useState } from "react";
import { Datagrid, NumberField, Record } from "react-admin";
import { Group } from "../../types";
import _ from "lodash";
import { SubjectField } from "../../../components/SubjectField";
import { CustomPagination } from "../../../components/CustomPagination";
import { PurchaseChargesDatagrid } from "./PurchaseChargesDatagrid";

const useStyles = makeStyles((theme) => ({
  headerCell: {
    backgroundColor: "white",
    color: theme.palette.secondary.dark,
  },
}));

interface SupplierGroupedChargeDatagridProps {
  setActiveCharge: (charge: Record | undefined) => void;
  activeCharge?: Record;
  rowStyle: (record: Record, index: number) => any;
  Actions: React.ElementType;
}

export const SupplierGroupedChargeDatagrid = (
  props: SupplierGroupedChargeDatagridProps
) => {
  const { setActiveCharge, rowStyle, Actions, activeCharge } = props;
  const { ids, data, loading, loaded } = useListContext();
  const classes = useStyles();
  const [groupedData, setGroupedData] = useState<Group[]>([]);
  const [selectedCharges, setSelectedCharges] = useState<Identifier[]>([]);
  const listContext = useList({
    data: groupedData,
    ids: groupedData.map((group) => group.id),
    loading: loading,
    loaded: loaded,
  });
  const theme = useTheme();
  useEffect(() => {
    const listData = ids.map((id) => data[id]);

    const groupCharges = (data: Record[]) => {
      const groupedData: Group[] = [];
      let color = "white";
      const groupedBySupplier = _.groupBy<Record>(data, "supplier_id");
      _.forEach(groupedBySupplier, function (value, supplierKey) {
        const supplier_charges = {
          supplier: value[0]["supplier"],
          client: null,
          id: supplierKey,
          charges: value
            .sort((a: Record, b: Record) => {
              if (a.delivery < b.delivery) {
                return -1;
              }
              if (a.delivery > b.delivery) {
                return 1;
              }
              // a must be equal to b
              return 0;
            })
            .map((charge, index, data) => {
              if (
                index - 1 >= 0 &&
                data[index - 1].delivery !== charge.delivery
              ) {
                color = color === "white" ? theme.palette.grey[200] : "white";
              }
              return { ...charge, color };
            }),
          total: value.reduce((sum, charge) => sum + charge.taxable, 0),
        };
        groupedData.push(supplier_charges);
      });
      return groupedData;
    };
    setGroupedData(groupCharges(listData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, ids]);

  const onSelect = (ids: Identifier[]) => {
    setSelectedCharges(
      ids.reduce((chargeIdList, id) => {
        listContext.data[id].charges.forEach((charge: Record) =>
          chargeIdList.push(charge.id)
        );
        return chargeIdList;
      }, [] as Identifier[])
    );
  };

  const onToggleItem = (id: Identifier) => {
    const chargesIdSupplier = ids.reduce((chargeIdList, chargeId) => {
      if (data[chargeId].supplier_id.toString() === id.toString())
        chargeIdList.push(chargeId);
      return chargeIdList;
    }, [] as Identifier[]);
    const inSelectedCharges = chargesIdSupplier.every((id) =>
      selectedCharges.includes(id)
    );
    setSelectedCharges((old) => {
      const newSelectedCharges = inSelectedCharges
        ? old.filter((chargeId) => !chargesIdSupplier.includes(chargeId))
        : [...old, ...chargesIdSupplier];
      return newSelectedCharges;
    });
  };

  const selectedSuppliers = useMemo(
    () =>
      listContext.ids.filter((id) => {
        const record = listContext.data[id] as Group;
        return record.charges.every((charge) =>
          selectedCharges.includes(charge.id)
        );
      }),
    [listContext.data, listContext.ids, selectedCharges]
  );
  useEffect(() => {
    const getNext = (id: Identifier, charges: Record[]) => {
      const index = charges.findIndex((charge) => charge.id === id);
      const nextIndex = index + 1 < charges.length ? index + 1 : index;
      return charges[nextIndex];
    };

    let activeCharge: Record | undefined = undefined;
    if (selectedCharges.length > 0) {
      const lastSelectedCharge = selectedCharges[selectedCharges.length - 1];
      const charges = listContext.ids.reduce((charges, id) => {
        charges.push(...listContext.data[id].charges);
        return charges;
      }, [] as Record[]);

      activeCharge = getNext(lastSelectedCharge, charges);
    }
    setActiveCharge && setActiveCharge(activeCharge);
  }, [listContext.data, listContext.ids, selectedCharges, setActiveCharge]);

  return (
    <ListContextProvider value={listContext}>
      <Actions
        selectedCharges={selectedCharges}
        onUnselect={() => onSelect([])}
      />

      <Datagrid
        hasBulkActions
        onSelect={onSelect}
        classes={classes}
        selectedIds={selectedSuppliers}
        onToggleItem={onToggleItem}
        expand={
          <PurchaseChargesDatagrid
            selectedCharges={selectedCharges}
            setSelectedCharges={setSelectedCharges}
            setActiveCharge={setActiveCharge}
            rowStyle={rowStyle}
            activeCharge={activeCharge}
          />
        }
      >
        <SubjectField sortable={false} source="supplier" label="Fornitore" />
        <NumberField
          sortable={false}
          source="total"
          label="Totale imponibile"
        />
      </Datagrid>
      <CustomPagination />
    </ListContextProvider>
  );
};
