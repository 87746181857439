import { TableBody, TableCell, TableFooter, TableRow } from "@material-ui/core";
import classnames from "classnames";
import React, { FC, cloneElement, isValidElement, useCallback } from "react";
import {
  Datagrid,
  DatagridBodyProps,
  DatagridProps,
  DatagridRow,
} from "react-admin";

export const DatagridBody: FC<DatagridBodyProps & { totalFields: string[] }> =
  React.forwardRef(
    (
      {
        totalFields,
        basePath,
        children,
        classes,
        className,
        data,
        expand,
        hasBulkActions,
        hover,
        ids,
        onToggleItem,
        resource,
        row,
        rowClick,
        rowStyle,
        selectedIds,
        isRowSelectable,
        ...rest
      },
      ref
    ) => {
      const getTotal = useCallback(
        (source: string) => {
          return ids!
            .reduce((accumulator: number, id) => {
              return accumulator + data![id][source];
            }, 0)
            .toFixed(2);
        },
        [data, ids]
      );
      return (
        <>
          <TableBody
            ref={ref}
            className={classnames("datagrid-body", className)}
            {...rest}
          >
            {ids!.map((id, rowIndex) =>
              cloneElement(
                row!,
                {
                  basePath,
                  classes,
                  className: classnames(classes!.row, {
                    [classes!.rowEven]: rowIndex % 2 === 0,
                    [classes!.rowOdd]: rowIndex % 2 !== 0,
                    [classes!.clickableRow]: rowClick,
                  }),
                  expand,
                  hasBulkActions: hasBulkActions && !!selectedIds,
                  hover,
                  id,
                  key: id,
                  onToggleItem,
                  record: data![id],
                  resource,
                  rowClick,
                  selectable: !isRowSelectable || isRowSelectable(data![id]),
                  selected: selectedIds?.includes(id),
                  style: rowStyle ? rowStyle(data![id], rowIndex) : null,
                },
                children
              )
            )}
          </TableBody>
          <TableFooter
            style={{
              left: 0,
              bottom: 0, // <-- KEY
              zIndex: 2,
              backgroundColor: "white",
              position: "sticky",
            }}
          >
            <TableRow>
              {hasBulkActions && <TableCell />}
              {React.Children.map(children, (field, index) => {
                return isValidElement(field) &&
                  totalFields.includes((field.props as any).source) ? (
                  <TableCell
                    className={field.props.cellClassName}
                    align={field.props.textAlign}
                    key={`footer-${(field.props as any).source || index}`}
                  >
                    {getTotal((field.props as any).source)}
                  </TableCell>
                ) : (
                  <TableCell />
                );
              })}
            </TableRow>
          </TableFooter>
        </>
      );
    }
  );
DatagridBody.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  row: <DatagridRow />,
};

interface DatagridWithTotalsProps extends DatagridProps {
  totalFields: string[];
}
export const DatagridWithTotals = (props: DatagridWithTotalsProps) => {
  const { totalFields, ...rest } = props;

  return (
    <Datagrid {...rest} body={<DatagridBody totalFields={totalFields} />} />
  );
};
