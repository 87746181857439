import { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';

export const BooleanPartialFieldCustom = (props: any) => {
    const [partial, setPartial] = useState(false);

    useEffect(() => {
        setPartial(props.props.partial);
    }, [props])

    return partial ? (<span style={{ color: "red" }}><b>SI</b></span>) : (<span></span>);
}

BooleanPartialFieldCustom.defaultProps = { label: 'Consegna parziale' };
