import { CreateProps } from "react-admin";
import { CustomCreate } from "../components/CustomCreate";
import { SubjectForm } from "./SubjectForm";

export const SubjectCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props}>
      <SubjectForm />
    </CustomCreate>
  );
};
