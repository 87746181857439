import { Button, ButtonProps, useListContext, useNotify } from "react-admin";
import FeaturedVideoIcon from "@material-ui/icons/FeaturedVideo";
import { client, clientNoJson } from "../../dataProvider";
import { useState } from "react";
import { url as URL } from "../../constants";
import { CircularProgress } from "@material-ui/core";
import { getFileNameFromContentDisposition } from "../../shared/utils";

export const PrintPackagesMarkers10x10BulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;

    try {
      res = await clientNoJson(`${URL}/customer_orders/print_packages_markers_10x10/`, {
        method: "POST",
        data: { ids: selectedIds },
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
    refetch();

  };

  return (
    <Button
      {...props}
      disabled={loading || selectedIds.length <= 0}
      label="Stampa segna colli 10x10"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <FeaturedVideoIcon />}
    </Button>
  );
};
