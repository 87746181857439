import { setSidebarVisibility } from "ra-core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export function useClosedSidebar() {
  const dispatch = useDispatch();

  return useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);
}
