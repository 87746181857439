import {
  BooleanInput,
  BulkActionProps,
  BulkDeleteButton,
  DateInput,
  ListProps,
  SelectInput,
} from "ra-ui-materialui";
import { CustomList } from "../components/CustomList";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../shared/utils";
import { NumberInput } from "../components/NumberInput";
import { InvoiceDatagrid } from "./InvoiceDatagrid";
import { useOptions } from "../shared/hooks/useOptions";
import { TariffType } from "../shared/types";
import { InvoiceListHeader } from "./InvoiceListHeader";
import { ReferenceInputAccountCompany } from "../components/reference-inputs/ReferenceInputAccountCompany";
import { BulkActions } from "./BulkActions";
import { AutocompleteArrayInput, AutocompleteInput, NullableBooleanInput, ReferenceArrayInput, ReferenceInput } from "react-admin";


export const InvoiceList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsTerms } = useOptions("payment-terms", "GET");

  const filters =
    options && optionsTerms
      ? [
        <ReferenceInputAccountCompany
          {...initField("company", options)}
          alwaysOn
        />,
        <DateInput source="invoice_date_after" label="Dal" />,
        <DateInput source="invoice_date_before" label="Al" />,
        <DateInput source="expiration_date_after" label="Scadenza dal" />,
        <DateInput source="expiration_date_before" label="Scadenza al" />,
        <NumberInput {...initField("sequence_number", options)} />,
        <NumberInput {...initField("id", options)} />,
        <NullableBooleanInput
          fullWidth
          source="fuel"
          alwaysOn
        />,

        <ReferenceInputSubject
          filter={{ type: "client" }}
          {...initField("client", options)}
          alwaysOn
        />,
        <ReferenceInput
          {...initField("delivery", options)}
          reference="autocomplete/delivery"
          alwaysOn
          className="filter-field-small"
          label="Missione"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <SelectInput
          choices={options.type.choices}
          {...initField("type", options, "array")}
        />,
        <SelectInput
          choices={options.status.choices}
          {...initField("status", options, "array")}
        />,
        <SelectInput
          choices={optionsTerms.method.choices}
          {...initField("payment_method", optionsTerms, "array")}
          label="Metodo di pagamento"
        />,
        <NumberInput
          {...initField("sequencerange_min", options)}
          label="da N° Ft."
        />,
        <NumberInput
          {...initField("sequencerange_max", options)}
          label="a N° Ft."
        />,
        <ReferenceArrayInput
          reference="items"
          defaultValue={[]}
          source={"items"}
          label="Articoli"
          sort={{ "field": "label", "order": "ASC" }}
          className="filter-field-small">
          <AutocompleteArrayInput optionText="label" fullWidth />
        </ReferenceArrayInput>,
      ]
      : [];

  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      filter={{ "invoice_type": TariffType.SALE }}
      {...getDefaultListProps(props)}
      topElement={<InvoiceListHeader />}
      bulkActionButtons={<BulkActions {...props} />}
      filterDefaultValues={{ company: 1 }}
    >
      <InvoiceDatagrid
        options={options}
      //isRowSelectable={(record) => record.status !== "C"}
      />
    </CustomList>
  ) : null;
};
