import { Button, ButtonProps, Grid, Typography } from "@material-ui/core";
import React from "react";
import { AutocompleteInput, BooleanInput, FormDataConsumer, ReferenceInput, SimpleForm, TextInput, useNotify } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { client } from "../../dataProvider";
import { useForm, useFormState } from "react-final-form";

const ValidateAddressButton = (props: ButtonProps) => {
  const { values } = useFormState();
  const form = useForm();
  const notify = useNotify();
  return (
    <Button
      {...props}
      onClick={() => {
        if (values.locality || values.province || values.postal_code) {
          const url = `autocomplete/address_info`;
          client(url, {
            method: "GET",
            params: {
              postal_code: values.postal_code,
              province: values.province,
              locality: values.locality,
            },
          })
            .then((response) => {
              form.change("locality", response.locality);
              form.change("province_setter", response.province_id);
              form.change("postal_code", response.postal_code);
            })
            .catch(() => notify("Errore!", "error"));
        } else {
          notify(
            "Inserire almeno un dato tra localita, cap e provincia, prima di validare",
            "error"
          );
        }
      }}
    >
      Valida indirizzo
    </Button>
  );
};
export const CustomerSubjectForm = (props: FormProps) => {
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomToolbar canEdit={props.canEdit} canDelete={props.canDelete} />
      }
    >
      <div style={{ "width": "100%" }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Info principali
            </Typography>

            <TextInput {...initInput("business_name", props.fieldOptions, props.canEdit)} required />
            <TextInput {...initInput("address", props.fieldOptions, props.canEdit)} required />
            <TextInput {...initInput("postal_code", props.fieldOptions, props.canEdit)} required />
            <TextInput {...initInput("locality", props.fieldOptions, props.canEdit)} required />

            <ReferenceInput
              source="province_setter"
              label="Provincia"
              reference="autocomplete/province"
              required
              fullWidth
            >
              <AutocompleteInput optionText="label" />
            </ReferenceInput>
            <ValidateAddressButton color="primary" />
            <BooleanInput {...initInput("hide", props.fieldOptions, props.canEdit)} />


          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Info secondarie
            </Typography>

            <TextInput {...initInput("nation", props.fieldOptions, props.canEdit)} />
            <TextInput {...initInput("vat_number", props.fieldOptions, props.canEdit)} />
            <TextInput {...initInput("fiscal_code", props.fieldOptions, props.canEdit)} />
            <TextInput {...initInput("phone_number", props.fieldOptions, props.canEdit)} />
            <TextInput {...initInput("fax", props.fieldOptions, props.canEdit)} />
            <TextInput {...initInput("email", props.fieldOptions, props.canEdit)} />
            <TextInput {...initInput("business_name_print", props.fieldOptions, props.canEdit)} />
            <TextInput {...initInput("cod_sdi", props.fieldOptions, props.canEdit)} />
            <TextInput {...initInput("pec", props.fieldOptions, props.canEdit)} />

          </Grid>

        </Grid>
      </div>
    </SimpleForm>
  );
};
