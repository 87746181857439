import { Button } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { FieldProps, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

export const LittleDeliveryField = (
  props: FieldProps & { deliverySource: string }
) => {
  const record = useRecordContext(props);
  const delivery_id = _.get(record, props.deliverySource);
  return delivery_id ? (
    <span onClick={(e) => e.stopPropagation()}>
      {/* Missione principale: */}
      <Button
        color="primary"
        component={Link}
        target={"_blank"}
        to={"/deliveries/" + delivery_id}
      >
        {delivery_id}
      </Button>
    </span>
  ) : null;
};
