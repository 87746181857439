import React from "react";
import {
  AutocompleteArrayInput,
  Datagrid,
  FunctionField,
  ListBase,
  ListProps,
  ListToolbar,
  ReferenceArrayInput,
  TextInput,
  useCreate,
  useDeleteMany,
  useListContext,
  useNotify,
  useUpdate,
} from "react-admin";
import { MapContainer, TileLayer } from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import L, { FeatureGroup, Layer } from "leaflet";
import { useEffect, useRef } from "react";
import "leaflet-draw";
import randomColor from "randomcolor";
import { generateError, initField } from "../../shared/utils";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { CustomList } from "../../components/CustomList";
import { Grid, TextField } from "@material-ui/core";
import { DatagridPropsWithOptions } from "../../shared/types";
import { IdentifierField } from "../../components/IdentifierField";

export function DrawControl() {
  const context = useLeafletContext();

  const drawLayerRef = useRef<FeatureGroup>();
  const drawControl = useRef<any>();

  const { data, ids, refetch } = useListContext();
  const notify = useNotify();
  const [createLayer] = useCreate();
  const [deleteLayers] = useDeleteMany();
  const [editLayer] = useUpdate();

  useEffect(() => {
    const map = context.map;

    drawLayerRef.current = new L.FeatureGroup();

    L.EditToolbar.Delete.include({
      removeAllLayers: false
    });

    map.addLayer(drawLayerRef.current);

    drawControl.current = new L.Control.Draw({
      draw: {
        polygon: {},
        polyline: false,
        circle: false,
        rectangle: false,
        circlemarker: false,
        marker: false,
      },
      edit: {
        featureGroup: drawLayerRef.current,
        remove: true
      },
    });

    map.addControl(drawControl.current);

    map.on(L.Draw.Event.CREATED, function (event) {
      const layer = event.layer;
      const name = prompt("Inserisci il nome della zona", undefined);
      if (name) {
        const geoLayer = layer.toGeoJSON();
        geoLayer.properties["name"] = name;
        createLayer("delivery-zones", geoLayer, {
          onSuccess: (data) => {
            refetch();
          },
          onFailure: (error) => {
            generateError(error, notify);
          },
        });
      } else {
        notify("Inserisci un nome per la zona!", "warning");
      }
    });

    map.on(L.Draw.Event.EDITED, function (e: any) {
      const layers = e.layers;
      layers.eachLayer(function (layer: any) {
        const geoLayer = layer.toGeoJSON();
        editLayer("delivery-zones", geoLayer.id, geoLayer, undefined, {
          onSuccess: (data) => {
            refetch();
          },
          onFailure: (error) => {
            generateError(error, notify);
          },
        });
      });
    });

    map.on(L.Draw.Event.DELETED, function (e: any) {
      const layers = e.layers;
      const ids: number[] = [];
      layers.eachLayer(function (layer: any) {
        const geoJson = layer.toGeoJSON();
        ids.push(geoJson.id);
      });
      deleteLayers("delivery-zones", ids, {
        onSuccess: () => {
          refetch();
        },
        onFailure: (error) => {
          generateError(error, notify);
        },
      });
    });

    return () => {
      map.removeEventListener(L.Draw.Event.CREATED);
      map.removeEventListener(L.Draw.Event.DELETED);
      map.removeEventListener(L.Draw.Event.EDITED);
      map.removeControl(drawControl.current);
      map.removeLayer(drawLayerRef.current as Layer);
    };
  }, [context.map, createLayer, deleteLayers, editLayer, notify, refetch]);

  useEffect(() => {
    if (drawLayerRef.current) {
      drawLayerRef.current.clearLayers();
      const realData = ids.map((id) => data[id]);
      L.geoJSON(realData as any, {
        onEachFeature: function (feature, layer: any) {
          layer.bindTooltip(`<b>${feature.properties.name}</b>`,
            {
              permanent: true,
              direction: "center",
              className: "leaflet-tooltip-no-bg"
            }
          ).openTooltip();
          layer.options.color = feature.properties.color.length > 6 ? feature.properties.color : "#" + feature.properties.color; //randomColor();
        },
      }).eachLayer((l) => drawLayerRef.current!.addLayer(l));
    }
  }, [data, ids]);

  return null;
}

export const DeliveryZoneList = (props: ListProps) => {

  const { data: options } = useOptionsResource("GET");
  const filters = options ? [
    <TextInput {...initField("id", options)} />,
    <TextInput {...initField("name", options)} />,
    <TextInput {...initField("tags", options)} />,
    <ReferenceArrayInput
      helperText={false}
      fullWidth
      source="zones"
      filter={{ type: "zones" }}
      reference="autocomplete/delivery-zones"
      label="Zone"
    >
      <AutocompleteArrayInput optionText="label" resettable />
    </ReferenceArrayInput>,
  ]
    : [];
  return (
    options ?
      <CustomList
        filters={filters} {...props}
        perPage={99999}
      >
        <div
          style={{
            display: "flex",
            flex: "auto",
            height: "calc(100vh - 180px)",
            boxSizing: "border-box",
          }}
        >
          {options ? <Grid container>
            <Grid item xs={3} style={{ height: "100%", overflowY: "scroll" }}>
              <DeliveryZoneDatagrid options={options} />
            </Grid>
            <Grid item xs={9}>
              <MapContainer
                center={[41.2925, 12.5736]}
                zoom={5}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "static",
                }}
                zoomControl={false}
              >
                <DrawControl />
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  //url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
                />
              </MapContainer>
            </Grid>
          </Grid> : null}
        </div>
      </CustomList> : null
  );
};


const DeliveryZoneDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <FunctionField
        label="Nome"
        render={(record: any) => {
          return (
            record.properties.name
          );
        }}
      />
      <FunctionField
        label="Colore"
        render={(record: any) => {
          return (
            <div>
              <div style={{
                float: "left", height: "20px", width: "20px", marginBottom: "15px",
                border: "1px solid black", clear: "both", backgroundColor: record.properties.color.length > 6 ? record.properties.color : "#" + record.properties.color,
                marginTop: "10px"
              }}></div>
            </div>

          );
        }}
      />
      <FunctionField
        label="Tags"
        render={(record: any) => {
          return (
            record.properties.tags
          );
        }}
      />

    </Datagrid>
  );
};