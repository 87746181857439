import { ListProps } from "react-admin";
import { CustomList } from "../components/CustomList";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { getDefaultListProps } from "../shared/utils";
import { SiteDatagrid } from "./SiteDatagrid";

export const SiteList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");

  return options ? (
    <CustomList {...getDefaultListProps(props)}>
      <SiteDatagrid options={options} />
    </CustomList>
  ) : null;
};
