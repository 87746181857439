import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { ReduxState } from "ra-core";
import { useSelector } from "react-redux";
import { AppBar, LayoutProps, Notification, Sidebar } from "react-admin";
import ErrorBoundary from "./ErrorBoundary";
import { CustomMenu } from "./CustomMenu";
import { useSize } from "../shared/hooks/useSize";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    height: "100vh",
    width: "100%",
  },

  container: {},
}));

export const Layout = ({
  theme: themeOverride,
  ...props
}: LayoutProps): JSX.Element => {
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(() => createTheme(themeOverride));
  const {
    appBar: DefaultAppBar = AppBar,
    children,
    className,
    dashboard,
    logout,
    menu: DefaultMenu = CustomMenu,
    notification: DefaultNotification = Notification,
    sidebar: DefaultSidebar = Sidebar,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);
  const open = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );

  const ref = useRef<HTMLDivElement>(null);
  const size = useSize(ref.current);
  const sizeAppBar = useSize(document.getElementById("appbar-main"));
  return (
    <ThemeProvider theme={theme}>
      <div className={classnames("layout", classes.root, className)}>
        <DefaultSidebar classes={{}}>
          <DefaultMenu hasDashboard={!!dashboard} />
        </DefaultSidebar>
        <div style={{ display: "block", overflow: "hidden", width: "100%" }}>
          <DefaultAppBar
            id="appbar-main"
            position="fixed"
            open={open}
            logout={logout}
          />
          <div ref={ref} id="secondary-app-bar"></div>
          <div
            id="layout-main-content"
            style={{
              height: `calc(100% - ${(size ? size.height : 0) + (sizeAppBar ? sizeAppBar.height : 0)
                }px)`,
              overflow: "auto",
              width: "100%",
            }}
          >
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
        </div>
        <DefaultNotification />
      </div>
    </ThemeProvider>
  );
};
