import React, { useContext, useEffect, useState } from "react";
import { PossibleGroupings } from "./GroupedList";

export interface GroupedListContextI {
  groupedBy?: string;
  changeGrouping?: (value?: string) => void;
  possibleGroupings: PossibleGroupings;
}

const defaultState = {
  groupedBy: undefined,
  possibleGroupings: [],
};

export const GroupedListContext =
  React.createContext<GroupedListContextI>(defaultState);

interface GroupedListProviderProps {
  defaultGroupedBy?: string;
  possibleGroupings: PossibleGroupings;
  children: React.ReactNode;
}
export const GroupedListProvider = (props: GroupedListProviderProps) => {
  const [groupedBy, setGroupedBy] = useState<string>();
  useEffect(() => {
    if (props.defaultGroupedBy) setGroupedBy(props.defaultGroupedBy);
  }, [props.defaultGroupedBy]);

  return (
    <GroupedListContext.Provider
      value={{
        possibleGroupings: props.possibleGroupings,
        groupedBy,
        changeGrouping: setGroupedBy,
      }}
    >
      {props.children}
    </GroupedListContext.Provider>
  );
};
export const useGroupedListContext = () => {
  const context = useContext(GroupedListContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a GroupedListContext`);
  }
  return context;
};
