import * as React from "react";
import useResizeObserver from "@react-hook/resize-observer";

export const useSize = (target: HTMLElement | null) => {
  const [size, setSize] = React.useState<DOMRect>();

  React.useLayoutEffect(() => {
    if (target) setSize(target.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};
