import { useNotify } from "ra-core";
import { useQueryClient, useMutation } from "react-query";
import { ASSIGN_MISSING_SUBJECT_API } from "../constants";
import { client } from "../dataProvider";
import { SubjectNotFound } from "./SubjectSolverGrid";
import { generateError } from "../shared/utils";

export function useAssignMissingSubject() {
  const queryClient = useQueryClient();
  const notify = useNotify();
  return useMutation(
    (data: {
      not_found_subject: string | number;
      subject_id: string | number;
    }) =>
      client(ASSIGN_MISSING_SUBJECT_API, {
        method: "POST",
        data: data,
      }),
    {
      onSuccess: (data) => {
        notify("Fatto!", "success");
        queryClient.setQueryData<SubjectNotFound[]>(
          "missing-subjects",
          (old) => {
            if (old)
              return [
                ...old.filter((missing_subject) => {
                  return (
                    missing_subject.not_found_subject.address !==
                    data.address &&
                    missing_subject.not_found_subject.business_name !==
                    data.business_name &&
                    missing_subject.not_found_subject.locality !==
                    data.locality
                  );
                }),
              ];
            return [];
          }
        );
      },
      onError: (error) => {
        generateError(error, notify);
      },
    }
  );
}
