import {
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  Record,
  FunctionField,
  SelectField,
  ArrayField,
  ChipField,
  SingleFieldList,
} from "react-admin";
import { DriverField } from "../../components/DriverField";
import { CarrierField } from "../../components/CarrierField";
import { VehicleField } from "../../components/VehicleField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { getFileNameFromContentDisposition, initField } from "../../shared/utils";
import { GoToDeliveriesButton } from "../../components/GoToDeliveriesButton";
import { GoToCachetsButton } from "../../components/GoToCachetsButton";
import { IdentifierField } from "../../components/IdentifierField";
import React from "react";
import { Typography } from "@material-ui/core";
import { RouteSegmentMonitorShow } from "./RouteSegmentMonitorShow";
import { RouteField } from "../../components/RouteField";

export const RouteMonitorDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {

  return (
    <Datagrid {...rest}>
      <RouteField routeSource={"id"} />
      <SelectField
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />
      <FunctionField
        label={"Progresso"}
        render={(record: Record | undefined) =>
          record && record.monitor_info["deliveries"] ? (
            <div style={{
              display: "flex", flexDirection: "row"
            }} >
              <div style={{
                display: "flex", flexDirection: "row", width: "200px", height: "15px",
                borderRadius: "5px", border: "2px solid gray", padding: "0px"
              }}>
                <div style={{
                  backgroundColor: "green",
                  width: (Math.round(record.monitor_info["done"] / record.monitor_info["deliveries"] * 10000) / 100).toString() + "%", height: "100%", margin: "0px"
                }}>
                </div>
                <div style={{
                  backgroundColor: "red",
                  width: (Math.round(record.monitor_info["anomaly"] / record.monitor_info["deliveries"] * 10000) / 100).toString() + "%", height: "100%", margin: "0px"
                }}>
                </div>
              </div>
              <div> <Typography>% {(Math.round((record.monitor_info["done"] + record.monitor_info["anomaly"]) / record.monitor_info["deliveries"] * 10000) / 100)}</Typography></div>
            </div>
          ) : null
        } />
      <FunctionField
        label={"Info"}
        render={(record: Record | undefined) =>
          record ? (
            <div title={record.monitor_info["done"] + " COMPLETATE \n" + record.monitor_info["anomaly"] + " IN ANOMALIA \n" + (record.monitor_info["deliveries"] - record.monitor_info["done"] - record.monitor_info["anomaly"]) + " MANCANTI\n" + record.monitor_info["deliveries"] + " TOTALI"}>
              {record.monitor_info["done"]} / {record.monitor_info["anomaly"]} / -{record.monitor_info["deliveries"] - record.monitor_info["done"] - record.monitor_info["anomaly"]} / {record.monitor_info["deliveries"]}
            </div>
          ) : null
        } />

      <RouteSegmentMonitorShow {...{ label: "Prossima consegnaaa" }} />
      <NumberField {...initField("total_distance", options)} />
      <DateField {...initField("planned_date", options)} showTime />
      <FunctionField
        label={"Autista/Vettore"}
        render={(record: Record | undefined) =>
          record ? (
            record.driver ? (<DriverField {...initField("driver", options)} />) : (<CarrierField {...initField("carrier", options)} />)
          ) : null
        } />
      <VehicleField {...initField("vehicle", options)} />
      <GoToDeliveriesButton filterAttribute={"route"} label="Miss." />
      <ArrayField source="tags_objects" label="Tag">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" source="label" />
            )}
          />
        </SingleFieldList>
      </ArrayField>

    </Datagrid >
  );
};
