import {
  Datagrid,
  FunctionField,
  Loading,
  NumberField,
  ReferenceArrayField,
} from "ra-ui-materialui";
import {
  Identifier,
  ListContextProvider,
  useList,
  useListContext,
  useQueryWithStore,
  useResourceContext,
  Record,
} from "react-admin";
import { DriverField } from "../../components/DriverField";
import { useGroupedListContext } from "../../components/GroupedList/GroupedListContext";
import { SubjectField } from "../../components/SubjectField";
import { CreateRemittanceButton } from "./CreateRemittanceButton";
import { CachetPaymentDatagrid } from "./CachetPayment/CachetPaymentDatagrid";

interface GroupedDatagridProps {
  showCreateRemittanceButton: boolean;
}
const Expand = ({
  record,
}: {
  basePath: string;
  id: Identifier;
  record: Record;
  resource: string;
}) => {
  return (
    <ReferenceArrayField
      reference="cachet-payments"
      record={record}
      source="payments_ids"
    >
      <CachetPaymentDatagrid />
    </ReferenceArrayField>
  );
};
export const GroupedDatagrid = ({
  showCreateRemittanceButton,
}: GroupedDatagridProps) => {
  const resource = useResourceContext();
  const { filterValues, filter } = useListContext();
  const { groupedBy } = useGroupedListContext();

  const { data, loading, loaded } = useQueryWithStore({
    type: "getGroupedBy",
    resource: resource,
    payload: { group_by: groupedBy, ...filterValues, ...filter },
  });
  const listContext = useList({
    data,
    ids: data ? data.map((d: any) => d.driver) : [],
    loaded,
    loading,
  });
  if (loading) return <Loading />;
  return (
    <ListContextProvider value={listContext}>
      <Datagrid expand={showCreateRemittanceButton ? Expand : undefined}>
        {groupedBy === "client" && (
          <SubjectField label="Cliente" source="client"></SubjectField>
        )}
        {groupedBy === "driver" && (
          <DriverField label="Autista" source="driver"></DriverField>
        )}
        {groupedBy === "carrier" && (
          <SubjectField label="Vettore" source="carrier"></SubjectField>
        )}
        <NumberField
          label="Tot. contanti"
          source="total_cash"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. assegno"
          source="total_check"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. abbuono"
          source="total_rebate"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. incassato"
          source="total_cashed"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. da incassare"
          source="total_to_cash_in"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>

        {groupedBy === "client" && showCreateRemittanceButton && (
          <FunctionField
            render={(record: any) => (
              <CreateRemittanceButton ids={record.payments_ids} />
            )}
          />
        )}
      </Datagrid>
    </ListContextProvider>
  );
};
