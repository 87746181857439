import React, { useState } from "react";
import { Delivery } from "../shared/types";
import { AppBar, Badge, Tab, Tabs } from "@material-ui/core";
import { DeliveriesTable } from "./DeliveriesTable";

function tabTablesProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export function ReportTabPanel(props: {
  value: number;
  index: number;
  report: {
    error: Delivery[];
    existing: Delivery[];
    file_name: string;
    no_price: Delivery[];
  };
}) {
  const { value, index, report, ...other } = props;
  const [openTab, setOpenTab] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setOpenTab(newValue);
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ overflowX: "auto", flex: "1" }}
      {...other}
    >
      <AppBar position="sticky" color="secondary">
        <Tabs
          value={openTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          aria-label="report"
          scrollButtons="auto"
        >
          <Tab
            disabled={report.no_price.length <= 0}
            label={
              <>
                <Badge color="secondary" badgeContent={report.no_price.length}>
                  Senza prezzo
                </Badge>
              </>
            }
            {...tabTablesProps(0)}
          />
          <Tab
            disabled={report.existing.length <= 0}
            label={
              <>
                <Badge color="secondary" badgeContent={report.existing.length}>
                  Esistenti
                </Badge>
              </>
            }
            {...tabTablesProps(1)}
          />
          <Tab
            disabled={report.error.length <= 0}
            label={
              <>
                <Badge color="secondary" badgeContent={report.error.length}>
                  Errore
                </Badge>
              </>
            }
            {...tabTablesProps(2)}
          />
        </Tabs>
      </AppBar>
      <div
        role="tabpanel"
        hidden={openTab !== 0}
        id={`tabpanel-${0}`}
        aria-labelledby={`tab-${0}`}
        style={{ overflowX: "auto" }}
        {...other}
      >
        <div style={{ display: "flex", flex: 1, height: "100%" }}>
          {openTab === 0 && <DeliveriesTable deliveries={report.no_price} />}
        </div>
      </div>
      <div
        role="tabpanel"
        hidden={openTab !== 1}
        id={`tabpanel-${1}`}
        aria-labelledby={`tab-${1}`}
        style={{ overflowX: "auto" }}
        {...other}
      >
        <div style={{ display: "flex", flex: 1, height: "100%" }}>
          {openTab === 1 && <DeliveriesTable deliveries={report.existing} />}
        </div>
      </div>
      <div
        role="tabpanel"
        hidden={openTab !== 2}
        id={`tabpanel-${2}`}
        aria-labelledby={`tab-${2}`}
        style={{ overflowX: "auto" }}
        {...other}
      >
        <div style={{ display: "flex", flex: 1, height: "100%" }}>
          {openTab === 2 && <DeliveriesTable deliveries={report.error} />}
        </div>
      </div>
    </div>
  );
}