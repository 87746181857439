import { FormDataConsumer, required } from "ra-core";
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "ra-ui-materialui";
import {
  Record, useNotify, useRefresh,
} from "react-admin";
import { FormApi } from "final-form";
import { NumberInput } from "../../components/NumberInput";
import React, { useEffect, useMemo, useState } from "react";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { ChargeType, TariffType, FormProps, DeliveryType } from "../../shared/types";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { client } from "../../dataProvider";
import { Button, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { FunctionField } from "react-admin";
import { EditableDatagrid, EditableNumberField } from "../../components/EditableDatagrid";
import { useOptions } from "../../shared/hooks/useOptions";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { sortBy } from "lodash";

const useStyles = makeStyles((theme) => ({
  "spacing-xs-2": {
    "& > .MuiGrid-item	": {
      padding: "0px 8px",
    },
  },
}));

const useIteratorStyle = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1em',
    flexWrap: 'wrap',
  }
}));

const useTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    "& .MuiTableCell-root": {
      whiteSpace: "break-spaces",
      wordBreak: "break-word"
    },
  },


}));

export const TariffForm = (props: FormProps) => {
  const classes = useStyles();
  const iteratorClasses = useIteratorStyle();
  const tableClasses = useTableStyle();
  const rrr: any = []
  const [regions, setRegions] = useState(rrr);
  const [regionsStart, setRegionsStart] = useState(rrr);
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const notify = useNotify();
  const refresh = useRefresh();

  const [searchString, setSearchString] = useState("");
  const postRowStyle = (record: any, index: any) => {
    return {
      backgroundColor: searchString !== "" && JSON.stringify(record).includes(searchString) ? '#efe' : 'white',
    }
  };

  const { data: optionsSubTariffs } = useOptions("sub-tariffs", "GET");

  const initialValues =
    props.record && props.initialValues;

  const [isExtended, setIsExtended] = useState(true);

  const getRegionAllString = (selected: number, total: number) => {
    return selected === total ? "S" : (selected > 0) ? "M" : "N";
  }

  const getRegionColor = (all: string) => {
    return all === "S" ? "green" : all === "N" ? "black" : "orange"
  }

  useMemo(() => {
    client(`get_regions`, {
      method: "GET",
    })
      .then((result) => {

        let temp_regions = [];
        let temp_regions_start = [];
        for (let r of result) {
          temp_regions.push(
            {
              "id": r["id"],
              "name": r["name"],
              "provinces": r["provinces"],
              "all": getRegionAllString(0, r["provinces"].length)
            }
          )
          temp_regions_start.push(
            {
              "id": r["id"],
              "name": r["name"],
              "provinces": r["provinces"],
              "all": getRegionAllString(0, r["provinces"].length)
            }
          )
        }
        setRegions(temp_regions);
        setRegionsStart(temp_regions_start);
      })
      .catch((e) => { })
      .finally(() => setIsExtended(false));

  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setLoading(false);
      setIsExtended(true);
      clearInterval(timer);
    }, 500);

    return () => clearInterval(timer);
  }, [isExtended]);


  const getInputProps = (source: string) => {
    return (
      record: Record,
      form: FormApi<
        globalThis.Record<string, any>,
        Partial<globalThis.Record<string, any>>
      >
    ) => ({
      style: { minWidth: "11em" },
      endAdornment: (
        <>
        </>
      ),
    });
  };

  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomToolbar canEdit={props.canEdit} canDelete={props.canDelete} />
      }
      initialValues={initialValues}
    >
      <Grid container style={{ "width": "100%" }} classes={classes} spacing={2}>

        <Grid item xs={4}>
          <SelectInput
            choices={props.fieldOptions.charge_type.choices}
            {...initInput(
              "charge_type",
              props.fieldOptions,
              props.canEdit,
              "array"
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <SelectInput
            choices={props.fieldOptions.tariff_type.choices}
            {...initInput(
              "tariff_type",
              props.fieldOptions,
              props.canEdit,
              "array"
            )}
            defaultValue={TariffType.SALE}
          />
        </Grid>

        <Grid item md={4}>
          <DateInput
            {...initInput("start_date", props.fieldOptions, props.canEdit)}
          />
        </Grid>
        <Grid item md={12}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.tariff_type === TariffType.PURCHASE ?
                (<ReferenceInputSubject
                  {...initInput("supplier_id", props.fieldOptions, props.canEdit)}
                  filter={{ type: "supplier" }}
                />) : null
            }
          </FormDataConsumer>
        </Grid>



        <Grid item xs={4}>
          <ReferenceInputSubject
            {...initInput("client_id", props.fieldOptions, props.canEdit)}
            filter={{ type: "client" }}
          />
        </Grid>


        <Grid item xs={4}>
          <ReferenceInput
            {...initInput("item_id", props.fieldOptions, props.canEdit)}
            reference="autocomplete/item"
          >
            <AutocompleteInput optionText="label" />
          </ReferenceInput>
        </Grid>

        <Grid item xs={4}>
          <SelectInput
            choices={props.fieldOptions.quantity_type.choices}
            {...initInput(
              "quantity_type",
              props.fieldOptions,
              props.canEdit,
              "array"
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.charge_type !== ChargeType.CACHET &&
                formData.charge_type !== ChargeType.STOCK &&
                formData.charge_type !== ChargeType.INSURANCE ? (
                <SelectInput
                  choices={props.fieldOptions.unification.choices}
                  defaultValue="N"
                  {...initInput(
                    "unification",
                    props.fieldOptions,
                    props.canEdit,
                    "array"
                  )}
                  validate={required()}
                />
              ) : null
            }
          </FormDataConsumer>
        </Grid>

        <Grid item xs={3}>
          <NumberInput {...initInput("vat", props.fieldOptions, props.canEdit)} />
        </Grid>

        <Grid item xs={3}>
          <NumberInput
            {...initInput("priority", props.fieldOptions, props.canEdit)}
          >
          </NumberInput>
        </Grid>

        <Grid item xs={3}>
          <BooleanInput
            {...initInput(
              "disabled",
              props.fieldOptions,
              props.canEdit
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.charge_type !== ChargeType.CACHET &&
                formData.charge_type !== ChargeType.STOCK &&
                formData.charge_type !== ChargeType.INSURANCE ? (
                <BooleanInput
                  {...initInput(
                    "unification_aqueduct",
                    props.fieldOptions,
                    props.canEdit
                  )}
                />
              ) : null
            }
          </FormDataConsumer>
        </Grid>

        <Grid item xs={12}>

          <ArrayInput
            validate={required()}
            label="Fasce"
            source="pricerange_set"
            onChange={() => {
              setIsSaved(false)
            }}
          >
            <SimpleFormIterator classes={iteratorClasses}>

              <NumberInput
                validate={required()}
                source="limit"
                label="A partire da (valore escluso)"
              />

              <SelectInput
                label="Tipo"
                source="type"
                allowEmpty={false}
                defaultValue="M"
                choices={[
                  { name: "Moltiplicatore", id: "M" },
                  { name: "Forfettario", id: "F" },
                ]}
              />

              <NumberInput
                source="grouping"
                label="Gruppa per"
              />
            </SimpleFormIterator>
          </ArrayInput>

        </Grid>

        <Grid item xs={6}>
          <TextInput {...initInput("notes", props.fieldOptions, props.canEdit)} />
        </Grid>

        <Grid item xs={6}>
          <TextInput {...initInput("note_clients", props.fieldOptions, props.canEdit)} />
        </Grid>

        {
          loading ?
            <Grid item xs={12}>
              <Typography style={{ textAlign: "center" }} variant="h2">Ricaricando...</Typography>
            </Grid> :
            props.record.id && isSaved &&
            <>
              <Grid item xs={6}>
                <h3>{
                  props.record.charge_type !== DeliveryType.BACK && props.record.charge_type !== DeliveryType.WITHDRAW ?
                    "Province partenza" : "Province arrivo merce"}</h3>
                <Grid container >
                  <Grid item xs={12} >
                    <FormDataConsumer>
                      {({ formData }) =>
                        <>
                          {
                            !loading && regionsStart.map(function (object: any, i: number) {
                              {
                                return <Button style={{ "border": "1px solid black", "color": getRegionColor(object["all"]) }} onClick={(event) => {

                                  setLoading(true);

                                  for (let r of object["provinces"]) {
                                    if (!formData.provinces_start) {
                                      formData.provinces_start = []
                                    }
                                    let add = true;
                                    for (let p of formData.provinces_start) {
                                      if (r.id === p || r.id === p.id) {
                                        add = false;
                                        break;
                                      }
                                    }
                                    if (add) {
                                      formData.provinces_start.push(r.id)
                                    }
                                  }

                                  let temp_regions = [];
                                  for (let r of regionsStart) {
                                    temp_regions.push(
                                      {
                                        "id": r["id"],
                                        "name": r["name"],
                                        "provinces": r["provinces"],
                                        "all": r["id"] === object["id"] ? "S" : r["all"]
                                      }
                                    )
                                  }

                                  setRegionsStart(temp_regions);

                                  setIsExtended(false);

                                }}>
                                  {object["name"]}
                                </Button>
                              }
                            })
                          }

                        </>
                      }
                    </FormDataConsumer>

                  </Grid>

                  <Grid item container xs={12} >

                    <Grid item xs={10}>

                      <ReferenceArrayInput
                        {...initInput("provinces_start", props.fieldOptions, props.canEdit)}
                        reference="autocomplete/province"
                        defaultValue={[]}
                        label={
                          props.record.charge_type !== DeliveryType.BACK && props.record.charge_type !== DeliveryType.WITHDRAW ?
                            "Province partenza" : "Province arrivo merce"}
                        onChange={(e: any) => {
                          let temp_regions = [];
                          for (let r of regionsStart) {
                            let selected = 0;
                            for (let p of r["provinces"]) {
                              if (e.includes(p["id"])) {
                                selected += 1;
                              }
                            }
                            temp_regions.push(
                              {
                                "id": r["id"],
                                "name": r["name"],
                                "provinces": r["provinces"],
                                "all": getRegionAllString(selected, r["provinces"].length)
                              }
                            )
                            setRegionsStart(temp_regions);
                          }
                        }}
                      >
                        <AutocompleteArrayInput optionText="label" />
                      </ReferenceArrayInput>


                    </Grid>

                    <FormDataConsumer>
                      {({ formData }) =>
                        <>
                          <Grid item xs={1} style={{ marginTop: 8 }}>
                            <Button style={{ "border": "1px solid black", "color": "red" }} onClick={
                              () => {

                                setLoading(true);

                                formData.provinces_start = []

                                let temp_regions = [];
                                for (let r of regionsStart) {
                                  temp_regions.push(
                                    {
                                      "id": r["id"],
                                      "name": r["name"],
                                      "provinces": r["provinces"],
                                      "all": getRegionAllString(0, r["provinces"].length)
                                    }
                                  )
                                  setRegionsStart(temp_regions);
                                }

                                setIsExtended(false);
                              }
                            }>Svuota</Button>
                          </Grid>
                        </>
                      }
                    </FormDataConsumer>

                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <h3>{
                  props.record.charge_type !== DeliveryType.BACK && props.record.charge_type !== DeliveryType.WITHDRAW ?
                    "Province arrivo" : "Province ritiro merce"}</h3>
                <Grid container >
                  <Grid item xs={12} >
                    <FormDataConsumer>

                      {({ formData }) =>
                        <>
                          {
                            regions.map(function (object: any, i: number) {
                              {
                                return <Button style={{ "border": "1px solid black", "color": getRegionColor(object["all"]) }} onClick={(event) => {

                                  setLoading(true);

                                  for (let r of object["provinces"]) {
                                    if (!formData.provinces) {
                                      formData.provinces = []
                                    }
                                    let add = true;
                                    for (let p of formData.provinces) {
                                      if (r.id === p || r.id === p.id) {
                                        add = false;
                                        break;
                                      }
                                    }
                                    if (add) {
                                      formData.provinces.push(r.id)
                                    }
                                  }

                                  let temp_regions = [];
                                  for (let r of regions) {

                                    temp_regions.push(
                                      {
                                        "id": r["id"],
                                        "name": r["name"],
                                        "provinces": r["provinces"],
                                        "all": r["id"] === object["id"] ? "S" : r["all"]
                                      }
                                    )
                                  }

                                  setRegions(temp_regions);

                                  setIsExtended(false);

                                }}>
                                  {object["name"]}
                                </Button>
                              }
                            })
                          }

                        </>
                      }
                    </FormDataConsumer>

                  </Grid>

                  <Grid item container xs={12} >

                    <Grid item xs={10}>

                      <ReferenceArrayInput
                        {...initInput("provinces", props.fieldOptions, props.canEdit)}
                        reference="autocomplete/province"
                        defaultValue={[]}
                        label={
                          props.record.charge_type !== DeliveryType.BACK && props.record.charge_type !== DeliveryType.WITHDRAW ?
                            "Province arrivo" : "Province ritiro merce"}
                        onChange={(e: any) => {
                          let temp_regions = [];
                          for (let r of regions) {
                            let selected = 0;
                            for (let p of r["provinces"]) {
                              if (e.includes(p["id"])) {
                                selected += 1;
                              }
                            }
                            temp_regions.push(
                              {
                                "id": r["id"],
                                "name": r["name"],
                                "provinces": r["provinces"],
                                "all": getRegionAllString(selected, r["provinces"].length)
                              }
                            )
                            setRegions(temp_regions);
                          }
                        }}
                      >
                        <AutocompleteArrayInput optionText="label" />
                      </ReferenceArrayInput>

                    </Grid>

                    <FormDataConsumer>
                      {({ formData }) =>
                        <>
                          <Grid item xs={1} style={{ marginTop: 8 }}>
                            <Button style={{ "border": "1px solid black", "color": "red" }} onClick={
                              () => {

                                setLoading(true);

                                formData.provinces = []

                                let temp_regions = [];
                                for (let r of regions) {
                                  temp_regions.push(
                                    {
                                      "id": r["id"],
                                      "name": r["name"],
                                      "provinces": r["provinces"],
                                      "all": getRegionAllString(0, r["provinces"].length)
                                    }
                                  )
                                  setRegions(temp_regions);
                                }

                                setIsExtended(false);
                              }
                            }>Svuota</Button>
                          </Grid>
                        </>
                      }
                    </FormDataConsumer>

                  </Grid>

                </Grid>

              </Grid>

              <Grid item xs={2} >

                <FormDataConsumer>
                  {({ formData }) =>
                    <Button style={{ "border": "1px solid black", "color": "red" }}
                      onClick={
                        () => {

                          client(`tariffs/${props.record.id}/generate_sub_tariff`, {
                            method: "POST", data: {
                              provinces: formData.provinces,
                              provinces_start: formData.provinces_start,
                            }

                          })
                            .then((data) => {
                              if (data["created"] === data["total"]) {
                                notify(`Create ${data["created"]} nuove tariffe`, "success")
                                refresh()
                              }
                              else if (data["created"] === 0) {
                                notify("Attanzione, nessuna tariffa creata, provincie già presenti!", "warning")
                              }
                              else {
                                notify(`Attanzione, create ${data["created"]} su ${data["total"]}, provincie già presenti!`, "warning")
                                refresh()
                              }

                            })
                            .catch(() => notify("Errore!", "warning"))
                            .finally(() => {
                            });

                        }
                      }
                    >Genera tariffe</Button>
                  }
                </FormDataConsumer>

              </Grid>
              <Grid item xs={2} >

                <FormDataConsumer>
                  {({ formData }) =>
                    <Button style={{ "border": "1px solid black", "color": "green" }}
                      onClick={
                        () => {

                          client(`tariffs/${props.record.id}/generate_missing_sub_tariff`, {
                            method: "POST", data: {
                            }

                          })
                            .then((data) => {
                              notify(`Create ${data["created"]} nuove tariffe`, "success")
                              refresh()
                            })
                            .catch(() => notify("Errore!", "warning"))
                            .finally(() => {
                            });

                        }
                      }
                    >Genera tariffe fasce mancanti</Button>
                  }
                </FormDataConsumer>

              </Grid>

              <Grid item xs={4} ></Grid>

              <Grid item xs={4} >

                <FormDataConsumer>
                  {({ formData }) =>
                    <TextField
                      variant="outlined"
                      onChange={(e: any) => {
                        setSearchString(e.target.value)
                      }}
                      label={"cerca nella tabella..."}
                      style={{ width: "100%" }}
                    />

                  }
                </FormDataConsumer>

              </Grid>

              <Grid item xs={12} ><br /></Grid>
            </>
        }

      </Grid>

      {
        props.record.id && isSaved &&

        <FormDataConsumer>
          {({ formData }) =>

            <Grid item xs={12} classes={tableClasses}>
              {optionsSubTariffs &&

                <CustomList {...getDefaultListProps({
                  resource: "sub-tariffs",
                  basePath: "/",
                  filter: {
                    price_range__tariff: formData.id
                  }
                })}
                  sort={{ field: "first_sub_tariff", order: "DESC" }}
                  actions={<div style={{ height: "0px" }}></div>}
                  title={" "}
                >

                  <EditableDatagrid rowStyle={postRowStyle}>

                    <IdentifierField
                      source="id"
                    />

                    <FunctionField
                      label="Inverti"
                      source="id"
                      render={(tableRecord: any) => {
                        if (tableRecord && tableRecord.id && formData.pricerange_set[0] &&
                          tableRecord.price_range === formData.pricerange_set[0].id) {

                          return (
                            <Button
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();

                                client(`sub-tariffs/${tableRecord.id}/invert`, {
                                  method: "POST",
                                  data: {},
                                })
                                  .then((response) => {
                                    notify("Create", "success");
                                    refresh();
                                  })
                                  .catch(() => notify("Errore!", "error"));
                              }
                              }
                            ><AutorenewIcon /></Button>)
                        }
                        return null;
                      }}
                      fullWidth={true}
                    />

                    <FunctionField
                      label="Tariffa base"
                      source="first_sub_tariff_id"
                      render={(tableRecord: any) => {
                        return (
                          <Typography>{tableRecord.first_sub_tariff_id}</Typography>
                        );
                      }}
                    />

                    <FunctionField
                      label={
                        props.record.charge_type !== DeliveryType.BACK && props.record.charge_type !== DeliveryType.WITHDRAW ?
                          "Province partenza" : "Province arrivo merce"}
                      source="provinces_start_string"
                      render={(tableRecord: any) => {
                        return (
                          <Typography>{tableRecord.provinces_start_string}</Typography>
                        );
                      }}
                      fullWidth={true}
                    />
                    <FunctionField
                      label={
                        props.record.charge_type !== DeliveryType.BACK && props.record.charge_type !== DeliveryType.WITHDRAW ?
                          "Province arrivo" : "Province ritiro merce"}
                      source="provinces_string"
                      render={(tableRecord: any) => {
                        return (
                          <Typography>{tableRecord.provinces_string}</Typography>
                        );
                      }}
                      fullWidth={true}
                    />

                    <FunctionField
                      label="Fascia"
                      source="price_range"
                      render={(tableRecord: any) => {
                        if (tableRecord && tableRecord.id) {
                          let index = 0;
                          for (let i in formData.pricerange_set) {
                            if (formData.pricerange_set[i]) {
                              if (tableRecord.price_range === formData.pricerange_set[i].id) {
                                index = parseInt(i) + 1;
                              }
                            }
                          }
                          return <Typography>{index}</Typography>;
                        }
                        return null;
                      }}
                      fullWidth={true}
                    />

                    <FunctionField
                      label={
                        props.record.charge_type !== DeliveryType.BACK && props.record.charge_type !== DeliveryType.WITHDRAW ?
                          "Regioni partenza" : "Regioni arrivo merce"}
                      source="regions_start"
                      render={(tableRecord: any) => {
                        if (tableRecord && tableRecord.id) {
                          return <Typography>{tableRecord.regions_start}</Typography>;
                        }
                        return null;
                      }}
                      fullWidth={true}
                    />

                    <FunctionField
                      label={props.record.charge_type !== DeliveryType.BACK && props.record.charge_type !== DeliveryType.WITHDRAW ?
                        "Regioni arrivo" : "Regioni ritiro merce"}
                      source="regions"
                      render={(tableRecord: any) => {
                        if (tableRecord && tableRecord.id) {
                          return <Typography>{tableRecord.regions}</Typography>;
                        }
                        return null;
                      }}
                      fullWidth={true}
                    />


                    <FunctionField
                      label="Prezzo"
                      source="price"
                      render={(tableRecord: any) => {
                        return (
                          <NumberInput
                            alwaysEditable
                            label={"Prezzo"}
                            source={"price"}
                            onChange={(e: any) => {
                              e.currentTarget.classList.add('red-border');
                            }}
                            onKeyPress={(e: any) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();

                                client(`sub-tariffs/${tableRecord.id}`, {
                                  method: "PATCH",
                                  data: {
                                    price: e.target.value
                                  },
                                })
                                  .then((response) => {
                                    e.target.classList.remove('red-border');
                                  })
                                  .catch(() => notify("Errore!", "error"));
                              }
                            }}
                          />)
                      }}
                      fullWidth={true}
                    />

                    <FunctionField
                      label="Prezzo minimo €"
                      source="minimum_price"
                      render={(tableRecord: any) => {
                        if (tableRecord && tableRecord.id && formData.pricerange_set[0] &&
                          tableRecord.price_range === formData.pricerange_set[0].id) {

                          return (<NumberInput
                            alwaysEditable
                            label={"Prezzo minimo"}
                            source={"minimum_price"}
                            onChange={(e: any) => {
                              e.currentTarget.classList.add('red-border');
                            }}
                            onKeyPress={(e: any) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();

                                client(`sub-tariffs/${tableRecord.id}`, {
                                  method: "PATCH",
                                  data: {
                                    minimum_price: e.target.value
                                  },
                                })
                                  .then((response) => {
                                    e.target.classList.remove('red-border');
                                  })
                                  .catch(() => notify("Errore!", "error"));
                              }
                            }}
                          />)
                        }
                        return null;
                      }}
                      fullWidth={true}
                    />


                  </EditableDatagrid>

                </CustomList>

              }

            </Grid>
          }
        </FormDataConsumer>
      }

    </SimpleForm >
  );
};
