import React from "react";
import { FunctionField, Record } from "react-admin";


interface Props {
  source: string;
  options?: any;
  label?: string;
  sortBy?: string

}
export const DeliveryTypeField = (props: Props) => {
  const { source, label, sortBy } = props;
  const type_map: { [key: string]: string } = {
    "NO": "CON",
    "BA": "RES",
    "RI": "RIT",
    "RC": "RIC",
    "PH": "SGN"
  }


  return (
    <FunctionField
      label={label ? label : "Tipo"}
      source={source}
      sortBy={sortBy ? sortBy : source}
      render={(tableRecord: Record | undefined) => {
        if (tableRecord) {
          return <>{type_map[tableRecord[source]]}</>
        }
        return null;
      }}
    />

  );
};
