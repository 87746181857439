import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { PurchaseCreateInvoiceForm } from "./PurchaseCreateInvoiceForm";

export const PurchaseInvoiceCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props}>
      <PurchaseCreateInvoiceForm />
    </CustomCreate>
  );
};
