import { Dialog, DialogContent, DialogActions, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Button,
  ButtonProps,
  DateInput,
  FormWithRedirect,
  Identifier,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useCreate,
  useListContext,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import { useState } from "react";
import { client } from "../../dataProvider";
import FileCopyIcon from '@material-ui/icons/FileCopy';


export const TariffDuplicationBulkButton = (props: ButtonProps) => {
  const { value, setFalse, setTrue } = useToggler();
  const { data: options } = useOptions("tariffs-clone-option", "GET");

  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const notify = useNotify();
  const handleSubmit = (values: any) => {

    let tot = selectedIds.length;
    let done = 0;

    for (let id of selectedIds) {
      client(`tariffs/${id}/clone`, { method: "POST", data: values })
        .then((data) => {
          if (data) {
            notify(`Tariffa ${id} clonata`, "info");
          }
        })
        .catch(() => notify(`Errore nella clonazione della tariffa ${id}`, "warning"))
        .finally(() => {
          done += 1;
          if (done === tot) {
            window.location.reload();
          }
        });
    }

  };


  return options ? (
    <>
      <Button
        disabled={selectedIds.length <= 0}
        label="Clona tariffe"
        onClick={() => setTrue()}
      >
        <FileCopyIcon />
      </Button>
      <Dialog open={value} maxWidth="md" fullWidth>
        <FormWithRedirect
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <DateInput {...initInput("start_date", options, true)}
                  label="Data inizio"
                />

                <SelectArrayInput
                  choices={options.charge_type.choices}
                  {...initInput(
                    "charge_type",
                    options.charge_type,
                    options.charge_type.canEdit,
                    "array"
                  )}
                  label="Modifica tipo addebito"
                />

                <ReferenceArrayInput
                  reference="autocomplete/subject"
                  filter={{ type: "client" }}
                  defaultValue={[]}
                  source={"clients"}
                  label="Clienti"
                  sort={{ "field": "business_name", "order": "ASC" }}>
                  <AutocompleteArrayInput optionText="label" fullWidth />
                </ReferenceArrayInput>

                <ReferenceArrayInput
                  reference="autocomplete/subject"
                  filter={{ type: "supplier" }}
                  defaultValue={[]}
                  source={"suppliers"}
                  label="Fornitori"
                  sort={{ "field": "business_name", "order": "ASC" }}>
                  <AutocompleteArrayInput optionText="label" fullWidth />
                </ReferenceArrayInput>

                <ReferenceArrayInput
                  reference="autocomplete/item"
                  defaultValue={[]}
                  source={"items"}
                  label="Articoli">
                  <AutocompleteArrayInput optionText="label" fullWidth />
                </ReferenceArrayInput>

                <Typography variant="h4" style={{ textAlign: "center" }}>
                  Altera prezzi
                </Typography>

                <Grid container>
                  <Grid item xs={5}>
                    <Typography variant="h4" style={{ textAlign: "center" }}>
                      Normali
                    </Typography>
                    <NumberInput
                      {...initInput(
                        "add_percentage",
                        options.add_percentage,
                        options.add_percentage.canEdit,
                      )}
                      label="Aggiungi percentuale"
                    />
                    <NumberInput
                      {...initInput(
                        "add_static",
                        options.add_percentage,
                        options.add_percentage.canEdit,
                      )}
                      label="Aggiungi prezzo"
                    />
                    <BooleanInput
                      {...initInput(
                        "add_zero",
                        options.add_zero,
                        options.add_zero.canEdit,
                      )}
                      label="Azzera prezzo"
                    />
                  </Grid>

                  <Grid item xs={2}>

                    <div style={
                      {
                        borderLeft: "3px solid lightgray",
                        height: "140px",
                        position: "relative",
                        left: "50%",
                        top: "30px"
                      }
                    }></div>

                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="h4" style={{ textAlign: "center" }}>
                      Minimi
                    </Typography>
                    <NumberInput
                      {...initInput(
                        "add_percentage_min",
                        options.add_percentage,
                        options.add_percentage.canEdit,
                      )}
                      label="Aggiungi percentuale (ai prezzi minimi)"
                    />
                    <NumberInput
                      {...initInput(
                        "add_static_min",
                        options.add_percentage,
                        options.add_percentage.canEdit,
                      )}
                      label="Aggiungi prezzo (ai prezzi minimi)"
                    />
                    <BooleanInput
                      {...initInput(
                        "add_zero_min",
                        options.add_zero_min,
                        options.add_zero_min.canEdit,
                      )}
                      label="Azzera prezzo (nai prezzi minimi)"
                    />
                  </Grid>


                </Grid>

              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={false}
                  label="Duplica"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
