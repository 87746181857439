import { Button, useListContext, useNotify, useUnselectAll } from "react-admin";
import { clientNoJson } from "../../dataProvider";
import PublishIcon from "@material-ui/icons/Print";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition } from "../../shared/utils";

export const PrintDriverRemittanceButton = () => {
  const { selectedIds, refetch } = useListContext();
  const notify = useNotify();

  const unselect = useUnselectAll("cachets");
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    let res = null;

    try {
      res = await clientNoJson(`${URL}/print_driver_remittance/`, {
        method: "POST",
        data: { ids: selectedIds },
        responseType: "arraybuffer",
      });
    } catch (err: any) {
      try {
        notify(new TextDecoder().decode(err.response.data), "error");
      } catch (error) {
        notify("ra.message.error", "error");
      }
      return;
    }
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
    refetch();
    unselect()
  };

  return (
    <Button
      disabled={selectedIds.length <= 0}
      label="Verifica e stampa rimessa autista"
      onClick={handleSubmit}
    >
      <PublishIcon />
    </Button>
  );
};
