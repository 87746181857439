import _ from "lodash";
import React from "react";
import { FieldProps, Link, linkToRecord, useRecordContext } from "react-admin";

export const CustomerSubjectField = (props: FieldProps & { source: string }) => {
  const record = useRecordContext(props);
  const customer_subject = _.get(record, props.source);

  return customer_subject ? (
    <Link to={linkToRecord("/customer_subjects", customer_subject.id, "edit")}>
      {`${customer_subject.business_name}`}
    </Link>
  ) : null;
};
