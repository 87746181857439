import React from "react";
import { useForm } from "react-final-form";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import { useStore } from "./store";
import { Button } from "react-admin";
import { Grid } from "@material-ui/core";

export const EditButtonDatagrid = () => {
  const form = useForm();
  const { editable, toggleEdit } = useStore();
  return (
    <Grid container justifyContent="center" alignItems="center">
      {editable ? (
        <>
          <Grid item>
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                form.submit();
                toggleEdit();
              }}
            >
              <SaveIcon />
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleEdit();
              }}
            >
              <ClearIcon />
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleEdit();
            }}
          >
            <EditIcon />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
