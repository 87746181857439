import React from "react";
import greenIconImg from "../assets/marker-icon-green.png";
import redIconImg from "../assets/marker-icon-red.png";
export const NumberedIcon = ({
  sequences,
  starting,
}: {
  sequences: number[];
  starting: boolean;
}) => {
  return (
    <div className="sequence-icon">
      <div className="circles-sequence">
        {sequences.map((sequence) => (
          <span key={sequence}>{sequence}</span>
        ))}
      </div>
      <img
        alt={starting ? "punto di partenza" : "punto di scarico"}
        src={starting ? redIconImg : greenIconImg}
      />
    </div>
  );
};
