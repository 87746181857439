import { FormDataConsumer, required } from "ra-core";
import {
  ReferenceInput,
  SimpleForm,
  SelectInput,
  DateInput,
  TextInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
} from "ra-ui-materialui";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../shared/hooks/useOptions";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { AddInvoiceLineForm } from "../components/AddInvoiceLineForm";
import { OnChange } from "react-final-form-listeners";
import { useForm } from "react-final-form";
import { updateTaxableForm } from "../utils";
import { NumberInput } from "../../components/NumberInput";
import { ReferenceInputAccountCompany } from "../../components/reference-inputs/ReferenceInputAccountCompany";
import { ReferenceInputBank } from "../../components/reference-inputs/ReferenceInputBank";
const InvoiceLinesInput = () => {
  const { data: optionsInvoiceLines } = useOptions("invoice-lines", "POST");
  const form = useForm();
  return optionsInvoiceLines ? (
    <ArrayInput required label="Righe fattura" source="invoice_lines" fullWidth>
      <SimpleFormIterator>
        <FormDataConsumer>
          {(propsForm) => {
            return (
              <>
                <OnChange name={propsForm.getSource!("quantity")}>
                  {(value, previous) => {
                    if (value) {
                      updateTaxableForm(
                        form,
                        propsForm.getSource!("quantity"),
                        propsForm.getSource!("price"),
                        propsForm.getSource!("vat"),
                        propsForm.getSource!("taxable"),
                        propsForm.getSource!("amount")
                      );
                    }
                  }}
                </OnChange>
                <OnChange name={propsForm.getSource!("vat")}>
                  {(value, previous) => {
                    updateTaxableForm(
                      form,
                      propsForm.getSource!("quantity"),
                      propsForm.getSource!("price"),
                      propsForm.getSource!("vat"),
                      propsForm.getSource!("taxable"),
                      propsForm.getSource!("amount")
                    );
                  }}
                </OnChange>
                <OnChange name={propsForm.getSource!("price")}>
                  {(value, previous) => {
                    if (value) {
                      updateTaxableForm(
                        form,
                        propsForm.getSource!("quantity"),
                        propsForm.getSource!("price"),
                        propsForm.getSource!("vat"),
                        propsForm.getSource!("taxable"),
                        propsForm.getSource!("amount")
                      );
                    }
                  }}
                </OnChange>
              </>
            );
          }}
        </FormDataConsumer>
        <TextInput
          {...initInput("replaced_description", optionsInvoiceLines, true)}
          label="Descrizione riga"
        />
        <NumberInput {...initInput("quantity", optionsInvoiceLines, true)} />
        <NumberInput {...initInput("price", optionsInvoiceLines, true)} />
        <NumberInput
          {...initInput("vat", optionsInvoiceLines, true)}
          defaultValue={22}
        />
        <NumberInput
          variant="outlined"
          source="taxable"
          disabled
          label="Imponibile"
          fullWidth
        />
        <NumberInput
          variant="outlined"
          source="amount"
          disabled
          label="Importo"
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  ) : null;
};
export const CreateInvoiceForm = (props: FormProps) => {
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const { data: optionsinvoiceLines } = useOptions("invoice-lines", "GET");
  const { fieldOptions, ...rest } = props;

  return optionsinvoiceLines && optionsTariff ? (
    <SimpleForm
      {...rest}
      initialValues={{ invoice_lines: [{}] }}
      redirect="show"
    >
      <ReferenceInputAccountCompany
        {...initInput("company", props.fieldOptions, props.canEdit)}
        required
      />
      <ReferenceInputBank
        {...initInput("bank_id", props.fieldOptions, props.canEdit)} />

      <SelectInput
        choices={props.fieldOptions.invoice_type.choices}
        {...initInput("invoice_type", props.fieldOptions, props.canEdit, "array")}
        required
        defaultValue={"S"}
      />

      <ReferenceInputSubject
        {...initInput("client", props.fieldOptions, props.canEdit)}
        filter={{ type: "client" }}
        required
      />
      <ReferenceInput
        {...initInput("terms_payment", props.fieldOptions, props.canEdit)}
        reference="payment-terms"
        required
        perPage={10000}
      >
        <SelectInput optionText="label" />
      </ReferenceInput>
      <DateInput
        {...initInput("invoice_date", props.fieldOptions, props.canEdit)}
        required
      />
      <SelectInput
        choices={props.fieldOptions.type.choices}
        {...initInput("type", props.fieldOptions, props.canEdit, "array")}
        required
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === "NC" && (
            <ReferenceInput
              {...initInput(
                "invoice_reference",
                props.fieldOptions,
                props.canEdit
              )}
              reference="autocomplete/invoice"
              validate={required()}
            >
              <AutocompleteInput optionText="label" required />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <TextInput
        defaultValue="Servizi svolti per vostro conto"
        multiline
        {...initInput("causal", props.fieldOptions)}
        required
      />
      <TextInput
        multiline
        {...initInput("notes", props.fieldOptions, props.canEdit)}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) => formData.id && <AddInvoiceLineForm />}
      </FormDataConsumer>
      <InvoiceLinesInput />
    </SimpleForm>
  ) : null;
};
