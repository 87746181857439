import { Accordion, FormControlLabel, Grid, makeStyles, Switch, TableContainer, Typography } from "@material-ui/core";
import { filter } from "lodash";
import React, { useMemo, useState } from "react";
import { BooleanField, BooleanInput, Datagrid, DateTimeInput, FormDataConsumer, ListContextProvider, NumberField, NumberInput, ReferenceArrayField, SelectInput, SimpleForm, TextField, TextInput, useDataProvider, useGetList, useList, useQuery, useRecordContext, useRefresh } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReferenceInputCustomerSubject } from "../../components/reference-inputs/ReferenceInputCustomerSubject";
import { ReferenceInputItem } from "../../components/reference-inputs/ReferenceInputItem";
import { client } from "../../dataProvider";
import { useOptions } from "../../shared/hooks/useOptions";
import { FormProps } from "../../shared/types";
import { initField, initInput } from "../../shared/utils";
import { AddCustomerOrderItemForm } from "./AddCustomerOrderItemForm";
import { CustomerOrderAddTariffField } from "./CustomerOrderAddTariffField";
import { CustomerOrderItemDatagrid } from "./CustomerOrderItemDatagrid";

const useStyles = makeStyles((theme) => ({
  "spacing-xs-2": {
    "& > .MuiGrid-item	": {
      padding: "0px 8px",
    },
  },
}));

export type DeliveryStatusListProps = {
  data?: [{ id: number, status: string }];
};

const DeliveryStatusList = (props: DeliveryStatusListProps) => {
  const listContext = useList({
    data: props.data ? props.data : [],
    ids: props.data ? props.data.map(s => s.id) : [],
    loading: false,
    loaded: true
  });
  return (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <TextField source="status" label={"Stato"} />
        <TextField source="date" label={"Data"} />
      </Datagrid>
    </ListContextProvider>
  );
};

export const CustomerOrderForm = (props: FormProps) => {
  const classes = useStyles();
  const record = useRecordContext();

  const refresh = useRefresh()
  /*
  let tempFlagService = {
    spi: false,
    sd: false
  }

  const [flagService, setFlagservice] = useState(tempFlagService)


  useMemo(() => {
    client(`tariffs-client`, {
      method: "GET",
      params: { only_service: true }
    }).then((result) => {

      let tfs = tempFlagService;

      for (let x of result.results) {
        if (x.item.code === "SPI") {
          tfs.spi = true
        }
      }

      setFlagservice(tfs)

    })
  }, [])

  */

  function getAfter2HoursDate() {
    let d = new Date();
    d.setTime((d.getTime() + (2 * 60 * 60 * 1000)));
    return d.toISOString();
  }

  const { data: optionsCustomerOrderItems } = useOptions("customer_order_items", "GET");
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomToolbar canEdit={props.canEdit} canDelete={props.canDelete}
          hide_3={record.id ? true : false} />
      }
      defaultValues={{ delivery_date: getAfter2HoursDate() }}
    >
      <>
        <Grid container classes={classes} spacing={2}>
          {
            /* 
            <Grid item xs={6}>
            <SelectInput
              {...initInput(
                "delivery_type",
                props.fieldOptions,
                props.canEdit,
                "array"
              )}
              defaultValue={DeliveryType.WITHDRAW}
              choices={props.fieldOptions.delivery_type.choices}
              required
              disabled
            />
          </Grid>
          <Grid item xs={6}>
          </Grid>
            */
          }



          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Luoghi consegna
            </Typography>
          </Grid>


          <Grid item xs={6}>
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInputCustomerSubject
                  {...initInput(
                    "starting_point_on_delivery",
                    props.fieldOptions,
                    props.canEdit
                  )}
                  label={
                    "Punto partenza della merce"
                  }
                  required
                />
              )}
            </FormDataConsumer>
          </Grid>

          <Grid item xs={6}>
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInputCustomerSubject
                  {...initInput(
                    "receiver",
                    props.fieldOptions,
                    props.canEdit
                  )}
                  label="Punto consegna merce"
                  required
                />
              )}
            </FormDataConsumer>
          </Grid>


          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Riferimenti consegna
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <DateTimeInput
              {...initInput(
                "delivery_date",
                props.fieldOptions,
                props.canEdit
              )}
              defaultValue={getAfter2HoursDate()}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              {...initInput(
                "document_number",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>

          {/* <Grid item xs={6}>
            <TextInput
              {...initInput(
                "first_delivery_reference",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              {...initInput(
                "second_delivery_reference",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Info trasportato
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <NumberInput
              {...initInput(
                "packages_number",
                props.fieldOptions,
                props.canEdit
              )}
              required={true}
              onWheel={(e: any) => { e.target.blur() }}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              {...initInput(
                "weight",
                props.fieldOptions,
                props.canEdit
              )}
              required={true}
              onWheel={(e: any) => { e.target.blur() }}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              {...initInput(
                "volume",
                props.fieldOptions,
                props.canEdit
              )}
              onWheel={(e: any) => { e.target.blur() }}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              {...initInput(
                "cachet",
                props.fieldOptions,
                props.canEdit
              )}
              onWheel={(e: any) => { e.target.blur() }}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              {...initInput(
                "value",
                props.fieldOptions,
                props.canEdit,
              )}
              label={"Valore merce"}
              onWheel={(e: any) => { e.target.blur() }}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              {...initInput(
                "insurance",
                props.fieldOptions,
                props.canEdit,
              )}
              label={"Valore Assicurazione"}
              onWheel={(e: any) => { e.target.blur() }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              {...initInput(
                "notes",
                props.fieldOptions,
                props.canEdit
              )}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12}>
            {
              /*
              <Typography variant="h6" gutterBottom>
                Preventivo : <NumberField variant="h6" {...initField("estimate_price", props)} /> €
              </Typography>
              */
            }
            {
              record && record.price_warning === true ?
                <Typography variant="h6" gutterBottom color="error">
                  Attenzione, la somma dei colli/peso non corrisponde al totale dei serivzi di trasporto
                </Typography> :
                null
            }

            <Typography variant="caption" gutterBottom color="error">
              Eventuali servizi vanno aggiunti dopo il salvataggio dell'ordine
            </Typography>

          </Grid>
          {(!props.record || !props.record.id) && (
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Servizi
              </Typography>
              <CustomerOrderAddTariffField options={props.fieldOptions} />
            </Grid>
          )}
          {props.record && props.record.id && optionsCustomerOrderItems && (
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Servizi consegna
              </Typography>
              <Typography variant="caption" gutterBottom color="error">
                Inserire eventuali servizi aggiuntivi o in casi di trasporti misti specificando le diverse tipologie di merce con relative quantità
              </Typography>
              <AddCustomerOrderItemForm />
              <TableContainer>
                <ReferenceArrayField
                  label="Pacchi"
                  reference="customer_order_items"
                  source="customerorderitem_set"
                >
                  <CustomerOrderItemDatagrid
                    options={optionsCustomerOrderItems}
                    updateRevenues={() => {
                      refresh();
                    }}
                  />
                </ReferenceArrayField>
              </TableContainer>
              {/* 

              <Typography variant="h6" gutterBottom>
                Servizi aggiuntivi
              </Typography>
              <Grid container xs={12}>
                <Grid item xs={2}>
                  <BooleanInput
                    {...initInput(
                      "spi",
                      props.fieldOptions,
                      flagService.spi === true
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <BooleanInput
                    {...initInput(
                      "sd",
                      props.fieldOptions,
                      flagService.sd === true
                    )}
                  />
                </Grid>
              </Grid>
              
              */}

            </Grid>
          )}
        </Grid>
        {
          <DeliveryStatusList data={props.record.delivery_status_all} />
        }

      </>
    </SimpleForm >
  );
};
