import React, { useState } from "react";
import { SimpleForm, TextInput } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { ColorInput } from "../../components/ColorInput";
import { SketchPicker } from "react-color";
import { useOptions } from "../../shared/hooks/useOptions";

export const DeliveryZoneForm = (props: FormProps) => {

  const { data: options } = useOptions("delivery-zones", "GET");

  return (
    options ?
      <SimpleForm
        {...props}
      >
        <TextInput {...initInput("properties.name", props.fieldOptions, props.canEdit)} />

        <ColorInput source="properties.color" label="Colore" />

        <TextInput {...initInput("properties.tags", props.fieldOptions, props.canEdit)} label="Tags" />
      </SimpleForm> : null
  );
};
