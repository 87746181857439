import { BooleanInput, DateInput, ListProps, SelectInput } from "ra-ui-materialui";
import { CustomList } from "../../components/CustomList";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";
import { RemittanceDatagrid } from "./RemittanceDatagrid";

export const RemittanceList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <ReferenceInputSubject
        {...initField("client", options)}
        filter={{ type: "client" }}
      />,
      <SelectInput
        {...initField("status", options, "array")}
        choices={options.status.choices}
      />,
      <DateInput
        {...initField("remittance_date_after", options)}
        label="Emessi dal"
      />,
      <DateInput
        {...initField("remittance_date_before", options)}
        label="Emessi al"
      />,
    ]
    : [];

  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      {...getDefaultListProps(props)}
    >
      <RemittanceDatagrid options={options} />
    </CustomList>
  ) : null;
};
