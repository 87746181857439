import {
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  Record,
  FunctionField,
  SelectField,
  ArrayField,
  ChipField,
  SingleFieldList,
} from "react-admin";
import { DriverField } from "../../components/DriverField";
import { CarrierField } from "../../components/CarrierField";
import { VehicleField } from "../../components/VehicleField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";
import { IdentifierField } from "../../components/IdentifierField";
import React from "react";

export const RouteSmallDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <SelectField
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />
      <NumberField {...initField("total_distance", options)} />
      <DateField {...initField("planned_date", options)} showTime />
      <DriverField {...initField("driver", options)} />
      <CarrierField {...initField("carrier", options)} />
      <VehicleField {...initField("vehicle", options)} />
      <ArrayField source="tags_objects" label="Tag">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" source="label" />
            )}
          />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  );
};
