import { Datagrid, TextField } from "react-admin";
import { IdentifierField } from "../components/IdentifierField";
import { SubjectField } from "../components/SubjectField";
import { DatagridPropsWithOptions } from "../shared/types";
import { initField } from "../shared/utils";

export const SiteDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <TextField {...initField("business_name", options)} />
      <SubjectField {...initField("default_store", options)} />
    </Datagrid>
  );
};
