import {
  Create,
  CreateProps,
  FileField,
  FileInput,
  SimpleForm,
} from "react-admin";

import { FileUploadCreateToolbar } from "./FileUploadCreateToolbar";

export const FileUploadCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<FileUploadCreateToolbar />}>
        <FileInput
          multiple
          source="files"
          label="File da caricare"
          accept=".ods,.csv,.txt,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          maxSize={500000}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
