import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
const useStyles = makeStyles<Theme, StatsCardProps>((theme) =>
  createStyles({
    statsCardTitle: { fontWeight: "bolder" },
    statsCardSubtitle: { fontSize: "0.7rem" },
    weightTitle: {
      color: ({ leftWeight }) =>
        leftWeight < 0 ? theme.palette.error.main : "inherit",
    },
    packageTitle: {
      color: ({ currentPackages, maxPackages }) =>
        maxPackages < currentPackages ? theme.palette.error.main : "inherit",
    },
  })
);
interface StatsCardProps {
  leftWeight: number;
  maxWeight: number;
  currentWeight: number;
  totalKm: number;
  totalPackages: number;
  maxPackages: number;
  currentPackages: number;
  n_destination: number
}
export const StatsCard = (props: StatsCardProps) => {
  const classes = useStyles(props);
  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      <Grid item>
        <Typography
          className={classNames(classes.statsCardTitle, classes.weightTitle)}
          color="textPrimary"
          variant="h5"
        >
          {Math.round(props.currentWeight)}/{Math.round(props.maxWeight)}
        </Typography>
        <Typography
          className={classes.statsCardSubtitle}
          color="textSecondary"
          variant="caption"
        >
          Saturazione peso mezzo (kg)
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className={classNames(classes.statsCardTitle, classes.packageTitle)}
          color="textPrimary"
          variant="h5"
        >
          {Math.round(props.currentPackages)}/{Math.round(props.maxPackages)}
        </Typography>
        <Typography
          className={classes.statsCardSubtitle}
          color="textSecondary"
          variant="caption"
        >
          Saturazione colli mezzo
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className={classNames(classes.statsCardTitle)}
          color="textPrimary"
          variant="h5"
        >
          {props.n_destination}
        </Typography>
        <Typography
          className={classes.statsCardSubtitle}
          color="textSecondary"
          variant="caption"
        >
          N° Destini
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className={classes.statsCardTitle}
          color="textPrimary"
          variant="h5"
        >
          {Math.round(props.totalKm)} km
        </Typography>
        <Typography
          className={classes.statsCardSubtitle}
          color="textSecondary"
          variant="caption"
        >
          Tot. km
        </Typography>
      </Grid>
    </Grid>
  );
};
