import create from "zustand";
import createContext from "zustand/context";
import { devtools } from "zustand/middleware";
import produce from "immer";

type IState = {
  editable: boolean;
  toggleEdit: () => void;
};

// pass the type to `createContext` rather than to `create`
const { Provider, useStore } = createContext<IState>();
const createStore = () =>
  create<IState>(
    devtools((set, get) => {
      return {
        editable: false,
        toggleEdit: () => {
          set(
            produce((state) => {
              state.editable = !state.editable;
            })
          );
        },
      };
    })
  );

export { Provider, useStore, createStore };
