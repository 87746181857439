import {
  Grid,
  Typography,
  ButtonGroup,
  Dialog,
  Button,
  TextField as MuiTextInput,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  TableContainer,
  Box,
  makeStyles,
} from "@material-ui/core";
import { useMemo } from "react";
import {
  TextInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  ButtonProps,
  useNotify,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  BooleanInput,
  ReferenceManyField,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { NumberInput } from "../components/NumberInput";
import { MapContainer, TileLayer } from "react-leaflet";
import { AddressInput } from "../components/AddressInput";
import { DrawControl } from "../components/DrawControls";
import { useToggler } from "../shared/hooks/useToggler";
import { initInput } from "../shared/utils";
import { useForm, useFormState } from "react-final-form";
import MapIcon from "@material-ui/icons/Map";
import { useOptions } from "../shared/hooks/useOptions";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { client } from "../dataProvider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TariffDatagrid } from "../Items/Tariff/TariffDatagrid";
import { ReferenceInputTermsPayment } from "../components/reference-inputs/ReferenceInputTermsPayment";
import { getUser } from "../authProvider";
import { ReferenceInputBank } from "../components/reference-inputs/ReferenceInputBank";

const useIteratorStyle = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1em',
    flexWrap: 'wrap',
  }
}));

const CalculateCoordinatesButton = (props: ButtonProps) => {
  const { values } = useFormState();
  const form = useForm();
  const notify = useNotify();
  return (
    <Button
      {...props}
      onClick={() => {
        if (
          (values.locality || values.postal_code) &&
          values.province &&
          values.address
        ) {
          const url = `autocomplete/address_latlon`;
          client(url, {
            method: "GET",
            params: {
              postal_code: values.postal_code,
              province: values.province,
              locality: values.locality,
              address: values.address,
            },
          })
            .then((response) => {
              if (response) {
                form.change("latitude", response.lat);
                form.change("longitude", response.lon);
                form.change("altitude", response.altitude);
              } else {
                notify("Nessun risultato trovato!", "warning");
              }
            })
            .catch(() => notify("Errore!", "error"));
        } else {
          notify(
            "Inserire indirizzo, localita, cap e provincia, prima di calcolare le coordinate",
            "error"
          );
        }
      }}
    >
      Calcola
    </Button>
  );
};

const ValidateAddressButton = (props: ButtonProps) => {
  const { values } = useFormState();
  const form = useForm();
  const notify = useNotify();
  return (
    <Button
      {...props}
      onClick={() => {
        if (values.locality || values.province || values.postal_code) {
          const url = `autocomplete/address_info`;
          client(url, {
            method: "GET",
            params: {
              postal_code: values.postal_code,
              province: values.province,
              locality: values.locality,
            },
          })
            .then((response) => {
              form.change("locality", response.locality);
              form.change("province", response.province);
              form.change("postal_code", response.postal_code);
            })
            .catch(() => notify("Errore!", "error"));
        } else {
          notify(
            "Inserire almeno un dato tra localita, cap e provincia, prima di validare",
            "error"
          );
        }
      }}
    >
      Valida indirizzo
    </Button>
  );
};
const AddTariffButton = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  return (
    <Button
      color="primary"
      onClick={() =>
        redirect(
          "create",
          "/tariffs",
          undefined,
          {},
          { record: { client_id: record.id } }
        )
      }
    >
      Crea tariffa
    </Button>
  );
};

export const SubjectFormContent = (props: {
  canEdit?: boolean;
  fieldOptions: any;
}) => {
  const drawOptions = useMemo<L.Control.DrawOptions>(() => {
    return {
      polygon: false,
      polyline: false,
      circle: false,
      rectangle: false,
      circlemarker: false,
      marker: false,
    };
  }, []);
  const { data: optionsNotes } = useOptions("specification-note", "GET");
  const { data: optionsReferrers } = useOptions("referrers", "GET");
  const { value, setTrue, setFalse } = useToggler();
  const { getState, change } = useForm();
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const iteratorClasses = useIteratorStyle();

  return optionsNotes && optionsReferrers ? (
    <>
      <Grid container justifyContent="flex-start" direction={"row"} spacing={2}>
        <Grid item container xs={8}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Info principali
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              {...initInput("business_name", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectArrayInput
              optionValue="value"
              choices={props.fieldOptions.type.child.choices}
              optionText="display_name"
              {...initInput("type", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="phone"
              {...initInput("phone_number", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="phone"
              {...initInput("fax", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="email"
              {...initInput("email", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="pec"
              {...initInput("pec", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              type="email_op"
              {...initInput("email_op", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              type="text"
              {...initInput("notes", props.fieldOptions, props.canEdit)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Contabilità
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextInput
              {...initInput("vat_number", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              {...initInput("fiscal_code", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              {...initInput("cod_sdi", props.fieldOptions, props.canEdit)}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInputTermsPayment
              {...initInput(
                "payment_terms_id",
                props.fieldOptions,
                props.canEdit
              )}
              perPage={100}
            />
            <Grid item xs={12}>
              <TextInput
                {...initInput("iban", props.fieldOptions, props.canEdit)}
              />
            </Grid>
            <Grid>
              <ReferenceInputBank
                {...initInput(
                  "bank_id",
                  props.fieldOptions,
                  props.canEdit
                )}
                allowEmpty
              />
            </Grid>
            {
              /*
              <ReferenceInput
              {...initInput(
                "payment_terms_id",
                props.fieldOptions,
                props.canEdit
              )}
              reference="payment-terms"
              perPage={10000}
            >
              <SelectInput optionText="label" />
            </ReferenceInput>
              */
            }
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item direction="row" container alignItems="center">
            <Grid item xs>
              <Typography variant="h6">Posizione</Typography>
            </Grid>
            <Grid item>
              <ButtonGroup variant="text" color="primary">
                <ValidateAddressButton />
                <CalculateCoordinatesButton />
                <Button aria-label="Apri mappa" onClick={setTrue}>
                  <MapIcon color="primary" />
                </Button>
                <Button
                  onClick={() => {
                    const { values } = getState();
                    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      (
                        values.business_name +
                        " " +
                        values.address +
                        " " +
                        values.locality +
                        " " +
                        values.postal_code +
                        " " +
                        values.province
                      ).replaceAll("null", "")
                    )}`;

                    window.open(url, "_blank");
                  }}
                  aria-label="Apri google maps"
                >
                  Gmaps
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid item>
            <MuiTextInput
              onChange={(event) => {
                const value = event.target.value;
                const coords = value.replaceAll(" ", "").split(",");
                if (value.includes(",") && coords.length === 2) {
                  change("latitude", coords[0]);
                  change("longitude", coords[1]);
                }
              }}
              margin="dense"
              variant="outlined"
              fullWidth
              label="Incolla da gmaps"
            />
          </Grid>
          <Grid item>
            <AddressInput
              options={props.fieldOptions}
              canEdit={props.canEdit}
            />
          </Grid>
          <Grid item container>
            <Grid item xs={4}>
              <NumberInput
                {...initInput("latitude", props.fieldOptions, props.canEdit)}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                {...initInput("longitude", props.fieldOptions, props.canEdit)}
              />
            </Grid>

            <Grid item xs={4}>
              <NumberInput
                {...initInput("altitude", props.fieldOptions, props.canEdit)}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Dettagli secondari
            </Typography>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={6}>
                <BooleanInput
                  {...initInput(
                    "internal_notes",
                    props.fieldOptions,
                    props.canEdit
                  )}
                /></Grid>
              <Grid item xs={6}>
                <BooleanInput
                  {...initInput(
                    "no_print_notes",
                    props.fieldOptions,
                    props.canEdit
                  )}
                /></Grid>
              <Grid item xs={6}>
                <BooleanInput
                  {...initInput(
                    "to_be_tractioned",
                    props.fieldOptions,
                    props.canEdit
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  {...initInput(
                    "hide",
                    props.fieldOptions,
                    props.canEdit
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <TextInput
              {...initInput(
                "external_reference",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>
          <Grid item>
            <TextInput
              {...initInput(
                "business_name_print",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>

          <Grid item>
            <ReferenceInputSubject
              {...initInput(
                "main_subject_id",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Dettagli magazzino
            </Typography>
          </Grid>
          <Grid item>
            <ReferenceInputSubject
              {...initInput(
                "starting_point_id",
                props.fieldOptions,
                props.canEdit
              )}
              filter={{ type: "receiver" }}
              allowEmpty
            />
          </Grid>

          <Grid item>
            <ReferenceArrayInput
              {...initInput("stores", props.fieldOptions, props.canEdit)}
              reference="autocomplete/subject"
              filter={{ type: "receiver" }}
            >
              <AutocompleteArrayInput optionText="label" />
            </ReferenceArrayInput>
          </Grid>
          <Grid item>
            <ReferenceInputSubject
              {...initInput(
                "back_point_id",
                props.fieldOptions,
                props.canEdit
              )}
              filter={{ type: "receiver" }}
              allowEmpty
            />
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="h6">Referenti</Typography>
      <ArrayInput
        {...initInput("referrers", props.fieldOptions, props.canEdit)}
        label="Referenti"
      >
        <SimpleFormIterator classes={iteratorClasses}>
          <TextInput source="first_name" label="Nome" />
          <TextInput source="surname" label="Cognome" />
          <SelectArrayInput
            optionValue="value"
            choices={optionsReferrers.type.child.choices}
            optionText="display_name"
            source="type"
            label="Tipo"

          />
          <TextInput source="phone_number" label="Telefono" />

          <TextInput source="email" label="Email" />
          <TextInput multiline source="notes" label="Note" />

        </SimpleFormIterator>
      </ArrayInput>
      <Typography variant="h6">Note di capitolato</Typography>
      <ArrayInput
        {...initInput("specification_notes", props.fieldOptions, props.canEdit)}
        label="Note di capitolato"
      >
        <SimpleFormIterator classes={iteratorClasses}>
          <ReferenceInputSubject
            source="client"
            filter={{ type: "client" }}
            label="Cliente"
          />
          <SelectInput
            optionValue="value"
            choices={optionsNotes.type.choices}
            optionText="display_name"
            source="type"
            label="Tipo"
          />
          <TextInput multiline source="notes" label="Note" />
        </SimpleFormIterator>
      </ArrayInput>
      {getState().values && getState().values.id && optionsTariff &&
        getUser().permissions.includes("distribution.change_tariff") && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography variant="h6" gutterBottom>
                Tariffe
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              <Box display="flex" justifyContent="flex-end">
                <AddTariffButton />
              </Box>
              <Grid item xs={12}>
                <TableContainer>
                  <ReferenceManyField reference="tariffs" target="client" filter={{ last_tariff: true }}>
                    <TariffDatagrid options={optionsTariff} />
                  </ReferenceManyField>
                </TableContainer>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      <Dialog open={value} onClose={setFalse} fullWidth>
        <MapContainer
          center={[41.2925, 12.5736]}
          zoom={5}
          style={{ height: "300px", width: "auto" }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <DrawControl edit={{}} drawOptions={drawOptions} />
        </MapContainer>
      </Dialog>
    </>
  ) : null;
};
