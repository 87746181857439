import { Box, Dialog, DialogTitle, TableContainer } from "@material-ui/core";
import { ListBase, Record, useListContext } from "ra-core";
import {
  FilterButton,
  ListToolbar,
  Pagination,
  TextInput,
  TopToolbar,
  SelectInput,
} from "react-admin";
import { VehicleDatagrid } from "../../Vehicles/VehicleDatagrid";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";

interface VehicleDialogProps {
  onClose?: () => void;
  open: boolean;
  onSelect: (value: Record | undefined) => void;
}

const Datagrid = (props: {
  onSelect: (value: Record | undefined) => void;
  onClose?: () => void;
  options: any;
}) => {
  const { data } = useListContext();
  return (
    <VehicleDatagrid
      onToggleItem={(id) => {
        props.onSelect(data[id]);
        props.onClose && props.onClose();
      }}
      rowClick="toggleSelection"
      options={props.options}
    />
  );
};
export const VehicleDialog = (props: VehicleDialogProps) => {
  const { data: options } = useOptions("vehicles", "GET");
  return options ? (
    <Dialog onClose={props.onClose} open={props.open} fullWidth maxWidth="xl">
      <DialogTitle>Seleziona veicolo</DialogTitle>
      <ListBase
        resource="vehicles"
        syncWithLocation={false}
        basePath="/vehicles"
        perPage={100}
        sort={{ field: "plate", order: "ASC" }}
        filterDefaultValues={{ status: "D", internal_code: "" }}
      >
        <Box padding={2}>
          <ListToolbar
            filters={[
              <TextInput {...initField("internal_code", options)} alwaysOn />,
              <TextInput {...initField("plate", options)} alwaysOn />,
              <SelectInput
                choices={options.status.choices}
                {...initField("status", options, "array")}
                alwaysOn
              />,
            ]}
            actions={
              <TopToolbar>
                <FilterButton />
              </TopToolbar>
            }
          />
        </Box>
        <TableContainer>
          <Datagrid
            onClose={props.onClose}
            onSelect={props.onSelect}
            options={options}
          />
          <Pagination />
        </TableContainer>
      </ListBase>
    </Dialog>
  ) : null;
};
