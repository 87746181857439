import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from "ra-ui-materialui";
import React from "react";

type ReferenceInputAccountCompanyProps = Omit<
  ReferenceInputProps,
  "children" | "reference" | "sort"
>;

export const ReferenceInputAccountCompany = (props: ReferenceInputAccountCompanyProps) => {
  return (
    <ReferenceInput
      {...(props as ReferenceInputProps)}
      reference="autocomplete/accountcompany"
      sort={{ field: "name", order: "ASC" }}
      allowEmpty={props.allowEmpty === false ? false : true}
      isRequired={props.isRequired || props.required}
    >
      <AutocompleteInput
        options={{ ...props.options }}
        optionText="label"
      />
    </ReferenceInput>

  );
};
