
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  ButtonProps,
  FormWithRedirect,
  SaveButton,
  SelectInput,
  useListContext,
  useNotify,
  useRecordContext,
} from "react-admin";
import PublishIcon from "@material-ui/icons/Print";
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition, initInput } from "../../shared/utils";
import { useState } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { getUser } from "../../authProvider";
import React from "react";
import { AxiosResponse } from "axios";
import { useToggler } from "../../shared/hooks/useToggler";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { tr } from "date-fns/locale";
import IconCancel from "@material-ui/icons/Cancel";
import { useOptions } from "../../shared/hooks/useOptions";
import { sub } from "date-fns";


export const PrintDeliveryGroupBorderauxBulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { value, setTrue, setFalse } = useToggler();
  const { selectedIds, refetch } = useListContext();
  const { data: option } = useOptions("delivery-groups", "GET");
  const handleSubmit = async (values: any) => {
    setLoading(true);
    let res: AxiosResponse<any, any>;
    try {
      res = await clientNoJson(`${URL}/deliveries/print_delivery_group_borderaux/`, {
        method: "POST",
        data: {
          ids: selectedIds,
          type: values.type,
          subject: values.subject,
          force: values.force
        },
        responseType: "arraybuffer",
      });
    } catch (err: any) {
      console.log(err)
      notify("Attenzione verifcare cliente missioni e/o correttezza tipi", "error");
      return;
    } finally {
      setLoading(false);
      setFalse();
    }

    if (res !== null) {
      const data = res.data; // or res.blob() if using blob responses

      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: res.headers["content-type"],
        })
      );
      const actualFileName = getFileNameFromContentDisposition(
        res.headers["content-disposition"]
      );


      // uses the download attribute on a temporary anchor to trigger the browser
      // download behavior. if you need wider compatibility, you can replace this
      // part with a library such as filesaver.js
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", actualFileName);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) link.parentNode.removeChild(link);
      try {
        refetch();
      }
      catch (e) {

      }
    }



  };

  return (
    <>
      <Button
        {...props}
        disabled={loading}
        label="Raggruppa missioni"
        title="Crea e stampa pdf gruppo missioni"
        onClick={setTrue}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <PublishIcon />}
      </Button>
      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <ReferenceInputSubject
                  fullWidth
                  label="Soggetto"
                  source="subject"
                  reference="autocomplete/subject"
                  allowEpty={true}
                >
                  <AutocompleteInput optionText="label" />
                </ReferenceInputSubject>
                <SelectInput
                  {...initInput(
                    "type",
                    option.type,
                    true,
                    "array"
                  )}
                  choices={option.type.choices}
                  defaultValue={"BA"}
                  value={"BA"}
                  label="Tipo"
                />
                <BooleanInput
                  {...initInput(
                    "force",
                    true,
                  )}
                  label="Forza creazione"
                />

              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
