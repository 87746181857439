import {
  Datagrid,
  TextField,
  ListProps,
  NumberField,
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { getDefaultListProps } from "../../shared/utils";

import { XMLImportButton } from "./XMLImportButton";
export const XMLFileUploadList = (props: ListProps) => {
  return (
    <CustomList
      {...getDefaultListProps(props)}
      pagination={false}
      perPage={Infinity}
      additionalActions={<XMLImportButton />}
    >
      <Datagrid>
        <TextField source="name" label="Nome file" />
        <NumberField source="size" label="Dimensione" />
      </Datagrid>
    </CustomList>
  );
};
