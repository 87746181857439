import { ListContextProvider, Record, useList } from "ra-core";
import {
  Datagrid,
  FunctionField,
  NumberField,
  TextField,
} from "ra-ui-materialui";
import { TableButton } from "../RoutePlanner/components/TableButton";
import AddIcon from "@material-ui/icons/Add";

export const SimilSubjectsTable = ({ assignSubject, data }: any) => {
  const listContext = useList({
    data,
    ids: data.map((d: any) => d.id),
    loaded: true,
    loading: false,
  });
  return (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <FunctionField
          label=""
          render={(record: Record | undefined) =>
            record ? (
              <>
                <TableButton
                  title={"Assegna"}
                  onClick={(e) => {
                    e.stopPropagation();
                    assignSubject({
                      not_found_subject: record.id_missing as string,
                      subject_id: record.id,
                    });
                  }}
                >
                  <AddIcon />
                </TableButton>
              </>
            ) : null
          }
        />
        <TextField source="subject.business_name" label="Rag. sociale" />
        <TextField source="subject.address" label="Indirizzo" />
        <TextField source="subject.locality" label="Località" />
        <NumberField source="similarity" label="Simile al" />
      </Datagrid>
    </ListContextProvider>
  );
};
