import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { ChartAccountsTagsForm } from "./ChartAccountsTagsForm";

export const ChartAccountsTagsCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props} component="div">
      <ChartAccountsTagsForm />
    </CustomCreate>
  );
};
