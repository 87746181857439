import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { NoteForm } from "./NoteForm";

export const NoteEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props}>
      <NoteForm />
    </DefaultEdit>
  );
};
