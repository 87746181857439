import { NumberField, useRecordContext } from "react-admin";
import { useFormState } from "react-final-form";

import { client } from "../../dataProvider";
import { useQuery } from "react-query";
import React, { useMemo, useState } from "react";
import { AxiosError } from "axios";
import { Alert } from "@material-ui/lab";
import { getUser } from "../../authProvider";

export const RevenuesSingleDelivery = () => {
  const record = useRecordContext();

  const { values } = useFormState();
  const [error, setError] = useState<string>();

  const canDoRequest = useMemo(() => {
    return !!(
      values &&
      "receiver" in values &&
      values.receiver &&
      "client" in values &&
      values.client &&
      (("weight" in values && values.weight) ||
        ("packages_number" in values && values.packages_number))
    );
  }, [values]);

  const { data: stats } = useQuery(
    [record],
    () =>
      client(`deliveries/${record.id}/revenues_single_delivery`, {
        method: "GET",
      }),
    {
      initialData: { total: 0 },
      enabled: canDoRequest,
      onSuccess: () => {
        setError(undefined);
      },
      onError: (error: AxiosError) => {
        setError(error.response?.data?.detail);
      },
      retry: false,
    }
  );

  return canDoRequest ? (
    error ? (
      <Alert severity="error">{error}</Alert>
    ) : (
      getUser().permissions.includes('distribution.view_deliveryitem') ?
        <>
          Ricavo:{" "}
          <b>
            <NumberField
              record={stats}
              source="total"
              variant="inherit"
              options={{ style: "currency", currency: "EUR" }}
            />
          </b>
        </> : <></>
    )
  ) : (
    <Alert severity="info">Compila tutti i campi per la stima tariffa</Alert>
  );
};
