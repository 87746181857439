import { Button, Dialog, Table, TableCell, TableRow, makeStyles } from "@material-ui/core";
import _ from "lodash";
import React, { useState } from "react";
import { FieldProps, useRecordContext } from "react-admin";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { client } from "../dataProvider";
import { DeliveryStatusChoice } from "../shared/types";

export const DeliveryTrackingField = (
  props: FieldProps & { deliverySource: string }
) => {
  const record = useRecordContext(props);

  const [openDialog, setOpenDialog] = useState(false)
  const [eta, setEta] = useState("")
  const [statusHistory, setStatusHistory] = useState([])
  const today = new Date();

  function getInfo() {

    client(`customer_deliveries/${record.id}/get_tracking_info`, {
      method: "GET",
    })
      .then((response) => {
        setEta(response["eta"])
        setStatusHistory(response["status_history"])
      })
      .catch((e) => {
        console.log(e)
      });

    setOpenDialog(true);
  }

  const handleClose = (event: any, reason: any) => {
    if (reason && reason == "backdropClick")
      setOpenDialog(false);;

  }

  return record ? (
    <span onClick={(e) => e.stopPropagation()}>
      <Dialog open={openDialog} fullWidth maxWidth={'md'}
        onClose={handleClose}
      >
        <h2>Tracking e storico spedizione</h2>
        <Table >
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell><b>{record.id}</b></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>N° DDT</TableCell>
            <TableCell><b>{record.document_number}</b></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Destinatario</TableCell>
            <TableCell><b>{record.receiver && record.receiver.label ? record.receiver.label : ""}</b></TableCell>
          </TableRow>
          {record && [
            DeliveryStatusChoice.DELIVERING,
            DeliveryStatusChoice.LOAD,
          ].includes(record.status) && eta &&
            parseInt(eta.slice(8, 10)) === today.getDate() &&
            parseInt(eta.slice(5, 7)) === (today.getMonth() + 1) ?
            (<TableRow>
              <TableCell>Stima consegna: </TableCell>
              <TableCell style={{ color: "red", marginLeft: "16xp" }}>{eta}</TableCell>
            </TableRow>) : null
          }

          <TableRow>
            <TableCell>Stati</TableCell>
            <TableCell>
              <Table >
                {statusHistory && statusHistory.map((status: any) => {
                  return <TableRow>
                    <TableCell><b>{status["get_status_display"]}</b></TableCell>
                    <TableCell><b>{status["created_date"]}</b></TableCell>
                  </TableRow>
                })}
              </Table>
            </TableCell>
          </TableRow>
        </Table>

        <Button onClick={() => setOpenDialog(false)}>Chiudi</Button>


      </Dialog>
      <Button
        style={{ lineHeight: 0.75 }}
        color="primary"

        onClick={(e: any) => {
          e.preventDefault()
          getInfo();

        }}
      >
        <LocalShippingIcon />
      </Button>
    </span>
  ) : null;
};
