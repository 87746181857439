import { Button, ButtonProps, useListContext, useNotify } from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import { client } from "../../dataProvider";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";

export const RefuseOrderBulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);

    client(`customer_orders/refuse_orders`, {
      method: "POST",
      data: { ids: selectedIds },
    })
      .then(() => {
        refetch();
        notify("Fatto", "success");
      })
      .catch(() => {
        notify("ra.message.error", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      {...props}
      disabled={loading || selectedIds.length <= 0}
      label="Rifiuta"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <DeleteIcon />}
    </Button>
  );
};
