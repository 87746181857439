import * as React from "react";
import { useCallback, useMemo, FC, ReactElement } from "react";
import {
  TablePagination,
  TablePaginationBaseProps,
  Toolbar,
} from "@material-ui/core";
import {
  useTranslate,
  useListPaginationContext,
  sanitizeListRestProps,
} from "ra-core";
import { PaginationActions, PaginationLimit } from "ra-ui-materialui";

export const Pagination = (props: PaginationProps) => {
  const { rowsPerPageOptions, actions, limit, ...rest } = props;

  const { loading, page, perPage, total, setPage } =
    useListPaginationContext(props);

  const translate = useTranslate();

  const totalPages = useMemo(() => {
    return Math.ceil(total / perPage) || 1;
  }, [perPage, total]);

  /**
   * Warning: material-ui's page is 0-based
   */
  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation();
      if (page < 0 || page > totalPages - 1) {
        throw new Error(
          translate("ra.navigation.page_out_of_boundaries", {
            page: page + 1,
          })
        );
      }
      setPage(page + 1);
    },
    [totalPages, setPage, translate]
  );

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) =>
      translate("ra.navigation.page_range_info", {
        offsetBegin: from,
        offsetEnd: to,
        total: count,
      }),
    [translate]
  );

  // Avoid rendering TablePagination if "page" value is invalid
  if (total === null || total === 0 || page < 1 || page > totalPages) {
    return loading ? <Toolbar variant="dense" /> : limit!;
  }

  return (
    <TablePagination
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      onPageChange={handlePageChange}
      rowsPerPageOptions={[]}
      component="span"
      labelDisplayedRows={labelDisplayedRows}
      {...sanitizeListRestProps(rest)}
    />
  );
};

Pagination.defaultProps = {
  actions: PaginationActions,
  limit: <PaginationLimit />,
  rowsPerPageOptions: [5, 10, 25],
};

export interface PaginationProps extends TablePaginationBaseProps {
  rowsPerPageOptions?: number[];
  actions?: FC;
  limit?: ReactElement;
}

export default React.memo(Pagination);
