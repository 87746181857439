import { ListProps, SelectInput, TextInput } from "react-admin";
import { CustomList } from "../components/CustomList";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../shared/utils";
import { VehicleDatagrid } from "./VehicleDatagrid";

export const VehicleList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput {...initField("internal_code", options)} />,
      <TextInput {...initField("plate", options)} />,
      <SelectInput
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />,
    ]
    : [];
  return options ? (
    <CustomList
      filters={filters}
      filterDefaultValues={{ internal_code: "" }}
      {...getDefaultListProps(props)}>
      <VehicleDatagrid options={options} />
    </CustomList>
  ) : null;
};
