import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteInput,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { NumberInput } from "../components/NumberInput";
import { CustomToolbar } from "../components/CustomToolbar";
import { FormProps } from "../shared/types";
import { initInput } from "../shared/utils";

export const VehicleForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"row"}
                spacing={2}
              >
                <Grid item>
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Info principali
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextInput
                      {...initInput("plate", props.fieldOptions, props.canEdit)}
                    />
                  </Grid>
                  <Grid item>
                    <TextInput
                      {...initInput(
                        "internal_code",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <TextInput
                      {...initInput(
                        "description",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <SelectInput
                      choices={props.fieldOptions.status.choices}
                      {...initInput(
                        "status",
                        props.fieldOptions,
                        props.canEdit,
                        "array"
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <TextInput
                      {...initInput("owner", props.fieldOptions, props.canEdit)}
                    />
                  </Grid>
                  <Grid item>
                    <ReferenceInput
                      {...initInput(
                        "default_driver_id",
                        props.fieldOptions,
                        props.disabled
                      )}
                      filter={{ departments: "Autista" }}
                      reference="autocomplete/employee"
                      allowEmpty
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>
                  <Grid item>
                    <TextInput
                      multiline
                      {...initInput("notes", props.fieldOptions, props.canEdit)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Dimensioni
                    </Typography>
                  </Grid>

                  <Grid item>
                    <NumberInput
                      {...initInput(
                        "max_weigth",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <NumberInput
                      {...initInput(
                        "max_items",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <NumberInput
                      {...initInput(
                        "pallets",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />
  );
};
