import { useMemo } from "react";
import { useListContext } from "react-admin";
import {
  getFilterQuery,
  getPaginationQuery,
  getOrderingQuery,
} from "../../dataProvider";

export function useListQueryParams() {
  const { page, filterValues, perPage, currentSort } = useListContext();
  return useMemo(
    () => ({
      ...getFilterQuery(filterValues),
      ...getPaginationQuery({ page, perPage }),
      ...getOrderingQuery(currentSort),
    }),
    [currentSort, filterValues, page, perPage]
  );
}
