import { Card } from "@material-ui/core";
import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { TariffForm } from "./TariffForm";

export const TariffEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component={Card}>
      <TariffForm />
    </DefaultEdit>
  );
};
