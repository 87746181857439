import * as React from "react";
import classnames from "classnames";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Identifier, useTranslate } from "ra-core";
import { TopToolbar } from "ra-ui-materialui";

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      zIndex: 3,
      color:
        theme.palette.type === "light"
          ? theme.palette.primary.main
          : theme.palette.text.primary,
      justifyContent: "space-between",
      backgroundColor:
        theme.palette.type === "light"
          ? lighten(theme.palette.primary.light, 0.85)
          : theme.palette.primary.dark,
      minHeight: theme.spacing(8),
      height: theme.spacing(8),
      transition: `${theme.transitions.create(
        "height"
      )}, ${theme.transitions.create("min-height")}`,
    },
    topToolbar: {
      paddingTop: theme.spacing(2),
    },
    buttons: {},
    collapsed: {
      minHeight: 0,
      height: 0,
      overflowY: "hidden",
    },
    title: {
      display: "flex",
      flex: "0 0 auto",
    },
    icon: {
      marginLeft: "-0.5em",
      marginRight: "0.5em",
    },
  }),
  { name: "RaBulkActionsToolbar" }
);
interface ActionsToolbarProps {
  selected: Identifier[];
  onUnselect: () => void;
  children: React.ReactNode;
}

export const ActionsToolbar = (props: ActionsToolbarProps) => {
  const { selected, onUnselect, children } = props;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Toolbar
      data-test="bulk-actions-toolbar"
      className={classnames(classes.toolbar, {
        [classes.collapsed]: selected.length === 0,
      })}
    >
      <div className={classes.title}>
        <IconButton
          className={classes.icon}
          aria-label={translate("ra.action.unselect")}
          title={translate("ra.action.unselect")}
          onClick={onUnselect}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Typography color="inherit" variant="subtitle1">
          {translate("ra.action.bulk_actions", {
            _: "ra.action.bulk_actions",
            smart_count: selected.length,
          })}
        </Typography>
      </div>
      <TopToolbar className={classes.topToolbar}>{children}</TopToolbar>
    </Toolbar>
  );
};
