import { cloneElement, ReactNode, useContext, useMemo } from "react";
import { sanitizeListRestProps, useResourceDefinition } from "ra-core";
import {
  ListActionsProps,
  TopToolbar,
  CreateButton,
  ExportButton,
  usePermissions,
  useResourceContext,
  FilterContext,
  useListContext,
  FilterButton,
  Exporter,
  Button,
} from "react-admin";
import { PERMISSIONS } from "../constants";
import { getRealResource } from "../dataProvider";
import EuroExportButton from "./EuroExportButton";

export const CustomListActions = (
  props: ListActionsProps & {
    additionalActions?: ReactNode;
    shouldExport?: boolean;
    euroExport?: boolean;
    exporter?: Exporter;
    euroExporter?: Exporter;
  }
) => {
  const { permissions } = usePermissions();
  const resource = useResourceContext(props);
  const filters = useContext(FilterContext);
  const {
    className,
    exporter,
    euroExporter,
    filters: filtersProp,
    shouldExport = true,
    euroExport,
    additionalActions,

    ...rest
  } = props;
  const { hasCreate } = useResourceDefinition(rest);
  const { displayedFilters, filterValues, showFilter } = useListContext(props);
  return useMemo(
    () => (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters
          &&
          <Button label="x"
            onClick={() => window.location.href = window.location.href.split("?")[0]}></Button>
        }
        {filtersProp
          ? cloneElement(filtersProp, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })
          : filters && <FilterButton />}
        {additionalActions && additionalActions}
        {hasCreate &&
          permissions &&
          permissions.includes(PERMISSIONS[getRealResource(resource)].edit) && (
            <CreateButton />
          )}
        {shouldExport && <ExportButton exporter={exporter} />}
        {euroExport && <EuroExportButton exporter={euroExporter} />}
      </TopToolbar>
    ),
    [
      shouldExport,
      additionalActions,
      className,
      displayedFilters,
      filterValues,
      filters,
      filtersProp,
      hasCreate,
      permissions,
      resource,
      rest,
      showFilter,
    ]
  );
};
