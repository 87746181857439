import React from "react";
import { FunctionField, Record } from "react-admin";


interface Props {
  source: string;
  options?: any;
  label?: string;
  sortBy?: string

}
export const DeliveryInvoiceStatusField = (props: Props) => {
  const { source, label, sortBy } = props;
  const type_map: { [key: string]: string } = {
    "N": "",
    "PV": "Validato Parz.",
    "V": "Validato",
    "PI": "Fatturato Parz.",
    "I": "Fatturato"
  }


  return (
    <FunctionField
      label={label ? label : "Stato addebito/fattura"}
      source={source}
      sortBy={sortBy ? sortBy : source}
      render={(tableRecord: Record | undefined) => {
        if (tableRecord) {
          return <>{type_map[tableRecord[source]]}</>
        }
        return null;
      }}
    />

  );
};
