import { Button } from "@material-ui/core";
import React from "react";
import { FieldProps, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

export const GoToDeliveriesButton = (
  props: FieldProps & { filterAttribute: string }
) => {
  const record = useRecordContext(props);
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/deliveries",
        search: `filter=${JSON.stringify({
          [props.filterAttribute]: record.id,
        })}`,
      }}
      onClick={(e: any) => e.stopPropagation()}
    >
      Miss.
    </Button>
  ) : null;
};
