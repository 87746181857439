import L from "leaflet";
import _ from "lodash";
import { useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import { DeliveryType } from "../../shared/types";
import { useStore } from "../store";
import { Segment, DeliveryGroup } from "../types";
import { getGroupKey, getKeyLatLon, keyToLatLon } from "../utils";
import * as Icons from "./Icons";
import { NumberedIcon } from "./Icons/NumberedIcon";
import underlineIcon from "./assets/underlineIcon.png";


interface MarkerPlannerProps {
  segmentsMarker: Segment[];
  keyArrivalPoint: string;
  openPopup: (key: string, x: number, y: number) => void;
  allSegments: Segment[];
  weigthPackageAlertOnCluster: (keyArrivalPoint: string) => void;
  setHightlightClusterKey: (listClusterKeys: string[]) => void;
}

export const MarkerPlanner = ({
  segmentsMarker,
  keyArrivalPoint,
  openPopup,
  allSegments,
  weigthPackageAlertOnCluster,
  setHightlightClusterKey,
}: MarkerPlannerProps) => {
  const {
    addedSegments,
    groupedDeliveries,
    subjectByCoordsMap,
    addDeliveries,
    deleteSegments,
  } = useStore();
  const icon = useMemo(() => {
    const getIcon = (
      cluster: Segment[],
      addedSegments: Segment[],
      groupedDeliveries: DeliveryGroup[],
      clusterKey: string
    ) => {
      const getPreIcon = (
        cluster: Segment[],
        addedSegments: Segment[],
        groupedDeliveries: DeliveryGroup[],
        clusterKey: string
      ) => {
        const sequences: number[] = [];
        //add sequences if is a delivery point

        const segmentsInCommon = _.intersectionBy(addedSegments, cluster, "id");
        segmentsInCommon
          .filter(
            (segment) =>
              clusterKey ===
              getKeyLatLon(
                segment.arrival_point.latitude,
                segment.arrival_point.longitude
              )
          )
          .reduce((sequences, segment) => {
            const indexGroup = groupedDeliveries.findIndex(
              (deliveryGroup) => deliveryGroup.id === getGroupKey(segment)
            );
            if (indexGroup !== -1 && !sequences.includes(indexGroup + 1))
              sequences.push(indexGroup + 1);
            return sequences;
          }, sequences);

        const containsDeliveries = sequences.length > 0;

        //add starting point sequences
        groupedDeliveries.forEach((deliveryGroup, index) => {
          if (
            deliveryGroup.isPickUpPoint &&
            getKeyLatLon(deliveryGroup.latitude, deliveryGroup.longitude) ===
            clusterKey
          )
            sequences.push(index + 1);
        });

        sequences.sort();

        if (sequences.length > 0)
          if (containsDeliveries)
            return L.divIcon({
              className: "number-icon",
              iconSize: [25, 57],
              iconAnchor: [12.5, 57],
              shadowUrl: "",
              popupAnchor: [12.5, -35],
              html: ReactDOMServer.renderToString(
                <NumberedIcon sequences={sequences} starting={false} />
              ),
            });
          else
            return L.divIcon({
              className: "number-icon",
              iconSize: [25, 57],
              iconAnchor: [12.5, 57],
              shadowUrl: "",
              popupAnchor: [12.5, -35],
              html: ReactDOMServer.renderToString(
                <NumberedIcon sequences={sequences} starting={true} />
              ),
            });

        //   const active = cluster.some((segment) =>
        //     addedSegments.some((addedSegment) => addedSegment.id === segment.id)
        //   );
        //   if (active) return greenIcon;
        const anySegmentDeliveryActive = addedSegments.some((addedSegment) =>
          cluster.some(
            (segment) => addedSegment.delivery_id === segment.delivery_id
          )
        );
        if (anySegmentDeliveryActive) return Icons.purpleIcon;
        let isStartingPoint = false;
        let isWithdraw = false;
        let isBack = false;
        let isDelivery = false;
        let isReturn = false;
        let isPlaceHolder = false
        let totalTrue = 0
        for (const segment of cluster) {
          const keyStartingPoint = getKeyLatLon(
            segment.starting_point.latitude,
            segment.starting_point.longitude
          );
          const keyArrivalPoint = getKeyLatLon(
            segment.arrival_point.latitude,
            segment.arrival_point.longitude
          );
          if (keyStartingPoint === clusterKey) {
            if (segment.delivery_type === DeliveryType.WITHDRAW) {
              isWithdraw = true;
            }
            else {
              isStartingPoint = true;
            }
          }
          if (keyArrivalPoint === clusterKey) {
            if (segment.delivery_type === DeliveryType.WITHDRAW) { // || segment.delivery_type === DeliveryType.BACK) {
              isStartingPoint = true
            }
            else if (segment.delivery_type === DeliveryType.PLACEHOLDER) {
              isPlaceHolder = true;
            }
            else if (segment.delivery_type === DeliveryType.RETURN) {
              isReturn = true;
            }
            else if (segment.delivery_type === DeliveryType.BACK) {
              isBack = true;
            }
            else {
              isDelivery = true;
            }
          }
        }

        for (let b of [isStartingPoint, isWithdraw, isBack, isDelivery, isReturn, isPlaceHolder]) {
          if (b === true) {
            totalTrue += 1;
          }
        }

        if (totalTrue > 3) {
          return Icons.fuchsiaIcon;
        }
        else {
          if (isStartingPoint && isDelivery && isReturn) {
            return Icons.grayBlueSkyIcon;
          }
          else if (isStartingPoint && isDelivery && isWithdraw) {
            return Icons.grayBlueRedIcon;
          }
          else if (isStartingPoint && isDelivery && isBack) {
            return Icons.grayBlueOrangeIcon;
          }
          else if (isStartingPoint && isDelivery && isPlaceHolder) {
            return Icons.grayBlueYellowIcon;
          }
          else if (isStartingPoint && isReturn && isWithdraw) {
            return Icons.graySkyRedIcon;
          }
          else if (isStartingPoint && isReturn && isBack) {
            return Icons.graySkyOrangeIcon;
          }
          else if (isStartingPoint && isReturn && isPlaceHolder) {
            return Icons.graySkyYellowIcon;
          }
          else if (isStartingPoint && isWithdraw && isBack) {
            return Icons.grayRedOrangeIconIcon;
          }
          else if (isStartingPoint && isWithdraw && isPlaceHolder) {
            return Icons.grayRedYellowIcon;
          }
          else if (isStartingPoint && isBack && isPlaceHolder) {
            return Icons.grayOrangeYellowIcon;
          }
          else if (isDelivery && isReturn && isWithdraw) {
            return Icons.blueSkyRedIcon;
          }
          else if (isDelivery && isReturn && isBack) {
            return Icons.blueSkyOrangeIcon;
          }
          else if (isDelivery && isReturn && isPlaceHolder) {
            return Icons.blueSkyYellowIcon;
          }
          else if (isDelivery && isWithdraw && isBack) {
            return Icons.blueRedOrangeIcon;
          }
          else if (isDelivery && isWithdraw && isPlaceHolder) {
            return Icons.blueRedYellowIcon;
          }
          else if (isDelivery && isBack && isPlaceHolder) {
            return Icons.blueOrangeYellowIcon;
          }
          else if (isReturn && isWithdraw && isBack) {
            return Icons.skyRedOrangeIcon;
          }
          else if (isReturn && isWithdraw && isPlaceHolder) {
            return Icons.skyRedYellowIcon;
          }
          else if (isReturn && isBack && isPlaceHolder) {
            return Icons.skyOrangeYellowIcon;
          }
          else if (isWithdraw && isBack && isPlaceHolder) {
            return Icons.redOrangeYellowIcon;
          }
          else if (isDelivery && isStartingPoint) {
            return Icons.blueGrayIcon
          }
          else if (isDelivery && isReturn) {
            return Icons.blueSkyIcon
          }
          else if (isDelivery && isWithdraw) {
            return Icons.blueRedIcon
          }
          else if (isDelivery && isBack) {
            return Icons.blueOrangeIcon
          }
          else if (isDelivery && isPlaceHolder) {
            return Icons.blueYellowIcon
          }
          else if (isStartingPoint && isReturn) {
            return Icons.graySkyIcon
          }
          else if (isStartingPoint && isWithdraw) {
            return Icons.grayRedIcon
          }
          else if (isStartingPoint && isBack) {
            return Icons.grayOrangeIcon
          }
          else if (isStartingPoint && isPlaceHolder) {
            return Icons.grayYellowIcon
          }
          else if (isReturn && isWithdraw) {
            return Icons.skyRedIcon
          }
          else if (isReturn && isBack) {
            return Icons.skyOrangeIcon
          }
          else if (isReturn && isPlaceHolder) {
            return Icons.skyYellowIcon
          }
          else if (isWithdraw && isBack) {
            return Icons.orangeRedIcon
          }
          else if (isWithdraw && isPlaceHolder) {
            return Icons.yellowRedIcon
          }
          else if (isBack && isPlaceHolder) {
            return Icons.orangeYellowcon
          }
          else if (isStartingPoint) {
            return Icons.grayIcon;
          }
          else if (isDelivery) {
            return Icons.blueIcon;
          }
          else if (isReturn) {
            return Icons.skyIcon;
          }
          else if (isWithdraw) {
            return Icons.redIcon
          }
          else if (isBack) {
            return Icons.orangeIcon
          }
          else if (isPlaceHolder) { //  is True
            return Icons.yellowIcon
          }
        }

        return Icons.fuchsiaIcon;
      }

      let i = getPreIcon(cluster,
        addedSegments,
        groupedDeliveries,
        clusterKey);
      let totW = 0;
      let totPN = 0;
      for (const segment of cluster) {
        totW += segment.delivery.weight ? segment.delivery.weight : 0
        totPN += segment.delivery.packages_number ? segment.delivery.packages_number : 0
      }

      if (totPN >= 50 || totW >= 300) {
        if (i && i.options && i.options.iconUrl) {
          i = new L.Icon({
            iconUrl: i.options.iconUrl,
            iconSize: i.options.iconSize,
            iconAnchor: i.options.iconAnchor,
            shadowUrl: underlineIcon,
            popupAnchor: i.options.popupAnchor,
          });
        }

      }
      /*
      if (totPN >= 50 || totW >= 300) {
        switch (i) {
          case purpleIcon:
            i = purpleIconS;
            break;
          case redBlueYellowIcon:
            i = redBlueYellowIconS;
            break;
          case redBlueIcon:
            i = redBlueIconS;
            break;
          case redIcon:
            i = redIconS;
            break;
          case blueYellowIcon:
            i = blueYellowIconS;
            break;
          case blueIcon:
            i = blueIconS;
            break;
          case redYellowIcon:
            i = redYellowIconS;
            break;
        }
      }
      */

      return i;


    };
    return getIcon(
      segmentsMarker,
      addedSegments,
      groupedDeliveries,
      keyArrivalPoint
    );
  }, [addedSegments, groupedDeliveries, keyArrivalPoint, segmentsMarker]);
  return (
    <Marker
      icon={icon}
      eventHandlers={{
        click: (e) => {
          addDeliveries(keyArrivalPoint);
          weigthPackageAlertOnCluster(keyArrivalPoint);
          //openPopup(keyArrivalPoint, e.containerPoint.x, e.containerPoint.y);
        },
        contextmenu: (e) => {
          if (e.originalEvent.ctrlKey) {
            // handle ctrl + click ...
            deleteSegments(segmentsMarker);
          } else {
            openPopup(keyArrivalPoint, e.containerPoint.x, e.containerPoint.y);
          }
        },
        mouseout: () => setHightlightClusterKey([]),
        mouseover: (e) => {
          const listLatLonKeys: string[] = [];
          allSegments.reduce((listLatLonKeys: any, segment: any) => {
            const segmentArrivalPointKey = getKeyLatLon(
              segment.arrival_point.latitude,
              segment.arrival_point.longitude
            );
            const segmentStartingPointKey = getKeyLatLon(
              segment.starting_point.latitude,
              segment.starting_point.longitude
            );
            if (keyArrivalPoint !== segmentArrivalPointKey &&
              keyArrivalPoint === segmentStartingPointKey &&
              !listLatLonKeys.includes(segmentStartingPointKey))
              listLatLonKeys.push(segmentArrivalPointKey);
            if (keyArrivalPoint !== segmentStartingPointKey &&
              keyArrivalPoint === segmentArrivalPointKey &&
              !listLatLonKeys.includes(segmentStartingPointKey))
              listLatLonKeys.push(segmentStartingPointKey);
            return listLatLonKeys;
          }, listLatLonKeys);
          setHightlightClusterKey(listLatLonKeys);
        },
      }}
      position={keyToLatLon(keyArrivalPoint)}
    >
      <Tooltip>
        {`${subjectByCoordsMap[keyArrivalPoint].business_name}, ${subjectByCoordsMap[keyArrivalPoint].locality} ${subjectByCoordsMap[keyArrivalPoint].province}`}
      </Tooltip>
    </Marker>
  );
};
