import { Box, Card, CardContent, CardHeader, CircularProgress, Dialog, Grid, Typography } from "@material-ui/core";
import { useCallback, useState } from "react";
import {
  FunctionField,
  NumberField,
  Record,
  Identifier,
  useListContext,
  Button,
  useRecordContext,
  useNotify,
  useRedirect,
  DeleteButton,
  BooleanField,
  RecordContextProvider,
  FormWithRedirect,
  required,
  useRefresh,
  TextInput,
  SelectInput,
} from "react-admin";
import {
  EditableDatagrid,
  EditButtonDatagrid,
} from "../../../components/EditableDatagrid";
import { EditableTextField } from "../../../components/EditableDatagrid/EditableTextField";
import { client } from "../../../dataProvider";

import { useOptions } from "../../../shared/hooks/useOptions";
import { generateError, initField } from "../../../shared/utils";
import { ExpandedInvoiceLine } from "../ExpandedInvoiceLine";
import { ActionsToolbar } from "../../../components/ActionsToolbar";
import { ReferenceInputChartAccounts } from "../../../components/reference-inputs/ReferenceInputChartAccounts";
import { map } from "lodash";
import { EditableSelectField } from "../../../components/EditableDatagrid/EditableSelectField";
import { ReferenceInputSite } from "../../../components/reference-inputs/ReferenceInputSite";


export const InvoiceLineDatagrid = () => {
  const { data: optionsinvoiceLines } = useOptions("invoice-lines", "GET");
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const record = useRecordContext();
  const {
    data,
    ids,
    onSelect: onSelectFromContext,
    selectedIds,
    onToggleItem: onToggleItemContext,
  } = useListContext();
  const [selectedCharges, setSelectedCharges] = useState<Identifier[]>([]);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [saving, setSaving] = useState(false);
  const autoToggleInvoice = (newSelectedCharges: Identifier[]) => {
    //check for every invoiceline if we need to toggle selected state
    //used to toggle invoiceline on charge selection (multiple or single)
    ids.forEach((invoiceLineId) => {
      const invoiceLine = data[invoiceLineId];

      if (
        (!selectedIds.includes(invoiceLineId) &&
          invoiceLine.charge_set.every((chargeId: Identifier) =>
            newSelectedCharges.includes(chargeId)
          )) ||
        (invoiceLine.charge_set.some(
          (chargeId: Identifier) => !newSelectedCharges.includes(chargeId)
        ) &&
          selectedIds.includes(invoiceLineId))
      ) {
        onToggleItemContext(invoiceLineId);
      }
    });
  };
  const onToggleCharge = (id: Identifier) => {
    const result = selectedCharges.find((recordId) => recordId === id)
      ? selectedCharges.filter((recordId) => recordId !== id)
      : [...selectedCharges, id];
    autoToggleInvoice(result);
    setSelectedCharges(result);
  };
  const onSelectCharge = (ids: Identifier[], allCharges: Identifier[]) => {
    let result: Identifier[] = [];
    if (ids.length === 0) {
      result = selectedCharges.filter((oldId) => !allCharges.includes(oldId));
    } else {
      result = [...new Set([...selectedCharges, ...allCharges])];
    }
    autoToggleInvoice(result);
    setSelectedCharges(result);
  };

  const onSelect = (ids: Identifier[]) => {
    const selectedCharges = ids.reduce(
      (accumulator, id) => [...accumulator, ...data[id].charge_set],
      [] as Identifier[]
    );
    setSelectedCharges(selectedCharges);
    onSelectFromContext(ids);
  };

  const onToggleItem = (id: Identifier) => {
    const element = selectedIds.find((old) => old === id);
    setSelectedCharges((oldSelectedCharges) => {
      if (element) {
        return oldSelectedCharges.filter(
          (old) => !data[id].charge_set.includes(old)
        );
      }
      return [...oldSelectedCharges, ...data[id].charge_set];
    });

    onToggleItemContext(id);
  };

  const unselectAllCharges = () => {
    onSelectCharge([], selectedCharges);
  };


  const unselectAllInvoiceLines = () => {
    onSelect([]);
  };

  const createCreditNote = () => {
    setSaving(true);
    client(`invoices/${record.id}/create_credit_note`, {
      method: "POST",
      data: { charges: selectedCharges },
    })
      .then((data) => {
        setSaving(false);
        redirect(`/invoices/${data.id}/show`);
      })
      .catch((error) => {
        setSaving(false);
        generateError(error, notify);
      });
  };


  const assigniToChartAccounts = useCallback(
    (values: Partial<Record>) => {
      client(`invoicechartaccounts/assigno_to_chart_accounts`, {
        method: "POST",
        data: values,
      })
        .then((data) => {
          setSaving(false);
          setOpenAssChartAccDialog(false);
          notify("Fatto");
          unselectAllInvoiceLines();
          refresh();
        })
        .catch((error) => {
          setSaving(false);
          generateError(error, notify);
        });
    },
    [notify, refresh]
  );

  const calculateFuel = useCallback(
    (values: Partial<Record>) => {
      client(`charges/calculate_fuel`, {
        method: "POST",
        data: values,
      })
        .then((data) => {
          setSaving(false);
          setOpenCalcFuelDialog(false);
          notify("Fatto");
          unselectAllInvoiceLines();
          refresh();
        })
        .catch((e) => {
          setSaving(false);
          notify("Errore, fattura già emessa!", "error");
        });
    },
    [notify, refresh]
  );

  const [openAssChartAccDialog, setOpenAssChartAccDialog] = useState(false);
  const [openCalcFuelDialog, setOpenCalcFuelDialog] = useState(false);

  const openAssChartAccDialogFunc = () => {
    setOpenAssChartAccDialog(true);
  };

  const openCalcFuelDialogFunc = () => {
    setOpenCalcFuelDialog(true);
  };

  const canCreateNc = record.type === "FT" && record.status === "C";
  return optionsinvoiceLines && optionsTariff ? (
    <>

      {canCreateNc && (
        <ActionsToolbar
          selected={selectedCharges}
          onUnselect={unselectAllCharges}
        >
          <Button
            disabled={saving}
            label="Crea nota credito"
            onClick={createCreditNote}
          >
            {saving ? <CircularProgress size={18} thickness={2} /> : undefined}
          </Button>
        </ActionsToolbar>
      )}

      <ActionsToolbar
        selected={selectedIds}
        onUnselect={unselectAllInvoiceLines}
      >

        <Button
          disabled={saving}
          label="Assegna piano dei conti"
          onClick={openAssChartAccDialogFunc}
        >
          {saving ? <CircularProgress size={18} thickness={2} /> : undefined}
        </Button>
        <Button
          disabled={saving}
          label="Calcola fuel"
          onClick={openCalcFuelDialogFunc}
        >
          {saving ? <CircularProgress size={18} thickness={2} /> : undefined}
        </Button>
      </ActionsToolbar>

      <EditableDatagrid
        selectedIds={selectedIds}
        hasBulkActions={true}
        isRowExpandable={(record) =>
          record && record.charge_set && record.charge_set.length > 0
        }
        onSelect={onSelect}
        onToggleItem={onToggleItem}
        expand={
          <ExpandedInvoiceLine
            chargesDatagridHasBulkActions={canCreateNc}
            onToggleCharge={onToggleCharge}
            selectedCharges={selectedCharges}
            onSelectCharge={onSelectCharge}
          />
        }
      >
        {record.status !== "C" && (
          <FunctionField
            label=""
            render={(record?: Record) =>
              record ? <EditButtonDatagrid /> : null
            }
          />
        )}
        <EditableTextField
          {...initField("replaced_description", optionsinvoiceLines)}
        />
        <EditableSelectField
          {...initField("reference_reg", optionsinvoiceLines, "array")}
          choices={optionsinvoiceLines.reference_reg.choices}
        />
        <BooleanField {...initField("import_in_chart_accounts", optionsinvoiceLines)} />
        <NumberField {...initField("vat", optionsinvoiceLines)} />
        <NumberField {...initField("price", optionsinvoiceLines)} />
        <NumberField {...initField("quantity", optionsinvoiceLines)} />
        <NumberField {...initField("taxable", optionsinvoiceLines)} />
        <NumberField {...initField("amount", optionsinvoiceLines)} />
        {record.status !== "C" && (
          <FunctionField
            render={(record?: Record) =>
              record && record.charge_set.length <= 0 ? (
                <DeleteButton redirect={false} record={record} />
              ) : null
            }
          />
        )}
      </EditableDatagrid>

      <Dialog
        open={openAssChartAccDialog}
        onClose={() => setOpenAssChartAccDialog(false)}
      >
        <Card style={{ minWidth: "500px", padding: "15px" }}>
          <Typography variant="h5">
            Assegna a piano dei conti
          </Typography>
          <CardContent>
            <RecordContextProvider value={undefined}>
              <FormWithRedirect
                save={assigniToChartAccounts}
                initialValues={{ invoice_lines: selectedIds }}
                render={(formProps) => (
                  <>
                    <Grid container alignItems="flex-start">

                      <Grid item xs={6}>
                        <ReferenceInputChartAccounts
                          source="chart_accounts"
                          label="Piano dei conti"
                          fullWidth={true}
                          validate={[required()]}
                          filter={{ last_level: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ReferenceInputSite
                          source="site"
                          label="Sede"
                          fullWidth={true}
                          validate={[]}
                        />
                      </Grid>

                    </Grid>
                    <Box mt={1} display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={formProps.handleSubmit}
                        label="Assegna"
                      >
                        <></>
                      </Button>
                    </Box>
                  </>
                )}
              />
            </RecordContextProvider>
          </CardContent>
        </Card>


      </Dialog>
      <Dialog
        open={openCalcFuelDialog}
        onClose={() => setOpenCalcFuelDialog(false)}
      >
        <Card style={{ minWidth: "500px", padding: "15px" }}>
          <Typography variant="h5">
            Calcola Fuel
          </Typography>
          <CardContent>
            <RecordContextProvider value={undefined}>
              <FormWithRedirect
                save={calculateFuel}
                initialValues={{ invoice_lines: selectedIds }}
                render={(formProps) => (
                  <>
                    <Grid container alignItems="flex-start">

                      <Grid item xs={9}>
                        <TextInput
                          source="replaced_description"
                          label="Descrizione riga"
                          fullWidth={true}
                          validate={[required()]}
                          defaultValue={"Calcolo fuel"}
                        />
                      </Grid>

                    </Grid>
                    <Box mt={1} display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={formProps.handleSubmit}
                        label="Calcola"
                      >
                        <></>
                      </Button>
                    </Box>
                  </>
                )}
              />
            </RecordContextProvider>
          </CardContent>
        </Card>


      </Dialog>
    </>
  ) : null;
};
