import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { TariffForm } from "./TariffForm";

export const TariffCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props}>
      <TariffForm />
    </CustomCreate>
  );
};
