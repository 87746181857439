import { useResourceContext } from "react-admin";
import { useQuery } from "react-query";
import { client, getRealResource } from "../../dataProvider";

export function useOptionsResource(type: "POST" | "GET" | "PUT") {
  const resource = useResourceContext();
  return useQuery(
    ["options", resource, type],
    () =>
      client(getRealResource(resource), { method: "OPTIONS" }).then(
        (data) => data.actions[type]
      ),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
}
