import * as React from "react";
import { useCallback } from "react";
import get from "lodash/get";
import MenuItem from "@material-ui/core/MenuItem";
import { useInput, FieldTitle, useListContext } from "ra-core";
import {
  Labeled,
  LinearProgress,
  ResettableTextField,
  InputHelperText,
  sanitizeInputRestProps,
  SelectInputProps,
  useChoices,
  useSupportCreateSuggestion,
  warning,
} from "react-admin";
import { Dialog, IconButton, InputAdornment } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";

interface DialogSelectInputProps extends SelectInputProps {
  children: React.ReactElement;
}
export const DialogSelectInput = (props: DialogSelectInputProps) => {
  const {
    allowEmpty,
    choices = [],
    classes: classesOverride,
    className,
    create,
    createLabel,
    createValue,
    disableValue,
    emptyText,
    emptyValue,
    format,
    helperText,
    label,
    loaded,
    loading,
    margin = "dense",
    onBlur,
    onChange,
    onCreate,
    onFocus,
    options,
    optionText,
    optionValue,
    parse,
    resource,
    source,
    translateChoice,
    validate,
    children,
    styleMapFunction,
    ...rest
  } = props;
  const [open, setOpen] = React.useState(false);
  warning(
    source === undefined,
    `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the source prop`
  );

  warning(
    choices === undefined,
    `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the choices prop`
  );

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  const { id, input, isRequired, meta } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source: source!,
    validate,
    ...rest,
  });

  const { touched, error, submitError } = meta;
  const {
    // fetched data
    data,
    ids,
  } = useListContext();
  const renderEmptyItemOption = useCallback(() => {
    return React.isValidElement(emptyText)
      ? React.cloneElement(emptyText)
      : emptyText === ""
        ? " " // em space, forces the display of an empty line of normal height
        : " ";
  }, [emptyText]);

  const renderMenuItemOption = useCallback(
    (choice) => getChoiceText(choice),
    [getChoiceText]
  );

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLSelectElement>, newItem) => {
      if (newItem) {
        const value = getChoiceValue(newItem);
        input.onChange(value);
        return;
      }

      input.onChange(event);
    },
    [input, getChoiceValue]
  );

  const {
    getCreateItem,
    handleChange: handleChangeWithCreateSupport,
    createElement,
  } = useSupportCreateSuggestion({
    create,
    createLabel,
    createValue,
    handleChange,
    onCreate,
  });
  if (loading) {
    return (
      <Labeled
        id={id}
        label={label}
        source={source}
        resource={resource}
        className={className}
        isRequired={isRequired}
        meta={meta}
        input={input}
        margin={margin}
      >
        <LinearProgress />
      </Labeled>
    );
  }

  const createItem = getCreateItem();

  return (
    <>
      <ResettableTextField
        id={id}
        {...input}
        onChange={handleChangeWithCreateSupport}
        select
        label={
          label !== "" &&
          label !== false && (
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          )
        }
        className={`${className}`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={rest.disabled}
                aria-label="Seleziona tariffa"
                title="Seleziona tariffa"
                onClick={() => setOpen(true)}
                style={{ marginRight: "15px" }}
              >
                <ListIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={!!(touched && (error || submitError))}
        helperText={
          helperText && (
            <InputHelperText
              touched={!!touched}
              error={error || submitError}
              helperText={helperText}
            />
          )
        }
        margin={margin}
        {...options}
        {...sanitizeRestProps(rest)}
      >
        {allowEmpty ? (
          <MenuItem value={emptyValue} key="null" aria-label={""} title={""}>
            {renderEmptyItemOption()}
          </MenuItem>
        ) : null}
        {choices.map((choice) => (
          <MenuItem
            key={getChoiceValue(choice)}
            value={getChoiceValue(choice)}
            disabled={get(choice, disableValue)}
            style={styleMapFunction ? styleMapFunction(choices, getChoiceValue(choice)) : {}}
          >
            {renderMenuItemOption(choice)}
          </MenuItem>
        ))}
        {onCreate || create ? (
          <MenuItem value={createItem.id} key={createItem.id}>
            {createItem.name}
          </MenuItem>
        ) : null}
      </ResettableTextField>
      {createElement}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        {React.cloneElement(children, {
          data,
          ids,
          loaded: !loading,
          onToggleItem: (id: any) => {
            input.onChange(id);
            setOpen(false);
          },
          rowClick: "toggleSelection",
          ...children.props,
        })}
      </Dialog>
    </>
  );
};

const sanitizeRestProps = ({
  addLabel,
  afterSubmit,
  allowNull,
  beforeSubmit,
  choices,
  className,
  crudGetMatching,
  crudGetOne,
  data,
  filter,
  filterToQuery,
  formatOnBlur,
  isEqual,
  limitChoicesToValue,
  multiple,
  name,
  pagination,
  perPage,
  ref,
  reference,
  refetch,
  render,
  setFilter,
  setPagination,
  setSort,
  sort,
  subscription,
  type,
  validateFields,
  validation,
  value,
  ...rest
}: any) => sanitizeInputRestProps(rest);
