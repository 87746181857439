import { CreateProps } from "react-admin";
import { CustomCreate } from "../components/CustomCreate";
import { SiteForm } from "./SiteForm";

export const SiteCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props} component="div">
      <SiteForm />
    </CustomCreate>
  );
};
