import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useQuery } from "ra-core";
import { Delivery } from "../../shared/types";
import { EditSegmentDeliveryPayload, Segment } from "../types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import {
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
  TextField,
} from "ra-ui-materialui";
import { FormSaveButton } from "../../components/FormSaveButton";
import { AutocompleteInput, ReferenceField, ReferenceInput, TextInput } from "react-admin";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";

interface NewSegmentDialogProps {
  onClose?: () => void;
  open: boolean;
  delivery?: Delivery;
  onSave: (data: EditSegmentDeliveryPayload) => void;
}
export const NewSegmentDialog = (props: NewSegmentDialogProps) => {
  const { data } = useQuery(
    {
      type: "getList",
      resource: "segments",
      payload: {
        pagination: {
          page: 1,
          perPage: Infinity,
        },
        filter: { delivery: props.delivery?.id },
        sort: { field: "order_on_delivery", order: "ASC" },
      },
    },
    { enabled: !!props.delivery }
  );
  return props.delivery && data ? (
    <Dialog onClose={props.onClose} open={props.open} fullWidth maxWidth="xl">
      <DialogTitle>Modifica tratta missione {props.delivery?.id}</DialogTitle>

      <Form
        onSubmit={props.onSave}
        mutators={{ ...arrayMutators }} // necessary for ArrayInput
        keepDirtyOnReinitialize
        initialValues={{
          delivery_id: props.delivery?.id,
          segments: data
            ? data.map(
              ({
                arrival_point,
                starting_point,
                to_be_tractioned,
                split_id,
                route_id
              }: Segment) => ({
                arrival_point,
                starting_point,
                to_be_tractioned,
                split_id,
                route_id
              })
            )
            : [],
        }}
        render={(formProps) => (
          <>
            <DialogContent>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item>
                  <b>Mittente: </b>
                  <ReferenceField
                    record={props.delivery}
                    source="sender"
                    reference="subjects"
                  >
                    <TextField source="business_name" />
                  </ReferenceField>
                </Grid>
                <Grid item>
                  <b>Destinatario: </b>
                  <ReferenceField
                    record={props.delivery}
                    source="receiver"
                    reference="subjects"
                  >
                    <TextField source="business_name" />
                  </ReferenceField>
                </Grid>
                <Grid item>
                  <b>Peso: </b>
                  {`${props.delivery!.weight!}`}
                </Grid>
                <Grid item>
                  <b>Colli: </b>
                  {`${props.delivery!.packages_number!}`}
                </Grid>
              </Grid>
              <ArrayInput source="segments" label="Tratta">
                <SimpleFormIterator>
                  <ReferenceInputSubject
                    source="starting_point.id"
                    label="Da"
                  />
                  <BooleanInput
                    source="to_be_tractioned"
                    label="Da trazionare"
                  />
                  <ReferenceInputSubject source="arrival_point.id" label="A" />
                  <TextInput source="split_id" label="Split" disabled />

                  <ReferenceInput
                    label="Giro"
                    source="route_id"
                    reference="autocomplete/route"
                    allowEmpty={true}
                    isRequired={false}
                  >
                    <AutocompleteInput
                      optionText="label"
                    />
                  </ReferenceInput>

                </SimpleFormIterator>
              </ArrayInput>
            </DialogContent>
            <DialogActions>
              <FormSaveButton
                pristine={formProps.pristine}
                submitting={formProps.submitting}
                handleSubmit={formProps.handleSubmit}
              />
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  ) : null;
};
