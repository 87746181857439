import { Button, ButtonProps } from "ra-ui-materialui";
import React, { useEffect, useState } from "react";
import CheckCircle from "@material-ui/icons/CheckCircle";
import IconCancel from "@material-ui/icons/Cancel";
import { useToggler } from "../../shared/hooks/useToggler";
import { Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import { FormWithRedirect, TextInput, useCreate, useNotify } from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { generateError } from "../../shared/utils";
import { DeliveryStatusChoice } from "../../shared/types";

export const AdditionalActions = (props: ButtonProps) => {
  const { value, setTrue, setFalse } = useToggler();
  const [firstOpening, setFirstOpening] = useState(true);

  const [create, { loading }] = useCreate();

  const notify = useNotify();

  const handleSubmit = (values: any) => {
    if (values.delivery === null || values.delivery === "") {
      notify("Inserire il numero di consegna", "error");
      return;
    }
    else {
      values.delivery = values.delivery.replace("S.", "")
    }
    create("carrier_deliverystates", values, {
      onSuccess: ({ data }) => {
        notify(`Missione ${values.delivery} CONSEGNATA`, "success");
      },
      onFailure: (error) => {
        console.log(error);
        notify(`ERRORE Missione ${values.delivery}`, "error");
      },
    });
  };

  useEffect(() => {
    if (value === false && firstOpening === false) {
      window.location.reload();
    }
    if (firstOpening === true) {
      setFirstOpening(false);
    }
  }, [value]);

  return (
    <>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="delivery-states"
          save={handleSubmit}
          initialValues={{
            delivery: null,
            status: DeliveryStatusChoice.DELIVERED,
          }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <Typography variant="h6">Consegna missioni</Typography>
                <TextInput source="delivery" fullWidth onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    handleSubmitWithRedirect!();
                    form.change("delivery", null);
                  }
                }} />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
      <Button {...props} label="Consegna missioni" onClick={setTrue}>
        <CheckCircle />
      </Button>
    </>

  );
};
