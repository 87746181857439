import get from 'lodash/get'
import { FieldTitle, useInput, useRecordContext, Validator } from 'ra-core'
import { useEffect, useMemo, useRef, useState } from 'react'
import './ColorInput.css'
import { TextField, TextInput } from 'react-admin'
import * as ReactColor from 'react-color'
import { useForm } from 'react-final-form'
import { Grid } from '@material-ui/core'

interface ColorFieldProps {
    source: string
    // eslint-disable-next-line react/no-unused-prop-types
    label?: string
    className?: string
}

export const ColorField = ({ source, className }: ColorFieldProps) => {
    const record = useRecordContext()

    return (
        <div style={{ display: 'flex' }}>
            <div
                style={{
                    width: '20px',
                    height: '20px',
                    background: get(record, source),
                    marginRight: '5px',
                }}
            />
            <span className={className}>{get(record, source)}</span>
        </div>
    )
}

interface ColorInputProps {
    resource?: string
    label?: string
    options?: {
        disableAlpha: true
    }
    source: string
    helperText?: string
    className?: string
    isRequired?: boolean
    validate?: Validator | Validator[] | undefined
    variant?: 'filled' | 'outlined' | 'standard'
    picker?:
    | 'Alpha'
    | 'Block'
    | 'Check'
    | 'Chrome'
    | 'Circle'
    | 'Compact'
    | 'Github'
    | 'Hue'
    | 'Material'
    | 'Photoshop'
    | 'Sketch'
    | 'Slider'
    | 'Swatches'
    | 'Twitter'
    | 'Custom'
}


export const ColorInput = (props: ColorInputProps) => {
    const [show, setShow] = useState(false)
    const txtRef = useRef(null as any)
    const {
        input
    } = useInput({
        ...props,
    })
    let value = input.value
    const [tempValue, setTempValue] = useState(value)
    const form = useForm();

    const {
        label,
        source,
        className,
        options,
        picker = 'Chrome',
        resource,
        helperText,
        validate,
        variant = 'standard',
    } = props


    const handleOpen = () => {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
        txtRef.current.value = tempValue;
        form.change(props.source, tempValue);
    }
    const handleChange = ({ hex }: any) => {
        txtRef.current.value = hex; //onChange(e)
        value = hex; //onChange(e)
        setTempValue(value); //onChange(e)
        form.change(props.source, value);
    }

    const Picker = ReactColor[`${picker}Picker` as keyof typeof ReactColor]

    return (
        <div>
            <Grid container>
                <Grid item xs={8}>
                    <TextInput
                        inputRef={txtRef}
                        source={source}
                        label={label}
                        value={value}
                        onChange={(e) => {
                            value = e.target.value
                            setTempValue(value)
                        }}
                        onFocus={handleOpen}
                    />

                </Grid>
                <Grid item xs={4}>
                    <div style={{
                        float: "left", height: "40px", width: "40px", marginBottom: "15px",
                        border: "1px solid black", clear: "both", backgroundColor: tempValue,
                        marginTop: "10px"
                    }}></div>
                </Grid>
            </Grid>
            {show ? (
                <div className="ColorInput-popup">
                    {/* eslint-disable-next-line */}
                    <div className="ColorInput-cover" onClick={handleClose} />
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Picker {...options} color={tempValue} onChange={handleChange} />
                </div>
            ) : null}
        </div>
    )
}