import { Grid } from "@material-ui/core";
import { green, orange, red } from '@material-ui/core/colors';
import { stringify } from "querystring";
import { useListContext, useResourceContext } from "react-admin";
import { useQuery } from "react-query";
import { client } from "../../dataProvider";
import { useListQueryParams } from "../../shared/hooks/useListQueryParams";
import { DescriptionOutlined, Euro } from "@material-ui/icons";
import { ClearAll } from '@material-ui/icons';
import { StatCard } from "../../components/StatCard";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import ListAltIcon from '@material-ui/icons/ListAlt';

export const DeliveryListHeader = () => {
  const resource = useResourceContext();
  const query = useListQueryParams();
  const url = `${resource}/statistics/?${stringify(query)}`;
  const { data: stats } = useQuery(
    [url],
    () => client(url, { method: "GET" }),
    {
      initialData: {
        value: 0, value_unique: 0, value_initial: 0, value_final: 0,
        value_estimate_totals: 0, missing_borderaux: 0
      }
    }
  );
  const { total } = useListContext();
  return (
    <Grid container style={{ paddingTop: "0px", paddingBottom: "0px" }}>
      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`${total ? total : 0}`}
          icon={<ClearAll />}
          subtitle="N° missioni"
        />
      </Grid>

      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`${stats.value_unique}`}
          icon={<DescriptionOutlined />}
          subtitle="Missioni con Bolle"
        />
      </Grid>

      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`${stats.value_initial}`}
          icon={<DescriptionOutlined />}
          subtitle="Missioni con Bolle iniziali"
          color={orange[500]}
        />
      </Grid>

      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`${stats.value_final}`}
          icon={<DescriptionOutlined />}
          subtitle="Missioni con Bolle Finali"
          color={green[500]}
        />
      </Grid>

      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`${stats.total_package_number ? stats.total_package_number : 0}`}
          icon={<CropSquareIcon />}
          subtitle="Colli Totali"
        />
      </Grid>

      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`${Math.round(stats.total_weight ? stats.total_weight : 0)}`}
          icon={<EqualizerIcon />}
          subtitle="Peso Totale in kg"
        />
      </Grid>

      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`${stats.value_estimate_totals}`}
          icon={<Euro />}
          subtitle="Stima totale €"
        />
      </Grid>

      <Grid item style={{ width: "25%" }}>
        <StatCard
          label={`${stats.missing_borderaux}`}
          icon={<ListAltIcon />}
          subtitle="Borderaux mancanti"
          color={red[500]}
        />
      </Grid>
    </Grid>
  );
};
