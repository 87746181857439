import { Dialog, DialogContent, DialogActions, Typography } from "@material-ui/core";
import {
  ArrayField,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  FormWithRedirect,
  FunctionField,
  NumberField,
  ReferenceInput,
  required,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useDelete,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, generateError } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import { useState } from "react";

interface DeleteDeliveryStatusButtonProps { refreshMain?: boolean | undefined }

export const DeleteDeliveryStatusButton = (props: DeleteDeliveryStatusButtonProps) => {
  const record = useRecordContext();
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const [deleteOne, { loading, loaded }] = useDelete();
  const notify = useNotify();
  const handleSubmit = (values: any) => {

    deleteOne("delivery-states", values.id, undefined, {
      onSuccess: () => {
        setFalse();
        refresh();
        if (props.refreshMain) {
          window.location.reload();
        }
      },
      onFailure: (error) => {
        generateError(error, notify);
      },
    });
  };
  const { data: options } = useOptions("delivery-states", "GET");


  return options && (record.status === "D" || record.status === "SE" || record.status === "CA") ? (
    <>
      <Button tabIndex={-1} onClick={() => setTrue()}>
        <DeleteIcon />
      </Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="delivery-states"
          save={handleSubmit}
          initialValues={{ id: record.id }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>

                <Typography>Sicuro di voler cancellare il seguente stato?</Typography>


              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={false}
                  label="Cancella stato"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
