import React, { useState } from "react";
import {
  Button,
  ButtonProps,
  useListContext,
  useNotify,
  useRefresh,
  useResourceContext,
  useUnselectAll,
} from "react-admin";
import PublishIcon from "@material-ui/icons/Publish";
import { client } from "../dataProvider";
import { Delivery } from "../shared/types";
import {
  AppBar,
  CircularProgress,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  SlideProps,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Toolbar,
} from "@material-ui/core";
import { useToggler } from "../shared/hooks/useToggler";
import CloseIcon from "@material-ui/icons/Close";
import { ReportTabPanel } from "./ReportTabPanel";
import { FileImportFormDialog } from "./FileImportFormDialog";

interface ReportImport {
  file_error: [{
    file: string;
    error: string;
  }]
  info_import: {
    error: Delivery[];
    existing: Delivery[];
    file_name: string;
    no_price: Delivery[];
  }[];
  subjects_missing: number;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    overflowX: "hidden",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxWidth: "350px",
  },
  tab: { borderBottom: `1px solid ${theme.palette.divider}` },
}));

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ImportButton = (props: ButtonProps) => {
  const classes = useStyles();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const resource = useResourceContext();
  const { selectedIds } = useListContext();
  const unselecteAll = useUnselectAll("upload");
  const [reportImport, setReportImport] = useState<ReportImport | undefined>();
  const refresh = useRefresh();
  const { value, setTrue: openDialog, setFalse: closeDialog } = useToggler();
  const {
    value: isOpenDialogClientAssignment,
    setTrue: openDialogClientAssignment,
    setFalse: closeDialogClientAssignment,
  } = useToggler();

  const [openTab, setOpenTab] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setOpenTab(newValue);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    client(`${resource}/execute_import`, { method: "POST", data: values })
      .then((data) => {
        setReportImport(data);
        openDialog();

        if ("subjects_missing" in data && data.subjects_missing > 0)
          window.open(window.location.origin + "/subjects-solver", "_blank");
      })
      .catch((error) => {
        notify("ra.notification.http_error", "error");
      })
      .finally(() => setLoading(false));
  };

  const onDialogClose = () => {
    closeDialog();
    refresh();
    unselecteAll();
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={loading}
        message="Caricando..."
        action={
          <React.Fragment>
            <CircularProgress />
          </React.Fragment>
        }
      />
      <Button
        {...props}
        disabled={selectedIds.length <= 0}
        label="Importa"
        onClick={openDialogClientAssignment}
      >
        <PublishIcon />
      </Button>
      <FileImportFormDialog
        selectedIds={selectedIds}
        onSubmit={handleSubmit}
        isOpen={isOpenDialogClientAssignment}
        onClose={closeDialogClientAssignment}
        loading={loading}
      />
      {reportImport && (
        <Dialog
          onClose={onDialogClose}
          open={value}
          fullScreen
          TransitionComponent={Transition}
        >
          <AppBar position="static" color="secondary">
            <Toolbar variant="dense">
              <IconButton
                edge="start"
                color="inherit"
                onClick={onDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <Tabs
              value={openTab}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              orientation="vertical"
              variant="scrollable"
              aria-label="Report file import"
              scrollButtons="auto"
              className={classes.tabs}
            >
              <Tab
                label={"In errore"}
                disabled={reportImport.file_error.length <= 0}
                {...a11yProps(0)}
                className={classes.tab}
              />
              {reportImport.info_import.map((report, index) => (
                <Tab
                  key={index}
                  label={report.file_name}
                  {...a11yProps(index + 1)}
                  className={classes.tab}
                />
              ))}
            </Tabs>
            <div
              role="tabpanel"
              hidden={openTab !== 0}
              id={`vertical-tabpanel-0`}
              aria-labelledby={`vertical-tab-0`}
              style={{ overflowX: "auto", flex: "1" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome file</TableCell>
                    <TableCell>Errore</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportImport.file_error.map((file, index) => (
                    <TableRow key={index}>
                      <TableCell>{file.file}</TableCell>
                      <TableCell>{file.error}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {reportImport.info_import.map((report, index) => (
              <ReportTabPanel
                key={index}
                value={openTab}
                index={index + 1}
                report={report}
              />
            ))}
          </div>
        </Dialog>
      )}
    </>
  );
};
