import { CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  FunctionField,
  NumberField,
  Record,
  Identifier,
  useListContext,
  Button,
  useRecordContext,
  useNotify,
  useRedirect,
  DeleteButton,
  TextField,
} from "react-admin";
import {
  EditableDatagrid,
  EditButtonDatagrid,
} from "../../components/EditableDatagrid";


import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";

const useStyles = makeStyles({
  root: {
    padding: '30px',
  },
});

export const InvoiceChartAccountsDatagrid = () => {
  const { data: optionsinvoiceLines } = useOptions("invoice-lines", "GET");
  const { data: optionsInvoiceChartAccounts } = useOptions("invoicechartaccounts", "GET");
  const record = useRecordContext();
  const {
    data,
    ids,
    onSelect: onSelectFromContext,
    selectedIds,
    onToggleItem: onToggleItemContext,
  } = useListContext();
  const [selectedCharges, setSelectedCharges] = useState<Identifier[]>([]);
  const notify = useNotify();
  const redirect = useRedirect();
  const [saving, setSaving] = useState(false);

  const [sum, setSum] = useState(0.0);
  const [rest, setRest] = useState(0.0);

  useEffect(() => {
    let s = 0.0;
    for (let d in data) {
      s += data[d].amount
    }
    setSum(s);
    if ((record.amount - s) < 0) {
      setRest(0)
    } else {
      setRest(record.amount - s)
    }
  },
    [data, record])

  const classes = useStyles();

  return optionsinvoiceLines && optionsInvoiceChartAccounts ? (
    <>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={6}>
          {parseFloat(sum.toFixed(2)) > record.amount ? (
            <Typography variant="h5" color="error">
              Associati al piano dei conti: {sum.toFixed(2)} €
              <br></br>
              Attenzione importo superiore al totale della fattura
            </Typography>
          ) : (
            <Typography variant="h5">Associati al piano dei conti: {sum.toFixed(2)} €</Typography>
          )
          }


        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5" color={rest.toFixed(2) !== "0.00" ? ("error") : ("initial")}>
            Non associati al piano dei conti: {rest.toFixed(2)} €
          </Typography>
        </Grid>

      </Grid>

      <EditableDatagrid
        selectedIds={selectedIds}
      >
        <TextField source="chart_accounts_label" label="Piano dei conti" />
        <TextField source="site__business_name" label="Sede" />
        <NumberField {...initField("amount", optionsinvoiceLines)} />
        {record.status !== "C" && (
          <FunctionField
            render={(record?: Record) =>
              record ? (
                <DeleteButton redirect={false} record={record} />
              ) : null
            }
          />
        )}
      </EditableDatagrid>

    </>
  ) : null;
};
