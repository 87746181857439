import { Card } from "@material-ui/core";
import { EditProps } from "react-admin";
import { DefaultEdit } from "../components/DefaultEdit";
import { CategoryForm } from "./CategoryForm";

export const CategoryEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component={Card}>
      <CategoryForm />
    </DefaultEdit>
  );
};
