import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteInput,
  DateInput,
  FormDataConsumer,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
} from "react-admin";
import { NumberInput } from "../../components/NumberInput";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { dateFormatter, initInput } from "../../shared/utils";
import { useOptions } from "../../shared/hooks/useOptions";

export const PaymentStatusForm = (props: FormProps) => {
  const { data: options } = useOptions("invoice-payment-status", "POST");

  return (options ?
    (<FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"row"}
                spacing={2}
              >
                <Grid item xs={12}>
                  <DateInput
                    initialValue={dateFormatter(new Date())}
                    {...initInput("date", options, true)}
                  />
                  <SelectInput
                    {...initInput("status", options, true, "array")}
                    choices={options.status.choices}
                  />
                  <FormDataConsumer>
                    {({ formData }) => (
                      <>
                        {formData.status === "A" && (
                          <NumberInput
                            {...initInput("amount", options, true)}
                            validate={[required()]}
                          />
                        )}
                        {["A", "I"].includes(formData.status) && (
                          <SelectInput
                            {...initInput(
                              "collection_type",
                              options,
                              true,
                              "array"
                            )}
                            choices={options.collection_type.choices}
                            validate={[required()]}
                          />
                        )}
                      </>
                    )}
                  </FormDataConsumer>
                  <TextInput {...initInput("notes", options, true)} />

                </Grid>
              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />) : null
  );
};
