import { BooleanInput, Button, ButtonProps, DateInput, FormDataConsumer, FormWithRedirect, ReferenceInput, required, SaveButton, SelectInput, TextInput, useListContext, useNotify } from "react-admin";
import DescriptionIcon from "@material-ui/icons/Description";
import { client, clientNoJson } from "../../dataProvider";
import { useState } from "react";
import { url as URL } from "../../constants";
import { CircularProgress, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { generateError, initInput } from "../../shared/utils";
import { useToggler } from "../../shared/hooks/useToggler";
import { useOptions } from "../../shared/hooks/useOptions";
import IconCancel from "@material-ui/icons/Cancel";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DeliveryType } from "../../shared/types";

export const CloneDeliveriesBulkButton = (props: ButtonProps) => {
  const notify = useNotify();

  const { value, setTrue, setFalse } = useToggler();
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { data: options } = useOptions("deliveries", "GET");
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const onClick = (values: any) => {
    setLoading(true);

    client(`deliveries/clone_deliveries`, {
      method: "POST",
      data: {
        ids: selectedIds,
        ...values
      }
    }).then((response) => {
      notify("Missioni clonate");
      setFalse();
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
      });
    refetch();

  };


  return (
    <>
      <Button
        {...props}
        disabled={loading || selectedIds.length <= 0}
        label="Clona"
        title="Clona missioni"
        onClick={setTrue}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <FileCopyIcon />}
      </Button>

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          initialValues={{}}
          save={onClick}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <SelectInput
                  source="type"
                  choices={options.delivery_type.choices}
                  optionText="display_name"
                  optionValue="value"
                  validate={[required()]}
                  label="Tipo"
                  fullWidth
                  defaultValue={DeliveryType.NORMAL}
                />
                <BooleanInput
                  source="exclude_first_segment"
                  label="Escludi prima tratta"
                  fullWidth
                  defaultValue={true}
                />
                <BooleanInput
                  source="exclude_last_segment"
                  label="Escludi ultima tratta"
                  fullWidth
                  defaultValue={false}
                />
                <BooleanInput
                  source="exclude_all_segment"
                  label="Escludi tutte le tratte"
                  fullWidth
                />
                <BooleanInput
                  source="automatic_tariff"
                  label="Converti tariffa"
                  fullWidth
                />

                <DateInput
                  {...initInput(
                    "new_document_date",
                    true
                  )}
                  label="Nuova data documneto"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
