import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { CachetForm } from "./CachetForm";

export const CachetEdit = (props: EditProps) => {

  return (
    <DefaultEdit {...props}>
      <CachetForm  />
    </DefaultEdit>
  );
};
