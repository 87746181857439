import { Grid, Typography } from "@material-ui/core";
import { NumberField, useListContext, useResourceContext } from "react-admin";
import { useQuery } from "react-query";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import MoneyIcon from "@material-ui/icons/Money";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { useListQueryParams } from "../shared/hooks/useListQueryParams";
import { client, getRealResource } from "../dataProvider";
import { StatCard } from "../components/StatCard";


export const InvoiceListHeader = () => {
  const resource = useResourceContext();
  const query = useListQueryParams();
  const { filterValues, filter, ids } = useListContext();
  const url = `${getRealResource(resource)}/statistics`;


  const { data: stats } = useQuery(
    [url, query, filterValues, filter, ids],
    () =>
      client(url, {
        method: "GET",
        params: {
          ...query,
          ...filter,
          ...filterValues,
        },
        paramsSerializer: function handleQuery(q: any) {
          return Object.entries(q).map(([key, value], i) => Array.isArray(value) ? `${key}=${value.join('&' + key + '=')}` : `${key}=${value}`).join('&');
        }
      }),
    {
      initialData: {
        total_amount: 0
      },
    }
  );
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="taxable__sum"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
          }
          icon={<AttachMoneyIcon />}
          subtitle="Totale imponibile"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="amount__sum"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
          }
          icon={<AttachMoneyIcon />}
          subtitle="Totale importo"
        />
      </Grid>

    </Grid>
  );
};
