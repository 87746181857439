import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  BooleanInput,
  Button,
  DateInput,
  FormDataConsumer,
  FormWithRedirect,
  required,
  SelectInput,
  TextInput,
  useCreate,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, generateError, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import { NumberInput } from "../../components/NumberInput";
import { useCallback } from "react";

export const AddInvoicePaymentStatusButton = () => {
  const record = useRecordContext();
  const { data: termsPayment } = useGetOne(
    "payment-terms",
    record.terms_payment_id
  );
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const [create, { loading }] = useCreate();
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    if (values.status === "I" || values.status === "S") {
      values.amount = null;
    }
    create("invoice-payment-status", values, {
      onSuccess: (data) => {
        setFalse();
        refresh();
      },
      onFailure: (error) => {
        generateError(error, notify);
      },
    });
  };
  const paymentStatusChoices = useCallback(() => {
    if (termsPayment && termsPayment.method_code === "MP12")
      return [
        { value: "I", display_name: "Fattura incassata" },
      ];
    return [
      { value: "I", display_name: "Fattura incassata" },
      { value: "A", display_name: "Acconto" },
      { value: "S", display_name: "Pagamento sollecitato" },
    ];
  }, [termsPayment]);
  const { data: options } = useOptions("invoice-payment-status", "POST");
  return options ? (
    <>
      <Button
        variant="outlined"
        label="Aggiorna stato"
        onClick={() => setTrue()}
      ></Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="invoice-payment-status"
          save={handleSubmit}
          initialValues={{ invoice: record.id }}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <DateInput
                  initialValue={dateFormatter(new Date())}
                  {...initInput("date", options, true)}
                />
                <SelectInput
                  {...initInput("status", options, true, "array")}
                  choices={paymentStatusChoices()}
                />
                <FormDataConsumer>
                  {({ formData }) => (
                    <>
                      {formData.status === "A" && (
                        <NumberInput
                          {...initInput("amount", options, true)}
                          validate={[required()]}
                        />
                      )}
                      {["A", "I"].includes(formData.status) && (
                        <SelectInput
                          {...initInput(
                            "collection_type",
                            options,
                            true,
                            "array"
                          )}
                          choices={options.collection_type.choices}
                          validate={[required()]}
                        />
                      )}
                    </>
                  )}
                </FormDataConsumer>
                <TextInput {...initInput("notes", options, true)} />
                <FormDataConsumer>
                  {({ formData }) => (
                    <>
                      {formData.status === "S" && (
                        <BooleanInput
                          {...initInput("send_email", options, true)}
                        />
                      )}
                      {formData.send_email && (
                        <>
                          <TextInput
                            {...initInput("email_receiver", options, true)}
                            defaultValue={`${record.client.email}${record.client.email.includes(";") ? "" : ";"
                              }`}
                          />
                          <TextInput
                            {...initInput("email_subject", options, true)}
                            defaultValue="SOLLECITO PAGAMENTO"
                          />
                          <TextInput
                            {...initInput("email_text", options, true)}
                            multiline
                            defaultValue={
                              `Gentile cliente,\nriportiamo in allegato il Suo estratto conto relativo al ns avere alla data odierna.\nLa preghiamo pertanto di regolarizzare al più presto la sua posizione contabile, ricordando che il pagamento può essere effettuato a mezzo bonifico bancario, utilizzando i riferimenti presenti nella fattura elettronica.\n\nNel caso in cui il bonifico sia stato già disposto, potrà ritenere nulla la presente richiesta.\nIn attesa di suo gradito riscontro e restando a disposizione per eventuali delucidazioni,\nci è gradita l’occasione per porgere distinti saluti.`
                            }
                            rows={10}
                          />
                        </>
                      )}
                    </>
                  )}
                </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={false}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
