import {
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  Record,
  FunctionField,
  SelectField,
  ArrayField,
  ChipField,
  SingleFieldList,
  ReferenceArrayField,
  SelectInput,
  Button,
  Identifier,
} from "react-admin";
import { DriverField } from "../../components/DriverField";
import { CarrierField } from "../../components/CarrierField";
import { VehicleField } from "../../components/VehicleField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { getFileNameFromContentDisposition, initField } from "../../shared/utils";
import { GoToDeliveriesButton } from "../../components/GoToDeliveriesButton";
import { GoToCachetsButton } from "../../components/GoToCachetsButton";
import { PrintDocumentsButton } from "./PrintDocuments";
import { Print2XDocumentsButton } from "./Print2XDocuments";
import { DownloadFileButton } from "../../components/DownloadFileButton";
import { IdentifierField } from "../../components/IdentifierField";
import React from "react";
import { PrintRouteRemittanceButton } from "./PrintRouteRemittanceButton";
import { UndoSplitAndDeleteButton } from "./UndoSplitAndDelete";
import { SendTrackMeButtonsButtonSingle } from "./SendTrackMeButtonSingle";
import TocIcon from '@material-ui/icons/Toc';
import { getUser } from "../../authProvider";
import { DriverSummaryPdfButton } from "./DriverSummaryPdfButton";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { clientNoJson } from "../../dataProvider";
import { url } from "../../constants";
import { Typography } from "@material-ui/core";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloseIcon from '@material-ui/icons/Close';

let green = "rgb(76, 175, 80)";
let yellow = "rgb(255, 152, 0)";
let red = "#f44336";

export const downloadNote = async (routeId: Identifier, resource?: string) => {
  if (!resource) {
    resource = "notes"
  }
  let res = null;
  try {
    res = await clientNoJson(`${url}/${`${resource}/${routeId}/download/`}`, {
      responseType: "arraybuffer",
      method: "GET",
    });
  } catch (err) {
    return;
  }
  return res;
};

export const saveNoteToFile = async (routeId: Identifier) => {
  const res = await downloadNote(routeId, "routes");
  if (res) {
    const data = res.data;
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  }
};

export const RouteDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {

  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <SelectField
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />
      <FunctionField
        {...initField("file_info", options)}
        label={"File"}
        render={(tableRecord: any) => {
          if (tableRecord.file_info.name) {
            return (
              <Button onClick={() => {
                saveNoteToFile(tableRecord.id)
              }} >
                <AssignmentTurnedInIcon style={{ color: green[500] }} />
              </Button>);

          }
          return (<CloseIcon style={{ color: red[500] }} />);
        }}
      />
      <FunctionField
        sortable={false}
        source="deliveries_not_done"
        label={"!!!"}
        render={(tableRecord: Record | undefined) => {
          if (!tableRecord) {
            return null;
          }
          let color = green;
          let n = tableRecord.deliveries_not_done;

          if (tableRecord.deliveries_not_done == -1) {
            n = "X"
            color = green;
          }
          else if (tableRecord.deliveries_not_done > 0) {
            color = red;
          }

          return (
            <div style={{
              borderRadius: "50%",
              width: "15px",
              height: "15px",
              padding: "3px",
              background: color,
              //border: "1px solid #000",
              color: "#000",
              textAlign: "center",
              font: "14px Arial, sans-serif",
              cursor: "pointer"
            }}
              title={n !== "X" ? `Numero di missioni non completate ${n.toString()}` : "Non disponibile per missioni con stato diverso da partito"}
            >
              <b>{n}</b>
            </div>
          );
          return null;
        }}
      />
      <DateField showTime {...initField("printed_time", options)} />
      <TextField {...initField("device", options)} />
      <NumberField {...initField("total_distance", options)} />
      <DateField {...initField("planned_date", options)} showTime />
      <BooleanField {...initField("sent", options)} />
      <BooleanField {...initField("show_on_maps", options)} label="MsM" title="Mostra su mappa" />
      <FunctionField
        label={"Autista/Vettore"}
        render={(record: Record | undefined) =>
          record ? (
            record.driver ? (<DriverField {...initField("driver", options)} />) : (<CarrierField {...initField("carrier", options)} />)
          ) : null
        } />
      <VehicleField {...initField("vehicle", options)} />
      <GoToDeliveriesButton filterAttribute={"route"} label="Miss." />
      <GoToCachetsButton filterAttribute={"route"} label="Contr." />
      <ArrayField source="tags_objects" label="Tag">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" source="label" />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      {/*
      <ArrayField source="zones" label="Zone">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" record={{ id: record }} source="id" />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      */}
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <Print2XDocumentsButton mission_max_page={record.mission_max_page} />
          ) : null
        }
      />
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <DownloadFileButton
              title="Borderau pdf"
              urlDownload={`routes/${record.id}/get_pdf_borderau/`}
            />
          ) : null
        }
      />
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <DownloadFileButton
              title="Borderau excel"
              urlDownload={`routes/${record.id}/get_excel_borderau/`}
              icon={<TocIcon />}
            />
          ) : null
        }
      />
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <PrintDocumentsButton mission_max_page={record.mission_max_page} />
          ) : null
        }
      />
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <DriverSummaryPdfButton props={record} />
          ) : null
        }
      />

      {getUser().permissions.includes("route_management.delete_route") && <UndoSplitAndDeleteButton />}
      <FunctionField
        render={(record: Record | undefined) =>
          record ? (
            <SendTrackMeButtonsButtonSingle {...{ id: `${record.id}` }} />
          ) : null
        }
      />
      {getUser().permissions.includes("cash_on_delivery.display_cachet_cashed") && <PrintRouteRemittanceButton />}

    </Datagrid>
  );
};
