import { Checkbox, Switch } from "@material-ui/core";
import React from "react";
import {
  FieldProps,
  Record,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
  useUpdate,
} from "react-admin";

export const LoadedEditableCheckboxField = (
  props: FieldProps & {
    source: string;
    disabled?: (record: Record) => boolean;
    setLoadComplete: any,
    setActiveDelivery: (charge: Record | undefined) => void
  }
) => {
  const { disabled, ...rest } = props;
  const record = useRecordContext(rest);
  const [update] = useUpdate();
  const resource = useResourceContext();
  const { refetch, data, ids } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();
  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Checkbox
        disabled={disabled && disabled(record)}
        checked={record[props.source]}
        onChange={(e, value) => {
          update(
            {
              resource: "segments",
              payload: {
                id: record.id,
                data: {
                  [props.source]: value
                },
              }
            },
            {
              onSuccess: () => {
                record.loaded = value;
                if (value === true) {
                  record.delivery_status = "L";
                  record.delivery_status_display = "Carica-";
                }
                else {
                  record.delivery_status = "G";
                  record.delivery_status_display = "Piani-";
                }
                let allLoaded = true;
                let i = 0;
                let found = 0;
                for (let id of ids) {
                  if (data[id].loaded === false) {
                    allLoaded = false
                  }
                  if (record.id === id) {
                    found = i;
                  }
                  i++;
                }

                if (value === true) {
                  if (found < i - 1) {
                    found++
                  }
                }
                else {
                  if (found !== 0) {
                    found--
                  }
                }

                props.setLoadComplete(allLoaded);
                refresh()

                props.setActiveDelivery(data[ids[found]])

              }
              ,
              onFailure: (e: any) => {
                try {
                  notify(e.response.data.detail, "error")
                }
                catch (e: any) {
                  notify("Errore", "error")
                }
              }
            }
          );
        }}
      />
    </span>
  );
};
