import React from "react";
import { AutocompleteArrayInput, ReferenceArrayInput, SimpleForm, TextInput } from "react-admin";
import { CustomToolbar } from "../components/CustomToolbar";
import { FormProps } from "../shared/types";
import { initInput } from "../shared/utils";

export const ItemForm = (props: FormProps) => {
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomToolbar canEdit={props.canEdit} canDelete={props.canDelete} />
      }
    >
      <TextInput {...initInput("code", props.fieldOptions, props.canEdit)} />
      <TextInput
        multiline
        {...initInput("description", props.fieldOptions, props.canEdit)}
      />
      <ReferenceArrayInput
        {...initInput("categories", props.fieldOptions, props.canEdit)}
        reference="autocomplete/category"
      >
        <AutocompleteArrayInput optionText="label" />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};
