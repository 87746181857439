import { AutocompleteArrayInput, AutocompleteInput, BooleanField, BooleanInput, Button, Datagrid, DateField, DateInput, DateTimeInput, FormWithRedirect, FunctionField, ListProps, NullableBooleanInput, NumberField, NumberInput, ReferenceArrayInput, ReferenceField, ReferenceInput, SaveButton, SelectArrayInput, SelectField, SelectInput, TextField, TextInput, useNotify, useRecordContext, useRefresh } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import LocalAtm from "@material-ui/icons/LocalAtm";
import Euro from "@material-ui/icons/Euro";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Create from "@material-ui/icons/Create";
import IconCancel from "@material-ui/icons/Cancel";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../shared/hooks/useOptions";
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { DeliveryTrackingNumberField } from "../../components/DeliveryTrackingNumberField";
import { DeliveryTrackingField } from "../../components/DeliveryTrackingField";
import { client } from "../../dataProvider";
import { Dialog, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { useToggler } from "../../shared/hooks/useToggler";
import { DownloadCarrierCashBoxPdfButton } from "./DownloadCarrierCashBoxPdfButton";
import { getUser } from "../../authProvider";
import { ComplededBulkButton } from "./ComplededBulkButton";
import { NotComplededBulkButton } from "./NotComplededBulkButton";


const rowStyle = (record: any, index: any) => ({
  minHeight: "40px",
  height: "40px",
});

export const CarrierCashBoxList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsCarrierCashBox } = useOptions("carrier_cashboxes", "GET");

  const notify = useNotify();
  const refresh = useRefresh();

  const filters = options && optionsCarrierCashBox
    ? [
      <TextInput {...initField("id", options)} />,

      <DateInput
        {...initField("date_after", options)}
        label="Data dal"
      />,
      <DateInput
        {...initField("date_before", options)}
        label="Data fino al"
      />,
      <SelectInput
        {...initField("type", options, "array")}
        choices={optionsCarrierCashBox.type.choices}
        label="Tipo"
      />,
      <NullableBooleanInput
        {...initField("printed", options)}
        label="Stampata"
      />,
      <NullableBooleanInput
        {...initField("completed", options)}
        label="Completata"
      />,

    ]
    : [];

  if (options &&
    props &&
    props.permissions &&
    props.permissions.includes('registry.view_subject')) {
    filters.push(
      <ReferenceInputSubject
        {...initField("carrier", options)}
        filter={{ type: "carrier" }}
        label="Vettore"
      />
    )
  }


  return options && optionsCarrierCashBox ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      shouldExport={false}
      filterDefaultValues={{}}
      {...getDefaultListProps(props)}
      bulkActionButtons={getUser().is_admin ? <><ComplededBulkButton /><NotComplededBulkButton /></> : false}
    >
      <Datagrid rowStyle={rowStyle}>

        <TextField {...initField("id", options)} />
        {
          props.permissions && props.permissions.includes('registry.view_subject') ?
            <ReferenceField
              {...initField("carrier", options)}
              label="Vettore"
              reference="subjects"
            >
              <TextField source="business_name" />
            </ReferenceField>
            : null
        }
        <DownloadCarrierCashBoxPdfButton />
        <DateField source="date" options={options} label={"Data"} />
        <TextField {...initField("type", options)} label={"Tipo"} />
        <TextField {...initField("total", options)} label={"Totale"} />
        <TextField {...initField("cash", options)} label={"Totale contanti"} />
        <TextField {...initField("check", options)} label={"Totale assegni"} />
        <DateField {...initField("print_date", options)} label={"Stampata"} showTime />
        <BooleanField {...initField("completed", options)} label={"Completata"} />
      </Datagrid>
    </CustomList>
  ) : null;
};
