import {
  Datagrid,
  TextField,
  ListProps,
  NumberField,
} from "react-admin";
import { CustomList } from "../components/CustomList";
import { getDefaultListProps } from "../shared/utils";
import { ImportButton } from "./ImportButton";
export const FileUploadList = (props: ListProps) => {
  return (
    <CustomList
      {...getDefaultListProps(props)}
      pagination={false}
      perPage={Infinity}
      additionalActions={<ImportButton /> } 
    >
      <Datagrid>
        <TextField source="name" label="Nome file" />
        <NumberField source="size" label="Dimensione" />
      </Datagrid>
    </CustomList>
  );
};
