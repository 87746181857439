import {
  Button,
  ButtonProps,
  useListContext,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export const HideButton = (props: ButtonProps) => {
  const { selectedIds, refetch } = useListContext();
  const unselect = useUnselectAll("deliveries");
  const [update] = useUpdateMany("deliveries", selectedIds, { hide: true });
  const handleSubmit = () => {
    update();
    unselect();
    refetch()
  };

  return (
    <Button
      {...props}
      disabled={selectedIds.length <= 0}
      label="Nascondi"
      onClick={handleSubmit}
    >
      <VisibilityOffIcon />
    </Button>
  );
};
