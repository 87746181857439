import { get } from "lodash";
import { FieldProps, useRecordContext } from "react-admin";

export const VehicleField = (props: FieldProps & { source: string }) => {
  const record = useRecordContext(props);
  const vehicle = get(record, props.source);
  return (
    <>
      {vehicle ? `${vehicle.internal_code}` : null}
    </>
  );
};
