import { CreateProps } from "react-admin";
import { CustomCreate } from "../components/CustomCreate";
import { VehicleForm } from "./VehicleForm";

export const VehicleCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props} component="div">
      <VehicleForm />
    </CustomCreate>
  );
};
