import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  ArrayField,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  FormWithRedirect,
  FunctionField,
  NumberField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, generateError, initField, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import UpdateIcon from "@material-ui/icons/Update";
import { getPossibleNextStatusDelivery } from "./utils";
import { DeliveryStatusChoice } from "../../shared/types";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { SubjectField } from "../../components/SubjectField";
import { DeliveryStatusReasonField } from "./DeliveryStatusReasonField";
import { useEffect } from "react";

interface UpdateReasonDeliveryStatusButtonProps { refreshMain?: boolean | undefined }

export const UpdateReasonDeliveryStatusButton = (props: UpdateReasonDeliveryStatusButtonProps) => {
  const record = useRecordContext();
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const [update, { data, error, loading }] = useUpdate();
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    update("delivery-states", values.id, values);
  };

  useEffect(() => {
    if (data) {
      setFalse();
      refresh();
    }
    else {
      if (error) {
        generateError(error, notify);
      }
    }

  }, [data, error])

  const { data: options } = useOptions("delivery-states", "GET");
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  return options && optionsTariffs ? (
    <>
      <Button tabIndex={-1} label="Aggiorna motivo" onClick={() => setTrue()}>
        <UpdateIcon />
      </Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="delivery-states"
          save={handleSubmit}
          initialValues={{ delivery: record.id }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>

                <SelectInput
                  {...initInput("status", options, false, "array")}
                  choices={options.status.choices}
                />

                <DeliveryStatusReasonField
                  options={options}
                  form={form}
                />
                <TextInput {...initInput("status_notes", options, true)} />


              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Aggiorna"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
