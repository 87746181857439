import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from "ra-ui-materialui";
import React from "react";

type ReferenceInputVehicleProps = Omit<
  ReferenceInputProps,
  "children" | "reference" | "sort"
>;

export const ReferenceInputVehicle = (props: ReferenceInputVehicleProps) => {
  return (
    <ReferenceInput
      {...(props as ReferenceInputProps)}
      reference="autocomplete/vehicle"
      sort={{ field: "internal_code", order: "ASC" }}
      allowEmpty={true}
    >
      <AutocompleteInput optionText="label" />
    </ReferenceInput>
  );
};
