import { Card, CardContent } from "@material-ui/core";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  FormWithRedirect,
  ReferenceInput,
  TextInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { NumberInput } from "../../components/NumberInput";
export const CachetForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <ReferenceInput
                {...initInput("delivery_id", props.fieldOptions, props.canEdit)}
                reference="autocomplete/delivery"
              >
                <AutocompleteInput optionText="label" />
              </ReferenceInput>
              <BooleanInput
                {...initInput("verified", props.fieldOptions, props.canEdit)}
              />
              <NumberInput
                {...initInput("total", props.fieldOptions, props.canEdit)}
              />
              <NumberInput
                {...initInput("cash", props.fieldOptions, props.canEdit)}
              />
              <NumberInput
                {...initInput("check", props.fieldOptions, props.canEdit)}
              />
              <NumberInput
                {...initInput("rebate", props.fieldOptions, props.canEdit)}
              />
              <NumberInput
                {...initInput("credit_card", props.fieldOptions, props.canEdit)}
              />
              <DateInput
                {...initInput(
                  "collection_date",
                  props.fieldOptions,
                  props.canEdit
                )}
              />
              <TextInput
                multiline
                {...initInput("note", props.fieldOptions, props.canEdit)}
              />
            </CardContent>

            <CustomToolbar
              canDelete={props.canDelete}
              canEdit={props.canEdit}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />
  );
};
