import { Grid, TableContainer, TextField, Typography } from "@material-ui/core";
import React from "react";
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  NumberField,
  Record,
  SelectField,
  SingleFieldList,
  useList,
  useRecordContext,
} from "react-admin";
import { IdentifierField } from "../../components/IdentifierField";
import { SubjectField } from "../../components/SubjectField";
import { useOptions } from "../../shared/hooks/useOptions";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";



export const SubTariffExtend = () => {
  const { data: options } = useOptions("tariffs", "GET");
  const { data: optionSubTariffs } = useOptions("sub-tariffs", "GET");

  const mainRecord = useRecordContext();
  const listContext = useList({
    data: mainRecord!.pricerange_set,
    ids: mainRecord!.pricerange_set.map((item: Record) => item.id),
    loading: false,
    loaded: true,
  });

  const listContextSubTariffs = useList({
    data: mainRecord!.sub_tariffs,
    ids: mainRecord!.pricerange_set.map((item: Record) => item.id),
    loading: false,
    loaded: true,
  });

  return options && optionSubTariffs ? (
    <Grid container>
      <Grid item xs={1}></Grid>
      <Grid item xs={3}>
        <Typography variant="h5">Fasce</Typography>
        <ListContextProvider value={listContext}>
          <TableContainer>
            <Datagrid>
              <NumberField {...initField("id", options)} label={"ID Fascia"} />,
              <NumberField {...initField("limit", options)} label={"Limite inferiore"} />,
              <NumberField {...initField("type", options)} label={"Tipo"} />,
              <NumberField {...initField("grouping", options)} label={"Raggruppa per"} />,
            </Datagrid>
          </TableContainer>
        </ListContextProvider>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={7}>
        <Typography variant="h5">Sottotariffe</Typography>
        <ListContextProvider value={listContextSubTariffs}>
          <TableContainer>
            <Datagrid>
              <NumberField {...initField("price_range", optionSubTariffs,)} label={"Fascia"} />,
              <NumberField {...initField("regions_start", optionSubTariffs,)} label={"Regioni partenza"} />,
              <NumberField {...initField("regions", optionSubTariffs)} label={"Regioni arrivo"} />,
              <NumberField {...initField("price", optionSubTariffs)} />,
              <NumberField {...initField("minimum_price", optionSubTariffs)} />,
            </Datagrid>
          </TableContainer>
        </ListContextProvider>
      </Grid>
    </Grid>

  ) : null;
};

export const TariffDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest} expand={SubTariffExtend}>
      <IdentifierField {...initField("id", options)} />
      <SelectField
        choices={options.tariff_type.choices}
        {...initField("tariff_type", options, "array")}
      />
      <BooleanField {...initField("disabled", options)} />
      <SubjectField {...initField("client", options)} />
      <SubjectField {...initField("supplier", options)} />
      <SelectField
        choices={options.charge_type.choices}
        {...initField("charge_type", options, "array")}
      />
      <SelectField
        choices={options.quantity_type.choices}
        {...initField("quantity_type", options, "array")}
      />
      <DateField {...initField("start_date", options)} />
      <FunctionField
        label="Articolo"
        source="item"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord && tableRecord.item) {
            return `${tableRecord.item.code}: ${tableRecord.item.description}`;
          }
          return null;
        }}
      />
      <NumberField {...initField("priority", options)} />
      <FunctionField
        label="Senza prezzo"
        source="no_price"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord && tableRecord.no_price) {
            return <Typography variant="h5" color="error">X</Typography>;
          }
          return null;
        }}
      />

    </Datagrid>
  );
};
