import {
  BooleanField,
  Datagrid,
  SelectField,
  TextField,
} from "react-admin";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";
import { IdentifierField } from "../../components/IdentifierField";
import React from "react";

export const ReasonStatusDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <SelectField {...initField("status", options, "array")}
        choices={options.status.choices} />
      <TextField {...initField("description", options)} />
      <BooleanField {...initField("fault", options)} />
    </Datagrid>
  );
};
