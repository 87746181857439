import { BooleanInput, Button, ButtonProps, FormWithRedirect, Logout, PasswordInput, SaveButton, useListContext, useNotify, useRefresh } from "react-admin";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { client } from "../dataProvider";
import { useState } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import { useToggler } from "../shared/hooks/useToggler";
import { logout } from "../authProvider";

export const ChangePasswordMenu = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { value, setTrue, setFalse } = useToggler();

  const handleSubmit = async (values: any) => {
    setLoading(true);

    client(`change_password/`, {
      method: "POST",
      data: values,
    })
      .then(() => {
        notify("Fatto", "success");
        logout()
      })
      .catch(() => {
        notify("ra.message.error", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>

      <Button
        {...props}
        onClick={setTrue}
      >
        <li>
          <div className="MuiListItemIcon-root">
            &nbsp;&nbsp;
            {loading ? <CircularProgress /> : <VpnKeyIcon />}
            &nbsp;&nbsp;
            <Typography variant="body2"> Modifca password</Typography></div></li>
      </Button>

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >

        <FormWithRedirect
          resource="change_password"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <Typography variant="h5">Modifca password</Typography>
                <PasswordInput
                  source="password_old"
                  label="Vecchia password"
                  fullWidth
                />
                <PasswordInput
                  source="password_update"
                  label="Nuova password"
                  fullWidth
                />
                <PasswordInput
                  source="password_confirm"
                  label="Conderma nuova password"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={() => {
                    if (handleSubmitWithRedirect) {
                      handleSubmitWithRedirect()
                      setFalse()
                    }
                  }}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};



