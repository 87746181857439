import { ReactElement } from "react";
import { EditProps } from "react-admin";
import { PERMISSIONS } from "../constants";
import { AsideLog } from "./AsideLog";
import { CustomEdit } from "./CustomEdit";
import { getUser } from "../authProvider";

export const DefaultEdit = (props: EditProps & { children: ReactElement }) => {
  return (
    <CustomEdit
      component="div"
      {...props}
      aside={
        <AsideLog />
      }
      mutationMode={"pessimistic"}
    />
  );
};
