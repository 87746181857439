import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { FormWithRedirect, TextInput } from "react-admin";
import { CustomToolbar } from "../components/CustomToolbar";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { FormProps } from "../shared/types";
import { initInput } from "../shared/utils";

export const SiteForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"row"}
                spacing={2}
              >
                <Grid item>
                  <Grid item>
                    <Typography variant="h6" gutterBottom>
                      Info principali
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextInput
                      {...initInput(
                        "business_name",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <ReferenceInputSubject
                      {...initInput(
                        "default_store_id",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      filter={{ type: "store" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CustomToolbar
              canDelete={props.canDelete}
              canEdit={props.canEdit}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />
  );
};
