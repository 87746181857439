import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { CustomerImportFileForm } from "./CustomerImportFileForm";

export const CustomerImportFileCreate = (props: CreateProps) => {
  return (
    <CustomCreate component="div" {...props}>
      <CustomerImportFileForm />
    </CustomCreate>
  );
};
