import { Grid } from "@material-ui/core";
import React from "react";
import { required, TextInput } from "react-admin";
import { initInput } from "../shared/utils";

export const AddressInput = ({ options, canEdit }: any) => {

  return (
    <Grid container direction="column">
      <Grid spacing={2} container item direction="row">
        <Grid item xs={8}>
          <TextInput
            {...initInput("address", options, canEdit)}
            validate={required()}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput {...initInput("postal_code", options, canEdit)}
            validate={required()}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container item direction="row">
        <Grid item xs={8}>
          <TextInput {...initInput("locality", options, canEdit)}
            validate={required()}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput {...initInput("province", options, canEdit)}
            validate={required()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
