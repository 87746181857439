import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteInput,
  BooleanInput,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { ReferenceInputChartAccounts } from "../../components/reference-inputs/ReferenceInputChartAccounts";

export const ChartAccountsForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"row"}
                spacing={2}
              >
                <Grid item xs={4}>
                  <ReferenceInputChartAccounts
                    {...initInput(
                      "main",
                      props.fieldOptions,
                      props.canEdit
                    )}
                    filter={{}}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextInput
                    {...initInput("code",
                      props.fieldOptions,
                      props.canEdit)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextInput
                    {...initInput(
                      "name",
                      props.fieldOptions,
                      props.canEdit
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <BooleanInput
                    {...initInput(
                      "active",
                      props.fieldOptions,
                      props.canEdit
                    )}
                    defaultValue={true}
                  />
                </Grid>

              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card >
      )}
    />
  );
};
