import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  DeleteButton,
  NumberField,
  ResourceContextProvider,
  SelectField,
  TextField,
} from "react-admin";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { initField } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import { IdentifierField } from "../../components/IdentifierField";

export const ShowPaymentStatusHistoryButton = () => {
  const { value, setFalse, setTrue } = useToggler();

  const { data: options } = useOptions("invoice-payment-status", "POST");

  return options ? (
    <>
      <Button
        variant="outlined"
        label="Cronologia"
        onClick={() => setTrue()}
      ></Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <DialogContent>
          <ArrayField source="status_history">
            <ResourceContextProvider value={"invoice-payment-status"}>
              <Datagrid>
                <IdentifierField {...initField("id", options)} />
                <DateField {...initField("date", options)} />
                <SelectField
                  {...initField("status", options, "array")}
                  choices={options.status.choices}
                />
                <NumberField
                  {...initField("amount", options)}
                  label="Importo"
                  options={{
                    style: "currency",
                    currency: "EUR",
                  }}
                />
                <SelectField
                  {...initField("collection_type", options, "array")}
                  choices={options.collection_type.choices}
                />
                <TextField {...initField("notes", options)} />
                <DeleteButton redirect={false} />
              </Datagrid>
            </ResourceContextProvider>
          </ArrayField>
        </DialogContent>
        <DialogActions>
          <Button label="Chiudi" onClick={setFalse}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};
