import { BooleanField, BooleanInput, Datagrid, DateField, FunctionField, ListProps, NullableBooleanInput, NumberField, Record, SelectField, TextField, TextInput } from "react-admin";
import { CustomList } from "../components/CustomList";
import { IdentifierField } from "../components/IdentifierField";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../shared/utils";
import { Typography } from "@material-ui/core";
import { array } from "prop-types";

export const EmailList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");


  const filters = options
    ? [
      <TextInput {...initInput("params", options)} label="Parametri" />,
      <TextInput {...initInput("params_id", options)} label="ID OGG" />,
      <NullableBooleanInput {...initInput("not_sent", options)} label="Non inviate" defaultValue={true} />,
    ]
    : [];

  return options ? (
    <CustomList {...getDefaultListProps(props)}
      filters={filters}
    >
      <Datagrid>
        <TextField {...initField("id", options)} />
        <SelectField
          choices={options.function.choices}
          {...initField("function", options, "array")}
          label="Tipo mail" />

        <FunctionField
          label="ID Ogg."
          source="params"
          render={(record: any | undefined) => {
            if (record && record.params && record.params.id) {
              return (
                <Typography>{record.params.id}</Typography>
              );
            }
            return null;
          }}
          sortBy="company__company__business_name"
        />
        <DateField {...initField("send", options)} label="Inviata" showTime />
        <NumberField {...initField("try_times", options)} label="Tentativi" />
        <DateField {...initField("error_date", options)} label="Data errore" showTime />
        <TextField {...initField("try_times", options)} label="Info errore" />
        <TextField {...initField("params", options)} label="Parametri" />
        <TextField
          {...initField("receivers", options)} label="Destinatari"
        />
        <TextField
          {...initField("body", options)} label="Corpo" />
      </Datagrid>
    </CustomList>
  ) : null;
};
