import { useNotify } from "ra-core";
import { Button } from "ra-ui-materialui";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import React from "react";
import { clientNoJson } from "../dataProvider";
import { url as URL } from "../constants";
import { CircularProgress } from "@material-ui/core";
import { getFileNameFromContentDisposition } from "../shared/utils";

interface Props {
  urlDownload: string;
  title?: string;
  icon?: any;
}
export const DownloadFileButton = ({ title, urlDownload, icon }: Props) => {
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const handleClick = async () => {
    setLoading(true);

    let res = null;

    try {
      res = await clientNoJson(`${URL}/${urlDownload}`, {
        responseType: "arraybuffer",
        method: "GET",
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      notify("ra.message.error", "error");

      return;
    }

    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            if (!loading) handleClick();
          }}
          variant="text"
          title={title ? title : "Download"}
        >
          {loading ? <CircularProgress size={18} thickness={2} /> : (
            icon ? icon : <InsertDriveFile />)
          }
        </Button>
      )}
    </>
  );
};
