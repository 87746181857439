import { makeStyles, Typography } from "@material-ui/core";
import { AppBar, AppBarProps, UserMenu } from "react-admin";
import { getUser } from "../authProvider";
import { ChangePassword } from "../Users";
import { ChangePasswordMenu } from "../Users/ChangePasswordMenu";


const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft: "0.5em",
  },

  menuButton: { display: "none" },
});
export const CustomAppBar = (props: AppBarProps) => {
  const classes = useStyles();
  return (

    <AppBar {...props}
      userMenu={
        getUser() ?
          <>
            <b>
              {getUser().username}
            </b>
            <UserMenu
              logout={props.logout}
              label={getUser().username}
            >
              <ChangePasswordMenu />
            </UserMenu>
          </> : false
      }
      position="sticky" classes={classes}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  );
};
