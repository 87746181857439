import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Radio,
  TableContainer,
  Button,
} from "@material-ui/core";
import {
  Dispatch,
  Identifier,
  ListBase,
  useQueryWithStore,
  useRefresh,
  useUpdate,
} from "ra-core";
import {
  BooleanInput,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  Empty,
  FilterButton,
  FunctionField,
  ListToolbar,
  NullableBooleanInput,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
} from "ra-ui-materialui";
import { client } from "../../dataProvider";
import { SetStateAction, useState } from "react";
import { AutocompleteInput, DateInput, DateTimeInput, Record } from "react-admin";
import SmallPagination from "../../components/SmallPagination";
import { SubjectField } from "../../components/SubjectField";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useClosedSidebar } from "../../shared/hooks/useClosedSdebar";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";
import { NoteImage } from "./NoteImage";
import { IdentifierField } from "../../components/IdentifierField";
import DeleteIcon from '@material-ui/icons/Delete';

const DeliveryList = ({
  selectedNote,
  setSelectedNote,
}: {
  selectedNote?: Identifier;
  setSelectedNote: Dispatch<SetStateAction<Identifier | undefined>>;
}) => {
  const { data: options } = useOptions("deliveries", "GET");
  const [update] = useUpdate();
  const refresh = useRefresh();


  function getAYesterdaysDate() {
    let d = new Date();
    d.setTime((d.getTime() - (48 * 60 * 60 * 1000)));
    return d.toISOString();
  }

  const filters = options
    ? [
      <TextInput {...initField("id", options)} />,
      <TextInput {...initField("document_number", options)} alwaysOn />,
      <DateInput {...initField("document_date", options)} />,
      <ReferenceInputSubject {...initField("receiver", options)} />,
      <ReferenceInputSubject
        {...initField("client", options)}
        filter={{ type: "client" }}
      />,
      <ReferenceInput
        source="route"
        label="Giro"
        reference="autocomplete/route"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        {...initField("import_file", options)}
        reference="autocomplete/imported-files"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <DateInput
        {...initField("delivery_date_day", options)}
        label="Data missione"
      />,
      <DateInput
        {...initField("delivery_date_after", options)}
        label="Data missione dal"
      />,
      <DateInput
        {...initField("delivery_date_before", options)}
        label="Data missione fino al"
      />,
      <BooleanInput {...initField("bill_sent", options)} />,
      <NullableBooleanInput
        alwaysOn
        label="Contiene bolle"
        source="has_notes"
      />,
    ]
    : [];

  return options ? (
    <ListBase
      resource="deliveries"
      syncWithLocation={true}
      basePath="/deliveries"
      filterDefaultValues={{
        has_notes: false,
        delivery_date_after: getAYesterdaysDate()
      }}
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
    >
      <Box p={1}>
        <ListToolbar
          filters={filters}
          actions={
            <TopToolbar>
              <FilterButton />
            </TopToolbar>
          }
        />
      </Box>
      <Box component={Paper}>
        <TableContainer>
          <Button onClick={() => { window.open('/deliveries/create', '_blanck'); }} >Crea missione</Button>

          <Datagrid>
            <FunctionField
              render={(record: Record | undefined) => {
                if (record) {
                  return (
                    <Button
                      disabled={!selectedNote}
                      onClick={(e) => {
                        e.stopPropagation();
                        const check = window.confirm("Sei sicuro?");
                        if (check) {
                          update(
                            {
                              resource: "notes",
                              payload: {
                                id: selectedNote,
                                data: { delivery: record.id },
                              },
                            },
                            {
                              onSuccess: () => {
                                setSelectedNote(undefined);
                                refresh();
                              },
                            }
                          );
                        }
                      }}
                      color="primary"
                    >
                      Associa
                    </Button>
                  );
                }
                return null;
              }}
            />
            <IdentifierField {...initField("id", options)} />
            <TextField {...initField("document_number", options)} />
            <SubjectField {...initField("client_object", options)} />
            <SubjectField {...initField("receiver_object", options)} />
            <DateField {...initField("document_date", options)} />
            <DateField {...initField("delivery_date", options)} />
          </Datagrid>
        </TableContainer>
        <SmallPagination />
      </Box>
    </ListBase>
  ) : null;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      overflow: "hidden",

      [theme.breakpoints.up("sm")]: {
        height: "100%",
        width: "100%",
      },
    },
    column: {
      padding: "0.5em",
      overflowY: "auto",
      maxHeight: "100%",
    },
  })
);

export const NotAssignedNotes = () => {
  const classes = useStyles();
  const { data } = useQueryWithStore({
    type: "getList",
    resource: "notes",
    payload: {
      sort: { field: "id", order: "DESC" },
      filter: {
        assigned: false,
      },
      pagination: { perPage: 30, page: 1 },
    },
  });

  const refresh = useRefresh();

  const [selectedNote, setSelectedNote] = useState<Identifier>();

  useClosedSidebar();
  return (
    <Grid container className={classes.root}>
      <Grid container item xs={6} className={classes.column}>
        {data && data.length <= 0 && (
          <Grid item xs={12}>
            <Empty resource="notes" />
          </Grid>
        )}
        {data &&
          data.map((note: Record) => {
            return (
              <Grid
                xs={12}
                item
                key={note.id}
                justifyContent="center"
                alignItems="center"
                container
                direction="row"
              >
                <Grid item xs>
                  <Box mb={3} overflow="hidden" component={Paper}>
                    <NoteImage src={note.file_info.url} />
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item xs={12}>
                      <Radio
                        checked={selectedNote === note.id}
                        onChange={() => {
                          setSelectedNote(note.id);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DeleteWithConfirmButton
                        record={note}
                        resource="notes"
                        mutationMode="pessimistic"
                        onSuccess={() => refresh()}
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            );
          })}
      </Grid>
      <Grid item xs={6} className={classes.column}>
        <DeliveryList
          selectedNote={selectedNote}
          setSelectedNote={setSelectedNote}
        />
      </Grid>
    </Grid>
  );
};
