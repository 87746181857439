// botton crea riconsegna
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  Button,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  useNotify,
  AutocompleteInput,
  useUpdateMany,
  useListContext,
  required,
} from "react-admin";
import { useToggler } from "../../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";
import { CircularProgress } from "@material-ui/core";
import CameraFrontIcon from '@material-ui/icons/CameraFront';
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../shared/hooks/useOptions";
import { useState } from "react";
import { client } from "../../dataProvider";
import { generateError } from "../../shared/utils";

export const MigrateDeliveryClientBulkButton = () => {
  const { value, setTrue, setFalse } = useToggler();
  const notify = useNotify();
  const { data: options } = useOptions("deliveries", "GET");
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();


  const onClick = (values: any) => {
    setLoading(true);

    client(`deliveries/migrate_tariff`, {
      method: "POST",
      data: {
        ids: selectedIds,
        ...values
      }
    }).then((response) => {
      let errors = "";
      for (let k in response.errors) {
        errors += k + " -> " + response.errors[k] + "\n "
      }
      if (errors !== "") {
        window.alert(errors);
      }
      else {
        notify("Missioni aggiornate");
      }
      setFalse();
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
      })

      ;
    refetch();

  };

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setTrue();
        }}
        label={"Modifica Cliente"}
        title={"Modifica il ciente delle missioni selezionate"}
      >{loading ? <CircularProgress size={18} thickness={2} /> : <CameraFrontIcon />}</Button>
      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          save={onClick}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <ReferenceInputSubject
                  fullWidth
                  label="Nuovo cliente"
                  source="client"
                  reference="autocomplete/subject"
                  validate={[required()]}
                  allowEpty={false}
                  filter={{ type: "client" }}
                >
                  <AutocompleteInput optionText="label" />
                </ReferenceInputSubject>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
