import * as React from "react";
import { Children, cloneElement, ReactElement } from "react";
import Card from "@material-ui/core/Card";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  defaultExporter,
  ListControllerProps,
  useListContext,
  getListControllerProps,
  useVersion,
} from "ra-core";
import {
  ListToolbar,
  BulkActionsToolbar,
  Title,
  BulkDeleteButton,
  Empty,
  ListProps,
  ListActions,
  Pagination,
} from "react-admin";
import { Portal } from "@material-ui/core";

const useStylesToolbar = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));
export const CustomListView = (
  props: ListViewProps & { component: React.ElementType<any> }
) => {
  const {
    actions,
    aside,
    filters,
    bulkActionButtons,
    pagination,
    children,
    className,
    classes: classesOverride,
    component: Content,
    exporter, // = defaultExporter,
    title,
    empty,
    ...rest
  } = props;
  const controllerProps = getListControllerProps(props); // deprecated, to be removed in v4
  const listContext = useListContext(props);
  const classes = useStyles(props);
  const { defaultTitle, total, loaded, loading, filterValues } = listContext;
  const version = useVersion();
  const classesToolbar = useStylesToolbar();

  const renderList = () => (
    <>
      {(filters || actions) && (
        <Portal container={document.getElementById("secondary-app-bar")}>
          <ListToolbar
            classes={classesToolbar}
            filters={filters}
            {...controllerProps} // deprecated, use ListContext instead, to be removed in v4
            actions={actions}
            exporter={exporter} // deprecated, use ListContext instead, to be removed in v4
          />
          {bulkActionButtons !== false && bulkActionButtons && (
            <BulkActionsToolbar {...controllerProps}>
              {bulkActionButtons}
            </BulkActionsToolbar>
          )}
        </Portal>
      )}
      <div className={classes.main}>
        <Content className={classnames(classes.content)} key={version}>
          {children &&
            // @ts-ignore-line
            cloneElement(Children.only(children), {
              ...controllerProps, // deprecated, use ListContext instead, to be removed in v4
              hasBulkActions: bulkActionButtons !== false,
            })}
          {pagination && cloneElement(pagination, listContext)}
        </Content>
        {aside && cloneElement(aside, listContext)}
      </div>
    </>
  );

  const shouldRenderEmptyPage =
    loaded && !loading && total === 0 && !Object.keys(filterValues).length;

  return (
    <div
      className={classnames("list-page", classes.root, className)}
      {...sanitizeRestProps(rest)}
    >
      <Title title={title} defaultTitle={defaultTitle} />
      {shouldRenderEmptyPage && empty !== false
        ? cloneElement(empty!, listContext)
        : renderList()}
    </div>
  );
};

const DefaultBulkActionButtons = (props: any) => (
  <BulkDeleteButton {...props} />
);

CustomListView.defaultProps = {
  actions: <ListActions />,
  classes: {},
  component: Card,
  bulkActionButtons: <DefaultBulkActionButtons />,
  pagination: <Pagination />,
  empty: <Empty />,
};

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    main: {
      display: "flex",
    },
    content: {
      marginTop: 0,
      transition: theme.transitions.create("margin-top"),
      position: "relative",
      flex: "1 1 auto",
      [theme.breakpoints.down("xs")]: {
        boxShadow: "none",
      },
      overflow: "inherit",
    },
    // bulkActionsDisplayed: {
    //   marginTop: -theme.spacing(8),
    //   transition: theme.transitions.create("margin-top"),
    // },
    actions: {
      zIndex: 2,
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
    },
    noResults: { padding: 20 },
  }),
  { name: "RaList" }
);

export interface ListViewProps
  extends Omit<ListProps, "basePath" | "hasCreate" | "perPage" | "resource">,
  // Partial because we now get those props via context
  Partial<ListControllerProps> {
  children: ReactElement;
}

const sanitizeRestProps: (
  props: Omit<
    ListViewProps,
    | "actions"
    | "aside"
    | "filter"
    | "filters"
    | "bulkActionButtons"
    | "pagination"
    | "children"
    | "className"
    | "classes"
    | "component"
    | "exporter"
    | "title"
    | "empty"
  >
) => any = ({
  basePath = null,
  currentSort = null,
  data = null,
  defaultTitle = null,
  displayedFilters = null,
  filterDefaultValues = null,
  filterValues = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  hideFilter = null,
  history = null,
  ids = null,
  loading = null,
  loaded = null,
  location = null,
  match = null,
  onSelect = null,
  onToggleItem = null,
  onUnselectItems = null,
  options = null,
  page = null,
  permissions = null,
  perPage = null,
  refetch = null,
  resource = null,
  selectedIds = null,
  setFilters = null,
  setPage = null,
  setPerPage = null,
  setSort = null,
  showFilter = null,
  syncWithLocation = null,
  sort = null,
  total = null,
  ...rest
}) => rest;
