import {
  BulkUpdateButton,
  Button,
  DateInput,
  FormWithRedirect,
  Identifier,
  SelectInput,
  useNotify,
  useRefresh,
} from "react-admin";
import { ActionsToolbar } from "../../../components/ActionsToolbar";
import IconCancel from "@material-ui/icons/Cancel";

import BlockIcon from "@material-ui/icons/Block";
import { client, clientNoJson } from "../../../dataProvider";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { FormSaveButton } from "../../../components/FormSaveButton";
import { useToggler } from "../../../shared/hooks/useToggler";
import { useState } from "react";
import { getFileNameFromContentDisposition } from "../../../shared/utils";
import { CircularProgress } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Print";

const CreateInvoiceButton = ({ charges }: { charges: Identifier[] }) => {
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = (values: any) => {
    setLoading(true);
    client("invoices/new_invoice_from_charges", {
      method: "POST",
      data: values,
    })
      .then(() => {
        notify("Fatto!", "success");
        refresh();
      })
      .catch((response) =>
        notify(
          "Errore! Controllare che il cliente abbia i termini di pagamento associati!",
          "error"
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        label="Crea fattura"
        onClick={() => setTrue()}
      ></Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ charges }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <SelectInput
                  fullWidth
                  source="creation_type"
                  label="Modalità di creazione"
                  optionText={"display_name"}
                  optionValue="value"
                  required
                  choices={[
                    {
                      display_name: "Per missione",
                      value: "M",
                    },
                    {
                      display_name: "Consuntivo",
                      value: "C",
                    },
                  ]}
                />
                <SelectInput
                  fullWidth
                  source="invoice_type"
                  label="Tipo fattura"
                  optionText={"display_name"}
                  optionValue="value"
                  required
                  choices={[
                    {
                      display_name: "Vendita",
                      value: "S",
                    }
                  ]}
                  defaultValue={"S"}
                />
                <DateInput
                  fullWidth
                  source="invoice_date"
                  label="Data fattura"
                ></DateInput>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};


export const PrintChargesExcelButton = (
  { charges }: { charges: Identifier[] }
) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    let res = null;

    try {
      res = await clientNoJson(
        "api/charges/print_excel_detail/",
        {
          method: "POST",
          responseType: "arraybuffer",
          data: {
            "ids": charges
          }
        }
      );
    } catch (err) {
      notify("ra.notification.http_error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  };

  return (
    <Button
      label="Scarica excel"
      onClick={handleSubmit}>
      {loading ? <CircularProgress size={18} thickness={2} /> : <PublishIcon />}
    </Button>
  );
};


interface BulkActionProps {
  selectedCharges: Identifier[];
  onUnselect: () => void;
}
export const BulkActions = (props: BulkActionProps) => {
  const { selectedCharges, onUnselect } = props;

  return (
    <ActionsToolbar selected={selectedCharges} onUnselect={onUnselect}>
      <BulkUpdateButton
        selectedIds={selectedCharges}
        disabled={selectedCharges.length <= 0}
        data={{ verified: false }}
        mutationMode="pessimistic"
        label="Invalida"
        icon={<BlockIcon />}
      />
      <CreateInvoiceButton charges={selectedCharges} />
      <PrintChargesExcelButton charges={selectedCharges} />
    </ActionsToolbar>
  );
};
