import React, { useMemo } from "react";
import { ListContextProvider, useList } from "react-admin";
import { DeliveryDatagrid } from "../Distribution/Delivery/DeliveryDatagrid";
import { useOptions } from "../shared/hooks/useOptions";
import { Delivery } from "../shared/types";

export const DeliveriesTable = ({ deliveries }: { deliveries: Delivery[] }) => {
  //check if deliveries have ids
  const newDeliveries = useMemo(
    () =>
      deliveries.map((delivery) => ({
        ...delivery,
        id: delivery.id ? delivery.id : delivery.document_number!,
      })),
    [deliveries]
  );
  const listContext = useList({
    data: newDeliveries,
    ids: newDeliveries.map((delivery) => delivery.id),
    loading: false,
    loaded: true,
  });
  const { data: options } = useOptions("deliveries", "GET");
  return options ? (
    <ListContextProvider value={listContext}>
      <DeliveryDatagrid options={options} />
    </ListContextProvider>
  ) : null;
};
