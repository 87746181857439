import React, { useState } from "react";
import {
  Button,
  ButtonProps,
  Link,
  useListContext,
  useNotify,
  useRefresh,
  useResourceContext,
  useUnselectAll,
} from "react-admin";
import PublishIcon from "@material-ui/icons/Publish";
import { client } from "../../dataProvider";
import {
  AppBar,
  CircularProgress,
  Dialog,
  Grid,
  makeStyles,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { useToggler } from "../../shared/hooks/useToggler";

interface ReportXMLImport {
  results: [{
    name: string,
    status: string,
    id?: string
  }];
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    overflowX: "hidden",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxWidth: "350px",
  },
  tab: { borderBottom: `1px solid ${theme.palette.divider}` },
}));

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const XMLImportButton = (props: ButtonProps) => {
  const classes = useStyles();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const resource = useResourceContext();
  const { selectedIds } = useListContext();
  const unselecteAll = useUnselectAll("upload");
  const [reportXMLImport, setReportXMLImport] = useState<ReportXMLImport | undefined>();
  const refresh = useRefresh();

  const { value, setTrue: openDialog, setFalse: closeDialog } = useToggler();

  const [openTab, setOpenTab] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setOpenTab(newValue);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    client(`${resource}/execute_import`, {
      method: "POST", data: {
        "files": values
      }
    })
      .then((data) => {
        setReportXMLImport(data);
        openDialog();
      })
      .catch((error) => {
        notify("ra.notification.http_error", "error");
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    closeDialog();
    refresh();
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={loading}
        message="Caricando..."
        action={
          <React.Fragment>
            <CircularProgress />
          </React.Fragment>
        }
      />
      <Button
        {...props}
        disabled={selectedIds.length <= 0}
        label="Importa"
        onClick={() => handleSubmit(selectedIds)}
      >
        <PublishIcon />
      </Button>
      <Dialog
        open={value}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <div style={{ padding: 5 }}>

          {reportXMLImport && reportXMLImport.results ?
            reportXMLImport.results.map((report, index) => (
              <Grid container>
                <Grid item xs={5}>
                  <Typography style={{ fontWeight: 600 }}>
                    {report.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {report.status}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {report.id ?
                    <Link href={`/invoices-a/${report.id}/show`} to={`/invoices-a/${report.id}/show`}>{report.id}</Link>
                    : null
                  }
                </Grid>
                <hr color="black"></hr>
              </Grid>
            )) : null}
        </div>
      </Dialog>

    </>
  );
};
