import {
  ListProps,
  TextInput,
  DateInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  NullableBooleanInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  BulkDeleteButton,
  useRefresh,
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../../shared/utils";
import { RouteMonitorDatagrid } from "./RouteMonitorDatagrid";
import { useEffect } from "react";
import { getUser } from "../../authProvider";
import { useClosedSidebar } from "../../shared/hooks/useClosedSdebar";
import { UpdateRouteStatusButton } from "./UpdateRouteStatusButton";

export const RouteMonitorList = (props: ListProps) => {

  const refresh = useRefresh();
  useClosedSidebar();

  const user = getUser();

  var counter = 0
  let limitTime = 24 * 60 * 60 * 1000;

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter < limitTime && (["admin"].includes(user.username) || user.username.toLowerCase().includes("bacheca"))) {
        refresh();
        counter = counter + 120000
      }

    }, 120000);

    return () => clearInterval(timer);
  }, []);

  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput alwaysOn {...initField("id", options)} />,
      <SelectInput
        choices={options.status.choices}
        {...initField("status", options, "array")}
        alwaysOn
      />,
      <ReferenceInput
        {...initField("start_point", options)}
        label="Magazzino partenza"
        filter={{}}
        reference="autocomplete/subject"
        alwaysOn
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <DateInput {...initField("planned_date_after", options)} label="Pianificada dal" />,
      <DateInput {...initField("planned_date_before", options)} label="Pianificada al" />,
      <DateInput {...initField("planned_date__date", options)} label="Pianificada il" />,

      <ReferenceInput
        {...initField("driver", options)}
        filter={{ departments: "Autista" }}
        reference="autocomplete/employee"
        alwaysOn
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        {...initField("carrier", options)}
        filter={{ type: "carrier" }}
        reference="autocomplete/subject"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        {...initField("vehicle", options)}
        reference="autocomplete/vehicle"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceArrayInput
        {...initField("tags_filter", options)}
        label="Tag"
        reference="autocomplete/tag"
      >
        <AutocompleteArrayInput optionText="label" />
      </ReferenceArrayInput>,

    ]
    : [];
  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      {...getDefaultListProps(props)}
      bulkActionButtons={
        <UpdateRouteStatusButton />
      }
      filterDefaultValues={{ status: ['S'] }}
      perPage={100}
    >
      <RouteMonitorDatagrid options={options} />
    </CustomList>
  ) : null;
};
