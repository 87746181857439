import { BooleanInput, Button, ButtonProps, FormWithRedirect, SaveButton, useListContext, useNotify, useRefresh } from "react-admin";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { client } from "../../dataProvider";
import { useState } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import { useToggler } from "../../shared/hooks/useToggler";

export const AcceptOrderBulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { value, setTrue, setFalse } = useToggler();
  const { selectedIds, refetch, onUnselectItems } = useListContext();

  const handleSubmit = async (values: any) => {
    setLoading(true);

    client(`customer_orders/order_to_deliverys`, {
      method: "POST",
      data: values,
    })
      .then((response: any) => {
        onUnselectItems()
        refetch();

        if (response && response.message.length > 0) {
          notify(
            "Ordini già creati:" + response.message.join(", ")
            , "error");
        }
        else {
          notify("Fatto", "success");
        }


      })
      .catch(() => {
        notify("ra.message.error", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>

      <Button
        {...props}
        disabled={loading || selectedIds.length <= 0}
        label="Accetta e crea"
        onClick={setTrue}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <CheckCircleIcon />}
      </Button>

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >

        <FormWithRedirect
          resource="project-collaborators"
          initialValues={{ ids: selectedIds }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <Typography variant="h5">Crea missioni da ordini:</Typography>
                <BooleanInput
                  source="exclude_first_segment"
                  label="Escludi prima tratta"
                  fullWidth
                  defaultValue={true}
                />
                <BooleanInput
                  source="exclude_last_segment"
                  label="Escludi ultima tratta"
                  fullWidth
                  defaultValue={false}
                />
                <BooleanInput
                  source="create_withdraw"
                  label="Crea ritiro collegato"
                  fullWidth
                  defaultValue={false}
                />
                <ReferenceInputSubject
                  {...initInput(
                    "transit_point",
                    true
                  )}
                  //filter={{ type: "receiver" }}
                  label="Transit point"
                />

              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={() => {
                    if (handleSubmitWithRedirect) {
                      handleSubmitWithRedirect()
                      setFalse()
                    }
                  }}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};



