import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  DateTimeInput,
  FileField,
  FileInput,
  FormDataConsumer,
  FormWithRedirect,
  FunctionField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReferenceInputDriver } from "../../components/reference-inputs/ReferenceInputDriver";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { ReferenceInputVehicle } from "../../components/reference-inputs/ReferenceInputVehicle";
import { FormProps } from "../../shared/types";
import { initField } from "../../shared/utils";
import { initInput } from "../../shared/utils";
import { saveNoteToFile } from "./RouteDatagrid";

export const RouteForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"row"}
                spacing={2}
              >
                <Grid item container xs={12} spacing={2}>
                  <Grid item container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Info principali
                      </Typography>
                    </Grid>
                    <Grid item>
                      {
                        props.record.file_info.name && (
                          <FunctionField
                            {...initField("file_info", props.fieldOptions)}
                            label={"Borderaux firmato"}
                            render={(tableRecord: any) => {
                              if (tableRecord.file_info.name) {
                                return (
                                  <Button onClick={() => {
                                    saveNoteToFile(tableRecord.id)
                                  }} >
                                    <Typography variant="body1">Borderaux firmato</Typography>
                                  </Button>);

                              }
                              return null;
                            }}
                          />

                        )
                      }

                    </Grid>
                    <Grid item>
                      <BooleanInput
                        {...initInput(
                          "sent",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        label="Inviato a TrackMe"
                      />
                    </Grid>
                    <Grid item>
                      <BooleanInput
                        {...initInput(
                          "show_on_maps",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        label="Motra su mappa"
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <DateTimeInput
                      {...initInput(
                        "planned_date",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectInput
                      {...initInput(
                        "status",
                        props.fieldOptions,
                        props.canEdit,
                        "array"
                      )}
                      allowEmpty
                      choices={props.fieldOptions.status.choices}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <ReferenceInputDriver
                      {...initInput(
                        "driver.id",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      filter={{ departments: "Autista" }}
                      reference="autocomplete/employee"
                      label="Autista"
                      allowEmpty
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ReferenceInputVehicle
                      {...initInput(
                        "vehicle.id",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      reference="autocomplete/vehicle"
                      label="Veicolo"
                      allowEmpty
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ReferenceInput
                      {...initInput(
                        "carrier.id",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      filter={{ tag: "Vettore" }}
                      reference="autocomplete/subject"
                      label="Vettore"
                      allowEmpty
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>
                  {/*
                  <Grid item xs={6}>
                    <ReferenceInputSubject
                      {...initInput(
                        "start_point",
                        props.fieldOptions,
                        false
                      )}
                      filter={{ type: "destination" }}
                      validate={required()}
                    />

                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInputSubject
                      {...initInput(
                        "final_point",
                        props.fieldOptions,
                        false
                      )}
                      filter={{ type: "destination" }}
                      validate={required()}
                    />
                </Grid>
                      */}

                  <Grid item xs={12}>
                    <ReferenceArrayInput
                      reference="autocomplete/tag"
                      defaultValue={[]}
                      source={"tags"}
                      label="Tag"
                      sort={{ "field": "label", "order": "ASC" }}
                      className="filter-field-small">
                      <AutocompleteArrayInput optionText="label" fullWidth />
                    </ReferenceArrayInput>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              //handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card >
      )}
    />
  );
};
