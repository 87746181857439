import { Button, ButtonProps, useListContext, useNotify, useRecordContext } from "react-admin";
import TableChartIcon from '@material-ui/icons/TableChart';
import { CircularProgress } from "@material-ui/core";
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { useState } from "react";

export const TariffExcelBulkButton = (props: ButtonProps) => {
  const record = useRecordContext();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const { selectedIds, refetch } = useListContext();

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    clientNoJson(`${URL}/tariffs/tariff_excel/`, {
      method: "POST",
      data: {
        ids: selectedIds
      },
      responseType: "arraybuffer",
    }).then(
      ({ data }) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/ms-excel",
          })
        );
        const actualFileName = `tariffe.xlsx`

        // uses the download attribute on a temporary anchor to trigger the browser
        // download behavior. if you need wider compatibility, you can replace this
        // part with a library such as filesaver.js
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", actualFileName);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) link.parentNode.removeChild(link);
        setLoading(false);
      }

    ).catch(error => {
      console.error(error);
      setLoading(false);
      notify('ra.notification.http_error', { type: 'warning' });
    })

  };

  return (
    <Button {...props} label="Esporta excel" onClick={handleSubmit}>
      {loading ? <CircularProgress size={18} thickness={2} /> : <TableChartIcon />}

    </Button>
  );
};
