import { Card } from "@material-ui/core";
import { CreateProps } from "react-admin";
import { CustomCreate } from "../components/CustomCreate";
import { CategoryForm } from "./CategoryForm";

export const CategoryCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props} component={Card}>
      <CategoryForm />
    </CustomCreate>
  );
};
