import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { DeliveryForm } from "./DeliveryForm";
import { transformDelivery } from "./utils";

export const DeliveryCreate = (props: CreateProps) => {
  return (
    <CustomCreate component="div" {...props} transform={transformDelivery}>
      <DeliveryForm />
    </CustomCreate>
  );
};
