import {
  BulkUpdateButton,
  Button,
  FormWithRedirect,
  Identifier,
  SelectInput,
  useNotify,
  useRefresh,
} from "react-admin";
import { ActionsToolbar } from "../../../components/ActionsToolbar";
import IconCancel from "@material-ui/icons/Cancel";

import BlockIcon from "@material-ui/icons/Block";
import { client } from "../../../dataProvider";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { FormSaveButton } from "../../../components/FormSaveButton";
import { useToggler } from "../../../shared/hooks/useToggler";
import { useState } from "react";
import { TariffType } from "../../../shared/types";

const CreateInvoiceButton = ({ charges }: { charges: Identifier[] }) => {
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = (values: any) => {
    setLoading(true);
    client("invoices/new_invoice_from_charges", {
      method: "POST",
      data: values,
    })
      .then(() => {
        notify("Fatto!", "success");
        refresh();
      })
      .catch((response) =>
        notify(
          "Errore! Controllare che il fornitore abbia i termini di pagamento associati!",
          "error"
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        label="Crea fattura Acquisto"
        onClick={() => setTrue()}
      ></Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ charges }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <SelectInput
                  fullWidth
                  source="creation_type"
                  label="Modalità di creazione"
                  optionText={"display_name"}
                  optionValue="value"
                  required
                  choices={[
                    {
                      display_name: "Per missione",
                      value: "M",
                    },
                    {
                      display_name: "Consuntivo",
                      value: "C",
                    },
                  ]}
                />
                <SelectInput
                  fullWidth
                  source="invoice_type"
                  label="Tipo fattura"
                  optionText={"display_name"}
                  optionValue="value"
                  required
                  choices={[
                    {
                      display_name: "Acquisto",
                      value: "P",
                    }
                  ]}
                  defaultValue={"P"}
                />

              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

interface PurchaseBulkActionsProps {
  selectedCharges: Identifier[];
  onUnselect: () => void;
}
export const PurchaseBulkActions = (props: PurchaseBulkActionsProps) => {
  const { selectedCharges, onUnselect } = props;

  return (
    <ActionsToolbar selected={selectedCharges} onUnselect={onUnselect}>
      <BulkUpdateButton
        selectedIds={selectedCharges}
        disabled={selectedCharges.length <= 0}
        data={{ verified: false }}
        mutationMode="pessimistic"
        label="Invalida"
        icon={<BlockIcon />}
      />
      <CreateInvoiceButton charges={selectedCharges} />
    </ActionsToolbar>
  );
};
