import { useCallback, useEffect, useState } from "react";
import { Box, Dialog, IconButton, makeStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useTheme } from "@material-ui/core";
import { usePanzoom } from "../shared/hooks/usePanzoom";
import { Identifier } from "react-admin";
import { getNoteImage, saveNoteToFile } from "../Distribution/Note/utils";
import RotateRightIcon from '@material-ui/icons/RotateRight';

interface ImageViewerProps {
  open: boolean;
  onClose: () => void;
  noteId: Identifier;
  resource?: string;
}

const useStyles = makeStyles((theme) => ({ paper: { height: "100000000px" } }));

export const ImageViewer = (props: ImageViewerProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const handlePanzoom = usePanzoom();
  const [activeNoteImage, setActiveNoteImage] = useState<string>();
  const [rotation, setRotation] = useState(0);

  const getActiveNoteImage = useCallback(async () => {
    const image = await getNoteImage(props.noteId, props.resource);
    return image;
  }, [props.noteId]);

  useEffect(() => {
    (async function fetchImage() {
      const image = await getActiveNoteImage();
      setActiveNoteImage(image);
    })();
  }, [getActiveNoteImage]);
  return (
    <Dialog
      classes={classes}
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
      style={{ height: "100%" }}
    >
      <div
        style={{
          overflow: "hidden",
          userSelect: "none",
          touchAction: "none",
          height: "100%",
        }}
      >
        <div ref={handlePanzoom} style={{ height: "100%" }}>
          <img
            src={activeNoteImage}
            style={{ width: "100%", height: "100%", transform: `rotate(${rotation}deg)` }}
            alt={"Bolla"}
          />
        </div>
        <Box position={"absolute"} bottom={10} right={10}>
          <IconButton
            style={{ background: theme.palette.primary.main, color: "white" }}
            onClick={() => {
              saveNoteToFile(props.noteId, props.resource);
            }}
          >
            <GetAppIcon />
          </IconButton>
        </Box>
        <Box position={"absolute"} bottom={10} left={10}>
          <IconButton
            style={{ background: theme.palette.primary.main, color: "white" }}
            onClick={() => {
              setRotation(rotation + 90);
            }}
          >
            <RotateRightIcon />
          </IconButton>
        </Box>
      </div>
    </Dialog>
  );
};
