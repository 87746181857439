import { Switch } from "@material-ui/core";
import React from "react";
import {
  FieldProps,
  Record,
  useListContext,
  useNotify,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from "react-admin";

export const EditableCheckboxField = (
  props: FieldProps & { source: string; disabled?: (record: Record) => boolean }
) => {
  const { disabled, ...rest } = props;
  const record = useRecordContext(rest);
  const [update] = useUpdate();
  const resource = useResourceContext();
  const { refetch } = useListContext();
  const notify = useNotify();
  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Switch
        disabled={disabled && disabled(record)}
        checked={record[props.source]}
        onChange={(e, value) => {
          update(
            {
              resource,
              payload: { id: record.id, data: { [props.source]: value } },
            },
            {
              onSuccess: () => refetch(),
              onFailure: (e: any) => {
                try {
                  notify(e.response.data.detail, "error")
                }
                catch (e: any) {
                  notify("Errore", "error")
                }
              }
            }
          );
        }}
      />
    </span>
  );
};
