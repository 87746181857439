import React from "react";
import { FieldProps, useRecordContext } from "react-admin";
import IconPopover from "./IconPopover";



export const LocationField = (props: FieldProps) => {
  const record = useRecordContext({ props });

  const link = record?.province
    ? `${record?.locality} (${record?.province})`
    : record?.locality;
  let address = record?.address ? `${record?.address} ${link}` : link;
  address = record?.postal_code ? `${address} ${record?.postal_code}` : address;
  return link ? <IconPopover content={address} /> : null;
};
