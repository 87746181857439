import L, { PointExpression } from "leaflet";
/*
import blueIconImg from "../assets/marker-icon-blue.png";
import redIconImg from "../assets/marker-icon-red.png";
import yellowIconImg from "../assets/marker-icon-yellow.png";
import purpleIconImg from "../assets/marker-icon-violet.png";
import greenIconImg from "../assets/marker-icon-green.png";
import redBlueIconImg from "../assets/marker-icon-red-blue.png";
import redBlueYellowIconImg from "../assets/marker-icon-blue-yellow-red.png";
import blueYellowIconImg from "../assets/marker-icon-blue-yellow.png";
import redYellowIconImg from "../assets/marker-icon-yellow-red.png";
import blueIconImgS from "../assets/marker-icon-blue-s.png";
import redIconImgS from "../assets/marker-icon-red-s.png";
import yellowIconImgS from "../assets/marker-icon-yellow-s.png";
import purpleIconImgS from "../assets/marker-icon-violet-s.png";
import greenIconImgS from "../assets/marker-icon-green-s.png";
import redBlueIconImgS from "../assets/marker-icon-red-blue-s.png";
import redBlueYellowIconImgS from "../assets/marker-icon-blue-yellow-red-s.png";
import blueYellowIconImgS from "../assets/marker-icon-blue-yellow-s.png";
import redYellowIconImgS from "../assets/marker-icon-yellow-red-s.png";
*/

import blueIconImg from "../assets/marker-blue.png";
import grayIconImg from "../assets/marker-gray.png";
import yellowIconImg from "../assets/marker-yellow.png";
import redIconImg from "../assets/marker-red.png";
import greenIconImg from "../assets/marker-green.png";
import skyIconImg from "../assets/marker-sky.png";
import purpleIconImg from "../assets/marker-purple.png";
import fuchsiaIconImg from "../assets/marker-fuchsia.png";
import orangeIconImg from "../assets/marker-orange.png";
import endIconImg from "../assets/marker-end.png"
import startIconImg from "../assets/marker-start.png"
import startZoomIconImg from "../assets/marker-s-p.png"

import blueGrayIconImg from "../assets/marker-blue-gray.png";
import blueOrangeYellowIconImg from "../assets/marker-blue-orange-yellow.png";
import blueOrangeIconImg from "../assets/marker-blue-orange.png";
import blueRedOrangeIconImg from "../assets/marker-blue-red-orange.png";
import blueRedYellowIconImg from "../assets/marker-blue-red-yellow.png";
import blueRedIconImg from "../assets/marker-blue-red.png";
import blueSkyOrangeIconImg from "../assets/marker-blue-sky-orange.png";
import blueSkyRedIconImg from "../assets/marker-blue-sky-red.png";
import blueSkyYellowIconImg from "../assets/marker-blue-sky-yellow.png";
import blueSkyIconImg from "../assets/marker-blue-sky.png";
import blueYellowIconImg from "../assets/marker-blue-yellow.png";
import grayBlueOrangeIconImg from "../assets/marker-gray-blue-orange.png";
import grayBlueRedIconImg from "../assets/marker-gray-blue-red.png";
import grayBlueSkyIconImg from "../assets/marker-gray-blue-sky.png";
import grayBlueYellowIconImg from "../assets/marker-gray-blue-yellow.png";
import grayOrangeYellowIconImg from "../assets/marker-gray-orange-yellow.png";
import grayOrangeIconImg from "../assets/marker-gray-orange.png";
import grayRedOrangeIconImg from "../assets/marker-gray-red-orange.png";
import grayRedYellowIconImg from "../assets/marker-gray-red-yellow.png";
import grayRedIconImg from "../assets/marker-gray-red.png";
import graySkyOrangeIconImg from "../assets/marker-gray-sky-orange.png";
import graySkyRedIconImg from "../assets/marker-gray-sky-red.png";
import graySkyYellowIconImg from "../assets/marker-gray-sky-yellow.png";
import graySkyIconImg from "../assets/marker-gray-sky.png";
import grayYellowIconImg from "../assets/marker-gray-yellow.png";
import orangeRedIconImg from "../assets/marker-orange-red.png"
import orangeYellowconImg from "../assets/marker-orange-yellow.png"
import redOrangeYellowIconImg from "../assets/marker-red-orange-yellow.png"
import skyOrangeYellowIconImg from "../assets/marker-sky-orange-yellow.png"
import skyOrangeIconImg from "../assets/marker-sky-orange.png"
import skyRedOrangeIconImg from "../assets/marker-sky-red-orange.png"
import skyRedYellowIconImg from "../assets/marker-sky-red-yellow.png"
import skyRedIconImg from "../assets/marker-sky-red.png"
import skyYellowIconImg from "../assets/marker-sky-yellow.png"
import yellowRedIconImg from "../assets/marker-yellow-red.png"


import "./Icons.css";

/*
let iconSize: PointExpression = [25, 41];
let iconAnchor: PointExpression = [12.5, 41];
let popupAnchor: PointExpression = [12.5, -35];
*/

let iconSize: PointExpression = [18, 32];
let iconAnchor: PointExpression = [9, 32];
let popupAnchor: PointExpression = [9, -31];

let iconSizeBigger: PointExpression = [35, 50];
let iconAnchorBigger: PointExpression = [17.5, 50];
let popupAnchorBigger: PointExpression = [17.5, -35];


export const blueIcon = new L.Icon({
  iconUrl: blueIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayIcon = new L.Icon({
  iconUrl: grayIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const redIcon = new L.Icon({
  iconUrl: redIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const yellowIcon = new L.Icon({
  iconUrl: yellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const greenIcon = new L.Icon({
  iconUrl: greenIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const skyIcon = new L.Icon({
  iconUrl: skyIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const purpleIcon = new L.Icon({
  iconUrl: purpleIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const fuchsiaIcon = new L.Icon({
  iconUrl: fuchsiaIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const orangeIcon = new L.Icon({
  iconUrl: orangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueGrayIcon = new L.Icon({
  iconUrl: blueGrayIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueOrangeYellowIcon = new L.Icon({
  iconUrl: blueOrangeYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueOrangeIcon = new L.Icon({
  iconUrl: blueOrangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueRedOrangeIcon = new L.Icon({
  iconUrl: blueRedOrangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueRedIcon = new L.Icon({
  iconUrl: blueRedIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueRedYellowIcon = new L.Icon({
  iconUrl: blueRedYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueSkyOrangeIcon = new L.Icon({
  iconUrl: blueSkyOrangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueSkyRedIcon = new L.Icon({
  iconUrl: blueSkyRedIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueSkyYellowIcon = new L.Icon({
  iconUrl: blueSkyYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueSkyIcon = new L.Icon({
  iconUrl: blueSkyIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blueYellowIcon = new L.Icon({
  iconUrl: blueYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayBlueOrangeIcon = new L.Icon({
  iconUrl: grayBlueOrangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayBlueRedIcon = new L.Icon({
  iconUrl: grayBlueRedIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayBlueSkyIcon = new L.Icon({
  iconUrl: grayBlueSkyIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayBlueYellowIcon = new L.Icon({
  iconUrl: grayBlueYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayOrangeYellowIcon = new L.Icon({
  iconUrl: grayOrangeYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayOrangeIcon = new L.Icon({
  iconUrl: grayOrangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayRedOrangeIconIcon = new L.Icon({
  iconUrl: grayRedOrangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayRedYellowIcon = new L.Icon({
  iconUrl: grayRedYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayRedIcon = new L.Icon({
  iconUrl: grayRedIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const graySkyOrangeIcon = new L.Icon({
  iconUrl: graySkyOrangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const graySkyRedIcon = new L.Icon({
  iconUrl: graySkyRedIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const graySkyYellowIcon = new L.Icon({
  iconUrl: graySkyYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const graySkyIcon = new L.Icon({
  iconUrl: graySkyIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const grayYellowIcon = new L.Icon({
  iconUrl: grayYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const orangeRedIcon = new L.Icon({
  iconUrl: orangeRedIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const orangeYellowcon = new L.Icon({
  iconUrl: orangeYellowconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const redOrangeYellowIcon = new L.Icon({
  iconUrl: redOrangeYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const skyOrangeYellowIcon = new L.Icon({
  iconUrl: skyOrangeYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const skyOrangeIcon = new L.Icon({
  iconUrl: skyOrangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const skyRedOrangeIcon = new L.Icon({
  iconUrl: skyRedOrangeIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const skyRedYellowIcon = new L.Icon({
  iconUrl: skyRedYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const skyRedIcon = new L.Icon({
  iconUrl: skyRedIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const skyYellowIcon = new L.Icon({
  iconUrl: skyYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const yellowRedIcon = new L.Icon({
  iconUrl: yellowRedIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});


/*
export const blueIcon = new L.Icon({
  iconUrl: blueIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const redYellowIcon = new L.Icon({
  iconUrl: redYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const blueYellowIcon = new L.Icon({
  iconUrl: blueYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const redIcon = new L.Icon({
  iconUrl: redIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const redBlueIcon = new L.Icon({
  iconUrl: redBlueIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const redBlueYellowIcon = new L.Icon({
  iconUrl: redBlueYellowIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const yellowIcon = new L.Icon({
  iconUrl: yellowIconImg,
  iconSize: [37.5, 61.5],
  iconAnchor: [18.75, 61.5],
  shadowUrl: "",
  popupAnchor: [18.75, -35],
});

export const purpleIcon = new L.Icon({
  iconUrl: purpleIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const greenIcon = new L.Icon({
  iconUrl: greenIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});

export const blackIcon = new L.Icon({
  iconUrl: blackIconImg,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const blueIconS = new L.Icon({
  iconUrl: blueIconImgS,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const redYellowIconS = new L.Icon({
  iconUrl: redYellowIconImgS,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const blueYellowIconS = new L.Icon({
  iconUrl: blueYellowIconImgS,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const redIconS = new L.Icon({
  iconUrl: redIconImgS,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const redBlueIconS = new L.Icon({
  iconUrl: redBlueIconImgS,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const redBlueYellowIconS = new L.Icon({
  iconUrl: redBlueYellowIconImgS,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});


export const yellowIconS = new L.Icon({
  iconUrl: yellowIconImgS,
  iconSize: [37.5, 61.5],
  iconAnchor: [18.75, 61.5],
  shadowUrl: "",
  popupAnchor: [18.75, -35],
});
export const purpleIconS = new L.Icon({
  iconUrl: purpleIconImgS,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});
export const greenIconS = new L.Icon({
  iconUrl: greenIconImgS,
  iconSize: iconSize,
  iconAnchor: iconAnchor,
  shadowUrl: "",
  popupAnchor: popupAnchor,
});


*/

export const endIcon = new L.Icon({
  iconUrl: endIconImg,
  iconSize: iconSizeBigger,
  iconAnchor: iconAnchorBigger,
  shadowUrl: "",
  popupAnchor: popupAnchorBigger,
});
export const startIcon = new L.Icon({
  iconUrl: startIconImg,
  iconSize: iconSizeBigger,
  iconAnchor: iconAnchorBigger,
  shadowUrl: "",
  popupAnchor: popupAnchorBigger,
});

export const startZoomIcon = new L.Icon({
  iconUrl: startZoomIconImg,
  iconSize: iconSizeBigger,
  iconAnchor: iconAnchorBigger,
  shadowUrl: "",
  popupAnchor: popupAnchorBigger,
});

export const numberedIcon = (sequences: number[], image: string) =>
  L.divIcon({
    className: "number-icon",
    iconSize: [25, 57],
    iconAnchor: [12.5, 57],
    shadowUrl: "",
    popupAnchor: [12.5, -35],
    html: `<div class="sequence-icon">
            <div class="circles-sequence">
            ${sequences.map((sequence) => `<span>${sequence}</span>`).join("")}
            </div>
            <img src="${image}"/>
           
            </div>`,
  });
