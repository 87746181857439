import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";

export const LoadRouteForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid
                container
                justifyContent="flex-start"
                direction={"row"}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Grid item container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h6" gutterBottom>
                        Info principali
                      </Typography>
                    </Grid>
                    <Grid item>
                      <BooleanInput
                        {...initInput(
                          "sent",
                          props.fieldOptions,
                          props.canEdit
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid item>
                    <DateTimeInput
                      {...initInput(
                        "planned_date",
                        props.fieldOptions,
                        props.canEdit
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <SelectInput
                      {...initInput(
                        "status",
                        props.fieldOptions,
                        props.canEdit,
                        "array"
                      )}
                      allowEmpty
                      choices={props.fieldOptions.status.choices}
                    />
                  </Grid>

                  <Grid item>
                    <ReferenceInput
                      {...initInput(
                        "driver_id",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      filter={{ departments: "Autista" }}
                      reference="autocomplete/employee"
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>

                  <Grid item>
                    <ReferenceInput
                      {...initInput(
                        "carrier_id",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      filter={{ tag: "Vettore" }}
                      reference="autocomplete/subject"
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>
                  <Grid item>
                    <ReferenceInput
                      {...initInput(
                        "vehicle_id",
                        props.fieldOptions,
                        props.canEdit
                      )}
                      reference="autocomplete/vehicle"
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              //handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />
  );
};
