import {
  BooleanField,
  Datagrid, TextField,
} from "react-admin";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";
import { IdentifierField } from "../../components/IdentifierField";
import React from "react";
import { SubjectField } from "../../components/SubjectField";
import { RouteField } from "../../components/RouteField";


export const SimpleSegmentDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <TextField {...initField("id", options)} />
      <TextField {...initField("order_on_delivery", options)} label={"Ordine nella missione"} />
      <BooleanField {...initField("to_be_tractioned", options)} />
      <SubjectField {...initField("starting_point", options)} label={"Partenza"} />
      <SubjectField {...initField("arrival_point", options)} label={"Arrivo"} />
      <RouteField routeSource={"route_id"} label={"Giro"} />
      <TextField {...initField("sequence", options)} label={"Consegna N° sul giro"} />
      <BooleanField {...initField("loaded", options)} />
      <TextField {...initField("split", options)} />
    </Datagrid>
  );
};
