import React, { useEffect } from "react";
import {
  ReferenceInput,
  Datagrid,
  TextField,
  SelectField,
  DateField,
  NumberField,
  FunctionField,
  ArrayField,
  BooleanField,
  useDataProvider,
} from "react-admin";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { SubjectField } from "../../components/SubjectField";
import { useOptions } from "../../shared/hooks/useOptions";
import { initInput, initField } from "../../shared/utils";
import { Record } from "react-admin";
import { useFormState } from "react-final-form";
import { IdentifierField } from "../../components/IdentifierField";
import { ReasonStatus } from "../../shared/types";

interface DeliveryStatusReasonFieldProps {
  options: any;
  setFault?: any;
  form?: any;
  isRequired?: boolean;
  carrier?: boolean;
}
export const DeliveryStatusReasonField = (props: DeliveryStatusReasonFieldProps) => {
  const { options } = props;
  const { data: optionsReasons } = useOptions("reason_status", "GET");
  const { values } = useFormState();
  const disabled = values.status ? false : true;
  const dataProvider = useDataProvider();


  useEffect(() => {
    props.form.change("reason", null);
  }, [props.form.getState().values.status]);

  return optionsReasons ? (
    <ReferenceInput
      {...initInput("reason", options, !disabled)}
      reference="reason_status"
      filter={{
        status: values.status,
        description: props.carrier === true ? "VETTORE" : "",
      }}
      perPage={1000}
      sort={{ field: 'description', order: 'ASC' }}
      onChange={(value: any) => {

        if (props.form) {
          props.form.change("create_return", null);
        }

        if (value.target.value) {
          dataProvider.getOne<ReasonStatus>("reason_status", {
            id: value.target.value,
          })
            .then(({ data: reason_status }) => {
              if (reason_status && props.setFault) {
                props.setFault(reason_status.fault)
              }
            });
        }
      }}
      isRequired={props.isRequired}
    >
      <DialogSelectInput optionText="description">
        <Datagrid>
          <IdentifierField {...initField("id", optionsReasons)} />
          <TextField {...initField("description", optionsReasons)} />
          <BooleanField {...initField("fault", optionsReasons)} />
        </Datagrid>
      </DialogSelectInput>
    </ReferenceInput>
  ) : null;
};
