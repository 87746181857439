import { ListBase, ListProps } from "react-admin";
import { getDefaultListProps } from "../shared/utils";
import { SubjectSolverGrid } from "./SubjectSolverGrid";

export const SubjectSolverList = (props: ListProps) => {
  return (
    <ListBase
      {...getDefaultListProps(props)}
      pagination={false}
      perPage={Infinity}
    >
      <SubjectSolverGrid />
    </ListBase>
  );
};
