import { Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { FunctionField, Record, useRecordContext } from "react-admin";
import { initField } from "../../shared/utils";


interface Props {
  source: string;
  options?: any;
  label?: string;
  sortBy?: string

}
export const NoteOriginField = (props: Props) => {
  const { source, options, label, sortBy } = props;
  const record = useRecordContext(props);
  const type_map: { [key: string]: string } = {
    "M": "Manuale",
    "S": "Scanner",
    "A": "Automatica",
    "D": "Driver",
  }


  return (
    <FunctionField
      label={label ? label : "Origine bolla"}
      source={source}
      sortBy={sortBy ? sortBy : source}
      render={(tableRecord: Record | undefined) => {
        if (tableRecord) {
          return <>{type_map[tableRecord[source]]}</>
        }
        return null;
      }}
    />

  );
};
