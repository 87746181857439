import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { linkToRecord, useResourceContext } from "ra-core";
import { Button, FieldProps } from "react-admin";
import { get } from "lodash";

export const IdentifierField: FC<FieldProps & { show?: boolean }> = ({
  basePath = "",
  record,
  label,
  show,
  source = "id",
  ...rest
}) => {
  const resource = useResourceContext();
  const id = get(record, source);
  const link = useMemo(
    () => ({
      pathname: record
        ? linkToRecord(basePath || `/${resource}`, id, show ? "show" : "edit")
        : "",
      state: { _scrollToTop: true },
    }),
    [basePath, id, record, resource, show]
  );
  return id ? (
    <Button
      component={Link}
      target={"_blank"}
      to={link}
      label={id.toString()}
      onClick={stopPropagation}
      variant="text"
      {...(rest as any)}
    ></Button>
  ) : null;
};

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();
