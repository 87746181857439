import { AutocompleteInput, ListProps, ReferenceInput } from "ra-ui-materialui";
import React from "react";
import { Fragment } from "react";
import { BooleanInput, DateInput, NullableBooleanInput, SelectArrayInput, SelectInput, TextInput } from "react-admin";
import { GroupedList } from "../../components/GroupedList/GroupedList";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../shared/hooks/useOptions";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { CachetDatagrid } from "./CachetDatagrid";
import { CachetListHeader } from "./CachetListHeader";
import { GroupedDatagrid } from "./GroupedDatagrid";
import { PrintDriverRemittanceButton } from "./PrintDriverRemittanceButton";
import { VerifyAllButton } from "./VerifyAllButton";
import { ResetCachetBulkButton } from "./ResetCachetBulkButton";
import { CachetGroupedDatagrid } from "./CachetGroupedDatagrid";

export const CachetToCashInList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsDeliveryStates } = useOptions("delivery-states", "GET");

  const filters = options && optionsDeliveryStates
    ? [
      <TextInput source="document_number" label="DDT/ID/QR" alwaysOn
        className="filter-field-small" />,
      <ReferenceInput
        {...initField("delivery", options)}
        reference="autocomplete/delivery"
        className="filter-field-small"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        source="route"
        label="Giro"
        reference="autocomplete/route"
        className="filter-field-small"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        alwaysOn
        source="driver"
        label="Autista"
        filter={{ departments: "Autista" }}
        reference="autocomplete/employee"
        className="filter-field-small"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        alwaysOn
        source="carrier"
        label="Vettore"
        filter={{ type: "carrier" }}
        reference="autocomplete/subject"
        className="filter-field-small"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInputSubject
        source="client"
        label="Cliente"
        filter={{ type: "client" }}
        className="filter-field-small"
      />,
      <DateInput source="delivery_date_after" label="Data missione dal" />,
      <DateInput source="delivery_date_before" label="Data missione al" />,
      <DateInput source="document_date_after" label="Data documento dal" />,
      <DateInput source="document_date_before" label="Data documento al" />,
      <SelectArrayInput
        choices={optionsDeliveryStates.status.choices}
        {...initField(
          "delivery_states",
          optionsDeliveryStates.status,
          "array"
        )}
        label="Stati missioni"
      />,
      <ReferenceInputSubject
        source="receiver"
        label="Destinatario"
        filter={{ type: "receiver" }}
        className="filter-field-small"
      />,
      <NullableBooleanInput {...initField("cashed", options)} label={"Incassato"} />,
      <SelectInput
        {...initField("delivery_type", options, "array")}
        choices={options.delivery_type.choices}
        className="filter-field-small"
      />
    ]
    : [];

  return options ? (
    <GroupedList
      possibleGroupings={[
        {
          label: "Autista",
          source: "driver",
          component: <CachetGroupedDatagrid />,
        },
        {
          label: "Cliente",
          source: "client",
          component: <CachetGroupedDatagrid />,
        },
        {
          label: "Vettore",
          source: "carrier",
          component: <CachetGroupedDatagrid />,
        },
      ]}
      sort={{ field: "id", order: "DESC" }}
      filterDefaultValues={{ delivery_states: ["L", "D", "ID"] }}
      filter={{ verified: false }}
      filters={filters}
      syncWithLocation={true}
      {...getDefaultListProps(props)}
      bulkActionButtons={
        <Fragment>
          <VerifyAllButton />
          <PrintDriverRemittanceButton />
          <ResetCachetBulkButton />
        </Fragment>
      }
      topElement={<CachetListHeader />}
    >
      <CachetDatagrid options={options} />
    </GroupedList>
  ) : null;
};
