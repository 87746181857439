import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from "ra-ui-materialui";
import React from "react";


type ReferenceInputSiteProps = Omit<
  ReferenceInputProps,
  "children" | "reference" | "sort"
>;

export const ReferenceInputSite = (props: ReferenceInputSiteProps) => {
  return (
    <ReferenceInput
      {...(props as ReferenceInputProps)}
      reference="autocomplete/site"
      sort={{ field: "business_name", order: "ASC" }}
      allowEmpty={props.allowEmpty === false ? false : true}
      isRequired={props.isRequired || props.required}
    >
      <AutocompleteInput
        options={{ ...props.options }}
        optionText="label"
      />
    </ReferenceInput>

  );
};
