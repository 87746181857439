import { AutocompleteInput, Button, Datagrid, DateField, FormWithRedirect, FunctionField, ListContextProvider, ListProps, Record, SaveButton, SelectField, TextField, TextInput, useList, useNotify, useQuery, useRefresh } from "react-admin";
import { client } from "../dataProvider";
import { useEffect, useMemo, useState } from "react";
import { useToggler } from "../shared/hooks/useToggler";
import { generateError } from "../shared/utils";
import { Dialog, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";

import IconCancel from "@material-ui/icons/Cancel";


export const ForkliftList = () => {

  //"/api/forklift/get_messages"

  const devices: any = {
    "1250": 'ETSA1_221239467',
    "1251": 'ETSA1_221239479'
  }

  const [loading, setLoading] = useState(true);
  const [dataQ, setDataQ] = useState([]);

  const [filter, setFilter] = useState("")
  const refresh = useRefresh();

  useEffect(
    () => {
      client("forklifts/get_messages?filter=" + filter, {
        method: "GET",
      }).then((data) => {
        setDataQ(data["data"]["items"])
        setLoading(false)
      }).catch(() => {
        setDataQ([])
        setLoading(true)
      })
    },
    [filter]
  )

  const listContext = useList({
    data: dataQ ? dataQ : [],
    ids: dataQ ? dataQ.map((val: any, i: any) => val["id"]) : [],
    loading: loading,
    loaded: !loading
  });

  const onChangeHandler = (event: any) => {
    setFilter(event.target.value);
  };

  const { value, setTrue, setFalse } = useToggler();
  const notify = useNotify();

  const onClick = (values: any) => {
    setLoading(true);

    client(`forklifts/send_messages`, {
      method: "POST",
      data: {
        ...values
      }
    }).then((response) => {
      let errors = "";
      for (let k in response.errors) {
        errors += k + " -> " + response.errors[k] + "\n "
      }
      if (errors !== "") {
        window.alert(errors);
      }
      else {
        notify("Messaggio inviato");
      }
      setFalse();
      window.location.reload();
    })
      .catch((error) => {
        generateError(error, notify);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <>
      <Grid container>
        <Grid xs={6} item>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setTrue();
            }}
            label={"Nuovo messaggio"}
            title={"Modifica il ciente delle missioni selezionate"}
          ></Button>
        </Grid>
        <Grid xs={6} item>
          Cerca:
          <input
            type="text"
            name="name"
            onChange={onChangeHandler}
            value={filter}
          />
        </Grid>
      </Grid>

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="messages"
          save={onClick}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <Typography style={{ textAlign: "center" }}>Invia un messaggio:</Typography>
                <AutocompleteInput source="device"
                  label={"Dispositivo"}
                  choices={[
                    { id: '1250', name: 'ETSA1_221239467' },
                    { id: '1251', name: 'ETSA1_221239479' },
                  ]}
                  isRequired={true}
                  fullWidth
                />

                <TextInput
                  source="message"
                  label={"Messaggio"}
                  required={true}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
      <ListContextProvider value={listContext}>
        <Datagrid>
          <TextField source="id" />

          <FunctionField
            label={"Dispositivo"}
            source="asset"
            render={(record: Record | undefined) => {
              return record ? (
                <Typography>{devices[record.asset.id]}</Typography>
              )
                : null;
            }
            }
          />

          <TextField source="message" label={"Messaggio"} />
          <DateField source="dateSend" showTime label={"Data invio"} />
          <SelectField source="sendStatus" label={"Stato invio"} choices={[
            { id: '0', name: 'In Attesa' },
            { id: '1', name: 'Inviato' },
          ]} />
          <TextField source="comment" label={"Risposta"} />
          <DateField source="dateReceived" showTime label={"Data ricezione"} />
          <SelectField source="receivedStatus" label={"Stato ricezione"} choices={[
            { id: '0', name: 'In Attesa' },
            { id: '1', name: 'Ricevuto' },
          ]} />
        </Datagrid>
      </ListContextProvider >

    </>
  );

};
