import { FC } from "react";
import {
  DatagridRowProps,
  DatagridRow,
  useUpdate,
  useResourceContext,
  useListContext,
  useNotify,
} from "react-admin";
import { Form } from "react-final-form";
import { createStore, Provider } from "./store";

export const EditableDatagridRow: FC<DatagridRowProps> = (props) => {
  const [update] = useUpdate();
  const resource = useResourceContext();
  const { refetch } = useListContext();
  const notify = useNotify();
  return (
    <Provider createStore={createStore}>
      <Form
        onSubmit={(values) => {
          props.record &&
            update(
              {
                resource,
                payload: { id: props.record.id, data: values },
              },
              {
                onSuccess: () => {
                  notify("Aggiornato!", "success");
                  refetch();
                },
              }
            );
        }}
        initialValues={props.record}
        render={() => <DatagridRow {...props} />}
      />
    </Provider>
  );
};
