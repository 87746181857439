import { Button, Typography, withStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import _ from "lodash";
import React from "react";
import { FieldProps, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";


const RedTextTypography = withStyles({
  root: {
    color: red[500]
  }
})(Typography);

const GreenTextTypography = withStyles({
  root: {
    color: green[500]
  }
})(Typography);

export const RouteField = (props: FieldProps & {
  routeSource: string, hasFile?: boolean
}) => {
  const record = useRecordContext(props);
  const route_id = _.get(record, props.routeSource);
  return route_id ? (
    <span onClick={(e) => e.stopPropagation()}>
      <Button
        component={Link}
        target={"_blank"}
        to={"/routes/" + route_id}>
        {props.hasFile !== true ?
          <RedTextTypography variant="body1">
            {route_id}
          </RedTextTypography> :
          <GreenTextTypography variant="body1">
            {route_id}
          </GreenTextTypography>
        }
      </Button>
    </span>
  ) : null;
};
