import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  TableContainer,
  Typography,
} from "@material-ui/core";
import {
  DateInput,
  FormWithRedirect,
  SelectInput,
  NumberField,
  SelectField,
  TextInput,
  ReferenceArrayField,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { SubjectField } from "../../components/SubjectField";
import { DeliveryType, FormProps } from "../../shared/types";
import { initField, initInput } from "../../shared/utils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CachetPaymentDatagrid } from "../Cachet/CachetPayment/CachetPaymentDatagrid";

const SField = () => <SubjectField source="client" />;
const TField = () => (
  <NumberField
    source="total"
    options={{
      style: "currency",
      currency: "EUR",
    }}
  />
);

export const RemittanceForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Box mb={1} width={"100%"}>
                <Typography variant="h5">
                  <SField />
                </Typography>
              </Box>
              <Box mb={1} width={"100%"}>
                <Typography variant="h6">
                  Totale : <TField />
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" mb={1} width={"100%"}>
                <Typography variant="h6">
                  Stato :{" "}
                  <SelectField
                    {...initField("status", props.fieldOptions, "array")}
                    choices={props.fieldOptions.status.choices}
                  />
                </Typography>
                <Box ml={2}></Box>
              </Box>

              <NumberInput
                {...initInput(
                  "remittance_sequential_number",
                  props.fieldOptions,
                  props.canEdit
                )}
              />
              <DateInput
                {...initInput(
                  "remittance_date",
                  props.fieldOptions,
                  props.canEdit
                )}
              />

              <SelectInput
                {...initInput(
                  "type",
                  props.fieldOptions,
                  props.canEdit,
                  "array"
                )}
                choices={props.fieldOptions.type.choices}
              />
              <TextInput
                {...initInput("note", props.fieldOptions, props.canEdit)}
              />
              <ReferenceInput
                {...initInput(
                  "delivery",
                  props.fieldOptions,
                  props.canEdit
                )}
                reference="autocomplete/delivery"
                filter={{ delivery_type: DeliveryType.PLACEHOLDER }}
                allowEmpty
              >
                <AutocompleteInput optionText="label" />
              </ReferenceInput>
              {props.record && props.record.id && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h6">
                      Incassi dei contrassegni
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    <TableContainer>
                      <ReferenceArrayField
                        label="Incassi"
                        reference="cachet-payments"
                        source="payments"
                      >
                        <CachetPaymentDatagrid />
                      </ReferenceArrayField>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              )}
            </CardContent>

            <CustomToolbar
              canDelete={props.canDelete && props.record.status !== "B"}
              canEdit={props.canEdit}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />
  );
};
