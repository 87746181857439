import {
  ListProps,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  NullableBooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../../shared/utils";
import { LoadRouteDatagrid } from "./LoadRouteDatagrid";

export const LoadRouteList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput alwaysOn {...initField("id", options)} />,
      <DateInput {...initField("planned_date_after", options)} label="Pianificada dal" />,
      <DateInput {...initField("planned_date_before", options)} label="Pianificada al" />,
      <DateInput {...initField("planned_date__date", options)} label="Pianificada il" />,
      <SelectInput
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />,
      <ReferenceInput
        alwaysOn
        {...initField("driver", options)}
        filter={{ departments: "Autista" }}
        reference="autocomplete/employee"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        {...initField("carrier", options)}
        filter={{ tag: "Vettore" }}
        reference="autocomplete/subject"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        {...initField("vehicle", options)}
        reference="autocomplete/vehicle"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <NullableBooleanInput {...initField("sent", options)} />,
      <ReferenceArrayInput
        alwaysOn
        {...initField("tags_filter", options)}
        label="Tag"
        reference="autocomplete/tag"
      >
        <AutocompleteArrayInput optionText="label" />
      </ReferenceArrayInput>,
    ]
    : [];

  var appbarMain = document.getElementById("appbar-main");
  var secBar = document.getElementById("secondary-app-bar");

  if (appbarMain) {
    appbarMain.style.display = ""
  }
  if (secBar) {
    secBar.style.display = ""
  }

  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      {...getDefaultListProps(props)}
    >
      <LoadRouteDatagrid options={options} />
    </CustomList>
  ) : null;
};
