import {
  CreateContextProvider,
  Record as RecordRa,
  ResourceContextProvider,
  useCheckMinimumRequiredProps,
  useCreateController,
  useNotify,
  useRedirect,
  useResourceContext,
} from "ra-core";
import { ReactElement, useCallback } from "react";
import {
  CreateProps,
  CRUD_CREATE,
  useCreate,
  useSaveModifiers,
} from "react-admin";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { generateError } from "../shared/utils";
import { CustomCreateView } from "./CustomCreateView";

/**
 * Page component for the Create view
 *
 * The `<Create>` component renders the page title and actions.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes the `record` as prop.
 *
 * The <Create> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - successMessage
 * - title
 *
 * @example
 *
 * // in src/posts.js
 * import * as React from "react";
 * import { Create, SimpleForm, TextInput } from 'react-admin';
 *
 * export const PostCreate = (props) => (
 *     <Create {...props}>
 *         <SimpleForm>
 *             <TextInput source="title" />
 *         </SimpleForm>
 *     </Create>
 * );
 *
 * // in src/App.js
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 *
 * import { PostCreate } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={...}>
 *         <Resource name="posts" create={PostCreate} />
 *     </Admin>
 * );
 * export default App;
 */
export const CustomCreate = (
  props: CreateProps & { children: ReactElement; successMessage?: string }
): ReactElement => {
  useCheckMinimumRequiredProps("Create", ["children"], props);
  const controllerProps = useCreateController(props);
  const { data: fieldOptions } = useOptionsResource("POST");
  const resource = useResourceContext(props);
  const notify = useNotify();
  const redirect = useRedirect();
  const { basePath, successMessage, onSuccess, onFailure, transform } = props;

  const { onSuccessRef, transformRef } = useSaveModifiers({
    onSuccess,
    onFailure,
    transform,
  });
  const [create] = useCreate(resource);

  const save = useCallback(
    async (
      data: Partial<RecordRa>,
      redirectTo = "list",
      {
        onSuccess: onSuccessFromSave,
        onFailure: onFailureFromSave,
        transform: transformFromSave,
      } = {}
    ) => {
      try {
        await Promise.resolve(
          transformFromSave
            ? transformFromSave(data)
            : transformRef.current
            ? transformRef.current(data)
            : data
        ).then((data) =>
          create(
            { payload: { data } },
            {
              returnPromise: true,
              action: CRUD_CREATE,
              onSuccess: onSuccessFromSave
                ? onSuccessFromSave
                : onSuccessRef.current
                ? onSuccessRef.current
                : ({ data: newRecord }: any) => {
                    notify(
                      successMessage || "ra.notification.created",
                      "info",
                      {
                        smart_count: 1,
                      }
                    );
                    redirect(redirectTo, basePath, newRecord.id, newRecord);
                  },
            }
          )
        );
      } catch (error) {
        return generateError(error, notify);
      }
    },
    [
      transformRef,
      create,
      onSuccessRef,
      notify,
      successMessage,
      redirect,
      basePath,
    ]
  );
  if (!fieldOptions) return <></>;
  const body = (
    <CreateContextProvider value={{ ...controllerProps, save }}>
      <CustomCreateView
        {...props}
        {...{ ...controllerProps, save }}
        fieldOptions={fieldOptions}
      />
    </CreateContextProvider>
  );
  return props.resource ? (
    // support resource override via props
    <ResourceContextProvider value={props.resource}>
      {body}
    </ResourceContextProvider>
  ) : (
    body
  );
};
