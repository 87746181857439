import { useLeafletContext } from "@react-leaflet/core";
import L, { FeatureGroup, Layer } from "leaflet";
import { useEffect, useMemo, useRef } from "react";
import "leaflet-draw";
import { useFormState, useForm } from "react-final-form";
interface Props {
  drawOptions?: L.Control.DrawOptions;
  edit?: false | L.DrawOptions.EditHandlerOptions;
  remove?: false | null;
}

export function DrawControl({
  drawOptions = {},
  edit = false,
  remove = false,
}: Props) {
  const context = useLeafletContext();
  const drawLayerRef = useRef<FeatureGroup>();
  const drawControl = useRef<any>();
  const { values } = useFormState();
  const form = useForm();
  const defaultMarker = useMemo<{ lat: number; lng: number }>(() => {
    return values.latitude && values.longitude
      ? {
        lat: values.latitude,
        lng: values.longitude,
      }
      : {
        lat: 41.2925,
        lng: 12.5736,
      };
  }, [values.latitude, values.longitude]);
  useEffect(() => {
    const map = context.map;

    drawLayerRef.current = new L.FeatureGroup();
    map.addLayer(drawLayerRef.current);

    drawControl.current = new L.Control.Draw({
      draw: drawOptions,
      edit: {
        featureGroup: drawLayerRef.current,
        edit: edit,
        remove: remove,
      },
    });
    map.addControl(drawControl.current);

    map.on(L.Draw.Event.CREATED, function (event) {
      var layer = event.layer;

      drawLayerRef.current!.addLayer(layer);
    });

    if (defaultMarker) {
      L.marker(defaultMarker).addTo(drawLayerRef.current);
    }

    map.on(L.Draw.Event.CREATED, function (event) {
      var layer = event.layer;

      drawLayerRef.current!.addLayer(layer);
    });

    map.on("draw:edited", function (e: any) {
      const layers = e.layers;
      layers.eachLayer(function (layer: any) {
        if (layer instanceof L.Marker) {
          const latLng = layer.getLatLng();
          form.change("latitude", latLng.lat);
          form.change("longitude", latLng.lng);
        }
      });
    });
    return () => {
      map.removeEventListener(L.Draw.Event.CREATED);
      map.removeEventListener("draw:edited");
      map.removeControl(drawControl.current);
      map.removeLayer(drawLayerRef.current as Layer);
    };
  }, [context.map, defaultMarker, drawOptions, edit, form, remove]);

  return null;
}
