import { Identifier } from "ra-core";
import { Button } from "ra-ui-materialui";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import { green, orange } from "@material-ui/core/colors";
import { useState } from "react";
import { ImageViewer } from "./ImageViewer";

interface Props {
  title?: string;
  final?: boolean;
  noteId: Identifier;
  resource?: string;
}

export const DownloadNoteFileButton = ({ title, final, noteId, resource }: Props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  return (
    <>
      <Button
        onClick={(e) => {
          setOpenDialog(true);
        }}
        variant="text"
        title={title ? title : "Download"}
        style={{ padding: "0px", minWidth: "0px" }}
      >
        {final ? (
          <InsertDriveFile style={{ color: green[500] }} />
        ) : (
          <InsertDriveFile style={{ color: orange[500] }} />
        )}
      </Button>
      {openDialog && (
        <ImageViewer
          noteId={noteId}
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          resource={resource}
        />
      )}
    </>
  );
};
