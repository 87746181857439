import { MenuProps, ReduxState, toggleSidebar } from "react-admin";
import logo from "./assets/logo.png";

import { useCallback, useEffect, useState } from "react";
import LabelIcon from "@material-ui/icons/Label";
import {
  MenuItemLink,
  getResources,
  useTranslate,
  DashboardMenuItem,
} from "react-admin";
import { useSelector, shallowEqual } from "react-redux";
import classnames from "classnames";
import DefaultIcon from "@material-ui/icons/ViewList";

import { Fragment } from "react";

import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { NotificationIcons } from "./NotificationIcons";

const useStylesMenuItem = makeStyles<any>(
  (theme) => ({
    endIcon: {
      minWidth: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
      "& a": {
        paddingLeft: theme.spacing(3),
        transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      },
    },
    sidebarIsClosed: {
      "& a": {
        paddingLeft: theme.spacing(2),
        transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      },
    },
    menuItem: {},
    menuItemName: {
      flex: "1 auto",
      color: theme.palette.text.secondary,
    },
    openMenuItem: {},
  }),
  { name: "RaTreeCustomMenuItem" }
);

const CustomMenuItem = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  children,
  dense,
}: any) => {
  const classes = useStylesMenuItem();
  const translate = useTranslate();



  const header = (
    <MenuItem
      key={name}
      dense={dense}
      button
      onClick={handleToggle}
      className={classnames(classes.menuItem, {
        [classes.openMenuItem]: isOpen,
      })}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <Typography
        variant="inherit"
        className={classnames(classes.menuItemName, "menuItemName")}
      >
        {translate(name)}
      </Typography>
      {isOpen ? (
        <ExpandLess className={classes.endIcon} />
      ) : (
        <ExpandMore className={classes.endIcon} />
      )}
    </MenuItem>
  );

  const handleKeyPress = useCallback((event) => {
    if (event.shiftKey === true && event.ctrlKey === true && event.key === "N") {
      window.location.href = window.location.origin + "/deliveries/create";
    }
    else if (event.shiftKey === true && event.ctrlKey === true && event.key === "M") {
      window.location.href = window.location.origin + "/deliveries";
    }
    else if (event.shiftKey === true && event.ctrlKey === true && event.key === "S") {
      window.location.href = window.location.origin + "/subjects";
    }
    else if (event.shiftKey === true && event.ctrlKey === true && event.key === "G") {
      window.location.href = window.location.origin + "/routes";
    }
    else if (event.shiftKey === true && event.ctrlKey === true && event.key === "C") {
      window.location.href = window.location.origin + "/cachets";
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

const useStyles = makeStyles<any>(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "0.5em",

      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: 200,
    },
    closed: {
      width: 55,
    },
    menuButton: {
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: theme.spacing(5),
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(0deg)",
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(360deg)",
    },
  }),
  { name: "RaTreeMenu" }
);

export const CustomMenu = (props: MenuProps) => {
  const {
    className,
    dense = true,
    hasDashboard,
    onMenuClick,
    logout,

    ...rest
  } = props;

  const classes = useStyles(props);
  const translate = useTranslate();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const pathname = useSelector(
    (state: ReduxState) => state.router.location.pathname
  );
  const resources = useSelector(getResources, shallowEqual) as Array<any>;
  const hasList = (resource: any) => resource.hasList;
  const dispatch = useDispatch();
  const handleToggle = (parent: any) => {
    /**
     * Handles toggling of parents dropdowns
     * for resource visibility
     */
    setState((state: any) => ({ [parent]: !state[parent] }));
  };

  const isParent = (resource: any) =>
    /**
     * Check if the given resource is a parent
     * i.e. dummy resource for menu parenting
     */
    resource.options &&
    resource.options.hasOwnProperty("isMenuParent") &&
    resource.options.isMenuParent;

  const isOrphan = (resource: any) =>
    /**
     * Check if the given resource is an orphan
     * i.e. has no parents defined. Needed as
     * these resources are supposed to be rendered
     * as is
     *
     */
    resource.options &&
    !resource.options.hasOwnProperty("menuParent") &&
    !resource.options.hasOwnProperty("isMenuParent");

  const isChildOfParent = (resource: any, parentResource: any) =>
    /**
     * Returns true if the given resource is the
     * mapped child of the parentResource
     */
    resource.options &&
    resource.options.hasOwnProperty("menuParent") &&
    resource.options.menuParent === parentResource.name;
  const geResourceName = (slug: string) => {
    if (!slug) return;
    var words = slug.toString().split("_");
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return words.join(" ");
  };

  const getPrimaryTextForResource = (resource: any) => {
    let resourcename: any = "";
    if (resource.options && resource.options.label)
      resourcename = resource.options.label;
    else if (resource.name) {
      resourcename = translate(`resources.${resource.name}.name`);
      if (resourcename.startsWith("resources."))
        resourcename = geResourceName(resource.name);
    }
    return resourcename;
  };

  const MenuItem = (resource: any) => (
    /**
     * Created and returns the MenuItemLink object component
     * for a given resource.
     */
    <MenuItemLink
      key={resource.name}
      to={`/${resource.name}`}
      primaryText={getPrimaryTextForResource(resource)}
      leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
      onClick={onMenuClick}
      dense={dense}
      sidebarIsOpen={open}
    />
  );

  /**
   * Mapping a "parent" entry and then all its children to the "tree" layout
   */
  const mapParentStack = (parentResource: any) => (
    <CustomMenuItem
      key={parentResource.name}
      handleToggle={() => handleToggle(parentResource.name)}
      isOpen={
        state[parentResource.name] ||
        parentActiveResName === parentResource.name
      }
      sidebarIsOpen={open}
      name={getPrimaryTextForResource(parentResource)}
      icon={
        parentResource.icon ? (
          <parentResource.icon fontSize={"small"} />
        ) : (
          <LabelIcon fontSize={"small"} />
        )
      }
      dense={dense}
    >
      {
        // eslint-disable-next-line
        resources
          .filter(
            (resource) =>
              isChildOfParent(resource, parentResource) && hasList(resource)
          )
          .map((childResource) => {
            return MenuItem(childResource);
          })
      }
    </CustomMenuItem>
  );

  /**
   * Mapping independent (without a parent) entries
   */
  const mapIndependent = (independentResource: any) =>
    hasList(independentResource) && MenuItem(independentResource);

  /**
   * Initialising the initialExpansionState and
   * active parent resource name at the time of
   * initial menu rendering.
   */
  const initialExpansionState: any = {};
  let parentActiveResName: any = null;

  /**
   * Initialise all parents to inactive first.
   * Also find the active resource name.
   */
  resources.forEach((resource) => {
    if (isParent(resource)) {
      initialExpansionState[resource.name] = false;
    } else if (
      pathname.startsWith(`/${resource.name}`) &&
      resource.options.hasOwnProperty("menuParent")
    ) {
      let final_part = document.location.pathname.split("/").pop();

      parentActiveResName = resource.options.menuParent;
      if (document.location.pathname.includes("create")) {
        document.title = resource.options.label + ": New";
      }
      else if (
        document.location.pathname.includes("edit") ||
        (final_part && !isNaN(parseInt(final_part.toString())))
      ) {
        document.title = resource.options.label + ": " + document.location.pathname.split("/").pop();
      }
      else if (resource.options.label) {
        document.title = resource.options.label;
      }
      else {
        document.title = "Spsweb";
      }
    }
  });

  const [state, setState] = useState<any>(initialExpansionState);

  /**
   * The final array which will hold the array
   * of resources to be rendered
   */
  const resRenderGroup: any = [];

  /**
   * Looping over all resources and pushing the menu tree
   * for rendering in the order we find them declared in
   */
  resources.forEach((r) => {
    if (isParent(r)) resRenderGroup.push(mapParentStack(r));
    if (isOrphan(r)) resRenderGroup.push(mapIndependent(r));
  });

  return (
    <div>
      <div
        className={classnames(classes.main, className, {
          [classes.open]: open,
          [classes.closed]: !open,
        })}
        {...rest}
      >
        <Tooltip
          title={translate(
            open ? "ra.action.close_menu" : "ra.action.open_menu",
            {
              _: "Open/Close menu",
            }
          )}
          enterDelay={500}
        >
          <img
            src={logo}
            alt="logo supersonica"
            width="35px"
            onClick={() => dispatch(toggleSidebar())}
            className={classNames(
              classes.menuButton,
              open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed
            )}
          />
        </Tooltip>
        <NotificationIcons isOpen={open} />
        {hasDashboard && (
          <DashboardMenuItem
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
          />
        )}
        {resRenderGroup}
      </div>
    </div>
  );
};
