import { useNotify } from "ra-core";
import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from "ra-ui-materialui";
import React from "react";
import { client } from "../../dataProvider";
import { generateError } from "../../shared/utils";

type ReferenceInputSubjectProps = Omit<
  ReferenceInputProps,
  "children" | "reference" | "sort"
>;

export const ReferenceInputSubject = (props: ReferenceInputSubjectProps) => {
  const notify = useNotify();
  const mustHaveProvince = async (subject_id: number | undefined) => {
    if (subject_id) {
      return client(`subjects/${subject_id}`, {
        method: "GET",
      })
        .then((response) => {
          if (response.province && response.province.length === 2) {
            return undefined;
          } else {
            return "Il soggetto non ha la provincia";
          }
        })
        .catch((error) => {
          generateError(error, notify);
          return "Verificare che il soggetto abbia ha la provincia";
        });
    } else {
      return undefined;
    }
  };
  return (
    <ReferenceInput
      {...(props as ReferenceInputProps)}
      reference="autocomplete/subject"
      sort={{ field: "business_name", order: "ASC" }}
      allowEmpty={props.allowEmpty === false ? false : true}
      validate={mustHaveProvince}
      isRequired={props.isRequired || props.required}
    >
      <AutocompleteInput
        options={{ ...props.options }}
        optionText="label"
        onCreate={(filter) =>
          window.open(
            "/subjects/create",
            "_blank" // <- This is what makes it open in a new window.
          )
        }
      />
    </ReferenceInput>

  );
};
