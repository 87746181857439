import { Card } from "@material-ui/core";
import { EditProps } from "react-admin";
import { DefaultEdit } from "../components/DefaultEdit";
import { ItemForm } from "./ItemForm";

export const ItemEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component={Card}>
      <ItemForm />
    </DefaultEdit>
  );
};
