import styles from './LoadDeliveriesList.module.css'
import {
  useTheme,
  Portal,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import {
  AutocompleteInput,
  FormWithRedirect,
  ListContextProvider,
  Record,
  ReferenceInput,
  Toolbar,
  useList,
} from "react-admin";

import { RouteGroupedDeliveryeDatagrid } from "./RouteGroupedDeliveryDatagrid";
import { CustomListProps } from "../../components/CustomList";
import { client } from "../../dataProvider";
import { useLocation } from "react-router-dom";
import { useClosedSidebar } from "../../shared/hooks/useClosedSdebar";


export const LoadDeliveriesList = (
  props: Omit<CustomListProps, "children">
) => {
  const [activeDelivery, setActiveDelivery] = useState<Record>();
  const theme = useTheme();
  const location = useLocation();
  const [version, setVersion] = useState(0);
  const [data, setData] = useState<Record[]>([]);
  const [route, setRoute] = useState<Record>();
  const [route_id, setRouteId] = useState(0);

  useClosedSidebar()

  const listCtx = useList({ data: data, ids: data.map(s => s.id), loading: false, loaded: true });

  const rowStyle = (record: Record, index: number) => ({
    backgroundColor: record.deliveries.find(
      (delivery: Record) => delivery.id === activeDelivery?.id
    )
      ? theme.palette.primary.light
      : "white",
  });

  useEffect(() => {
    const r_id = new URLSearchParams(location.search).get("route");
    if (r_id) {
      setRouteId(parseInt(r_id));
      const fetchData = async () => {
        const data = await client(`deliveries/load_deliveries`, {
          method: "GET",
          params: { route: r_id }
        });
        setData(data["segments"]);
        setRoute(data["route"]);
      }

      fetchData()
        .catch(console.error);
    }
  },
    [version])

  const [isExtended, setIsExtended] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      var isExtendedTemp = isExtended;
      var mC = window.document.getElementById("layout-main-content");
      if (mC) {
        mC = mC.querySelector("tbody tr td ")
        if (mC) {
          if (mC.children[0]) {
            mC = mC.children[0] as HTMLDivElement
            if (!isExtendedTemp) {
              mC.click()
            }
            setIsExtended(true);
            isExtendedTemp = true;
          }
        }
      }
      if (isExtendedTemp) {
        clearInterval(timer);
      }
    }, 500);

    return () => clearInterval(timer);
  }, [isExtended]);

  var lmc = document.getElementById("layout-main-content");
  var appbarMain = document.getElementById("appbar-main");
  var secBar = document.getElementById("secondary-app-bar");
  if (lmc) {
    //lmc.style.overflowY = "hidden"
  }
  if (appbarMain) {
    appbarMain.style.display = "none"
  }
  if (secBar) {
    secBar.style.display = "none"
  }

  return (

    <div style={{ overflow: "hidden" }}>
      <Portal container={document.getElementById("secondary-app-bar")}>
        {/**/}
        {/*<Portal >*/}
        <Toolbar style={{ background: "white" }}>
          <FormWithRedirect
            initialValues={{ route: route_id }}
            render={(props) => {
              return (
                <ReferenceInput source="route" label="Giro" reference="autocomplete/route" onChange={(e) => {
                  window.location.href = `/load-deliveries?route=${e}`
                }}>
                  <AutocompleteInput optionText="label" />
                </ReferenceInput>
              );
            }}>

          </FormWithRedirect>
        </Toolbar>
      </Portal>
      <div className={styles.load_delivery_container}>
        <ListContextProvider
          value={listCtx} >
          <RouteGroupedDeliveryeDatagrid
            setVersion={setVersion}
            activeDelivery={activeDelivery}
            rowStyle={rowStyle}
            setActiveDelivery={setActiveDelivery}
            route={route!}
          />
        </ListContextProvider>
      </div>
    </div>

  );
};
