import { BooleanInput, ListProps, SelectInput, TextInput } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";
import { ChartAccountsTagsDatagrid } from "./ChartAccountsTagsDatagrid";

export const ChartAccountsTagsList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <BooleanInput {...initField("active", options)} />,
      <ReferenceInputSubject alwaysOn
        {...initField("supplier", options)}
        filter={{ type: "supplier" }}
      />,
      <TextInput {...initField("tag", options)} />,
    ]
    : [];
  return options ? (
    <CustomList
      filters={filters}
      filterDefaultValues={{ active: true }}
      {...getDefaultListProps(props)}>
      <ChartAccountsTagsDatagrid options={options} />
    </CustomList>
  ) : null;
};
