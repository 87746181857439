import { Tabs, Divider, Tab } from "@material-ui/core";
import { useState, useEffect, useCallback, Fragment } from "react";
import {
  Datagrid,
  TextField,
  ListProps,
  TextInput,
  DatagridProps,
  Identifier,
  ListContextProvider,
  useListContext,
  BooleanInput,
  BulkDeleteButton,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { CustomList } from "../components/CustomList";
import { IdentifierField } from "../components/IdentifierField";
import { TextListField } from "../components/TextListField";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../shared/utils";
import { AssociatMainSubjectButton } from "./AssociatMainSubjectButton";

const tabs = [
  { id: "*", name: "tutti" },
  { id: "cliente", name: "clienti" },
  { id: "destinatario", name: "destinatari" },
  { id: "vettore", name: "vettori" },
  { id: "fornitore", name: "fornitori" },
  { id: "altro", name: "altri" },
  { id: "produttore", name: "produttori" },
];

const TabbedDatagrid = (props: DatagridProps) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [all, setAll] = useState<Identifier[]>([] as Identifier[]);
  const [carrier, setCarrier] = useState<Identifier[]>([] as Identifier[]);
  const [receiver, setReceiver] = useState<Identifier[]>([] as Identifier[]);
  const [client, setClient] = useState<Identifier[]>([] as Identifier[]);
  const [supplier, setSupplier] = useState<Identifier[]>([] as Identifier[]);
  const [other, setOther] = useState<Identifier[]>([] as Identifier[]);
  const [productor, setProductor] = useState<Identifier[]>([] as Identifier[]);

  useEffect(() => {
    if (ids && ids !== filterValues.single_type) {
      switch (filterValues.single_type) {
        case "*":
          setAll(ids);
          break;
        case "vettore":
          setCarrier(ids);
          break;
        case "destinatario":
          setReceiver(ids);
          break;
        case "cliente":
          setClient(ids);
          break;
        case "fornitore":
          setSupplier(ids);
          break;
        case "altro":
          setOther(ids);
          break;
        case "produttore":
          setProductor(ids);
          break;
      }
    }
  }, [ids, filterValues.single_type]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, single_type: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );
  const { data: options } = useOptionsResource("GET");

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.single_type}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.name} value={choice.id} />
        ))}
      </Tabs>
      <Divider />
      {options ? (
        <div>
          {filterValues.single_type === "*" && (
            <ListContextProvider value={{ ...listContext, ids: ids }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("main_subject_id", options)} />
                <TextField {...initField("business_name", options)} />
                <TextListField {...initField("type", options)} />
                <TextField {...initField("locality", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.single_type === "vettore" && (
            <ListContextProvider value={{ ...listContext, ids: carrier }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("business_name", options)} />
                <TextListField {...initField("type", options)} />
                <TextField {...initField("locality", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.single_type === "destinatario" && (
            <ListContextProvider value={{ ...listContext, ids: receiver }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("business_name", options)} />
                <TextListField {...initField("type", options)} />
                <TextField {...initField("locality", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.single_type === "cliente" && (
            <ListContextProvider value={{ ...listContext, ids: client }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("business_name", options)} />
                <TextListField {...initField("type", options)} />
                <TextField {...initField("locality", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.single_type === "fornitore" && (
            <ListContextProvider value={{ ...listContext, ids: supplier }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("business_name", options)} />
                <TextListField {...initField("type", options)} />
                <TextField {...initField("locality", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.single_type === "altro" && (
            <ListContextProvider value={{ ...listContext, ids: other }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("business_name", options)} />
                <TextListField {...initField("type", options)} />
                <TextField {...initField("locality", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.single_type === "produttore" && (
            <ListContextProvider value={{ ...listContext, ids: productor }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("business_name", options)} />
                <TextListField {...initField("type", options)} />
                <TextField {...initField("locality", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
      ) : null}
    </Fragment>
  );
};

export const SubjectList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <NullableBooleanInput
        {...initField("is_not_main", options)}
        label="Ricerca soggetti secondari"
        alwaysOn
      />,
      <NullableBooleanInput
        {...initField("hide", options)}
        label="Nascosto"
        alwaysOn
        defaultChecked={false}
      />,
      <TextInput alwaysOn {...initField("id", options)} />,
      <TextInput alwaysOn {...initField("business_name", options)} />,
      <NullableBooleanInput
        {...initField("not_valid_address", options)}
        label="Indirizzo non valido"
      />,
      <NumberInput {...initField("main_subject_id", options)} />,
      <TextInput {...initField("vat_number", options)} />,
      <TextInput {...initField("locality", options)} />,
      <TextInput {...initField("province", options)} />,
      <TextInput {...initField("provinces", options)} label="Province (sep ,)" />,
      <ReferenceArrayInput
        source="regions"
        label="Regioni"
        reference="autocomplete/region"
        className="filter-field-small"
      >
        <AutocompleteArrayInput optionText="label" />
      </ReferenceArrayInput>,
    ]
    : [];
  return options ? (
    <CustomList
      filters={filters}
      filterDefaultValues={{ single_type: "*", is_not_main: false }}
      {...getDefaultListProps(props)}
      bulkActionButtons={<>
        <AssociatMainSubjectButton />
        <BulkDeleteButton />
      </>}
    >
      <TabbedDatagrid />
    </CustomList>
  ) : null;
};
