import {
  ListProps,
  TextInput,
  SelectInput,
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../../shared/utils";
import { ReasonStatusDatagrid } from "./ReasonStatusDatagrid";

export const ReasonStatusList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput {...initField("id", options)} />,
      <SelectInput
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />,
      <TextInput {...initField("description", options)} />,
    ]
    : [];
  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      {...getDefaultListProps(props)}
    >
      <ReasonStatusDatagrid options={options} />
    </CustomList>
  ) : null;
};
