import {
  Datagrid,
  FunctionField,
  Loading,
  NumberField,
  ReferenceArrayField,
} from "ra-ui-materialui";
import {
  Identifier,
  ListContextProvider,
  useList,
  useListContext,
  useQueryWithStore,
  useResourceContext,
  Record,
  TextField,
  getResources,
  useRecordContext,
} from "react-admin";
import { ChartAccountsField } from "../../components/ChartAccountsField";
import { useGroupedListContext } from "../../components/GroupedList/GroupedListContext";
import { Link } from "react-router-dom";
import { initField } from "../../shared/utils";
import { useOptions } from "../../shared/hooks/useOptions";


export const MainChartAccountsList = (props: any) => {
  const record = props.record;

  const { ids, data, loading, loaded } = useListContext();

  const { data: options } = useOptions("chartaccounts", "GET");

  const listContext = useList({
    data: record ? record.main_chart_accounts : [],
    ids: record ? record.main_chart_accounts.map((d: any) => d.id) : [],
    loaded,
    loading,
  })

  return options ? (
    <ListContextProvider value={listContext}>
      <Datagrid style={{ marginLeft: "10%", width: "90%" }}>
        <TextField
          {...initField("code", options)}
          style={{ width: "30%" }}
        />
        <TextField
          {...initField("name", options)}
          style={{ width: "30%" }}
        />
        <NumberField {...initField("amount", options)}
          style={{ width: "40%" }}
          options={{
            style: "currency",
            currency: "EUR",
          }}
          label="Totale" />
      </Datagrid>
    </ListContextProvider>) : null;
}


export const GroupedDatagrid = () => {
  const resource = useResourceContext();
  const { filterValues, filter } = useListContext();
  const { groupedBy } = useGroupedListContext();


  const { data, loading, loaded } = useQueryWithStore({
    type: "getGroupedBy",
    resource: resource,
    payload: { group_by: groupedBy, ...filterValues, ...filter },
  });

  const listContext = useList({
    data,
    ids: data ? (
      groupedBy === "site_main_chart_accounts" ?
        data.map((d: any) => d) :
        data.map((d: any) => d.chart_accounts)
    ) : [],
    loaded,
    loading,
  });

  if (loading) return <Loading />;

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        isRowExpandable={
          ((record) => groupedBy === "site_main_chart_accounts")
        }
        expand={
          <MainChartAccountsList {...{ record: (record: Record) => record, id: "id" }} />
        }
      >
        {groupedBy === "chart_accounts" && (
          <ChartAccountsField label="Piano dei conti" source="chart_accounts" />
        )}
        {groupedBy === "main_chart_accounts" && (
          <ChartAccountsField label="Piano dei conti principale" source="chart_accounts" />
        )}

        {groupedBy === "site_main_chart_accounts" && (
          <FunctionField label="Sede" source="site"
            render={(tableRecord: Record | undefined) => {
              if (tableRecord) {
                return <Link to={`/sites/${tableRecord.site}/show`}>
                  {tableRecord.business_name}
                </Link>;
              }
              return null;
            }}></FunctionField>
        )}

        <NumberField
          label="Totale"
          source="amount"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>

      </Datagrid>
    </ListContextProvider >
  );
};
