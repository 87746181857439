import { Button, ButtonProps, useNotify, useRecordContext } from "react-admin";
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition } from "../../shared/utils";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
export const DownloadCarrierCashBoxPdfButton = (props: ButtonProps) => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    let res = null;

    try {
      res = await clientNoJson(`${URL}/carrier_cashboxes/${record.id}/print_pdf/`, {
        method: "GET",
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.notification.http_error", "error");

      return;
    }
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  };

  return (
    <Button {...props} label="Download pdf" onClick={handleSubmit}>
      <PictureAsPdfIcon />
    </Button>
  );
};
