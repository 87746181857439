import Panzoom from "@panzoom/panzoom";
import { useCallback } from "react";

export function usePanzoom() {
  return useCallback((node) => {
    if (node) {
      const panzoom = Panzoom(node);
      const parent = node.parentElement!;
      const wheelListner = (event: any) => {
        if (!event.shiftKey) return;
        panzoom.zoomWithWheel(event);
      };
      parent.addEventListener("wheel", wheelListner);

      parent.addEventListener("dblclick", (event: any) => {
        panzoom.reset();
      })

    }
  }, []);
}
