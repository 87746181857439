import { Card, Portal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { CreateControllerProps, useCreateContext } from "ra-core";
import { CreateProps, TitleForRecord } from "ra-ui-materialui";
import * as React from "react";
import { Children, cloneElement, ReactElement } from "react";

export const CustomCreateView = (props: CustomCreateViewProps) => {
  const {
    actions,
    aside,
    children,
    classes: classesOverride,
    className,
    component: Content = Card,
    title,
    fieldOptions,
    ...rest
  } = props;

  const classes = useStyles(props);

  const {
    basePath,
    defaultTitle,
    hasList,
    record,
    redirect,
    resource,
    save,
    saving,
    version,
  } = useCreateContext(props);

  return (
    <div
      className={classnames("create-page", classes.root, className)}
      {...sanitizeRestProps(rest)}
    >
      <TitleForRecord
        title={title}
        record={record}
        defaultTitle={defaultTitle}
      />
      {actions && (
        <Portal container={document.getElementById("secondary-app-bar")}>
          {cloneElement(actions, {
            basePath,
            resource,
            hasList,
            //  Ensure we don't override any user provided props
            ...actions.props,
            style: {
              backgroundColor: "white",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            },
          })}
        </Portal>
      )}
      <div className={classnames(classes.main)}>
        <Content className={classes.card}>
          {cloneElement(Children.only(children), {
            basePath,
            record,
            redirect:
              typeof children.props.redirect === "undefined"
                ? redirect
                : children.props.redirect,
            resource,
            save:
              typeof children.props.save === "undefined"
                ? save
                : children.props.save,
            saving,
            version,
            fieldOptions,
          })}
        </Content>
        {aside &&
          cloneElement(aside, {
            basePath,
            record,
            resource,
            save:
              typeof children.props.save === "undefined"
                ? save
                : children.props.save,
            saving,
            version,
          })}
      </div>
    </div>
  );
};

interface CustomCreateViewProps
  extends CreateProps,
    Omit<CreateControllerProps, "resource"> {
  children: ReactElement;
  fieldOptions: any;
}

CustomCreateView.defaultProps = {
  classes: {},
  component: Card,
};

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    main: {
      display: "flex",
    },
    noActions: {
      [theme.breakpoints.up("sm")]: {
        marginTop: "1em",
      },
    },
    card: {
      flex: "1 1 auto",
    },
  }),
  { name: "RaCreate" }
);

const sanitizeRestProps = ({
  basePath = null,
  defaultTitle = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  history = null,
  loaded = null,
  loading = null,
  location = null,
  match = null,
  onFailure = null,
  onFailureRef = null,
  onSuccess = null,
  onSuccessRef = null,
  options = null,
  permissions = null,
  save = null,
  saving = null,
  setOnFailure = null,
  setOnSuccess = null,
  setTransform = null,
  transform = null,
  transformRef = null,
  ...rest
}: any) => rest;
