import { useListContext, Record } from "ra-core";
import React from "react";
import {
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  NumberField,
  SelectField,
  linkToRecord,
  Link,
  ReferenceField,
  BooleanField,
} from "react-admin";
import { IdentifierField } from "../components/IdentifierField";
import { DatagridPropsWithOptions } from "../shared/types";
import { initField } from "../shared/utils";
import { DownloadInvoiceDetailExcelButton } from "./components/DownloadInvoiceDetailExcelButton";
import { DownloadInvoiceDetailPdfButton } from "./components/DownloadInvoiceDetailPdfButton";
import { DownloadInvoicePdfButton } from "./components/DownloadInvoicePdfButton";
import { DownloadXmlButton } from "./components/DownloadXmlButton";

export const RibaDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  const { refetch } = useListContext();

  return (
    <Datagrid {...rest}>
      <IdentifierField show {...initField("id", options)} />
      <FunctionField
        label="Incassata"
        render={(record: any) => {
          const color = record.last_status === "I" ? "green" : "red";
          return (
            <div
              style={{
                backgroundColor: color,
                width: "30px",
                height: "30px",
                borderRadius: "100%",
              }}
            />
          );
        }}
      />
      <FunctionField
        label="Azienda"
        source="company_object"
        render={(record: Record | undefined) => {
          if (record && record.company_object) {
            return (
              <Link to={linkToRecord("/subjects", record.company_object.company.id, "edit")}>
                <TextField
                  record={record.company_object}
                  source="name"
                  variant="inherit"

                />
              </Link>
            );
          }
          return null;
        }}
        sortBy="company__company__business_name"
      />
      <FunctionField
        label="Cliente"
        source="client_id"
        render={(record: Record | undefined) => {
          if (record) {
            return (
              <Link to={linkToRecord("/subjects", record.client_id, "edit")}>
                <TextField
                  record={record.client}
                  source="business_name"
                  variant="inherit"
                />
              </Link>
            );
          }
          return null;
        }}
        sortBy="client__business_name"
      />
      <SelectField
        {...initField("status", options, "array")}
        choices={options.status.choices}
      />
      <BooleanField
        {...initField("loaded", options)}
      />
      <SelectField
        {...initField("invoice_type", options, "array")}
        choices={options.invoice_type.choices}
      />
      <SelectField
        {...initField("type", options, "array")}
        choices={options.type.choices}
      />
      <NumberField {...initField("sequence_number", options)} />
      <TextField {...initField("invoice_number", options)} />
      <DateField {...initField("invoice_date", options)} />
      <DateField {...initField("expiration_date", options)} />
      <DateField {...initField("riba_emitted", options)} />
      <NumberField {...initField("taxable", options)} />
      <NumberField {...initField("amount", options)} />
      <ReferenceField
        {...initField("invoice_reference", options)}
        reference="invoices"
      >
        <FunctionField
          render={(record: any) =>
            `N° ${record.type}-${record.sequence_number}`
          }
        />
      </ReferenceField>
    </Datagrid>
  );
};
