import { Box } from "@material-ui/core";
import { Identifier, useListContext, Record } from "ra-core";
import {
  DateField,
  NumberField,
  ReferenceField,
  TextField,
} from "ra-ui-materialui";
import {
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
  SelectField,
} from "react-admin";
import { DatagridWithTotals } from "../../components/DatagridWithTotals";
import { DeliveryField } from "../../components/DeliveryField";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { SimpleSubTariffField } from "../../components/SimpleSubTariffField";
import { TariffField } from "../../components/TariffField";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";

interface ChargeDatagridProps {
  selectedCharges: Identifier[];
  onToggleCharge: (id: Identifier) => void;
  onSelectCharge: (ids: Identifier[], allCharges: Identifier[]) => void;
  hasBulkActions: boolean;
}

export const ChargeDatagrid = (props: ChargeDatagridProps) => {
  const { data: options } = useOptions("charges", "GET");
  const { data: optionsTariff } = useOptions("tariffs", "GET");

  const { ids } = useListContext();
  return options && optionsTariff ? (
    <DatagridWithTotals
      selectedIds={props.selectedCharges}
      totalFields={["taxable", "quantity"]}
      hasBulkActions={props.hasBulkActions}
      onToggleItem={props.onToggleCharge}
      onSelect={(idsSelect) => props.onSelectCharge(idsSelect, ids)}
    >
      <DeliveryField
        sortable={false}
        source="delivery"
        deliverySource="delivery"
        label="Missione"
      />

      <SimpleSubTariffField source={"tariff_simple"} label={"Tariffa"} />

      <NumberField sortable={false} {...initField("quantity", options)} />
      <NumberField sortable={false} {...initField("price", options)} />
      <NumberField sortable={false} {...initField("taxable", options)} />
      <ReferenceField
        sortable={false}
        reference={"charges"}
        {...initField("main_charge", options)}
      >
        <TextField source="id" />
      </ReferenceField>
      <DateField sortable={false} {...initField("charge_date", options)} />
      <FunctionField
        sortable={false}
        label="Bolle"
        render={(tableRecord: Record | undefined) => {
          if (
            tableRecord &&
            tableRecord.delivery_notes &&
            tableRecord.delivery_notes.length > 0
          ) {
            return tableRecord.delivery_notes.map((note: any) => (
              <DownloadNoteFileButton
                key={note.id}
                title={note.file}
                noteId={note.id}
                final={note.final}
              />
            ));
          }
          return null;
        }}
      />
    </DatagridWithTotals>
  ) : null;
};
