import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { RouteForm } from "./RouteForm";

export const RouteEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props}>
      <RouteForm />
    </DefaultEdit>
  );
};
