import _ from "lodash";
import React from "react";
import { FieldProps, Link, linkToRecord, useRecordContext } from "react-admin";

export const ChartAccountsField = (props: FieldProps & { source: string }) => {
  const record = useRecordContext(props);
  const chartAccounts = _.get(record, props.source);

  return chartAccounts ? (
    <Link to={linkToRecord("/chartaccounts", chartAccounts.id, "edit")}>
      {`${chartAccounts.id}: [${chartAccounts.code}] ${chartAccounts.name}`}
    </Link>
  ) : null;
};
