import { Record } from "react-admin";
import { Datagrid, DateField, FunctionField, NumberField, SelectField } from "react-admin";
import { IdentifierField } from "../../components/IdentifierField";
import { SubjectField } from "../../components/SubjectField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";
import { CreatePlaceholderDeliveryButton } from "./CreatePlaceholderDeliveryButton";
import { DeliveryField } from "../../components/DeliveryField";

export const RemittanceDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {

  return (
    <Datagrid {...rest} isRowSelectable={(record) => record.status !== "B"}>
      <IdentifierField {...initField("id", options)} />
      <NumberField
        {...initField("remittance_sequential_number", options)}
      />
      <NumberField
        {...initField("total", options)}
        options={{
          style: "currency",
          currency: "EUR",
        }}
      />
      <DateField {...initField("remittance_date", options)} />
      <SelectField
        {...initField("status", options, "array")}
        choices={options.status.choices}
      />
      <SelectField
        {...initField("type", options, "array")}
        choices={options.type.choices}
      />
      <SubjectField {...initField("client", options, "array")} />
      <FunctionField
        label="Missione segnaposto"
        source="delivery"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord && tableRecord.delivery) {
            return <DeliveryField
              noTitle
              deliverySource={"delivery"}
              {...initField("delivery", options)} />
          }
          return <CreatePlaceholderDeliveryButton />;
        }}
        sortable={false}
      />

    </Datagrid>
  );
};
