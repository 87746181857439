import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { useCallback } from "react";
import { Button, FormWithRedirect, TextInput } from "react-admin";
import { useOptions } from "../../shared/hooks/useOptions";
import { initInput } from "../../shared/utils";
import {
  Record,
  RecordContextProvider,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from "ra-core";
import Add from "@material-ui/icons/Add";
import { OnChange } from "react-final-form-listeners";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { NumberInput } from "../../components/NumberInput";
import { ReferenceInputChartAccounts } from "../../components/reference-inputs/ReferenceInputChartAccounts";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { ReferenceInputSite } from "../../components/reference-inputs/ReferenceInputSite";
export const AddInvoiceChartAccountsForm = () => {
  const record = useRecordContext();
  const { data: optionsInvoiceLines } = useOptions("invoicechartaccounts", "GET");
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const onSubmit = useCallback(
    (values: Partial<Record>) => {
      create("invoicechartaccounts", values, {
        onSuccess: () => {
          notify("ra.notification.created", "success");
          refresh();
        },
        onFailure: () => notify("ra.notification.bad_item", "error"),
      });
    },
    [create, notify, refresh]
  );

  return optionsInvoiceLines ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panela-content"
        id="panela-header"
      >
        <Typography variant="h6" gutterBottom>
          Aggiungi riga piano dei conti
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        <RecordContextProvider value={undefined}>
          <FormWithRedirect
            save={onSubmit}
            initialValues={{ invoice: record?.id }}
            render={(formProps) => (
              <>
                <Grid container alignItems="flex-start">

                  <Grid item xs={4}>
                    <ReferenceInputChartAccounts
                      source="chart_accounts"
                      label="Piano dei conti"
                      fullWidth={true}
                      filter={{ last_level: true }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <ReferenceInputSite
                      source="site"
                      label="Sede"
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <NumberInput
                      variant="outlined"
                      source="amount"
                      label="Importo"
                      fullWidth={true}
                    />
                  </Grid>

                </Grid>
                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={formProps.handleSubmit}
                    label="Aggiungi"
                  >
                    <Add />
                  </Button>
                </Box>
              </>
            )}
          />
        </RecordContextProvider>
      </AccordionDetails>
    </Accordion>
  ) : null;
};
