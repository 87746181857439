import {
  TextField,
  ReferenceField,
  DeleteButton,
  FunctionField,
  Record,
  NumberField,
  ArrayField,
  Datagrid,
  SelectField,
  Button,
  Link,
  linkToRecord,
} from "react-admin";
import {
  EditableDatagrid,
  EditableNumberField,
} from "../../components/EditableDatagrid";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";
import { SaveButtonDatagrid } from "../Cachet/SaveButtonDatagrid";
import { FormApi } from "final-form";
import { makeStyles, Typography } from "@material-ui/core";
import { chargeTypeDisplay } from "./utils";

const useStyles = makeStyles((theme) => ({
  headerCell: {
    backgroundColor: "white",
    color: theme.palette.secondary.dark,
    borderBottom: "1px solid rgba(224, 224, 224, 1)!important",
  },
  row: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
}));
export const DeliveryItemDatagrid = ({
  options,
  updateRevenues,
  ...rest
}: DatagridPropsWithOptions & { updateRevenues: () => void }) => {
  const classes = useStyles();
  return (
    <EditableDatagrid rowClick={undefined} {...rest}>
      <TextField {...initField("id", options)} />
      <FunctionField
        label="Tariffa"
        render={(record: any) => {
          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Link
                target={"_blank"}
                to={linkToRecord("/tariffs", record.tariff_complete.tariff.id, "edit")}>
                <Typography>{`${chargeTypeDisplay(record.tariff_complete.tariff.charge_type, true)}: ${record.tariff_complete.tariff.item.code}`}</Typography>

              </Link>
            </span>
          );
        }}
      />

      <EditableNumberField
        alwaysEditable
        getInputProps={(
          record: Record,
          form: FormApi<
            globalThis.Record<string, any>,
            Partial<globalThis.Record<string, any>>
          >
        ) => ({
          style: { minWidth: "4em" },
          endAdornment: (
            <>
              <SaveButtonDatagrid callback={updateRevenues} />
            </>
          ),
        })}
        {...initField("quantity", options)}
      />
      <FunctionField
        label="Prezzo"
        render={(record: any) => {
          return (
            <ArrayField source="tariff_complete.pricerange_set">
              <Datagrid classes={classes}>
                <NumberField source="limit" label="Limite" />
                <NumberField source="price" label="Prezzo" />
                <SelectField
                  label="Tipo"
                  source="type"
                  choices={[
                    { name: "Moltiplicatore", id: "M" },
                    { name: "Forfettario", id: "F" },
                  ]}
                />
                <NumberField source="grouping" label="Raggruppa per" />
              </Datagrid>
            </ArrayField>
          );
        }}
      />
      <FunctionField
        render={(record: any) => (
          <DeleteButton
            record={record}
            resource="delivery-items"
            redirect={`/deliveries/${record.delivery}`}
            mutationMode="pessimistic"
          />
        )}
      />
    </EditableDatagrid>
  );
};
