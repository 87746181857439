import { Card } from "@material-ui/core";
import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { CustomerSubjectForm } from "./CustomerSubjectForm";

export const CustomerSubjectEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component={Card}>
      <CustomerSubjectForm />
    </DefaultEdit>
  );
};
