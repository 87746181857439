import { Grid, Typography } from "@material-ui/core";
import {
  EditProps,
  SelectField,
  ToolbarProps,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { DeliveryStatusBadge } from "../../components/DeliveryStatusBadge";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";
import { CloneDeliveryButton } from "./CloneDeliveryButton";
import { DeliveryForm } from "./DeliveryForm";
import { transformDelivery } from "./utils";
import { ResetDeliveryStatusButton } from "./ResetDeliveryStatusButton";
import { PrintFinalDeliveryButton } from "./PrintFinalDeliveryButton";
import { PrintDeliveryMarkers10x7Button } from "./PrintDeliveryMarkers10x7Button";
import { NewToPlanDeliveryStatusButton } from "./NewToPlanDeliveryStatusButton";
import { UndoStockSuspendDeliveryStatusButton } from "./UndoStockSuspendDeliveryStatusButton";
import { DeliveryType } from "../../shared/types";

const DeliveryEditActions = (props: ToolbarProps) => {

  const record = useRecordContext();
  return (
    <TopToolbar {...props}>
      <CloneDeliveryButton buttonLabel="Crea riconsegna" type={DeliveryType.NORMAL} />
      {record && (record.status === "CA" || record.status === "D") && record.partial === true && (
        <CloneDeliveryButton buttonLabel="Crea parziale" type={DeliveryType.NORMAL} partial={
          record.partial
        } />)}
      <ResetDeliveryStatusButton />
      <PrintFinalDeliveryButton />
      <PrintDeliveryMarkers10x7Button />
      <NewToPlanDeliveryStatusButton />
      <UndoStockSuspendDeliveryStatusButton />
    </TopToolbar>
  );
};
const Title = () => {
  const record = useRecordContext();
  const { data: optionsDeliveryStates } = useOptions("delivery-states", "GET");

  return optionsDeliveryStates ? (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
    >
      <Grid item>
        <Typography variant="h6">Missione {record.id}</Typography>
      </Grid>
      <Grid item>
        <DeliveryStatusBadge
          label={
            <SelectField
              {...initField("status", optionsDeliveryStates, "array")}
              choices={optionsDeliveryStates.status.choices}
              variant="inherit"
            />
          }
          record={record}
        ></DeliveryStatusBadge>
      </Grid>
    </Grid>
  ) : null;
};
export const DeliveryEdit = (props: EditProps) => {
  return (
    <DefaultEdit
      {...props}
      actions={<DeliveryEditActions />}
      title={<Title />}
      transform={transformDelivery}
    >
      <DeliveryForm />
    </DefaultEdit>
  );
};
