import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { DeliveryGroupForm } from "./DeliveryGroupForm";

export const DeliveryGroupEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props}>
      <DeliveryGroupForm />
    </DefaultEdit>
  );
};
