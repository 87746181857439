import { useNotify } from "ra-core";
import { Button } from "ra-ui-materialui";
import GetApp from "@material-ui/icons/GetApp";
import React from "react";
import { clientNoJson } from "../dataProvider";
import { url as URL } from "../constants";
import { CircularProgress, Typography } from "@material-ui/core";
import { getFileNameFromContentDisposition } from "../shared/utils";


export const DownloadCustomerImportTemplate = () => {
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const handleClick = async () => {
    setLoading(true);

    let res = null;

    try {
      res = await clientNoJson(`${URL}/customer_import_files/customer_import_template/`, {
        responseType: "arraybuffer",
        method: "GET",
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      notify("ra.message.error", "error");

      return;
    }

    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <div style={{ textAlign: "center" }}>
          <br></br>
          <Typography variant="h5">
            Scarica il template del file da importare:
            <br></br>
          </Typography>
          <Typography variant="h1">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (!loading) handleClick();
              }}
              title="Esempio import file"
              size="large"
            >
              {loading ? <CircularProgress size={18} thickness={2} /> : <GetApp />
              }
            </Button>
          </Typography>

        </div>
      )
      }
    </>
  );
};
