import {
  Button,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useOptions } from "../../shared/hooks/useOptions";
import { generateError } from "../../shared/utils";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { values } from "lodash";
import { DeliveryStatusChoice } from "../../shared/types";

interface CarrierAddDeliveredDeliveryStatusButtonProps { refreshMain?: boolean | undefined }

export const CarrierAddDeliveredDeliveryStatusButton = (props: CarrierAddDeliveredDeliveryStatusButtonProps) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [create, { loading }] = useCreate();
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    create("carrier_deliverystates", values, {
      onSuccess: ({ data }) => {
        refresh();
        if (props.refreshMain) {
          window.location.reload();
        }
      },
      onFailure: (error) => {
        generateError(error, notify);
      },
    });
  };
  const { data: options } = useOptions("carrier_deliverystates", "GET");

  const values = {
    delivery: record.id,
    status: DeliveryStatusChoice.DELIVERED,
  };


  return options && [
    DeliveryStatusChoice.DELIVERING,
    DeliveryStatusChoice.SUSPENDED
  ].includes(record.status) ? (
    <>
      <Button tabIndex={-1}
        title="Imposta consegnata"
        onClick={() => handleSubmit(values)}>
        <CheckCircle />
      </Button>
    </>
  ) : null;
};
