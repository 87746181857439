import {
  ListProps,
  TextInput,
  DateInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  NullableBooleanInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  BulkDeleteButton,
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../../shared/utils";
import { DriverSummaryPdfBulkButton } from "./DriverSummaryPdfBulkButton";
import { RouteDatagrid } from "./RouteDatagrid";
import { SendTrackMeButtonsButton } from "./SendTrackMeButton";
import { UpdateRouteStatusButton } from "./UpdateRouteStatusButton";
import { ForceUpdateRouteStatusButton } from "./ForceUpdateRouteStatusButton";
import { HideOnMapsButton, ShowOnMapsButton } from "./ShowHideOnMapsButtons";

export const RouteList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput alwaysOn {...initField("id", options)} />,
      <DateInput {...initField("planned_date_after", options)} label="Pianificada dal" />,
      <DateInput {...initField("planned_date_before", options)} label="Pianificada al" />,
      <DateInput {...initField("planned_date__date", options)} label="Pianificada il" />,
      <SelectInput
        choices={options.status.choices}
        {...initField("status", options, "array")}
        alwaysOn
      />,
      <ReferenceInput
        {...initField("driver", options)}
        filter={{ departments: "Autista" }}
        reference="autocomplete/employee"
        alwaysOn
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        {...initField("carrier", options)}
        filter={{ type: "carrier" }}
        reference="autocomplete/subject"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceInput
        {...initField("vehicle", options)}
        reference="autocomplete/vehicle"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <NullableBooleanInput {...initField("sent", options)} />,
      <NullableBooleanInput {...initField("show_on_maps", options)} />,
      <ReferenceArrayInput
        {...initField("tags_filter", options)}
        label="Tag"
        reference="autocomplete/tag"
      >
        <AutocompleteArrayInput optionText="label" />
      </ReferenceArrayInput>,
      <NullableBooleanInput {...initField("has_file", options)} label="Borderaux firmato" />,
    ]
    : [];
  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      {...getDefaultListProps(props)}
      bulkActionButtons={
        <>
          <SendTrackMeButtonsButton />
          <ShowOnMapsButton />
          <HideOnMapsButton />
          <BulkDeleteButton undoable />
          <DriverSummaryPdfBulkButton />
          <UpdateRouteStatusButton />
          <ForceUpdateRouteStatusButton />
        </>
      }
    >
      <RouteDatagrid options={options} />
    </CustomList>
  ) : null;
};
