import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { useCallback } from "react";
import { Button, FormWithRedirect, SelectInput, TextInput } from "react-admin";
import { useOptions } from "../../shared/hooks/useOptions";
import { initInput } from "../../shared/utils";
import {
  Record,
  RecordContextProvider,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from "ra-core";
import Add from "@material-ui/icons/Add";
import { OnChange } from "react-final-form-listeners";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { updateTaxableForm } from "../utils";
import { NumberInput } from "../../components/NumberInput";
export const AddInvoiceLineForm = () => {
  const record = useRecordContext();
  const { data: optionsInvoiceLines } = useOptions("invoice-lines", "GET");
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const onSubmit = useCallback(
    (values: Partial<Record>) => {
      create("invoice-lines", values, {
        onSuccess: () => {
          notify("ra.notification.created", "success");
          refresh();
        },
        onFailure: () => notify("ra.notification.bad_item", "error"),
      });
    },
    [create, notify, refresh]
  );

  return optionsInvoiceLines ? (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panela-content"
        id="panela-header"
      >
        <Typography variant="h6" gutterBottom>
          Aggiungi riga
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        <RecordContextProvider value={undefined}>
          <FormWithRedirect
            save={onSubmit}
            initialValues={{ invoice: record?.id, quantity: 1, price: 1 }}
            render={(formProps) => (
              <>
                <OnChange name="quantity">
                  {(value, previous) => {
                    if (value) {
                      updateTaxableForm(
                        formProps.form,
                        "quantity",
                        "price",
                        "vat",
                        "taxable",
                        "amount"
                      );
                    }
                  }}
                </OnChange>
                <OnChange name="price">
                  {(value, previous) => {
                    if (value) {
                      updateTaxableForm(
                        formProps.form,
                        "quantity",
                        "price",
                        "vat",
                        "taxable",
                        "amount"
                      );
                    }
                  }}
                </OnChange>
                <OnChange name="vat">
                  {(value, previous) => {
                    if (value) {
                      updateTaxableForm(
                        formProps.form,
                        "quantity",
                        "price",
                        "vat",
                        "taxable",
                        "amount"
                      );
                    }
                  }}
                </OnChange>
                <Grid container alignItems="flex-start">
                  <Grid item xs={2}>
                    <NumberInput
                      {...initInput("quantity", optionsInvoiceLines, true)}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <NumberInput
                      {...initInput("price", optionsInvoiceLines, true)}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <NumberInput
                      {...initInput("vat", optionsInvoiceLines, true)}
                      defaultValue={22}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <NumberInput
                      variant="outlined"
                      source="taxable"
                      disabled
                      label="Imponibile"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <NumberInput
                      variant="outlined"
                      source="amount"
                      disabled
                      label="Importo"
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <SelectInput
                      {...initInput(
                        "reference_reg",
                        optionsInvoiceLines,
                        true,
                        "array"
                      )}
                      choices={optionsInvoiceLines.reference_reg.choices}
                      label="Riferimento normativo"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextInput
                      {...initInput(
                        "replaced_description",
                        optionsInvoiceLines,
                        true
                      )}
                      label="Descrizione riga"
                    />
                  </Grid>
                </Grid>
                <Box mt={1} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={formProps.handleSubmit}
                    label="Aggiungi"
                  >
                    <Add />
                  </Button>
                </Box>
              </>
            )}
          />
        </RecordContextProvider>
      </AccordionDetails>
    </Accordion>
  ) : null;
};
