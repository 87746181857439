import { Box } from "@material-ui/core";
import { rest } from "lodash";
import {
  Datagrid,
  DateField,
  DateInput,
  ListProps,
  NumberField,
  SelectField,
  SelectInput,
} from "ra-ui-materialui";
import {
  BooleanInput,
  FunctionField,
  Link,
  linkToRecord,
  NullableBooleanInput,
  TextField,
} from "react-admin";
import { CustomList } from "../components/CustomList";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../shared/hooks/useOptions";
import { getDefaultListProps, initField } from "../shared/utils";
import { ShowPaymentStatusHistoryButton } from "./components/ShowPaymentStatusHistoryButton";
import { NumberInput } from "../components/NumberInput";
import { TariffType } from "../shared/types";
import { useEffect, useState } from "react";
import { AddPurcasePaymentStatusButton } from "./components/AddPurcasePaymentStatusButton";
export const PurchaseInvoiceTimeTableList = (props: ListProps) => {
  const { data: options } = useOptions("invoices", "POST");
  const { data: optionsTerms } = useOptions("payment-terms", "GET");
  const { data: optionsStatus } = useOptions("invoice-payment-status", "POST");

  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    if (optionsStatus) {
      let statusListCorrect = optionsStatus.status.choices;
      for (let s of statusListCorrect) {
        switch (s.value) {
          case "I":
            s.display_name = "Fattura pagata";
            break;
          case "S":
            s.display_name = "Sollecito ricevuto";
            break;
          default:
            break;
        }
      }
      setStatusList(statusListCorrect);
    }
  },
    [optionsStatus]
  )
  const filters =
    options && optionsStatus && statusList && optionsTerms
      ? [
        <DateInput source="invoice_date_after" label="Dal" />,
        <DateInput source="invoice_date_before" label="Al" />,
        <NumberInput
          {...initField("sequence_number", options)}
          label="N° sequenza"
        />,
        <NumberInput {...initField("id", options)} />,

        <ReferenceInputSubject
          filter={{ type: "client" }}
          {...initField("client", options)}
        />,
        <SelectInput
          choices={statusList}
          {...initField("last_status", optionsStatus, "array")}
          label="Ultimo stato"
        />,
        <NullableBooleanInput label="Non pagata" source={"not_cashed"} />,
        <SelectInput
          choices={optionsTerms.method.choices}
          {...initField("payment_method", optionsTerms, "array")}
          label="Metodo di pagamento"
        />,
      ]
      : [];

  return options && optionsStatus && statusList ? (
    <CustomList
      sort={{ field: "expiration_date", order: "DESC" }}
      filters={filters}
      filter={{ invoice_type: TariffType.PURCHASE }}
      {...getDefaultListProps(props)}
      bulkActionButtons={false}
      filterDefaultValues={{ not_cashed: true }}
    >
      <Datagrid {...rest}>
        <FunctionField
          label="Fattura"
          source="id"
          render={(record: any) => {
            if (record) {
              return (
                <Link to={linkToRecord("/invoices", record.id, "show")}>
                  {
                    <TextField
                      record={record}
                      source={record.sequence_number ? "sequence_number" : "id"}
                      variant="inherit"
                    />
                  }
                </Link>
              );
            }
            return null;
          }}
        />
        <FunctionField
          label="Azienda"
          source="company_object"
          render={(record: any | undefined) => {
            if (record && record.company_object) {
              return (
                <Link to={linkToRecord("/subjects", record.company_object.company.id, "edit")}>
                  <TextField
                    record={record.company_object}
                    source="name"
                    variant="inherit"

                  />
                </Link>
              );
            }
            return null;
          }}
        />
        <DateField {...initField("invoice_date", options)} />

        <FunctionField
          label="Fornitore"
          source="supplier_id"
          render={(record: any) => {
            if (record) {
              return (
                <Link to={linkToRecord("/subjects", record.client.id, "edit")}>
                  <TextField
                    record={record.client}
                    source="business_name"
                    variant="inherit"
                  />
                </Link>
              );
            }
            return null;
          }}
        />
        <NumberField
          {...initField("amount", options)}
          label="Importo"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        />
        <NumberField
          {...initField("residue", options)}
          label="A saldo"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        />
        {/* a saldo */}
        <FunctionField
          {...initField("expiration_date", options)}
          label="Data scadenza"
          render={(record: any) => {
            const color =
              new Date(record.expiration_date) > new Date() ? "green" : "red";
            return (
              <Box display={"flex"} alignItems={"center"}>
                {((record.last_status && record.last_status.status !== "I") ||
                  !record.last_status) && (
                    <div
                      style={{
                        backgroundColor: color,
                        width: "30px",
                        height: "30px",
                        borderRadius: "100%",
                        marginRight: "10px",
                      }}
                    />
                  )}
                <DateField {...initField("expiration_date", options)} />
              </Box>
            );
          }}
        />
        <TextField {...initField("terms_payment", options)} />

        <FunctionField
          {...initField("last_status", options)}
          label={"Stato pagamento"}
          render={(record: any) => (
            <SelectField
              record={record.last_status}
              {...initField("status", optionsStatus, "array")}
              choices={statusList}
            />
          )}
        />
        <FunctionField
          label={"Note"}
          render={(record: any) => (
            <TextField
              record={record.last_status}
              {...initField("notes", optionsStatus, "array")}
            />
          )}
        />
        <FunctionField
          render={(record: any) =>
            record?.last_status?.status !== "I" ? (
              <AddPurcasePaymentStatusButton />
            ) : null
          }
        />
        <ShowPaymentStatusHistoryButton />
      </Datagrid>
    </CustomList>
  ) : null;
};
