import { Card, CardContent } from "@material-ui/core";
import { FormWithRedirect } from "react-admin";
import { CustomToolbar } from "../components/CustomToolbar";
import { FormProps } from "../shared/types";
import { SubjectFormContent } from "./SubjectFormContent";

export const SubjectForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <SubjectFormContent
                canEdit={props.canEdit}
                fieldOptions={props.fieldOptions}
              />
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />
  );
};
