import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  FileInput,
  ReferenceInput,
  TextInput,
} from "ra-ui-materialui";
import { FileField, FormDataConsumer, FormWithRedirect, SelectInput } from "react-admin";
import { CustomFileField } from "../../components/CustomFIleField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReferenceInputCustomerSubject } from "../../components/reference-inputs/ReferenceInputCustomerSubject";
import { ReferenceInputItem } from "../../components/reference-inputs/ReferenceInputItem";
import { DeliveryType, FormProps } from "../../shared/types";
import { initField, initInput } from "../../shared/utils";

export const CustomerImportFileForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item container alignItems="center">
                  <Grid item xs={12}>
                    <Typography>File auttale:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomFileField
                      {...initField("file_info", props.fieldOptions)}
                      title="name"
                      src="url"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FileInput
                    {...initInput("file", props.fieldOptions, props.canEdit)}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  >
                    <FileField source="src" title="title" />
                  </FileInput>

                </Grid>
                <Grid item xs={4}>
                  <FormDataConsumer>
                    {({ formData }) => (
                      <ReferenceInputItem
                        {...initInput(
                          "item",
                          props.fieldOptions,
                          props.canEdit
                        )}
                        filter={{ client_order: true }}
                      />
                    )}
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={4}>
                  <SelectInput
                    {...initInput(
                      "delivery_type",
                      props.fieldOptions,
                      props.canEdit,
                      "array"
                    )}
                    defaultValue={DeliveryType.NORMAL}
                    choices={props.fieldOptions.delivery_type.choices}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormDataConsumer>
                    {({ formData }) => (
                      formData.delivery_type === DeliveryType.WITHDRAW &&
                      <ReferenceInputCustomerSubject
                        {...initInput(
                          "starting_point_id",
                          props.fieldOptions,
                          props.canEdit
                        )}
                      />
                    )}
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    multiline
                    {...initInput(
                      "note",
                      props.fieldOptions,
                      props.canEdit
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />
  );
};
