import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from "ra-ui-materialui";
import React from "react";

type ReferenceInputDriverProps = Omit<
  ReferenceInputProps,
  "children" | "reference" | "sort"
>;

export const ReferenceInputDriver = (props: ReferenceInputDriverProps) => {
  return (
    <ReferenceInput
      {...(props as ReferenceInputProps)}
      reference="autocomplete/employee"
      sort={{ field: "surname", order: "ASC" }}
      filter={{ department: "Autista" }}
      allowEmpty={true}
    >
      <AutocompleteInput optionText="label" />
    </ReferenceInput>
  );
};
