import { AutocompleteArrayInput, AutocompleteInput, BooleanField, BooleanInput, Button, Datagrid, DateField, DateInput, DateTimeInput, FunctionField, ListProps, NullableBooleanInput, NumberField, ReferenceArrayInput, ReferenceField, ReferenceInput, SelectArrayInput, SelectField, SelectInput, TextField, TextInput, useNotify, useRecordContext, useRefresh } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { CustomerSubjectField } from "../../components/CustomerSubjectField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../shared/hooks/useOptions";
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { DeliveryTrackingNumberField } from "../../components/DeliveryTrackingNumberField";
import { DeliveryTrackingField } from "../../components/DeliveryTrackingField";
import { ZipDeliveryNotesTrackingBulkButton } from "./ZipDeliveryNotesTrackingBulkButton";


export const CustomerDeliveryList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsDeliveryStates } = useOptions("delivery-states", "GET");


  const filters = options && optionsDeliveryStates
    ? [
      <TextInput {...initField("id", options)} />,
      <TextInput
        {...initField("document_number", options)}
        label="N. DDT"
      />,
      <SelectInput
        {...initField("delivery_type", options, "array")}
        choices={options.delivery_type.choices}
      />,
      <SelectArrayInput
        {...initField("delivery_states", optionsDeliveryStates, "array")}
        choices={optionsDeliveryStates.status.choices}
        alwaysOn
        label="Stato"
        className="filter-field-small"
      />,
      <TextInput
        {...initField("starting_point_on_delivery", options)}
        label="Partenza"
      />,
      <TextInput
        {...initField("receiver", options)}
        label="Destinazione"
      />,
      <DateTimeInput
        {...initField("delivery_date_after", options)}
        label="Data missione dal"
      />,
      <DateTimeInput
        {...initField("delivery_date_before", options)}
        label="Data missione fino al"
      />,
      <DateInput
        {...initField("document_date_after", options)}
        label="Data documento dal"
      />,
      <DateInput
        {...initField("document_date_before", options)}
        label="Data documento fino al"
      />,
      <ReferenceInput
        source="province"
        label="Provincia"
        reference="autocomplete/province"
        className="filter-field-small"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceArrayInput
        source="region"
        label="Regioni"
        reference="autocomplete/region"
        className="filter-field-small"
      >
        <AutocompleteArrayInput optionText="label" />
      </ReferenceArrayInput>,
      <SelectArrayInput
        {...initField("delivery_states", optionsDeliveryStates, "array")}
        choices={optionsDeliveryStates.status.choices}
        alwaysOn
        label="Stato"
        className="filter-field-small"
      />,
    ]
    : [];

  if (options &&
    props &&
    props.permissions &&
    props.permissions.includes('registry.view_subject')) {
    filters.push(
      <ReferenceInputSubject
        {...initField("client", options)}
        filter={{ type: "client" }}
        label="Cliente"
      />
    )
  }

  if (options && filters && props &&
    props.permissions && props.permissions.includes("distribution.change_delivery")) {
    filters.push(
      <SelectInput
        {...initField("delivery_heirarchy", options, "array")}
        choices={[
          { value: "G", display_name: "VERDE (ULTIMA MISSIONE)" },
          { value: "R", display_name: "ROSSA (PRIMA MISSIONE)" },
          { value: "Y", display_name: "GIALLA (MISSIONE INTERMEDIA)" },
        ]}
        className="filter-field-small"
        label="Colore"
      />
    );
  }

  return options && optionsDeliveryStates ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      shouldExport={false}
      filterDefaultValues={{}}
      {...getDefaultListProps(props)}
      bulkActionButtons={
        <>
          <ZipDeliveryNotesTrackingBulkButton />
        </>
      }
    >
      <Datagrid>
        {
          props.permissions.includes("distribution.change_delivery") ?
            <IdentifierField
              {...initField("id", options)}
              basePath="deliveries"
            /> :
            <TextField {...initField("id", options)} />
        }
        <DeliveryTypeField source="delivery_type" options={options} label={"Tipo"} />
        <DateField source="delivery_date" options={options} label={"Data"} />
        <DateField source="document_date" options={options} label={"Data DDT"} />


        <TextField {...initField("document_number", options)} label={"N. DDT"} />
        <SelectField
          {...initField("status", optionsDeliveryStates, "array")}
          choices={optionsDeliveryStates.status.choices}
          sortBy="delivery_last_status__status"
        />
        <DeliveryTrackingField
          {...initField("tracking_number", options)} deliverySource="tracking_number"
          label={"Tracking"}
        />
        <FunctionField
          label="Bolle"
          source="note_set"
          render={(tableRecord: any | undefined) => {
            if (tableRecord && tableRecord.note_set) {
              return tableRecord.note_set.map((note: any) => (
                <DownloadNoteFileButton
                  key={note.id}
                  title={note.file}
                  noteId={note.id}
                  final={note.final}
                  resource={"customer_notes"}
                />
              ));
            }
            return null;
          }}
        />
        <NumberField {...initField("weight", options)} />
        <NumberField {...initField("packages_number", options)} />
        <NumberField {...initField("cachet", options)} label="Contrass." sortable={false} />
        {
          props.permissions.includes("distribution.change_delivery") &&
          <TextField
            {...initField("client.label", options)}
            label={"Cliente"}
          />
        }
        <TextField
          {...initField("starting_point_on_delivery.label", options)}
          label={"Partenza"}
        />
        <TextField {...initField("province", options)}
          label={"Pr. Arrivo"} />
        <TextField
          {...initField("receiver.label", options)}
          label={"Arrivo"}
        />

      </Datagrid>
    </CustomList>
  ) : null;
};
