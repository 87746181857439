import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  Button,
  ButtonProps,
  FormWithRedirect,
  SelectInput,
  useListContext,
  useNotify,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, generateError, initField, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import UpdateIcon from "@material-ui/icons/Update";
import { useEffect, useState } from "react";
import { client } from "../../dataProvider";


export const ForceUpdateRouteStatusButton = (props: ButtonProps) => {

  const { value, setFalse, setTrue } = useToggler();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const notify = useNotify();
  const handleSubmit = async (e: any) => {

    setLoading(true);

    client(`routes/force_set_routes_status`, {
      method: "POST",
      data: { ids: selectedIds, status: e.status }
    }).then((response) => {
      notify("Stato giri modificato");
      setFalse()
      refetch()
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
      })

      ;
    refetch();

  };

  const { data: options } = useOptions("routes", "GET");
  return options ? (
    <>
      <Button tabIndex={-1} label="Forza modifica stato" onClick={() => setTrue()}>
        <UpdateIcon />
      </Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>

                <SelectInput
                  {...initInput("status", options, true, "array")}
                  choices={options.status.choices}
                  required={true}
                />


              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Forza"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
