import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  makeStyles,
  List,
  ListItem,
  Typography,
  StepConnector,
} from "@material-ui/core";
import {
  Identifier,
  useQueryWithStore,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { Log } from "../shared/types";
import { HistoryOutlined, PlusOneOutlined } from "@material-ui/icons";

const useEventStyles = makeStyles({
  stepper: {
    background: "none",
    border: "none",
    padding: "8px"
  },
});
const EventList = ({ id }: { id: Identifier }) => {
  const resource = useResourceContext();

  const { data } = useQueryWithStore({
    type: "getHistory",
    resource: resource,
    payload: { id },
  });
  const classes = useEventStyles();
  return (
    <Stepper orientation="vertical" classes={{ root: classes.stepper }}
      connector={<StepConnector style={{ minHeight: "8px" }}></StepConnector>}>
      {data &&
        data.map((event: Log, index: number) => (
          <Step key={index} expanded active completed>
            <StepLabel
              StepIconComponent={() => {
                const Component =
                  event.history_type === "Creato"
                    ? PlusOneOutlined
                    : HistoryOutlined;
                return (
                  <Component
                    fontSize="small"
                    color="disabled"
                    style={{ paddingLeft: 1 }}
                  />
                );
              }}
            >
              <b>{event.history_user}</b>
              <br></br>
              <b>{new Date(event.history_date).toLocaleString()}</b>
            </StepLabel>
            <StepContent style={{
              marginTop: "0px",
              marginBottom: "0px",
              maxHeight: "8px"
            }}>
              <List style={{
                padding: "0px"
              }}>
                {event.changed_fields.map((item: string, index: number) => (
                  <ListItem key={index} style={{
                    textTransform: "capitalize",
                    padding: "0px 0px 0px 16px"
                  }}>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: item }}
                      variant="body2"
                      color="textSecondary"
                    ></Typography>
                  </ListItem>
                ))}
              </List>
            </StepContent>
          </Step>
        ))}
    </Stepper>
  );
};
export const MicroAsideLog = () => {
  const record = useRecordContext();

  return (
    <>{record && <EventList id={record.id} />}</>
  );
};
