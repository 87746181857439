import {
  Create,
  CreateProps,
  FileField,
  FileInput,
  SimpleForm,
} from "react-admin";
import { XMLFileUploadCreateToolbar } from "./XMLFileUploadCreateToolbar";


export const XMLFileUploadCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<XMLFileUploadCreateToolbar />}>
        <FileInput
          multiple
          source="files"
          label="XML da caricare"
          accept="text/xml,.p7m"
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
