import { Box, Dialog, DialogTitle, TableContainer } from "@material-ui/core";
import { ListBase, Record, useListContext } from "ra-core";
import {
  Datagrid,
  FilterButton,
  ListToolbar,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";

interface CarrierDialogProps {
  onClose?: () => void;
  open: boolean;
  onSelect: (value: Record | undefined) => void;
}

const DatagridCarrier = (props: {
  onSelect: (value: Record | undefined) => void;
  onClose?: () => void;
  options: any;
}) => {
  const { data } = useListContext();
  return (
    <Datagrid
      onToggleItem={(id) => {
        props.onSelect(data[id]);
        props.onClose && props.onClose();
      }}
      rowClick="toggleSelection"
    >
      <TextField {...initField("business_name", props.options)} />
      <TextField {...initField("locality", props.options)} />
    </Datagrid>
  );
};

export const CarrierDialog = (props: CarrierDialogProps) => {
  const { data: options } = useOptions("subjects", "GET");

  return options ? (
    <Dialog onClose={props.onClose} open={props.open} fullWidth maxWidth="xl">
      <DialogTitle>Seleziona vettore</DialogTitle>
      {options && (
        <ListBase
          resource="subjects"
          syncWithLocation={false}
          basePath="/subjects"
          perPage={100}
          sort={{ field: "business_name", order: "ASC" }}
          filterDefaultValues={{ tag: "Vettore" }}
          filter={{ single_type: "vettore" }}
        >
          <Box padding={2}>
            <ListToolbar
              filters={[
                <TextInput alwaysOn {...initField("business_name", options)} />,
                <TextInput alwaysOn {...initField("locality", options)} />,
              ]}
              actions={
                <TopToolbar>
                  <FilterButton />
                </TopToolbar>
              }
            />
          </Box>
          <TableContainer>
            <DatagridCarrier
              onClose={props.onClose}
              onSelect={props.onSelect}
              options={options}
            />
            <Pagination />
          </TableContainer>
        </ListBase>
      )}
    </Dialog>
  ) : null;
};
