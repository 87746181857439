import { makeStyles } from "@material-ui/core";
import {
  EditContextProvider,
  Record as RecordRa,
  ResourceContextProvider,
  useCheckMinimumRequiredProps,
  useEditController,
} from "ra-core";
import * as React from "react";
import { ReactElement } from "react";
import {
  CRUD_GET_ONE,
  CRUD_UPDATE,
  EditProps,
  useGetOne,
  useNotify,
  useRedirect,
  useRefresh,
  useResourceContext,
  useSaveModifiers,
  useUpdate,
} from "react-admin";
import { useQuery } from "react-query";
import { client, getRealResource } from "../dataProvider";
import { generateError } from "../shared/utils";
import { CustomEditView } from "./CustomEditView";

/**
 * Page component for the Edit view
 *
 * The `<Edit>` component renders the page title and actions,
 * fetches the record from the data provider.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes the `record` as prop.
 *
 * The <Edit> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - successMessage
 * - title
 * - mutationMode
 * - undoable (deprecated)
 *
 * @example
 *
 * // in src/posts.js
 * import * as React from "react";
 * import { Edit, SimpleForm, TextInput } from 'react-admin';
 *
 * export const PostEdit = (props) => (
 *     <Edit {...props}>
 *         <SimpleForm>
 *             <TextInput source="title" />
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * // in src/App.js
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 *
 * import { PostEdit } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={...}>
 *         <Resource name="posts" edit={PostEdit} />
 *     </Admin>
 * );
 * export default App;
 */
const useStyles = makeStyles({
  card: {
    minWidth: 0
  },
});

// type Options = { actions?: { PUT?: any; POST?: any; DELETE?: any } };
// const checkCanDelete = (options: Options) => {
//   return options?.actions?.DELETE === true;
// };

export const CustomEdit = (
  props: EditProps & { children: ReactElement; successMessage?: string }
): ReactElement => {
  useCheckMinimumRequiredProps("Edit", ["children"], props);
  const controllerProps = useEditController(props);
  const { data: options } = useQuery(
    ["options", `${props.resource}/${props.id}`],
    () =>
      client(`${getRealResource(props.resource!)}/${props.id}`, {
        method: "OPTIONS",
      }).then((data) => data)
  );

  //   const canDelete = React.useMemo(() => checkCanDelete(options), [options]);
  //   const canEdit = React.useMemo(
  //     () => options?.actions?.PUT?.can_edit === true,
  //     [options]
  //   );
  const fieldOptions = React.useMemo(
    () => (options?.actions?.PUT ? options?.actions?.PUT?.serializer : {}),
    [options]
  );
  const {
    basePath,
    id,
    successMessage,
    // @deprecated use mutationMode: undoable instead
    undoable = true,
    onSuccess,
    onFailure,
    mutationMode = undoable ? "undoable" : undefined,
    transform,
  } = props;
  const resource = useResourceContext(props);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  if (process.env.NODE_ENV !== "production" && successMessage) {
    console.log(
      "<Edit successMessage> prop is deprecated, use the onSuccess prop instead."
    );
  }

  const { onSuccessRef, transformRef } = useSaveModifiers({
    onSuccess,
    onFailure,
    transform,
  });

  const { data: record } = useGetOne<RecordRa>(resource, id!, {
    action: CRUD_GET_ONE,
    onFailure: () => {
      notify("ra.notification.item_doesnt_exist", "warning");
      redirect("list", basePath);
      refresh();
    },
  });

  const [update] = useUpdate(
    resource,
    id,
    {}, // set by the caller
    record
  );

  const save = React.useCallback(
    async (
      data: Partial<RecordRa>,
      redirectTo = "edit",
      {
        onSuccess: onSuccessFromSave,
        onFailure: onFailureFromSave,
        transform: transformFromSave,
      } = {}
    ) => {
      try {
        await Promise.resolve(
          transformFromSave
            ? transformFromSave(data)
            : transformRef.current
              ? transformRef.current(data)
              : data
        ).then((data) =>
          update(
            { payload: { data } },
            {
              action: CRUD_UPDATE,
              returnPromise: true,
              onSuccess: onSuccessFromSave
                ? onSuccessFromSave
                : onSuccessRef.current
                  ? onSuccessRef.current
                  : () => {
                    notify(
                      successMessage || "ra.notification.updated",
                      "info",
                      {
                        smart_count: 1,
                      },
                      mutationMode === "undoable"
                    );
                    redirect(redirectTo, basePath, data.id, data);
                  },

              mutationMode,
            }
          )
        );
      } catch (error) {
        return generateError(error, notify);
      }
    },
    [
      transformRef,
      update,
      onSuccessRef,
      notify,
      successMessage,
      redirect,
      basePath,
      mutationMode,
    ]
  );

  const classes = useStyles();
  const body = (
    <EditContextProvider value={{ ...controllerProps, save }}>
      <CustomEditView
        {...props}
        {...{ ...controllerProps, save }}
        classes={classes}
        canDelete={true}
        canEdit={true}
        fieldOptions={fieldOptions}
      />
    </EditContextProvider>
  );
  if (!options) return <></>;
  return props.resource ? (
    // support resource override via props
    <ResourceContextProvider value={props.resource}>
      {body}
    </ResourceContextProvider>
  ) : (
    body
  );
};
