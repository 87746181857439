import { get } from "lodash";
import { FieldProps, Link, linkToRecord, useRecordContext } from "react-admin";

export const InvoiceField = (props: FieldProps & { source: string }) => {
  const record = useRecordContext(props);
  const invoice = get(record, props.source);
  return (
    <>
      <Link to={linkToRecord("/invoices", invoice.id, "show")}>
        {`${invoice.id}`}
      </Link>
    </>
  );
};
