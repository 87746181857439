import { Grid, Typography } from "@material-ui/core";
import { DatagridProps, NumberField, useListContext, useResourceContext } from "react-admin";
import { useQuery } from "react-query";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { useListQueryParams } from "../shared/hooks/useListQueryParams";
import { client, getRealResource } from "../dataProvider";
import { StatCard } from "../components/StatCard";
import { useEffect, useState } from "react";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

export const InvoiceTimeTableListHeader = (
  props: DatagridProps) => {
  const resource = useResourceContext();
  const query = useListQueryParams();
  const { filterValues, filter, ids, data, selectedIds } = useListContext(props);

  const [manualStats, setManualStats] = useState({
    amount_selected: 0,
    rest_selected: 0,
    total_selected: 0
  })
  useEffect(() => {
    if (selectedIds && data) {
      let tempsManualStats = {
        amount_selected: 0,
        rest_selected: 0,
        total_selected: 0
      }
      for (let sId of selectedIds) {
        if (data[sId].type === "FT") {
          tempsManualStats.amount_selected += data[sId].amount;
          tempsManualStats.rest_selected += data[sId].residue;
        }
        else {
          tempsManualStats.amount_selected -= data[sId].amount;
          tempsManualStats.rest_selected += data[sId].residue;
        }
      }
      tempsManualStats.total_selected = tempsManualStats.amount_selected - tempsManualStats.rest_selected;
      setManualStats(tempsManualStats);
    }
  }, [selectedIds])


  const url = `${getRealResource(resource)}/statistics`;

  const { data: stats } = useQuery(
    [url, query, filterValues, filter, ids],
    () =>
      client(url, {
        method: "GET",
        params: {
          ...query,
          ...filter,
          ...filterValues,
        },
        paramsSerializer: function handleQuery(q: any) {
          return Object.entries(q).map(([key, value], i) => Array.isArray(value) ? `${key}=${value.join('&' + key + '=')}` : `${key}=${value}`).join('&');
        }
      }),
    {
      initialData: {
        total_amount: 0
      },
    }
  );
  return (
    <Grid container alignItems="center"
      justify="center">
      <Grid item xs={12} sm={3}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="amount__sum"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
          }
          icon={<AttachMoneyIcon />}
          subtitle="Totale da incassare"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="rest__sum"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
          }
          icon={<MoneyOffIcon />}
          subtitle="Residuo da incassare"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StatCard
          label={
            <NumberField
              record={stats}
              source="total__sum"
              variant="inherit"
              options={{
                style: "currency",
                currency: "EUR",
              }}
              defaultValue={"0,0"}
            />
          }
          icon={<DoneAllIcon />}
          subtitle="Totale incassato"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant="h4"> &#60;- Totali</Typography>
      </Grid>

      {
        manualStats ? (
          <>
            <Grid item container xs={12}>
              <Grid item xs={3}>
                <StatCard
                  label={
                    <NumberField record={{
                      id: 0,
                      amount_selected: manualStats.amount_selected
                    }}
                      source="amount_selected"
                      variant="inherit"
                      options={{
                        style: "currency",
                        currency: "EUR",
                      }}
                    />
                  }
                  icon={<AttachMoneyIcon />}
                  subtitle="Totale da incassare della selezione"
                />
              </Grid>
              <Grid item xs={3}>
                <StatCard
                  label={
                    <NumberField record={{
                      id: 0,
                      rest_selected: manualStats.rest_selected
                    }}
                      source="rest_selected"
                      variant="inherit"
                      options={{
                        style: "currency",
                        currency: "EUR",
                      }}
                    />
                  }
                  icon={<MoneyOffIcon />}
                  subtitle="Resto da incassare della selezione"
                />
              </Grid>
              <Grid item xs={3}>
                <StatCard
                  label={
                    <NumberField record={{
                      id: 0,
                      total_selected: manualStats.total_selected
                    }}
                      source="total_selected"
                      variant="inherit"
                      options={{
                        style: "currency",
                        currency: "EUR",
                      }}
                    />
                  }
                  icon={<DoneAllIcon />}
                  subtitle="Incassato della selezione"
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h4"> &#60;- Selezionate</Typography>
              </Grid>

            </Grid>
          </>
        ) : null
      }

    </Grid>
  );
};
