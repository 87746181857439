import { Card, makeStyles } from "@material-ui/core";
import {
  DeleteWithConfirmButton,
  EditProps,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useRecordContext,
} from "react-admin";
import { DefaultEdit } from "../components/DefaultEdit";
import { PurchaseChargeForm } from "./PurchaseChargeForm";

const useStyles = makeStyles((theme) => ({
  toolbar: { display: "flex", justifyContent: "space-between" },
}));

const CustomToolbar = (props: ToolbarProps) => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <Toolbar {...props} classes={classes}>
      {record && !record.editable ? null : <SaveButton />}
      {record && !record.editable ? null : (
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      )}
    </Toolbar>
  );
};

export const PurchaseChargeEdit = (props: EditProps) => {
  return (
    <DefaultEdit component={Card} {...props}>
      <PurchaseChargeForm toolbar={<CustomToolbar />} />
    </DefaultEdit>
  );
};
