import { Box, Grid, Typography } from "@material-ui/core";
import { getWeekOfMonth } from "date-fns";
import React from "react";
import {
  CheckboxGroupInput,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
} from "react-admin";

export const RecurrentField = () => {
  const listFrequency = ["primo", "secondo", "terzo", "quarto", "quinto"];
  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography>Ripeti ogni</Typography>
        </Grid>
        <Grid item xs={2}>
          <NumberInput
            fullWidth
            label={false}
            defaultValue={1}
            source="recurrence_conf.interval"
            helperText={false}
            validate={[required()]}
          />
        </Grid>
        <Grid item xs>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <SelectInput
                  fullWidth
                  defaultValue={"W"}
                  source="recurrence_conf.type"
                  label={false}
                  helperText={false}
                  validate={[required()]}
                  choices={
                    formData?.interval === 1
                      ? [
                          { id: "D", name: "giorno" },
                          { id: "W", name: "settimana" },
                          { id: "M", name: "mese" },
                        ]
                      : [
                          { id: "D", name: "giorni" },
                          { id: "W", name: "settimane" },
                          { id: "M", name: "mesi" },
                        ]
                  }
                />
              );
            }}
          </FormDataConsumer>
        </Grid>
      </Grid>
      <FormDataConsumer>
        {({ formData }) => {
          const delivery_date = formData?.delivery_date
            ? new Date(formData?.delivery_date)
            : new Date();

          return formData?.recurrence_conf?.type === "W" ? (
            <>
              <CheckboxGroupInput
                source="recurrence_conf.configuration.weekdays"
                label="Si ripete il"
                validate={[required()]}
                choices={[
                  { id: 0, name: "Lunedì" },
                  { id: 1, name: "Martedì" },
                  { id: 2, name: "Mercoledì" },
                  { id: 3, name: "Giovedì" },
                  { id: 4, name: "Venerdì" },
                  { id: 5, name: "Sabato" },
                  { id: 6, name: "Domenica" },
                ]}
              />
            </>
          ) : formData?.recurrence_conf?.type === "M" ? (
            <>
              <SelectInput
                fullWidth
                source="recurrence_conf.configuration.type"
                helperText={false}
                label={false}
                defaultValue={"MD"}
                validate={[required()]}
                choices={[
                  {
                    id: "MD",
                    name: `Ogni mese il giorno ${delivery_date.getDate()}`,
                  },
                  {
                    id: "WD",
                    name: `Ogni mese il ${
                      listFrequency[getWeekOfMonth(delivery_date) - 1]
                    } ${delivery_date.toLocaleDateString("it-IT", {
                      weekday: "long",
                    })} del mese`,
                  },
                ]}
              />
            </>
          ) : null;
        }}
      </FormDataConsumer>
    </Box>
  );
};
