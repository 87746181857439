import { BooleanField, Datagrid, NumberField, SelectField, TextField } from "react-admin";
import { ChartAccountsField } from "../../components/ChartAccountsField";
import { DriverField } from "../../components/DriverField";
import { IdentifierField } from "../../components/IdentifierField";
import { SubjectField } from "../../components/SubjectField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";

export const ChartAccountsTagsDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <TextField {...initField("tag", options)} />
      <ChartAccountsField {...initField("chart_accounts_info", options)} />
      <SubjectField {...initField("supplier_info", options)} />
      <BooleanField {...initField("equal", options)} />
      <BooleanField {...initField("contain", options)} />
      <BooleanField {...initField("similar", options)} />
      <BooleanField {...initField("default", options)} />
      <BooleanField {...initField("active", options)} />
    </Datagrid>
  );
};
