import {
  AutocompleteInput,
  DateInput,
  ListProps,
  ReferenceInput,
  SelectArrayInput,
} from "ra-ui-materialui";
import { initField } from "../../shared/utils";
import { useOptions } from "../../shared/hooks/useOptions";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { BulkActions } from "./components/BulkActions";
import { SimpleChargesList } from "./SimpleChargesList";
import { TariffType } from "../../shared/types";
import { AutocompleteArrayInput, ReferenceArrayInput } from "react-admin";
export const VerifiedChargesList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const filters =
    options && optionsTariff
      ? [
        <DateInput
          source="charge_date_after"
          label="Data calcolo dal"
          alwaysOn
          className="filter-field-small"
        />,
        <DateInput
          source="charge_date_before"
          label="Data calcolo al"
          alwaysOn
          required
          className="filter-field-small"
        />,
        <DateInput
          source="delivery_date_after"
          label="Data consegna dal"
          className="filter-field-small"
        />,
        <DateInput
          source="delivery_date_before"
          label="Data consegna al"
          className="filter-field-small"
        />,
        <DateInput
          source="document_date_after"
          label="Data documento dal"
          className="filter-field-small"
        />,
        <DateInput
          source="document_date_before"
          label="Data documento al"
          className="filter-field-small"
        />,
        <ReferenceInput
          {...initField("delivery", options)}
          reference="autocomplete/delivery"
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <SelectArrayInput
          style={{ minWidth: "155px" }}
          choices={optionsTariff.charge_type.choices}
          {...initField("charge_type", optionsTariff, "array")}
        />,
        <ReferenceInputSubject alwaysOn
          {...initField("receiver", options)}
          filter={{ type: "receiver" }}
          className="filter-field-small"
        />,
        <ReferenceInputSubject alwaysOn
          {...initField("sender", options)}
          className="filter-field-small"
        />,

        <ReferenceInputSubject
          filter={{ type: "client" }}
          {...initField("client", options)}
          className="filter-field-small"
        />,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="clients"
          filter={{ type: "client" }}
          reference="autocomplete/subject"
          label="Clienti"
          alwaysOn
        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="items"
          reference="autocomplete/item"
          label="Articoli"

        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="not_items"
          reference="autocomplete/item"
          label="NON contiene Articoli"

        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,

      ]
      : [];

  return (
    <SimpleChargesList
      {...props}
      Actions={BulkActions}
      filters={filters}
      filter={{
        verified: true,
        no_invoice: true,
        no_page: true,
        tariff_type: TariffType.SALE
      }}
    />
  );
};
