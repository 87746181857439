import { Dialog, DialogTitle } from "@material-ui/core";
import { Identifier, Record, RecordMap, useRefresh } from "ra-core";
import { RouteSmallDatagrid } from "../../Distribution/Route/RouteSmallDatagrid";
import { useOptions } from "../../shared/hooks/useOptions";

interface RouteDialogProps {
  onClose?: () => void;
  open: boolean;
  onSelect: (value: Record) => void;
  selectedIds: Identifier[];
  onSelectAll: (routes: Record[]) => void;
  data: RecordMap<Record>;
  ids: Identifier[];
  loading: boolean;
}
export const RouteDialog = (props: RouteDialogProps) => {
  const { data: options } = useOptions("routes", "GET");
  const { data, ids, loading } = props;
  return options ? (
    <Dialog onClose={props.onClose} open={props.open} fullWidth maxWidth="xl">
      <DialogTitle>Seleziona giro</DialogTitle>
      <RouteSmallDatagrid
        onSelect={(ids) => {
          props.onSelectAll(ids.map((id) => data[id]));
        }}
        data={data}
        ids={ids}
        loaded={!loading}
        isRowSelectable={() => true}
        hasBulkActions
        selectedIds={props.selectedIds}
        onToggleItem={(id) => props.onSelect(data[id])}
        options={options}
        currentSort={{ field: "id", order: "ASC" }}
        rowClick={"toggleSelection"}
        total={ids.length}
      />
    </Dialog>
  ) : null;
};
