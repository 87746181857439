import { Card } from "@material-ui/core";
import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { CustomerOrderForm } from "./CustomerOrderForm";

export const CustomerOrderCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props} component={Card}>
      <CustomerOrderForm />
    </CustomCreate>
  );
};
