import { ReactNode } from "react";
import { Datagrid, DatagridProps } from "react-admin";

import { DndDatagridBody, DndDatagridBodyProps } from "./DndDatagridBody";

interface DndDatagridProps {
  datagridProps?: DatagridProps;
  dndContextProps: DndDatagridBodyProps;
  children: ReactNode;
}

export const DndDatagrid = ({
  children,
  datagridProps,
  dndContextProps,
}: DndDatagridProps) => {
  //   const [dataCopy, setDataCopy] = useState(data);
  //   const listContext = useList({
  //     data: dataCopy,
  //     ids: dataCopy.map((item) => item.id),
  //     loading: false,
  //     loaded: true,
  //   });

  //   useEffect(() => {
  //     setDataCopy(data);
  //   }, [data]);

  return (
    <Datagrid
      {...datagridProps}
      children={children}
      body={<DndDatagridBody {...dndContextProps} />}
    />
  );
};
