import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { BooleanInput, FormDataConsumer, required, SimpleForm, TextInput } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";

export const UserClientForm = (props: FormProps) => {
  return (
    <SimpleForm
      {...props}
      toolbar={
        <CustomToolbar canEdit={props.canEdit} canDelete={props.canDelete} />
      }
    >
      <div style={{ "width": "100%" }}>
        <Grid container>
          <Grid item xs={6}>
            <FormDataConsumer>
              {({ formData }) =>
                formData ? (
                  <TextInput
                    {...initInput(
                      "username",
                      props.fieldOptions,
                      formData.id ? (false) : (true)
                    )}
                  />) : null
              }
            </FormDataConsumer>
          </Grid>

          <Grid item xs={6}>
            <TextInput
              {...initInput(
                "password_update",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>

          <Grid item xs={6}>

            <FormDataConsumer>
              {({ formData }) =>
                formData ? (
                  <ReferenceInputSubject
                    {...initInput("client_id", props.fieldOptions, props.canEdit)}
                    filter={{ type: "client" }}
                    defaultValue={formData.client_object ? (formData.client_object.id) : null}
                    initialValue={formData.client_object ? (formData.client_object.id) : null}
                  />
                ) : null
              }
            </FormDataConsumer>
          </Grid>

          <Grid item xs={6}>
            <BooleanInput
              {...initInput(
                "is_active",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <BooleanInput
              {...initInput(
                "order_permission",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <BooleanInput
              {...initInput(
                "tracking_permission",
                props.fieldOptions,
                props.canEdit
              )}
            />
          </Grid>

        </Grid>
      </div>
    </SimpleForm>
  );
};
