import {
  ArrayField,
  Button,
  ChipField,
  Datagrid,
  DatagridProps,
  DateField,
  FunctionField,
  Identifier,
  ListContextProvider,
  NumberField,
  Record,
  ReferenceField,
  SelectField,
  SingleFieldList,
  TextField,
  useList,
  useRecordContext,
} from "react-admin";
import { Dispatch, SetStateAction } from "react";

import { Box, makeStyles, useTheme } from "@material-ui/core";

import { useOptionsResource } from "../../../shared/hooks/useOptionsResource";
import { IdentifierField } from "../../../components/IdentifierField";
import { TariffField } from "../../../components/TariffField";
import { useOptions } from "../../../shared/hooks/useOptions";
import { initField } from "../../../shared/utils";
import { DeliveryField } from "../../../components/DeliveryField";
import { SubTariffField } from "../../../components/SubTariffField";
import { SimpleSubTariffField } from "../../../components/SimpleSubTariffField";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";

interface ChargesDatagridProps extends DatagridProps {
  setActiveCharge: (charge: Record | undefined) => void;
  selectedCharges: Identifier[];
  setSelectedCharges: Dispatch<SetStateAction<Identifier[]>>;
  activeCharge?: Record;
}
const useStyles = makeStyles((theme) => ({
  row: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
  headerCell: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.secondary.dark,
    borderBottom: "1px solid rgba(224, 224, 224, 1)!important",
    "& .select-all": {
      display: "none",
    },
  },
}));

export const ChargesDatagrid = (props: ChargesDatagridProps) => {
  const { selectedCharges, setSelectedCharges, activeCharge, setActiveCharge } =
    props;
  const { data: options } = useOptionsResource("GET");
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const classes = useStyles();
  const mainRecord = useRecordContext();
  const theme = useTheme();
  const listContext = useList({
    data: mainRecord!.charges,
    ids: mainRecord!.charges.map((item: Record) => item.id),
    loading: false,
    loaded: true,
  });
  const onToggleItem = (id: Identifier) => {
    setSelectedCharges((old) => {
      if (old.includes(id)) return old.filter((chargeId) => chargeId !== id);
      return [...old, id];
    });
  };
  const rowStyle = (record: Record) => {
    return {
      backgroundColor:
        record.id === activeCharge?.id
          ? theme.palette.primary.light
          : record.color,
    };
  };
  return options && optionsTariff ? (
    <ListContextProvider value={listContext}>
      <Datagrid
        hasBulkActions
        selectedIds={selectedCharges}
        classes={classes}
        stickyHeader
        onToggleItem={onToggleItem}
        rowStyle={rowStyle}
      >
        <FunctionField
          tabIndex={-1}
          sortable={false}
          label="ID"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              return (
                <Button
                  tabIndex={-1}
                  //label={`${tableRecord.id}`}
                  onClick={() => setActiveCharge(tableRecord)}
                ><InsertDriveFile /></Button>
              );
            }
            return null;
          }}
        />
        <DeliveryField
          sortable={false}
          source="main_delivery_id"
          deliverySource="main_delivery_id"
          label="Miss. Princ."
          noTitle={true}
        />
        <DeliveryField
          sortable={false}
          source="delivery"
          deliverySource="delivery"
          label="Miss. Sec."
          noTitle={true}
        />
        <SimpleSubTariffField source={"tariff_simple"} label={"Tariffa"} />


        <NumberField sortable={false} {...initField("quantity", options)} />
        <NumberField sortable={false} {...initField("price", options)} />
        <NumberField sortable={false} {...initField("taxable", options)} />
        <ReferenceField
          sortable={false}
          reference={"charges"}
          {...initField("main_charge", options)}
          label={"Ragg."}
        >
          <TextField source="delivery" />
        </ReferenceField>
        {/**/}
        <DateField sortable={false} {...initField("charge_date", options)} />
        {/*
        <IdentifierField sortable={false} {...initField("id", options)} />          
        <FunctionField
          sortable={false}
          label="Bolle"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              return (
                <Button
                  label="Mostra bolle"
                  onClick={() => setActiveCharge(tableRecord)}
                ></Button>
              );
            }
            return null;
          }}
        />
        */}
      </Datagrid>
    </ListContextProvider>
  ) : null;
};
