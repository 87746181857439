import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  FileInput,
  ReferenceInput,
  TextInput,
} from "ra-ui-materialui";
import { FileField, FormWithRedirect, SelectInput } from "react-admin";
import { CustomFileField } from "../../components/CustomFIleField";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initField, initInput } from "../../shared/utils";

export const NoteForm = (props: FormProps) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container direction="column">
                <Grid item container spacing={2} alignItems="center">
                  <Grid item>
                    <Typography>File auttale:</Typography>
                  </Grid>
                  <Grid item>
                    <CustomFileField
                      {...initField("file_info", props.fieldOptions)}
                      title="name"
                      src="url"
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <FileInput
                    {...initInput("file", props.fieldOptions, props.canEdit)}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                </Grid>
                <Grid item>
                  <BooleanInput
                    label="Scansione finale"
                    source="final"
                  >
                  </BooleanInput>
                </Grid>
                <Grid item>
                  <ReferenceInput
                    {...initInput(
                      "delivery",
                      props.fieldOptions,
                      props.canEdit
                    )}
                    reference="autocomplete/delivery"
                  >
                    <AutocompleteInput optionText="label" />
                  </ReferenceInput>
                </Grid>
                <DateTimeInput
                  {...initInput("sent", props.fieldOptions, props.canEdit)}
                />
                <Grid item>
                  <TextInput
                    multiline
                    {...initInput("document_number", props.fieldOptions, props.canEdit)}
                  />
                </Grid>

                <Grid item>
                  <SelectInput
                    {...initInput(
                      "origin",
                      props.fieldOptions,
                      props.canEdit,
                      "array"
                    )}
                    defaultValue="M"
                    choices={props.fieldOptions.origin.choices}
                  />
                </Grid>

                <Grid item>
                  <TextInput
                    multiline
                    {...initInput("config_name", props.fieldOptions, props.canEdit)}
                    required={true}
                    defaultValue="manual"
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    multiline
                    {...initInput("notes", props.fieldOptions, props.canEdit)}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CustomToolbar
              canEdit={props.canEdit}
              canDelete={props.canDelete}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        </Card>
      )}
    />
  );
};
