import { BulkDeleteButton, BulkUpdateButton, Identifier } from "react-admin";
import { ActionsToolbar } from "../../../components/ActionsToolbar";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

interface BulkActionProps {
  selectedCharges: Identifier[];
  onUnselect: () => void;
}
export const BulkActions = (props: BulkActionProps) => {
  const { selectedCharges, onUnselect } = props;
  return (
    <ActionsToolbar selected={selectedCharges} onUnselect={onUnselect}>
      <BulkUpdateButton
        selectedIds={selectedCharges}
        disabled={selectedCharges.length <= 0}
        data={{ verified: true }}
        mutationMode="pessimistic"
        label="Verifica"
        icon={<VerifiedUserIcon />}
      />
      <BulkDeleteButton selectedIds={selectedCharges} undoable={false} />
    </ActionsToolbar>
  );
};
