import {
  Button,
  ButtonProps,
  useListContext,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";
import VisibilityIcon from "@material-ui/icons/Visibility";

export const ComplededBulkButton = (props: ButtonProps) => {
  const { selectedIds, refetch } = useListContext();
  const unselect = useUnselectAll("carrier_cashboxes");
  const [update] = useUpdateMany("carrier_cashboxes", selectedIds, { completed: true });
  const handleSubmit = () => {
    update();
    unselect();
    refetch();
  };

  return (
    <Button
      {...props}
      disabled={selectedIds.length <= 0}
      label="Completa"
      onClick={handleSubmit}
    >
      <VisibilityIcon />
    </Button>
  );
};
