import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { ReasonStatusForm } from "./ReasonStatusForm";

export const ReasonStatusEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props}>
      <ReasonStatusForm />
    </DefaultEdit>
  );
};
