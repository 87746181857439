import React, { useEffect } from "react";
import {
  ReferenceInput,
  Datagrid,
  TextField,
  SelectField,
  DateField,
  NumberField,
  FunctionField,
  ArrayField,
} from "react-admin";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { SubjectField } from "../../components/SubjectField";
import { useOptions } from "../../shared/hooks/useOptions";
import { initInput, initField } from "../../shared/utils";
import { Record } from "react-admin";
import { useFormState } from "react-final-form";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { DeliveryType } from "../../shared/types";
interface CustomerOrderAddTariffFieldProps {
  options: any;
}
export const CustomerOrderAddTariffField = (props: CustomerOrderAddTariffFieldProps) => {
  const { options } = props;
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { values } = useFormState();
  const disabled = values.receiver && values.starting_point_on_delivery && values.delivery_date ? false : true;

  return optionsTariffs ? (

    <ReferenceInput
      {...initInput("new_service", options, !disabled)}
      reference="customer_order_tariffs"
      filter={{
        delivery_date: values.delivery_date instanceof Date ? values.delivery_date.toISOString() : new Date(values.delivery_date).toISOString(),
        delivery_type: DeliveryType.NORMAL,
        receiver: values.receiver,
        starting_point_on_delivery: values.starting_point_on_delivery,
        no_service: true
      }}
      helperText={false}
      isRequired

    >
      <DialogSelectInput helperText={false} optionText="label">
        <Datagrid rowClick="toggleSelection">
          <SelectField
            choices={optionsTariffs.quantity_type.choices}
            {...initField("quantity_type", optionsTariffs, "array")}
          />
          <DateField {...initField("start_date", optionsTariffs)} />
          <NumberField {...initField("price", optionsTariffs)} />
          <FunctionField
            label="Articolo"
            source="item"
            render={(tableRecord: Record | undefined) => {
              if (tableRecord && tableRecord.item) {
                return `${tableRecord.item.description}`;
              }
              return null;
            }}
          />
          <ArrayField source="pricerange_set" label="Fasce">
            <Datagrid rowClick={"expand"}>
              <NumberField source="limit" label="A partire da" />
              <SelectField
                label="Tipo"
                source="type"
                choices={[
                  { name: "Moltiplicatore", id: "M" },
                  { name: "Forfettario", id: "F" },
                ]}
              />
              <NumberField source="grouping" label="Raggruppa per" />
            </Datagrid>
          </ArrayField>
          <DateField {...initField("note_clients", optionsTariffs)} />
        </Datagrid>
      </DialogSelectInput>
    </ReferenceInput>
  ) : null;
};
