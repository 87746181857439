import { Dialog, DialogContent, DialogActions, CircularProgress } from "@material-ui/core";
import {
  BooleanInput,
  Button,
  ButtonProps,
  DateInput,
  FormDataConsumer,
  FormWithRedirect,
  Identifier,
  required,
  SelectInput,
  TextInput,
  useCreate,
  useGetOne,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, generateError, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import { useState } from "react";
import { client, clientNoJson } from "../../dataProvider";
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

export const AddPaymentStatusPayedBulkButton = ({ ids }: { ids?: Identifier[] }) => {
  const { selectedIds, refetch, data } = useListContext();


  const [loading, setLoading] = useState(false);
  const dataArray = Object.values(data);

  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    setLoading(true);

    client(`invoice-payment-status/multiple_collection`, {
      method: "POST",
      data: {
        ids: selectedIds,
        ...values
      }
    }).then((response) => {
      notify("Fatture incassate");
      setFalse();
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
      });


    refetch();
  };
  const { data: options } = useOptions("invoice-payment-status", "POST");
  return options && selectedIds ? (
    <>
      <Button
        size="small"
        color="primary"
        label="Incasso multiplo"
        onClick={() => setTrue()}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <><EuroSymbolIcon /></>}
      </Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="invoice-payment-status"
          save={handleSubmit}
          initialValues={{ ids: selectedIds }}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <DateInput
                  initialValue={dateFormatter(new Date())}
                  {...initInput("date", options, true)}
                />
                <SelectInput
                  {...initInput("status", options, true, "array")}
                  choices={[
                    { value: "I", display_name: "Fattura incassata" },
                  ]}
                  defaultValue={"I"}
                />
                <SelectInput
                  {...initInput(
                    "collection_type",
                    options,
                    true,
                    "array"
                  )}
                  choices={options.collection_type.choices}
                  validate={[required()]}
                />
                <TextInput {...initInput("notes", options, true)} />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={false}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
