import {
  AutocompleteInput,
  DateInput,
  ListProps,
  ReferenceInput,
  SelectInput,
} from "ra-ui-materialui";
import { useEffect, useState } from "react";
import { GroupedList } from "../../../components/GroupedList/GroupedList";
import { ReferenceInputSubject } from "../../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../../shared/hooks/useOptions";
import { getDefaultListProps, initField } from "../../../shared/utils";
import { CachetListHeader } from "../CachetListHeader";
import { CreateRemittanceButtonList } from "../CreateRemittanceButton";
import { GroupedDatagrid } from "../GroupedDatagrid";
import { CachetPaymentDatagrid } from "./CachetPaymentDatagrid";
import { BooleanInput, NullableBooleanInput, TextInput } from "react-admin";
import { ResetCachetPaymentBulkButton } from "./ResetCachetPaymentBulkButton";

export const CachetPaymentList = (props: ListProps) => {

  const { data: optionsDeliveryStates } = useOptions("delivery-states", "GET");
  const { data: optionsDelivery } = useOptions("deliveries", "GET");

  const filters = optionsDeliveryStates && optionsDelivery && [
    <NullableBooleanInput
      source="remittance_created" label="Rimessate" alwaysOn />,
    <ReferenceInput
      source="delivery"
      label="Missione"
      reference="autocomplete/delivery"
      className="filter-field-small"
    >
      <AutocompleteInput optionText="label" />
    </ReferenceInput>,
    <ReferenceInput source="route" label="Giro" reference="autocomplete/route"
      className="filter-field-small">
      <AutocompleteInput optionText="label" />
    </ReferenceInput>,
    <ReferenceInput
      source="driver"
      label="Autista"
      filter={{ departments: "Autista" }}
      reference="autocomplete/employee"
      className="filter-field-small"
    >
      <AutocompleteInput optionText="label" />
    </ReferenceInput>,
    <ReferenceInput
      alwaysOn
      source="carrier"
      label="Vettore"
      filter={{ type: "carrier" }}
      reference="autocomplete/subject"
      className="filter-field-small"
    >
      <AutocompleteInput optionText="label" />
    </ReferenceInput>,
    <ReferenceInputSubject
      source="client"
      label="Cliente"
      filter={{ type: "client" }}
      alwaysOn
      className="filter-field-small"
    />,
    <SelectInput
      choices={[
        { value: "CO", display_name: "Contanti" },
        { value: "AS", display_name: "Assegno" },
        { value: "CC", display_name: "Carta di credito" },
        { value: "AB", display_name: "Abbuono" },
      ]}
      source="type"
      label="Tipo incasso"
      optionText="display_name"
      optionValue="value"
      className="filter-field-small"
    />,
    <DateInput source="delivery_date_after" label="Data missione dal"
      className="filter-field-small" />,
    <DateInput source="delivery_date_before" label="Data missione al"
      className="filter-field-small" />,
    <DateInput source="document_date_after" label="Data documento dal"
      className="filter-field-small" />,
    <DateInput source="document_date_before" label="Data documento al"
      className="filter-field-small" />,
    <DateInput source="collection_date_after" label="Data incasso dal"
      className="filter-field-small" />,
    <DateInput source="collection_date_before" label="Data incasso al"
      className="filter-field-small" />,
    <TextInput source="document_number" label="Numero documento"
      className="filter-field-small" />,
    <SelectInput
      {...initField("delivery_status", optionsDeliveryStates, "array")}
      choices={optionsDeliveryStates.status.choices}
      label="Stato missione"
      className="filter-field-small"
    />,
    <ReferenceInput
      source="cachet_box"
      label="Cassa"
      reference="autocomplete/cachet-boxes"
      className="filter-field-small"
    >
      <AutocompleteInput optionText="label" />
    </ReferenceInput>,
    <ReferenceInputSubject
      source="receiver"
      label="Destinatario"
      filter={{ type: "receiver" }}
    />,
    <SelectInput
      {...initField("delivery_type", optionsDelivery, "array")}
      choices={optionsDelivery.delivery_type.choices}
      className="filter-field-small"
    />
  ];

  return (
    <GroupedList
      //defaultGroupedBy="client"
      possibleGroupings={[
        {
          label: "Autista",
          source: "driver",
          component: <GroupedDatagrid showCreateRemittanceButton={false} />,
        },
        {
          label: "Cliente",
          source: "client",
          component: <GroupedDatagrid showCreateRemittanceButton={true} />,
        },
        {
          label: "Vettore",
          source: "carrier",
          component: <GroupedDatagrid showCreateRemittanceButton={false} />,
        },
      ]}
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      filter={{ cachet_verified: true }}
      filterDefaultValues={{ remittance_created: false }}
      {...getDefaultListProps(props)}
      topElement={<CachetListHeader />}
      bulkActionButtons={
        <>
          <CreateRemittanceButtonList />
          <ResetCachetPaymentBulkButton />
        </>}
    >
      <CachetPaymentDatagrid />
    </GroupedList>
  );
};
