import _ from "lodash";
import React from "react";
import { FieldProps, Link, linkToRecord, useRecordContext } from "react-admin";

export const SubjectField = (props: FieldProps & { source: string, short?: boolean }) => {
  const record = useRecordContext(props);
  const subject = _.get(record, props.source);

  return subject ? (
    <Link
      target={"_blank"}
      to={linkToRecord("/subjects", subject.id, "edit")}>
      {`${subject.id}: ${((props.short || props.short == undefined) && subject.business_name) ?
        subject.business_name.substring(0, 25) :
        subject.business_name
        }`}
    </Link>
  ) : null;
};
