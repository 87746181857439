import { useQuery } from "react-query";
import { client, getRealResource } from "../../dataProvider";

export function useOptions(resource: string, type: "POST" | "GET" | "PUT") {
  return useQuery(
    ["options", resource, type],
    () =>
      client(getRealResource(resource), { method: "OPTIONS" }).then(
        (data) => data.actions[type]
      ),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
}
