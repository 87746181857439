import {
  ArrayField,
  Button,
  ChipField,
  Datagrid,
  DatagridProps,
  DateField,
  FunctionField,
  Identifier,
  ListContextProvider,
  NumberField,
  Record,
  ReferenceField,
  SelectField,
  SingleFieldList,
  TextField,
  useList,
  useRecordContext,
} from "react-admin";
import { Dispatch, SetStateAction } from "react";

import { Box, makeStyles, useTheme } from "@material-ui/core";

import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { IdentifierField } from "../../components/IdentifierField";
import { TariffField } from "../../components/TariffField";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";
import { DeliveryField } from "../../components/DeliveryField";
import { SubTariffField } from "../../components/SubTariffField";
import { SubjectField } from "../../components/SubjectField";
import { SimpleSubTariffField } from "../../components/SimpleSubTariffField";

interface SimpleChargesDatagridProps extends DatagridProps {
  selectedCharges: Identifier[];
  setSelectedCharges: Dispatch<SetStateAction<Identifier[]>>;
}
const useStyles = makeStyles((theme) => ({
  row: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
  headerCell: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.secondary.dark,
    borderBottom: "1px solid rgba(224, 224, 224, 1)!important",
    "& .select-all": {
      display: "none",
    },
  },
}));

export const SimpleChargesDatagrid = (props: SimpleChargesDatagridProps) => {
  const { selectedCharges, setSelectedCharges } =
    props;
  const { data: options } = useOptionsResource("GET");
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const classes = useStyles();
  const mainRecord = useRecordContext();
  const listContext = useList({
    data: mainRecord!.charges,
    ids: mainRecord!.charges.map((item: Record) => item.id),
    loading: false,
    loaded: true,
  });
  const onToggleItem = (id: Identifier) => {
    setSelectedCharges((old) => {
      if (old.includes(id)) return old.filter((chargeId) => chargeId !== id);
      return [...old, id];
    });
  };

  return options && optionsTariff ? (
    <ListContextProvider value={listContext}>
      <Datagrid
        hasBulkActions
        selectedIds={selectedCharges}
        classes={classes}
        stickyHeader
        onToggleItem={onToggleItem}
      >
        <NumberField sortable={false} {...initField("id", options)} />
        <DeliveryField
          sortable={false}
          source="delivery"
          deliverySource="delivery"
          label="Missione"
          noTitle
        />
        <SubjectField source="receiver" label="Destinatario" />
        <TextField source="document_number" label="Num. documento" />
        <TextField source="document_date" label="Data documento" />
        <NumberField sortable={false} {...initField("packages_number", options)} />
        <NumberField sortable={false} {...initField("weight", options)} />


        <SimpleSubTariffField source={"tariff_simple"} label={"Tariffa"} />

        <NumberField sortable={false} {...initField("quantity", options)} />
        <NumberField sortable={false} {...initField("price", options)} />
        <NumberField sortable={false} {...initField("taxable", options)} />
        <ReferenceField
          sortable={false}
          reference={"charges"}
          {...initField("main_charge", options)}
        >
          <TextField source="id" />
        </ReferenceField>
        <DateField sortable={false} {...initField("charge_date", options)} />

      </Datagrid>
    </ListContextProvider>
  ) : null;
};
