import _ from "lodash";
import React from "react";
import { FieldProps, Link, linkToRecord, useRecordContext } from "react-admin";

export const LittleSubjectField = (props: FieldProps & { source: string }) => {
  const record = useRecordContext(props);
  const subject = _.get(record, props.source);

  return subject ? (
    <Link
      tabIndex={-1}
      target={"_blank"}
      to={linkToRecord("/subjects", subject.id, "edit")}
      title={`${subject.id}: ${subject.business_name}`}>
      {`${subject.business_name.substring(0, 20)}`}
    </Link>
  ) : null;
};
