import { Dialog, DialogContent, DialogActions, Typography } from "@material-ui/core";
import { useState } from "react";
import {
  Button,
  FormWithRedirect,
  SaveButton,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useToggler } from "../../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";
import { useOptions } from "../../shared/hooks/useOptions";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition } from "../../shared/utils";
import { AxiosResponse } from "axios";
import React from "react";
import { Label } from "@material-ui/icons";


export const PrintDeliveryMarkers10x7Button = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const record = useRecordContext();
  const { value, setTrue, setFalse } = useToggler();
  const handleSubmit = async (e: any) => {
    //e.stopPropagation();
    setLoading(true);
    let res: AxiosResponse<any, any>
      try {
        res = await clientNoJson(`${URL}/deliveries/print_packages_markers_10x7/`, {
          method: "POST",
          data: {
            ids: [record.id],
          },
          responseType: "arraybuffer",
        });
      } catch (err) {
        //notify("ra.message.error", "error");
        notify("Si è verificato un errore nella stampa. Riprovare più tardi.", "error");
        setLoading(false);
        return;
      }
      setLoading(false);
      const data = res.data; // or res.blob() if using blob responses

      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: res.headers["content-type"],
        })
      );
      const actualFileName = getFileNameFromContentDisposition(
        res.headers["content-disposition"]
      );

      // uses the download attribute on a temporary anchor to trigger the browser
      // download behavior. if you need wider compatibility, you can replace this
      // part with a library such as filesaver.js
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", actualFileName);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) link.parentNode.removeChild(link);
      /*try {
        refetch();
      }
      catch (e) {

      }*/


  };

  const { data: options } = useOptions("deliveries", "GET");

  return options && record ? (
    <>
      {
        <Button
          disabled={!!!record?.client}
          onClick={handleSubmit}
          label={"Stampa segnacollo 10x7"}
        ><Label /></Button>
      }
    </>
  ) : null;
};
