import { Grid } from "@material-ui/core";
import { useCallback } from "react";
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  FormWithRedirect,
  FunctionField,
  NumberField,
  ReferenceInput,
  SelectField,
  TextField,
} from "react-admin";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { SubjectField } from "../../components/SubjectField";
import { useOptions } from "../../shared/hooks/useOptions";
import { initInput, initField } from "../../shared/utils";
import {
  FormWithRedirectRenderProps,
  Record,
  useCreate,
  useDataProvider,
  useEditContext,
  useNotify,
  useRefresh,
} from "ra-core";
import Add from "@material-ui/icons/Add";
import { OnChange } from "react-final-form-listeners";
import { NumberInput } from "../../components/NumberInput";
interface AddCustomerOrderItemFormProps {
  disabled?: boolean;
}
export const AddCustomerOrderItemForm = (props: AddCustomerOrderItemFormProps) => {
  const { disabled } = props;
  const { record } = useEditContext();
  const { data: optionsTariffs } = useOptions("tariffs-client", "GET");
  const { data: options } = useOptions("customer_order_items", "GET");
  const [create] = useCreate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const onSubmit = useCallback(
    (values: Partial<Record>) => {
      create("customer_order_items", values, {
        onSuccess: () => {
          notify("ra.notification.created", "success");
          refresh();
        },
        onFailure: () => notify("ra.notification.bad_item", "error"),
      });
    },
    [create, notify, refresh]
  );

  const updateQuantity = (
    value: number,
    formProps: FormWithRedirectRenderProps
  ) => {
    /*
    dataProvider
      .getOne("customer_order_tariffs", {
        id: value,
      })
      .then(({ data }) => {
        if (record) {
          if (data.quantity_type === "CO" && record.packages_number) {
            formProps.form.change("quantity", record.packages_number);
          } else if (data.quantity_type === "PE" && record.weight) {
            formProps.form.change("quantity", record.weight);
          }
        }
      });
    */
  };

  return options && optionsTariffs ? (
    <FormWithRedirect
      save={onSubmit}
      initialValues={{ order: record?.id }}
      render={(formProps) => (
        <>
          <OnChange name="tariff">
            {(value, previous) => {
              if (value) {
                updateQuantity(value, formProps);
              }
            }}
          </OnChange>

          <Grid spacing={2} container alignItems="center">
            <Grid item xs={5}>
              <ReferenceInput
                {...initInput("tariff", options, disabled)}
                reference="customer_order_tariffs"
                filter={{ order: record?.id }}
                helperText={false}
              >
                <DialogSelectInput helperText={false} optionText="label">
                  <Datagrid rowClick="toggleSelection">
                    <TextField {...initField("id", optionsTariffs)} />
                    <SelectField
                      choices={optionsTariffs.tariff.children.quantity_type.choices}
                      {...initField("tariff_complete.tariff.quantity_type", optionsTariffs, "array")}
                    />
                    <NumberField {...initField("price", optionsTariffs)} />
                    <FunctionField
                      label="Articolo"
                      source="tariff_complete"
                      render={(tableRecord: Record | undefined) => {
                        if (tableRecord && tableRecord.tariff) {
                          return `${tableRecord.tariff.item.code}: ${tableRecord.tariff.item.description}`;
                        }
                        return null;
                      }}
                    />
                    <ArrayField source="pricerange_set" label="Fasce">
                      <Datagrid rowClick={"expand"}>
                        <NumberField source="limit" label="A partire da" />
                        <SelectField
                          label="Tipo"
                          source="type"
                          choices={[
                            { name: "Moltiplicatore", id: "M" },
                            { name: "Forfettario", id: "F" },
                          ]}
                        />
                        <NumberField source="grouping" label="Raggruppa per" />
                      </Datagrid>
                    </ArrayField>
                  </Datagrid>
                </DialogSelectInput>
              </ReferenceInput>
            </Grid>
            <Grid item xs={5}>
              <NumberInput
                helperText={false}
                {...initInput("quantity", options, disabled)}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={formProps.handleSubmit}
                label="Aggiungi"
                title="Aggiungi servizio"
              >
                <Add />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    />
  ) : null;
};
