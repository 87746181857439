import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from "ra-ui-materialui";
import React from "react";

type ReferenceInputItemProps = Omit<
  ReferenceInputProps,
  "children" | "reference" | "sort"
>;

export const ReferenceInputItem = (props: ReferenceInputItemProps) => {
  return (
    <ReferenceInput
      {...(props as ReferenceInputProps)}
      reference="autocomplete/item"
      sort={{ field: "description", order: "ASC" }}
      allowEmpty={true}
      perPage={1000}
    >
      <AutocompleteInput optionText="label" />
    </ReferenceInput>
  );
};
