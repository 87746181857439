import { Typography, withStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import _ from "lodash";
import React from "react";
import { FieldProps, Identifier, useRecordContext, Button } from "react-admin";
import { Link } from "react-router-dom";
import { clientNoJson } from "../dataProvider";
import { url } from "../constants";
import { getFileNameFromContentDisposition } from "../shared/utils";
import { DownloadFileButton } from "./DownloadFileButton";
import { DownloadFileRedIdButton } from "./DownloadFileRedIdButton";

export const downloadNote = async (routeId: Identifier, resource?: string) => {
  if (!resource) {
    resource = "delivery-groups"
  }
  let res = null;
  try {
    res = await clientNoJson(`${url}/${`${resource}/${routeId}/download/`}`, {
      responseType: "arraybuffer",
      method: "GET",
    });
  } catch (err) {
    return;
  }
  return res;
};

export const downloadBorderaux = async (routeId: Identifier, resource?: string) => {
  if (!resource) {
    resource = "delivery-groups"
  }
  let res = null;
  try {
    res = await clientNoJson(`${url}/${`${resource}/${routeId}/get_pdf_borderau/`}`, {
      responseType: "arraybuffer",
      method: "GET",
    });
  } catch (err) {
    return;
  }
  return res;
};

export const saveNoteToFile = async (routeId: Identifier) => {
  const res = await downloadNote(routeId, "delivery-groups");
  if (res) {
    const data = res.data;
    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  }
};

const RedTextTypography = withStyles({
  root: {
    color: red[500]
  }
})(Typography);

const GreenTextTypography = withStyles({
  root: {
    color: green[500]
  }
})(Typography);

export const DeliveryGroupField = (props: FieldProps & {
  deliveryGroupSource: string, hasFile?: boolean
}) => {
  const record = useRecordContext(props);
  const delivery_group_id = _.get(record, props.deliveryGroupSource);
  return delivery_group_id ? (
    <span onClick={(e) => e.stopPropagation()}>
      {props.hasFile !== true ?
        (<DownloadFileRedIdButton
          title={"" + delivery_group_id}
          urlDownload={`delivery-groups/${delivery_group_id}/get_pdf_borderau/`}
        />) :
        (<Button
          style={{ color: green[500] }}

          onClick={(e) => {
            e.stopPropagation()
            props.hasFile !== true ? downloadBorderaux(delivery_group_id) : saveNoteToFile(delivery_group_id)
          }}
          label={"" + delivery_group_id}

        />)
      }
    </span >
  ) : null;
};
