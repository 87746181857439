import { Button } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { FieldProps, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

export const RemittanceField = (props: FieldProps) => {
  const record = useRecordContext(props);
  const identifier = _.get(record, props.source!);
  return identifier ? (
    <span onClick={(e) => e.stopPropagation()}>
      <Button
        color="primary"
        component={Link}
        to={"/remittances/" + identifier}
      >
        {identifier}
      </Button>
    </span>
  ) : null;
};
