import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  Button,
  DateTimeInput,
  FormDataConsumer,
  FormWithRedirect,
  FunctionField,
  NumberField,
  ReferenceInput,
  required,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, dateTimeFormatter, generateError, initField, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import UpdateIcon from "@material-ui/icons/Update";
import { useEffect, useState } from "react";
import { DeliveryStatusReasonField } from "../../Distribution/Delivery/DeliveryStatusReasonField";
import { DeliveryStatusChoice } from "../../shared/types";

interface CarrierAddDeliveryStatusButtonProps { refreshMain?: boolean | undefined }

export const CarrierAddDeliveryStatusButton = (props: CarrierAddDeliveryStatusButtonProps) => {
  const record = useRecordContext();
  const { value, setFalse, setTrue } = useToggler();
  const [fault, setFault] = useState();
  const refresh = useRefresh();
  const [create, { loading }] = useCreate();
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    create("carrier_deliverystates", values, {
      onSuccess: ({ data }) => {
        setFalse();
        refresh();
        if (props.refreshMain) {
          window.location.reload();
        }
      },
      onFailure: (error) => {
        generateError(error, notify);
      },
    });
  };
  const { data: options } = useOptions("carrier_deliverystates", "GET");

  return options && [
    DeliveryStatusChoice.PLANNED,
    DeliveryStatusChoice.LOAD,
    DeliveryStatusChoice.DELIVERING,
    DeliveryStatusChoice.SUSPENDED,
  ].includes(record.status) ? (
    <>
      <Button tabIndex={-1} label="Aggiorna stato" onClick={() => setTrue()}>
        <UpdateIcon />
      </Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="carrier_deliverystates"
          save={handleSubmit}
          initialValues={{ delivery: record.id }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <DateTimeInput
                  initialValue={dateTimeFormatter(new Date())}
                  {...initInput("date", options, true)}
                  showTime={true}
                />
                <SelectInput
                  {...initInput("status", options, true, "array")}
                  choices={
                    [
                      { display_name: "Consegnata", value: DeliveryStatusChoice.DELIVERED },
                      { display_name: "Sospesa", value: DeliveryStatusChoice.SUSPENDED },]
                  }
                  validate={required()}
                />

                <DeliveryStatusReasonField
                  options={options}
                  setFault={setFault}
                  form={form}
                  carrier={true}
                />
                <TextInput {...initInput("status_notes", options, true)} />

              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
