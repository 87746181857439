import { Box } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { FieldProps, FunctionField, Link, linkToRecord, useRecordContext, Record } from "react-admin";


export const SimpleSubTariffField = (props: FieldProps & {
  source: string
}) => {
  const record = useRecordContext(props);
  const sub_tariff = _.get(record, props.source);

  const starting_point_on_delivery = record.starting_point_on_delivery ? (
    sub_tariff.provinces_start_string.includes(record.starting_point_on_delivery.province) ? record.starting_point_on_delivery.province : ""
  ) : "";

  const receiver = record.receiver ? (
    sub_tariff.provinces_string.includes(record.receiver.province) ? record.receiver.province : ""
  ) : "";

  const display_prov = starting_point_on_delivery || receiver ?
    `[${starting_point_on_delivery}|${receiver}]` :
    "";

  return sub_tariff ? (
    <FunctionField
      tabIndex={-1}
      sortable={false}
      label="Tariffa"
      render={(record: Record | undefined) => (
        <Box display="flex" gridGap={"10px"}>
          <Link
            target={"_blank"}
            to={linkToRecord("/tariffs", sub_tariff.main_tariff_id, "edit")}>
            {`${sub_tariff.label}${display_prov}`}
          </Link>


        </Box>
      )}
    />
  ) : null;
};
