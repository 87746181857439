import { useForm } from "react-final-form";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "react-admin";

interface SaveButtonDataGridProps {
  callback? : () => void
}

export const SaveButtonDatagrid = (props: SaveButtonDataGridProps) => {
  const form = useForm();
  return (
    <Button
      size="small"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.submit();
        props.callback && props.callback()
      }}
    >
      <SaveIcon />
    </Button>
  );
};
