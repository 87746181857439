import { Button, ButtonProps, useListContext, useNotify } from "react-admin";
import SendIcon from "@material-ui/icons/Send";
import { client } from "../../dataProvider";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";

export const SendTrackMeButtonsButtonSingle = (props: ButtonProps & { id: string }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    client(`routes/send_routes_trackme`, {
      method: "POST",
      data: { ids: [props.id] },
    })
      .then(() => {

        notify("Fatto", "success");
      })
      .catch(() => {
        notify("ra.message.error", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      {...props}
      disabled={loading}
      title="Invia a TrackMe"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <SendIcon />}
    </Button>
  );
};
