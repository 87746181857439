import { Button } from "@material-ui/core";
import React from "react";
import { FieldProps, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

export const GoToLoadDeliveriesButton = (
  props: FieldProps & { filterAttribute: string }
) => {
  const record = useRecordContext(props);
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/load-deliveries",
        search: `route=${record.id}`,
      }}
      onClick={(e: any) => e.stopPropagation()}
    >
      Carica Missioni
    </Button>
  ) : null;
};
