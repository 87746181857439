import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from "ra-ui-materialui";
import React from "react";

type ReferenceInputTermsPaymentProps = Omit<
  ReferenceInputProps,
  "children" | "reference" | "sort"
>;

export const ReferenceInputTermsPayment = (props: ReferenceInputTermsPaymentProps) => {

  return (
    <ReferenceInput
      {...(props as ReferenceInputProps)}
      reference="autocomplete/terms-payment"
      sort={{ field: "method", order: "ASC" }}
    >
      <AutocompleteInput
        options={{ ...props.options }}
        optionText="label"
      />
    </ReferenceInput>

  );
};
