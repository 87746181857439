import React from "react";
import arrayMutators from "final-form-arrays";
import { Form, FormProps } from "react-final-form";
const defaultMutators = {
  ...arrayMutators,
};
const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
  validating: true,
};
export const CustomForm = (props: FormProps) => {
  return (
    <Form
      {...props}
      mutators={defaultMutators}
      subscription={defaultSubscription} // do not redraw entire form each time one field changes
      keepDirtyOnReinitialize
    />
  );
};
