import { Button, ButtonProps, useNotify, useRecordContext } from "react-admin";
import PublishIcon from "@material-ui/icons/Print";
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition } from "../../shared/utils";

export const DownloadXmlButton = (
  props: ButtonProps & { onSuccess: () => void }
) => {
  const { onSuccess, ...rest } = props;
  const record = useRecordContext();
  const notify = useNotify();

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    const r = window.confirm(
      "Il download dell'xml porterà la fattura nello stato di completata, vuoi procedere?"
    );
    if (r) {
      let res = null;

      try {
        res = await clientNoJson(
          `${URL}/invoices/${record.id}/generate_xml_invoice/`,
          {
            method: "GET",
            responseType: "arraybuffer",
          }
        );
      } catch (err) {
        notify("ra.notification.http_error", "error");

        return;
      }
      const data = res.data; // or res.blob() if using blob responses

      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: res.headers["content-type"],
        })
      );
      const actualFileName = getFileNameFromContentDisposition(
        res.headers["content-disposition"]
      );

      // uses the download attribute on a temporary anchor to trigger the browser
      // download behavior. if you need wider compatibility, you can replace this
      // part with a library such as filesaver.js
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", actualFileName);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) link.parentNode.removeChild(link);
      onSuccess();
    }
  };

  return (
    <Button {...rest} label="Genera xml" onClick={handleSubmit}>
      <PublishIcon />
    </Button>
  );
};
