import { useFormState } from "react-final-form";

import { client } from "../../dataProvider";
import { useQuery } from "react-query";
import { useMemo, useState } from "react";
import { AxiosError } from "axios";
import { NumberField } from "react-admin";
import Alert from "@material-ui/lab/Alert";

export const RevenuesOnFly = () => {
  const { values } = useFormState();
  const [error, setError] = useState<string>();
  const canDoRequest = useMemo(() => {
    return !!(
      values &&
      "receiver" in values &&
      values.receiver &&
      "client" in values &&
      values.client &&
      (("weight" in values && values.weight) ||
        ("packages_number" in values && values.packages_number)) &&
      "new_service" in values &&
      values.new_service
    );
  }, [values]);

  const { data: stats } = useQuery(
    [values],
    () =>
      client(`deliveries/revenues_on_fly`, {
        method: "POST",
        data: values,
      }),
    {
      initialData: { total: 0 },
      enabled: canDoRequest,
      onSuccess: () => {
        setError(undefined);
      },
      onError: (error: AxiosError) => {
        setError(error.response?.data?.detail);
      },
      retry: false,
    }
  );

  return canDoRequest ? (
    error ? (
      <Alert severity="error">{error}</Alert>
    ) : (
      <>
        Ricavo:{" "}
        <b>
          <NumberField
            record={stats}
            source="total"
            variant="inherit"
            options={{ style: "currency", currency: "EUR" }}
          />
        </b>
      </>
    )
  ) : (
    <Alert severity="info">Compila tutti i campi per la stima tariffa</Alert>
  );
};
