import { Datagrid, DatagridBody, DatagridProps } from "react-admin";
import { EditableDatagridRow } from "./EditableDatagridRow";

export const EditableDatagrid = (props: DatagridProps) => {
  return (
    <Datagrid
      {...props}
      body={<DatagridBody row={<EditableDatagridRow />} />}
    />
  );
};
