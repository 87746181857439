import { Button, ButtonProps } from "ra-ui-materialui";
import React, { useState } from "react";
import FileCopy from "@material-ui/icons/FileCopy";
import { useRedirect } from "react-admin";
import { clientNoJson } from "../../dataProvider";
import { CircularProgress } from "@material-ui/core";
export const AdditionalActions = (props: ButtonProps) => {

  const [loading, setLoading] = useState(false);
  const redirect = useRedirect();
  const handleClick = () => {
    redirect("/notes/not-assigned");
  };
  const handleClick2 = async (e: any) => {
    e.stopPropagation();
    setLoading(true)
    let res = null;

    try {
      res = await clientNoJson(`api/notes/recheck_uploaded_documents`, {
        method: "GET",
      }).finally(() => {
        setLoading(false);
      })
    } catch (err: any) {
      setLoading(false)
    }

  };


  return (
    <>
      <Button {...props} label="Assegna bolle" onClick={handleClick}>
        <FileCopy />
      </Button>
      <Button {...props} label="Riprova associazione bolle" onClick={handleClick2}>
        {loading ? <CircularProgress size={18} thickness={2} /> : <FileCopy />}
      </Button>
    </>

  );
};
