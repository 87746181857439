import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  makeStyles,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DateTimeInput,
  FormDataConsumer,
  FormWithRedirectRenderProps,
  Identifier,
  NullableBooleanInput,
  ReferenceInput,
  required,
  SimpleFormIterator,
  useDataProvider,
} from "react-admin";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { CancelButton } from "../components/CancelButton";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { initInput } from "../shared/utils";
import { FormProps, Subject } from "../shared/types";
import dataProvider from "../dataProvider";
import { OnChange } from "react-final-form-listeners";
import { DialogSelectInput } from "../components/DialogSelectInput";
import { TariffClientDatagrid } from "../components/TariffClientDatagrid";
import { ReferenceInputItem } from "../components/reference-inputs/ReferenceInputItem";

interface FileImportFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: PayloadType) => void;
  selectedIds: Identifier[];
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  index: {
    width: "13rem",
    wordBreak: "break-word",
  },
}));

type PayloadType = Record<string, any> & {
  files: { name: string; client?: number }[];
};

export const FileImportFormDialog = ({
  isOpen,
  onClose,
  onSubmit,
  selectedIds,
  loading
}: FileImportFormProps) => {
  const classesArrayInput = useStyles();
  const [data, setData] = useState<PayloadType>({ files: [] });
  const dataProvider = useDataProvider();

  useEffect(() => {
    setData((old) => ({
      ...old,
      files: selectedIds.reduce((arrayOfFiles, file) => {
        arrayOfFiles.push({
          name: file,
          to_be_tractioned: false,
          same_day: false,
        });
        return arrayOfFiles;
      }, [] as any),
    }));
  }, [selectedIds]);

  const handleSubmit = (values: any) => {
    onSubmit(values);
  };
  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth={"lg"}>
      <Form
        // validate={(values) => {
        //   if (!values.site) {
        //     return { site: "Richiesto" };
        //   }
        //   return {};
        // }}
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }} // necessary for ArrayInput
        keepDirtyOnReinitialize
        initialValues={data}
        render={(formProps) => (
          <>

            <DialogTitle>Info spedizioni</DialogTitle>
            <DialogContent>
              <ArrayInput source="files">

                <SingleFileImportFormDialog
                  classesArrayInput={classesArrayInput}
                  selectedIds={selectedIds}
                  formProps={formProps} />

              </ArrayInput>
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={onClose} />

              <Button
                variant="contained"
                color="primary"
                onClick={formProps.handleSubmit}
                disabled={loading}
              >
                Importa
              </Button>
            </DialogActions>

          </>
        )
        }
      />
    </Dialog >
  );
};

const SingleFileImportFormDialog = (props: {
  classesArrayInput: any,
  selectedIds: any,
  formProps: any,
}) => {
  const { classesArrayInput, selectedIds, formProps } = props;
  const dataProvider = useDataProvider();

  const updateStartingPoinOnDelivery = (
    value: number,
    formProps: FormProps,
    state: string
  ) => {
    dataProvider
      .getOne<Subject>("subjects", {
        id: value,
      })
      .then(({ data: subject }) => {
        if (subject.starting_point_id) {
          if (subject.starting_point?.type.includes("destinatario")) {
            formProps.form.change(state, subject.starting_point_id);
          }
        } else {
          if (subject?.type.includes("destinatario")) {
            formProps.form.change(state, subject.id);
          }
        }
      });
  };


  return (


    <SimpleFormIterator
      classes={classesArrayInput}
      disableRemove
      disableAdd
      getItemLabel={(index) => selectedIds[index] as string}
    >
      <FormDataConsumer>
        {({ getSource, scopedFormData }) => (
          <>
            <ReferenceInputSubject
              record={scopedFormData}
              source={getSource ? getSource("client") : ""}
              label="Cliente"
              filter={{ type: "client" }}
              validate={required()}
            />
            <OnChange name={getSource ? getSource("client") : ""}>
              {(value, previous) => {
                if (formProps.form.getFieldState(
                  getSource ? getSource("client_source") : ""
                ).value && value) {
                  updateStartingPoinOnDelivery(value, formProps, getSource ? getSource("starting_point_on_delivery") : "");
                }
                else {
                  formProps.form.change(getSource ? getSource("starting_point_on_delivery") : "", null);
                }
                formProps.form.change(getSource ? getSource("client_source") : "", true);
              }}
            </OnChange>

          </>
        )
        }

      </FormDataConsumer >

      <FormDataConsumer>
        {({ getSource, scopedFormData }) => (
          <>
            <BooleanInput
              record={scopedFormData}
              source={getSource ? getSource("client_source") : ""}
              label="Sedi cliente"
            ></BooleanInput>
            <OnChange name={getSource ? getSource("client_source") : ""}>
              {(value, previous) => {
                let client = formProps.form.getFieldState(
                  getSource ? getSource("client") : ""
                )
                if (client && value) {
                  updateStartingPoinOnDelivery(client.value, formProps, getSource ? getSource("starting_point_on_delivery") : "");
                }
                else {
                  formProps.form.change(getSource ? getSource("starting_point_on_delivery") : "", null);
                }
              }}
            </OnChange>
          </>
        )}
      </FormDataConsumer >

      <ReferenceInputSubject source="sender" label="Mittente" />

      <FormDataConsumer>
        {({ getSource, scopedFormData }) => (
          <ReferenceInputSubject
            source={getSource ? getSource("starting_point_on_delivery") : ""}
            record={scopedFormData}
            label="Punto partenza in consegna"
            filter={
              formProps.form.getFieldState(
                getSource ? getSource("client_source") : ""
              ) && formProps.form.getFieldState(
                getSource ? getSource("client_source") : ""
              ).value ? {
                type: "receiver,store",
                subject: scopedFormData.client,
              } : {
                type: "receiver"
              }
            }
          />)
        }
      </FormDataConsumer >
      <ReferenceInputSubject
        source="transit_point"
        label="Transit point"
      />
      <FormDataConsumer>
        {({ getSource, scopedFormData }) => (
          (formProps.form.getFieldState(
            getSource ? getSource("client") : ""
          ) && formProps.form.getFieldState(
            getSource ? getSource("client") : ""
          ).value) ?
            <>
              <ReferenceInputItem
                record={scopedFormData}
                source={getSource ? getSource("item") : ""}
                label="Applica tariffa per trasporto di "
                filter={{
                  import_client: formProps.form.getFieldState(
                    getSource ? getSource("client") : ""
                  ).value
                }}
              />
              <OnChange name={getSource ? getSource("client") : ""}>
                {(value, previous) => {
                  formProps.form.change(getSource ? getSource("item") : "", null);
                }}
              </OnChange>

            </> : <></>
        )
        }

      </FormDataConsumer >
      <NullableBooleanInput
        fullWidth
        source="to_be_tractioned"
        label="Da trazionare"
        nullLabel="Di default"
      />
      <BooleanInput source="exclude_first_segment" label="Escludi prima tratta" defaultValue={true} />
      <BooleanInput source="exclude_last_segment" label="Escludi ultima tratta" />
      <BooleanInput source="exclude_all_segment" label="Escludi tutte le tratte" />
      <BooleanInput source="same_day" label="Same day" />
      <DateTimeInput source="delivery_date" label="Data missione" />
      <ReferenceInput
        {...initInput(
          "parent_delivery",
          true
        )}
        reference="autocomplete/delivery"
        label="Missione principale"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>

    </SimpleFormIterator>
  );
};
