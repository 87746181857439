import {
  BulkActionProps,
  BulkDeleteButton,
  Button,
  Identifier,
  useNotify,
  useRefresh,
} from "react-admin";
import { CircularProgress } from "@material-ui/core";
import { clientNoJson } from "../dataProvider";
import { useToggler } from "../shared/hooks/useToggler";
import { useState } from "react";
import { getFileNameFromContentDisposition } from "../shared/utils";
import SendIcon from '@material-ui/icons/Send';
import CodeIcon from '@material-ui/icons/Code';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';


const CalculateFuelButton = ({ values }: { values?: Identifier[] }) => {
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);


  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;

    try {
      res = await clientNoJson(`api/invoices/generate_fuel_invoices/`, {
        method: "POST",
        data: { ids: values },
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    notify("Fatture inviate", "success");
    try {
      refresh();
    }
    catch (e) {

    }

  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        label="Calcola fuel fatture"
        onClick={handleSubmit}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <><LocalGasStationIcon /></>}
      </Button>
    </>
  );
};

const SendInvoiceButton = ({ values }: { values?: Identifier[] }) => {
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);


  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;

    try {
      res = await clientNoJson(`api/invoices/send_invoice_emails/`, {
        method: "POST",
        data: { ids: values },
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    notify("Fatture inviate", "success");
    try {
      refresh();
    }
    catch (e) {

    }

  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        label="Invia fatture"
        onClick={handleSubmit}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <><SendIcon /></>}
      </Button>
    </>
  );
};

const GenerateXMLInvoiceButton = ({ values }: { values?: Identifier[] }) => {
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);


  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;

    try {
      res = await clientNoJson(`api/invoices/generate_xml_invoices/`, {
        method: "POST",
        data: { ids: values },
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
    try {
      refresh();
    }
    catch (e) {

    }

  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        label="Crea xml fatture"
        onClick={handleSubmit}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <><CodeIcon /></>}
      </Button>
    </>
  );
};


export const BulkActions = (props: BulkActionProps) => {
  const { selectedIds } = props;

  return (
    <>
      <GenerateXMLInvoiceButton values={selectedIds} />
      <SendInvoiceButton values={selectedIds} />
      <CalculateFuelButton values={selectedIds} />
      <BulkDeleteButton undoable={false} {...props} />
    </>
  );
};
