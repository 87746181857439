import {
  Button,
  useListContext,
  useNotify,
  useResourceContext,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";
import CheckIcon from "@material-ui/icons/Check";
export const VerifyAllButton = () => {
  const { selectedIds, refetch } = useListContext();
  const resource = useResourceContext();
  const unselect = useUnselectAll(resource);
  const notify = useNotify();
  const [update] = useUpdateMany(
    resource,
    selectedIds,
    { verified: true },
    {
      onFailure: () =>
        notify("Assicurati che i contrassegni siano incassati", "error"),
    }
  );
  const handleSubmit = () => {
    update();
    unselect();
    refetch();
  };

  return (
    <Button
      disabled={selectedIds.length <= 0}
      label="Verifica"
      onClick={handleSubmit}
    >
      <CheckIcon />
    </Button>
  );
};
