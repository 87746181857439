import { Button, ButtonProps, FormWithRedirect, SaveButton, useListContext, useNotify } from "react-admin";
import DescriptionIcon from "@material-ui/icons/Description";
import { client, clientNoJson } from "../../dataProvider";
import { useState } from "react";
import { url as URL } from "../../constants";
import { CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import { getUser } from "../../authProvider";
import { generateError } from "../../shared/utils";
import { useToggler } from "../../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";

export const ResetDeliveryStatusBulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const { value, setTrue, setFalse } = useToggler();

  const handleSubmit = async () => {
    setLoading(true);

    client(`deliveries/bulk_reset_status`, {
      method: "POST",
      data: {
        ids: selectedIds
      }
    }).then((response) => {
      notify("Missioni resettate");
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
        setFalse();
      })

      ;
    refetch();

  };

  return (
    <>
      <Button
        {...props}
        disabled={loading || selectedIds.length <= 0}
        label="Resetta stato"
        title="Resetta lo stato delle missioni selezionate"
        onClick={(e) => {
          e.stopPropagation();
          setTrue();
        }}
      >
        {loading ? <CircularProgress size={18} thickness={2} /> : <DescriptionIcon />}
      </Button >
      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          initialValues={{}}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <Typography>Sicuro di voler resettare gli stati delle missioni?</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                  title="Resetta"
                  label="Resetta"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
