import { Button, ButtonProps, useListContext, useNotify } from "react-admin";
import DescriptionIcon from "@material-ui/icons/Description";
import { client, clientNoJson } from "../../dataProvider";
import { useState } from "react";
import { url as URL } from "../../constants";
import { CircularProgress } from "@material-ui/core";
import { getUser } from "../../authProvider";
import { generateError } from "../../shared/utils";

export const UpdateStatusCanceledForcedBulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);

    client(`deliveries/set_delivery_canceled`, {
      method: "POST",
      data: {
        ids: selectedIds,
        force_modify: getUser().is_admin
      }
    }).then((response) => {
      notify("Missioni impostate come annullate");
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
      })

      ;
    refetch();

  };

  return (
    <Button
      {...props}
      disabled={loading || selectedIds.length <= 0}
      label="Forza annulla"
      title="Imposta forzatamente le missioni come annullate"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <DescriptionIcon />}
    </Button>
  );
};
