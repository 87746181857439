import { FC } from "react";
import { DatagridRowProps, DatagridRow } from "react-admin";
import { Draggable } from "react-beautiful-dnd";

export type DndDatagridRowProps = DatagridRowProps & {
  index?: number;
};
export const DndDatagridRow: FC<DndDatagridRowProps> = (props) => {
  const { style: mainStyle } = props;
  return (
    <Draggable draggableId={props.id as string} index={props.index!}>
      {(provided) => {
        const { style, ...rest } = provided.draggableProps;

        return (
          <DatagridRow
            {...props}
            ref={provided.innerRef}
            {...rest}
            {...provided.dragHandleProps}
            style={{ ...style, ...mainStyle }}
          />
        );
      }}
    </Draggable>
  );
};
