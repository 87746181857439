import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  Button,
  DateInput,
  FormWithRedirect,
  Identifier,
  SelectInput,
  TextInput,
  useCreate,
  useListContext,
  useNotify,
  useRedirect,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { generateError, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";

export const CreateRemittanceButtonList = () => {
  const { selectedIds, onSelect } = useListContext();
  return <CreateRemittanceButton ids={selectedIds} onSelect={onSelect} />;
};

export const CreateRemittanceButton = (props: any) => {
  const { ids, onSelect } = props;
  const { value, setFalse, setTrue } = useToggler();

  const [create, { loading }] = useCreate();
  const notify = useNotify();
  const handleSubmit = (values: any) => {
    create("remittances", values, {
      onSuccess: ({ data }) => {
        if (onSelect) {
          onSelect([])
        }
        setFalse();
        window.open("/remittances/" + data.id, "_blank");
        window.location.reload();
      },
      onFailure: (error) => {
        const errors = generateError(error, notify);
        notify(
          Object.keys(errors)
            .map((error) => errors[error])
            .join("\n"),
          "error"
        );
      },
    });
  };
  const { data: options } = useOptions("remittances", "GET");
  return options ? (
    <>
      <Button
        disabled={ids.length <= 0}
        label="Crea rimessa"
        onClick={() => setTrue()}
      ></Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          initialValues={{ cachet_payments: ids }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <DateInput {...initInput("remittance_date", options, true)} />
                <SelectInput
                  {...initInput("payment_type", options, true, "array")}
                  choices={options.payment_type.choices}
                />

                <SelectInput
                  {...initInput("type", options, true, "array")}
                  choices={options.type.choices}
                />
                <TextInput {...initInput("note", options, true)} />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={false}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
