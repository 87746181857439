import {
  Datagrid,
  FunctionField,
  Loading,
  NumberField,
  ReferenceArrayField,
} from "ra-ui-materialui";
import {
  Identifier,
  ListContextProvider,
  useList,
  useListContext,
  useQueryWithStore,
  useResourceContext,
  Record,
} from "react-admin";
import { DriverField } from "../../components/DriverField";
import { useGroupedListContext } from "../../components/GroupedList/GroupedListContext";
import { SubjectField } from "../../components/SubjectField";
import { CachetPaymentDatagrid } from "./CachetPayment/CachetPaymentDatagrid";
import { CachetDatagrid } from "./CachetDatagrid";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

interface CachetGroupedDatagridProps {
}
const Expand = ({
  record,
}: {
  basePath: string;
  id: Identifier;
  record: Record;
  resource: string;
}) => {

  const { data: options } = useOptionsResource("GET");
  return (
    <ReferenceArrayField
      reference="cachet-payments"
      record={record}
      source="payments_ids"
    >
      <CachetDatagrid options={options} />
    </ReferenceArrayField>
  );
};
export const CachetGroupedDatagrid = ({
}: CachetGroupedDatagridProps) => {
  const resource = useResourceContext();
  const { filterValues, filter } = useListContext();
  const { groupedBy } = useGroupedListContext();

  const { data, loading, loaded } = useQueryWithStore({
    type: "getGroupedBy",
    resource: resource,
    payload: { group_by: groupedBy, ...filterValues, ...filter },
  });
  const listContext = useList({
    data,
    ids: data ? data.map((d: any) => d.driver) : [],
    loaded,
    loading,
  });
  if (loading) return <Loading />;
  return (
    <ListContextProvider value={listContext}>
      <Datagrid>
        {groupedBy === "client" && (
          <SubjectField label="Cliente" source="client"></SubjectField>
        )}
        {groupedBy === "driver" && (
          <DriverField label="Autista" source="driver"></DriverField>
        )}
        {groupedBy === "carrier" && (
          <SubjectField label="Vettore" source="carrier"></SubjectField>
        )}
        <NumberField
          label="Tot. contanti"
          source="total_cash"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. assegno"
          source="total_check"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. abbuono"
          source="total_rebate"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. incassato"
          source="total_cashed"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>
        <NumberField
          label="Tot. da incassare"
          source="total_to_cash_in"
          options={{
            style: "currency",
            currency: "EUR",
          }}
        ></NumberField>

        {groupedBy && ["client", "driver", "carrier"].includes(groupedBy) && (
          <FunctionField
            label="Link"
            source="total_to_cash_in"
            render={(tableRecord: Record | undefined) => {
              if (!tableRecord)
                return ""
              let new_href = new URL(window.location.href);
              if (new_href.searchParams.get('group_by')) {
                new_href.searchParams.delete('group_by');
              }
              let j: any = {};
              let filter = new_href.searchParams.get("filter");
              if (filter) {
                j = JSON.parse(filter);
              }
              if (groupedBy === "driver") {
                j["driver"] = tableRecord.driver.id
              }
              if (groupedBy === "client") {
                j["client"] = tableRecord.client.id
              }
              if (groupedBy === "carrier") {
                j["carrier"] = tableRecord.carrier.id
              }
              new_href.searchParams.set("filter", JSON.stringify(j))
              return <Button
                style={{ lineHeight: 0.75 }}
                color="primary"
                component={Link}
                target={"_blank"}
                to={new_href.search}
                title="Link"
              >
                <Typography>Apri dettaglio</Typography>
              </Button>;
            }}
            sortable={false}
          />
        )}


      </Datagrid>
    </ListContextProvider>
  );
};
