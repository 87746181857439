import { Grid } from "@material-ui/core";
import { useCallback } from "react";
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  FormWithRedirect,
  FunctionField,
  NumberField,
  ReferenceInput,
  SelectField,
  TextField,
  TextInput,
} from "react-admin";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { SubjectField } from "../../components/SubjectField";
import { useOptions } from "../../shared/hooks/useOptions";
import { initInput, initField } from "../../shared/utils";
import {
  FormWithRedirectRenderProps,
  Record,
  useCreate,
  useDataProvider,
  useEditContext,
  useNotify,
  useRefresh,
} from "ra-core";
import Add from "@material-ui/icons/Add";
import { NumberInput } from "../../components/NumberInput";
interface AddDeliveryNoteRowFormProps {
  disabled?: boolean;
}
export const AddDeliveryNoteRowForm = (props: AddDeliveryNoteRowFormProps) => {
  const { disabled } = props;
  const { record } = useEditContext();
  const { data: options } = useOptions("delivery-note-rows", "GET");
  const [create] = useCreate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const onSubmit = useCallback(
    (values: Partial<Record>) => {
      create("delivery-note-rows", values, {
        onSuccess: () => {
          notify("ra.notification.created", "success");
          refresh();
        },
        onFailure: () => notify("ra.notification.bad_item", "error"),
      });
    },
    [create, notify, refresh]
  );

  return options ? (
    <FormWithRedirect
      save={onSubmit}
      initialValues={{ delivery: record?.id }}
      render={(formProps) => (
        <>

          <Grid spacing={2} container alignItems="center">
            <Grid item xs={5}>
              <TextInput
                {...initInput("description", options, disabled)}
              />
            </Grid>
            <Grid item xs={5}>
              <NumberInput
                helperText={false}
                {...initInput("quantity", options, disabled)}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={formProps.handleSubmit}
                label="Aggiungi"
                title="Aggiungi riga"
              >
                <Add />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    />
  ) : null;
};
