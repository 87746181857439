import { Button, ButtonProps, useNotify, useRecordContext } from "react-admin";
import PublishIcon from "@material-ui/icons/Print";
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition } from "../../shared/utils";
import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";

export const DownloadInvoicePdfButton = (
  props: ButtonProps
) => {
  const record = useRecordContext();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    let res = null;

    try {
      res = await clientNoJson(
        `${URL}/invoices/${record.id}/print_invoice_pdf/`,
        {
          method: "GET",
          responseType: "arraybuffer",
        }
      );
    } catch (err) {
      notify("ra.notification.http_error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  };

  return (
    <Button {...props}
      label="PDF fattura"
      onClick={handleSubmit}>
      {loading ? <CircularProgress size={18} thickness={2} /> : <PublishIcon />}
    </Button>
  );
};
