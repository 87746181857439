// botton crea riconsegna
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { useState } from "react";
import {
  useRecordContext,
  Button,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  SelectField,
  Record,
  TextField,
  useNotify,
  useRedirect,
  SelectInput,
  required,
  FormDataConsumer,
  BooleanField,
  BooleanInput,
  TextInput,
  DateInput,
} from "react-admin";
import { useToggler } from "../../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";
import { useOptions } from "../../shared/hooks/useOptions";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { SubjectField } from "../../components/SubjectField";
import { generateError, initField, initInput } from "../../shared/utils";
import { client } from "../../dataProvider";
import { TariffClientDatagrid } from "../../components/TariffClientDatagrid";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import AddIcon from '@material-ui/icons/Add';
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord';
import { DeliveryType } from "../../shared/types";

interface CloneDeliveryButtonProps {
  type: DeliveryType.NORMAL | DeliveryType.RETURN | DeliveryType.WITHDRAW;
  buttonLabel: string;
}
export const CloneDeliveryButton = (props: CloneDeliveryButtonProps & { partial?: boolean }) => {
  const { type } = props;
  const { value, setTrue, setFalse } = useToggler();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const onClick = (values: any) => {
    setLoading(true);
    if (props.partial === true) {
      values.make_partial = true
    }
    client(`deliveries/${record.id}/clone_delivery`, {
      method: "POST",
      data: values,
    })
      .then((data) => {
        redirect("edit", "/deliveries", data.id, data);
      })
      .catch((error) => {
        setLoading(false);
        generateError(error, notify);
      });
  };
  const [loading, setLoading] = useState(false);
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { data: options } = useOptions("deliveries", "GET");

  return record && options && optionsTariffs ? (
    <>
      {
        (
          record.status === "CA" ||
          record.status === "D" ||
          props.partial === true
        ) &&
        <Button
          disabled={!!!record?.client}
          onClick={(e) => {
            e.stopPropagation();
            setTrue();
          }}
          label={props.partial === true ? "Crea parziale" : "Crea figlia"}
        >
          {props.partial === true ? <FiberSmartRecordIcon /> : <AddIcon />}
        </Button>
      }

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          initialValues={{ type: type }}
          save={onClick}
          render={({ handleSubmitWithRedirect, form, saving }) => (
            <>
              <DialogContent>
                <SelectInput
                  source="type"
                  choices={options.delivery_type.choices}
                  optionText="display_name"
                  optionValue="value"
                  validate={[required()]}
                  label="Tipo"
                  fullWidth
                  onChange={(value: any) => {
                    if (value.target.value === DeliveryType.BACK && (record.delivery_type === DeliveryType.NORMAL || record.delivery_type === DeliveryType.RETURN)) {
                      form.change("new_sender", record.receiver);
                    }
                    else if (value.target.value === DeliveryType.BACK && record.delivery_type === DeliveryType.BACK) {
                      form.change("new_sender", record.sender);
                    }
                  }}
                />

                {
                  props.partial === true ?
                    <TextInput
                      source="partial_notes"
                      label="Note parziali"
                      isRequired={true}
                      fullWidth
                    />
                    : null
                }
                <BooleanInput
                  source="exclude_first_segment"
                  label="Escludi prima tratta"
                  fullWidth
                />
                {
                  form.change(
                    "exclude_first_segment", (
                      (form.getState().values?.type === DeliveryType.NORMAL && record.delivery_type === DeliveryType.WITHDRAW) ||
                      (form.getState().values?.type === DeliveryType.BACK && record.delivery_type === DeliveryType.WITHDRAW)
                    ) ? true : record.exclude_first_segment)
                }
                <BooleanInput
                  source="exclude_last_segment"
                  label="Escludi ultima tratta"
                  fullWidth
                  defaultValue={false}
                />
                {
                  form.change("exclude_last_segment", (
                    (form.getState().values?.type === DeliveryType.NORMAL && record.delivery_type === DeliveryType.WITHDRAW) ||
                    (form.getState().values?.type === DeliveryType.BACK && record.delivery_type === DeliveryType.WITHDRAW)
                  ) ? false : record.exclude_last_segment)
                }
                <BooleanInput
                  source="exclude_all_segment"
                  label="Escludi tutte le tratte"
                  fullWidth
                />
                <FormDataConsumer>
                  {({ formData }) => (
                    <ReferenceInputSubject
                      {...initInput(
                        "new_sender",
                        true
                      )}
                      required={(formData.type === DeliveryType.BACK || formData.type === DeliveryType.WITHDRAW) && formData.delivery_type !== DeliveryType.BACK}
                      label="Nuovo mittente"
                    />
                  )}
                </FormDataConsumer>
                <ReferenceInputSubject
                  {...initInput(
                    "new_receiver",
                    true
                  )}
                  filter={{ type: "receiver" }}
                  validate={required()}
                  label="Nuovo destinatario"
                />
                <FormDataConsumer>
                  {({ formData }) => (
                    <TextInput
                      source="new_document_number"
                      label="Nuovo numero documento"
                      fullWidth
                      required={(formData.type === DeliveryType.BACK || formData.type === DeliveryType.WITHDRAW) && formData.delivery_type !== DeliveryType.BACK}
                      defaultValue={formData.document_number}
                    />
                  )}
                </FormDataConsumer>
                <FormDataConsumer>
                  {({ formData }) => (
                    <DateInput
                      {...initInput(
                        "new_document_date",
                        true
                      )}
                      label="Nuova data documneto"
                      required={(formData.type === DeliveryType.BACK || formData.type === DeliveryType.WITHDRAW) && formData.delivery_type !== DeliveryType.BACK}
                      defaultValue={formData.document_date}
                    />
                  )}
                </FormDataConsumer>

                <FormDataConsumer>
                  {({ formData }) => (
                    <ReferenceInput
                      label="Nuova tariffa"
                      source="tariff"
                      reference="tariffs-client"
                      filter={{
                        client: record.client,
                        delivery_date: record.delivery_date,
                        delivery_type: formData.type,
                        delivery: record.id,
                        receiver: formData.new_receiver ? formData.new_receiver : record.receiver,
                        starting_point_on_delivery: record.starting_point_on_delivery
                      }}
                      isRequired={(formData.type === DeliveryType.BACK || formData.type === DeliveryType.WITHDRAW) && formData.delivery_type !== DeliveryType.BACK}
                      fullWidth
                      helperText={false}
                    >
                      <DialogSelectInput helperText={false} optionText="label">
                        <TariffClientDatagrid options={optionsTariffs} />
                      </DialogSelectInput>
                    </ReferenceInput>
                  )}
                </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
