import {
  BulkActionProps,
  BulkDeleteButton,
  DateInput,
  ListProps,
  SelectInput,
} from "ra-ui-materialui";
import { CustomList } from "../components/CustomList";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../shared/utils";
import { NumberInput } from "../components/NumberInput";
import { InvoiceDatagrid } from "./InvoiceDatagrid";
import { useOptions } from "../shared/hooks/useOptions";
import { TariffType } from "../shared/types";
import { InvoiceListHeader } from "./InvoiceListHeader";
import { RibaDatagrid } from "./RibaDatagrid";
import { PrintRibaPdfBulkButton } from "./components/PrintRibaPdfBulkButton";
import { ReferenceInputAccountCompany } from "../components/reference-inputs/ReferenceInputAccountCompany";
import { LoadRibaBulkButton } from "./components/LoadRibaBulkButton";
import { NullableBooleanInput } from "react-admin";

const BulkActionButtons = (props: BulkActionProps) => (
  <>
    {/* default bulk delete action */}
    <PrintRibaPdfBulkButton undoable={false} {...props} />
    <LoadRibaBulkButton undoable={false} {...props} />
  </>
);
export const RibaList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsTerms } = useOptions("payment-terms", "GET");

  const filters =
    options && optionsTerms
      ? [

        <ReferenceInputAccountCompany
          {...initField("company", options)}
          alwaysOn
        />,
        <DateInput source="invoice_date_after" label="Dal" />,
        <DateInput source="invoice_date_before" label="Al" />,
        <DateInput source="expiration_date_after" label="Scadenza dal" />,
        <DateInput source="expiration_date_before" label="Scadenza al" />,
        <NumberInput {...initField("sequence_number", options)} />,
        <NumberInput {...initField("id", options)} />,

        <ReferenceInputSubject
          filter={{ type: "client" }}
          {...initField("client", options)}
        />,
        <SelectInput
          choices={options.type.choices}
          {...initField("type", options, "array")}
        />,
        <SelectInput
          choices={options.status.choices}
          {...initField("status", options, "array")}
        />,
        <NullableBooleanInput 
          {...initField("loaded", options)}
          alwaysOn 
        />,
      ]
      : [];

  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      filter={{ "invoice_type": TariffType.SALE, "payment_method": "MP12" }}
      {...getDefaultListProps(props)}
      topElement={<InvoiceListHeader />}
      bulkActionButtons={<BulkActionButtons />}
      filterDefaultValues={{ company: 1, loaded: false }}
    >
      <RibaDatagrid
        options={options}
      />
    </CustomList>
  ) : null;
};
