import { Button, ButtonProps, useNotify, useRecordContext } from "react-admin";
import PublishIcon from "@material-ui/icons/Print";
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition } from "../../shared/utils";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";

export const PrintDocumentsButton = (props: ButtonProps & { mission_max_page: number }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);

    for (let i = 0; i < props.mission_max_page; i++) {
      let res = null;

      try {
        res = await clientNoJson(`${URL}/print_route_with_label/`, {
          method: "POST",
          data: {
            id: record.id,
            mission_max_page: i
          },
          responseType: "arraybuffer",
        });
      } catch (err) {
        notify("ra.message.error", "error");
        setLoading(false);
        return;
      }
      if (i === props.mission_max_page - 1) {
        setLoading(false);
      }
      const data = res.data; // or res.blob() if using blob responses

      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: res.headers["content-type"],
        })
      );
      const actualFileName = getFileNameFromContentDisposition(
        res.headers["content-disposition"]
      );

      // uses the download attribute on a temporary anchor to trigger the browser
      // download behavior. if you need wider compatibility, you can replace this
      // part with a library such as filesaver.js
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", actualFileName);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) link.parentNode.removeChild(link);
    }
  };

  return (
    <Button {...props} disabled={loading} title="Stampa bolle" onClick={handleSubmit}>
      {loading ? <CircularProgress size={18} thickness={2} /> : <PublishIcon />}
    </Button>
  );
};
