import { Button, ButtonProps, useNotify, useRecordContext } from "react-admin";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { clientNoJson } from "../../dataProvider";
import { url as URL } from "../../constants";
import { getFileNameFromContentDisposition } from "../../shared/utils";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";

export const PrintRouteRemittanceButton = (props: ButtonProps) => {
  const record = useRecordContext();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;

    try {
      res = await clientNoJson(`${URL}/print_route_remittance/`, {
        method: "POST",
        data: { id: record.id },
        responseType: "arraybuffer",
      });
    } catch (err: any) {
      try {
        notify(new TextDecoder().decode(err.response.data), "error");
      } catch (error) {
        notify("ra.message.error", "error");
      }
      setLoading(false);
      return;
    }
    setLoading(false);
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
  };

  return (
    <Button
      {...props}
      disabled={loading}
      title="Rimessa giro"
      onClick={handleSubmit}
    >
      {loading ? (
        <CircularProgress size={18} thickness={2} />
      ) : (
        <LocalAtmIcon />
      )}
    </Button>
  );
};
