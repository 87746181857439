import { BooleanField, BooleanInput, Datagrid, ListProps, NullableBooleanInput, ReferenceField, TextField, TextInput } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { SubjectField } from "../../components/SubjectField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";

export const UserCarrierList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");

  const filters = options
    ? [
      <NullableBooleanInput {...initField("is_active", options)} />
    ]
    : [];

  return options ? (
    <CustomList
      filters={filters}
      {...getDefaultListProps(props)}
      filterDefaultValues={{ is_active: true }}
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        <TextField {...initField("username", options)} />
        <SubjectField {...initField("carrier_object", options)} />
        <BooleanField {...initField("is_active", options)} />
      </Datagrid>
    </CustomList>
  ) : null;
};
