import { EditProps } from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { ChartAccountsTagsForm } from "./ChartAccountsTagsForm";

export const ChartAccountsTagsEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component="div">
      <ChartAccountsTagsForm />
    </DefaultEdit>
  );
};
