import { Datagrid, NumberField, SelectField, TextField } from "react-admin";
import { ChartAccountsField } from "../../components/ChartAccountsField";
import { DriverField } from "../../components/DriverField";
import { IdentifierField } from "../../components/IdentifierField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";

export const ChartAccountsDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <ChartAccountsField {...initField("main_info", options)} />
      <TextField {...initField("code", options)} />
      <TextField {...initField("name", options)} />
    </Datagrid>
  );
};
