import { Button, ButtonProps, useListContext, useNotify } from "react-admin";
import LocalAtm from "@material-ui/icons/LocalAtm";
import { client, clientNoJson } from "../../dataProvider";
import { useState } from "react";
import { url as URL } from "../../constants";
import { CircularProgress } from "@material-ui/core";
import { generateError } from "../../shared/utils";

export const ReloadDeliveryItemBulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);

    client(`deliveries/reload_delivery_items`, {
      method: "POST",
      data: { ids: selectedIds }
    }).then((response) => {
      let errors = "";
      for (let k in response.errors) {
        errors += k + " -> " + response.errors[k] + "\n "
      }
      if (errors !== "") {
        window.alert(errors);
      }
      else {
        notify("Missioni aggiornate");
      }

    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
      })

      ;
    refetch();

  };

  return (
    <Button
      {...props}
      disabled={loading || selectedIds.length <= 0}
      label="Ricalcola"
      title="Ricalcola le tariffe asociate alla missione"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <LocalAtm />}
    </Button>
  );
};
