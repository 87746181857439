import { BooleanField, BooleanInput, Datagrid, ListProps, TextField, TextInput } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";

export const CustomerSubjectList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");

  const filters = options
    ? [
      <TextInput {...initField("id", options)} />,
      <TextInput {...initField("business_name", options)} />,
      <TextInput {...initField("address", options)} />,
      <TextInput {...initField("locality", options)} />,
      <TextInput {...initField("province", options)} />,
    ]
    : [];

  return options ? (
    <CustomList
      filters={filters}
      {...getDefaultListProps(props)}
      filter={{ hide: false }}
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        {
          props.permissions.includes("registry.change_subject") &&
          <BooleanField {...initField("hide", options)} />
        }

        <TextField {...initField("business_name", options)} />
        <TextField {...initField("address", options)} />
        <TextField {...initField("locality", options)} />
        <TextField {...initField("province", options)} />
      </Datagrid>
    </CustomList>
  ) : null;
};
