import { Button, ButtonProps } from "ra-ui-materialui";
import CheckCircle from "@material-ui/icons/CheckCircle";
import IconCancel from "@material-ui/icons/Cancel";
import { useToggler } from "../../shared/hooks/useToggler";
import { Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import { DateInput, FormWithRedirect, useCreate, useNotify } from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { clientNoJson } from "../../dataProvider";

export const AdditionalActions = (props: ButtonProps) => {
  const { value, setTrue, setFalse } = useToggler();

  const [create, { loading }] = useCreate();

  const notify = useNotify();

  const handleSubmit = (values: any) => {
    clientNoJson(`api/delivery_mergers/compare_export/`, {
      method: "POST",
      data: values,
      responseType: "arraybuffer",
    }).then(
      ({ data }) => {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/ms-excel",
          })
        );
        const actualFileName = `export_comparazione.xlsx`

        // uses the download attribute on a temporary anchor to trigger the browser
        // download behavior. if you need wider compatibility, you can replace this
        // part with a library such as filesaver.js
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", actualFileName);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) link.parentNode.removeChild(link);

      }

    ).catch(error => {
      console.error(error);
      notify('ra.notification.http_error', { type: 'warning' });
    })
  };


  return (
    <>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="delivery_mergers"
          save={handleSubmit}
          initialValues={{
          }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <Typography variant="h6">Compara periodi</Typography>
                <DateInput source="start_1" fullWidth label={"Periodo 1 da:"} required />
                <DateInput source="end_1" fullWidth label={"Periodo 1 a:"} required />
                <DateInput source="start_2" fullWidth label={"Periodo 2 da:"} required />
                <DateInput source="end_2" fullWidth label={"Periodo 2 a:"} required />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Crea report"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
      <Button {...props} label="Compara periodi" onClick={setTrue}>
        <CheckCircle />
      </Button>
    </>

  );
};
