import { FormApi } from "final-form";
export const updateTaxableForm = (
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>,
  sourceQuantity: string,
  sourcePrice: string,
  sourceVat: string,
  sourceTaxable: string,
  sourceAmount: string
) => {
  const quantity = form.getFieldState(sourceQuantity)?.value;
  const price = form.getFieldState(sourcePrice)?.value;
  const vat = form.getFieldState(sourceVat)?.value;
  if (quantity && price) {
    const total = quantity * price;
    const amount = (vat * total) / 100 + total;
    form.change(sourceTaxable, total);
    form.change(sourceAmount, amount);
  }
};
