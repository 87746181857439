import {
  useTheme,
  Box,
  IconButton,
  Typography,
  TableContainer,
} from "@material-ui/core";
import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import {
  Datagrid,
  NumberField,
  Record,
  RecordContextProvider,
  TextField,
} from "react-admin";
import { CustomList, CustomListProps } from "../../components/CustomList";
import { getNoteImage, saveNoteToFile } from "../../Distribution/Note/utils";
import { usePanzoom } from "../../shared/hooks/usePanzoom";
import { useSize } from "../../shared/hooks/useSize";
import {
  dateFormatter,
  subtractMonth,
  getDefaultListProps,
} from "../../shared/utils";
import { ClientGroupedSimpleChargeDatagrid } from "./ClientGroupedSimpleChargeDatagrid";
import NavigateNext from "@material-ui/icons/SkipNext";
import NavigatePrev from "@material-ui/icons/SkipPrevious";
import GetAppIcon from "@material-ui/icons/GetApp";
import Close from "@material-ui/icons/Close";
import { DeliveryField } from "../../components/DeliveryField";
import { SubjectField } from "../../components/SubjectField";

const beforeDate = dateFormatter(new Date());
const afterDate = dateFormatter(subtractMonth(new Date(), 1));

export const SimpleChargesList = (
  props: Omit<CustomListProps, "children"> & { Actions: React.ElementType }
) => {
  const [activeCharge, setActiveCharge] = useState<Record>();

  const { Actions, ...rest } = props;

  var appbarMain = document.getElementById("appbar-main");

  if (appbarMain) {
    appbarMain.style.display = "none"
  }

  return (
    <CustomList
      {...getDefaultListProps(rest)}
      sort={{ field: "charge_date", order: "ASC" }}
      perPage={0}
      pagination={false}
      filterDefaultValues={{
        charge_date_before: beforeDate,
        clients: [3]
      }}
    >
      <ClientGroupedSimpleChargeDatagrid
        Actions={Actions}
        activeCharge={activeCharge}
        setActiveCharge={setActiveCharge}
      />
    </CustomList>
  );
};
