import {
  TextField,
  ReferenceField,
  DeleteButton,
  FunctionField,
  Record,
  NumberField,
  ArrayField,
  Datagrid,
  SelectField,
} from "react-admin";
import {
  EditableDatagrid,
  EditableNumberField,
} from "../../components/EditableDatagrid";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";
import { SaveButtonDatagrid } from "../Cachet/SaveButtonDatagrid";
import { FormApi } from "final-form";
import { makeStyles, Typography } from "@material-ui/core";
import { EditableTextField } from "../../components/EditableDatagrid/EditableTextField";

const useStyles = makeStyles((theme) => ({
  headerCell: {
    backgroundColor: "white",
    color: theme.palette.secondary.dark,
    borderBottom: "1px solid rgba(224, 224, 224, 1)!important",
  },
  row: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
}));
export const DeliveryNoteRowDatagrid = ({
  options,
  updateRows,
  ...rest
}: DatagridPropsWithOptions & { updateRows: () => void }) => {
  const classes = useStyles();
  return options ? (
    <EditableDatagrid rowClick={undefined} {...rest}>
      <TextField {...initField("id", options)} />

      <EditableTextField
        alwaysEditable
        getInputProps={(
          record: Record,
          form: FormApi<
            globalThis.Record<string, any>,
            Partial<globalThis.Record<string, any>>
          >
        ) => ({
          style: { minWidth: "4em" },
          endAdornment: (
            <>
              <SaveButtonDatagrid callback={updateRows} />
            </>
          ),
        })}
        {...initField("description", options)}
      />

      <EditableNumberField
        alwaysEditable
        getInputProps={(
          record: Record,
          form: FormApi<
            globalThis.Record<string, any>,
            Partial<globalThis.Record<string, any>>
          >
        ) => ({
          style: { minWidth: "4em" },
          endAdornment: (
            <>
              <SaveButtonDatagrid callback={updateRows} />
            </>
          ),
        })}
        {...initField("quantity", options)}
      />

      <FunctionField
        render={(record: any) => (
          <DeleteButton
            record={record}
            resource="delivery-note-rows"
            redirect={`/deliveries/${record.delivery}`}
            mutationMode="pessimistic"
          />
        )}
      />
    </EditableDatagrid>
  ) : <></>;
};
