import { Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { CustomList, CustomListProps } from "../CustomList";
import {
  GroupedListProvider,
  useGroupedListContext,
} from "./GroupedListContext";
import ViewListIcon from "@material-ui/icons/ViewList";
import { Button, useResourceContext, useUnselectAll } from "react-admin";
export type PossibleGroupings = {
  label: string;
  source: string;
  component: React.ReactElement;
}[];
interface GroupedListProps extends CustomListProps {
  defaultGroupedBy?: string;
  children: React.ReactElement;
  possibleGroupings: PossibleGroupings;
}

const ChangeGroupingButton = () => {
  const { groupedBy, changeGrouping, possibleGroupings } =
    useGroupedListContext();

  const unSelectAll = useUnselectAll();
  const resource = useResourceContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: string
  ) => {
    var new_href = new URL(window.location.href);
    if (new_href.searchParams.get("group_by") !== index) {
      new_href.searchParams.set('group_by', index);
      window.location.href = new_href.toString();
    }
    changeGrouping!(index === "" ? undefined : index);
    setAnchorEl(null);
    resource && unSelectAll(resource);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        aria-haspopup="true"
        aria-controls="lock-menu"
        variant="text"
        color="primary"
        onClick={handleClickListItem}
        label={
          "Raggruppa per: " +
          (groupedBy
            ? possibleGroupings
              .find((group) => group.source === groupedBy)!
              .label.toUpperCase()
            : "Disabilitato")
        }
      >
        <ViewListIcon />
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          selected={!groupedBy}
          onClick={(event) => handleMenuItemClick(event, "")}
        >
          Disabilitato
        </MenuItem>
        {possibleGroupings.map((option) => (
          <MenuItem
            key={option.source}
            selected={option.source === groupedBy}
            onClick={(event) => handleMenuItemClick(event, option.source)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
const GroupedDatagrid = (
  props: CustomListProps & {
    possibleGroupings: PossibleGroupings;
  }
) => {
  const { children, possibleGroupings, ...rest } = props;
  const { groupedBy } = useGroupedListContext();
  return (
    <CustomList
      {...rest}
      {...(groupedBy ? { pagination: false } : {})}
      additionalActions={
        <>
          {rest.additionalActions}
          <ChangeGroupingButton />
        </>
      }
    >
      {groupedBy
        ? possibleGroupings.find((group) => group.source === groupedBy)!
          .component
        : children}
    </CustomList>
  );
};
export const GroupedList = (props: GroupedListProps) => {
  const { defaultGroupedBy, possibleGroupings, ...rest } = props;

  return (
    <GroupedListProvider
      possibleGroupings={possibleGroupings}
      defaultGroupedBy={
        (new URL(window.location.href).searchParams.get("group_by")) ? (
          new URL(window.location.href).searchParams.get("group_by")?.toString()) :
          props.defaultGroupedBy
      }
    >
      <GroupedDatagrid possibleGroupings={possibleGroupings} {...rest} />
    </GroupedListProvider>
  );
};
