import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Link, makeStyles } from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover(props: {
  content: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<
    EventTarget & HTMLSpanElement
  >();

  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Link
        aria-describedby={id}
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <VisibilityIcon />
      </Link>
      <Popover
        onClick={(event) => event.stopPropagation()}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Typography className={classes.typography}>{props.content}</Typography>
      </Popover>
    </>
  );
}
