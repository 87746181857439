import {
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  Record,
  FunctionField,
  SelectField,
  ArrayField,
  ChipField,
  SingleFieldList,
} from "react-admin";
import { DriverField } from "../../components/DriverField";
import { CarrierField } from "../../components/CarrierField";
import { VehicleField } from "../../components/VehicleField";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";
import { GoToDeliveriesButton } from "../../components/GoToDeliveriesButton";
import { GoToCachetsButton } from "../../components/GoToCachetsButton";
import { DownloadFileButton } from "../../components/DownloadFileButton";
import { IdentifierField } from "../../components/IdentifierField";
import React from "react";
import { GoToLoadDeliveriesButton } from "../../components/GoToLoadDeliveriesButton";

export const LoadRouteDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <TextField {...initField("id", options)} />
      <SelectField
        choices={options.status.choices}
        {...initField("status", options, "array")}
      />
      <TextField {...initField("device", options)} />
      <NumberField {...initField("total_distance", options)} />
      <DateField {...initField("planned_date", options)} showTime />
      <BooleanField {...initField("sent", options)} />
      <DriverField {...initField("driver", options)} />
      <CarrierField {...initField("carrier", options)} />
      <VehicleField {...initField("vehicle", options)} />
      <GoToLoadDeliveriesButton filterAttribute={"route"} label="Carica missioni" />
      <GoToDeliveriesButton filterAttribute={"route"} label="Vedi Missioni" />
      {/*
      <ArrayField source="zones" label="Zone">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" record={{ id: record }} source="id" />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      */}
      <ArrayField source="tags_objects" label="Tag">
        <SingleFieldList>
          <FunctionField
            render={(record: any) => (
              <ChipField size="small" source="label" />
            )}
          />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  );
};
