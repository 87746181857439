import {
  ButtonProps,
  Toolbar,
  ToolbarProps,
  useMutation,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import ContentSave from "@material-ui/icons/Save";

import { useFormState } from "react-final-form";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { cloneElement } from "react";
const useStyles = makeStyles(
  (theme) => ({
    button: {
      position: "relative",
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: "RaSaveButton" }
);
const XMLFileUploadSaveButton = (props: ButtonProps) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  const formState = useFormState();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [approve, { loading }] = useMutation(
    {
      type: "uploadXMLFiles",
      resource: "xml_invoices_imports",
      payload: formState.values,
    },
    {
      onFailure: (error) => {
        error && notify(error.message, "warning");
      },
      onSuccess: () => {
        notify("ra.notification.created", "info");
        redirectTo("xml_invoices_imports");
      },
    }
  );

  const handleSave = () => {
    approve();
  };

  const label = props.label ? props.label : "ra.action.save";
  const displayedLabel = label && translate(label, { _: label });

  return (
    <Button
      className={classnames(classes.button, props.className)}
      variant={"contained"}
      onClick={handleSave}
      color={loading ? "default" : "primary"}
      aria-label={displayedLabel}
      disabled={formState.pristine}
    >
      {loading ? (
        <CircularProgress
          size={18}
          thickness={2}
          className={classes.leftIcon}
        />
      ) : (
        cloneElement(<ContentSave />, {
          className: classnames(classes.leftIcon, classes.icon),
        })
      )}
      {displayedLabel}
    </Button>
  );
};

export const XMLFileUploadCreateToolbar = (props: ToolbarProps) => {
  return (
    <Toolbar {...props}>
      <XMLFileUploadSaveButton />
    </Toolbar>
  );
};
