import { Card, CardContent, CardHeader, Grid, Modal, Typography } from "@material-ui/core";
import { getUser } from "../authProvider";


import { useEffect, useState, useCallback, useRef } from "react";
import { QrReader } from "react-qr-reader";
import { de } from "date-fns/locale";
import dataprovider, { client } from "../dataProvider";
import Webcam from "react-webcam";
import { Button, useNotify, useRedirect } from "react-admin";

import ReplayIcon from '@material-ui/icons/Replay';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import SendIcon from '@material-ui/icons/Send';

const videoConstraints = {
  width: 800,
  height: 1200,
  facingMode: "environment",
  peerIdentity: "user"
};

const WebcamCapture = (props: { imgSrc: any, setImgSrc: any }) => {
  const webcamRef = useRef<any>(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    props.setImgSrc(imageSrc);
  }, [webcamRef, props.setImgSrc,]);

  return (
    <div>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={videoConstraints}
        minScreenshotHeight={1200}
        minScreenshotWidth={800}
        forceScreenshotSourceSize={true}
        disablePictureInPicture={true}
        imageSmoothing={true}
        mirrored={false}
        onUserMediaError={(error: string | DOMException) => window.alert(error)}
        style={{ width: "200px", height: "200px" }}
      />
      <button onClick={capture} style={{ marginRight: "20px" }}><PhotoCameraIcon fontSize={"large"} /></button>

      {props.imgSrc && <img src={props.imgSrc} alt="img" width={200} height={300} />}

    </div>
  );
};

interface LocationOptions {
  enableHighAccuracy?: boolean;
  timeout?: number;
  maximumAge?: number;
}

interface LocationState {
  coords: {
    latitude: number | null;
    longitude: number | null;
    accuracy: number | null;
    altitude: number | null;
    altitudeAccuracy: number | null;
    heading: number | null;
    speed: number | null;
  };
  locatedAt: number | null;
  error: string | null;
}

const useLocation = (options: LocationOptions = {}) => {
  const [location, setLocation] = useState<LocationState>({
    coords: {
      latitude: null,
      longitude: null,
      accuracy: null,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      speed: null,
    },
    locatedAt: null,
    error: null,
  });

  useEffect(() => {
    // Ensuring this runs only in a client-side environment
    if (typeof window === "undefined" || !("geolocation" in navigator)) {
      setLocation((prevState) => ({
        ...prevState,
        error:
          "Geolocation is not supported by your browser or not available in the current environment",
      }));
      return;
    }

    const handleSuccess = (position: GeolocationPosition) => {
      setLocation({
        coords: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
          altitude: position.coords.altitude,
          altitudeAccuracy: position.coords.altitudeAccuracy,
          heading: position.coords.heading,
          speed: position.coords.speed,
        },
        locatedAt: position.timestamp,
        error: null,
      });
    };

    const handleError = (error: GeolocationPositionError) => {
      setLocation((prevState) => ({
        ...prevState,
        error: error.message,
      }));
    };

    const geoOptions = {
      enableHighAccuracy: options.enableHighAccuracy || false,
      timeout: options.timeout || Infinity,
      maximumAge: options.maximumAge || 0,
    };

    const watcher = navigator.geolocation.watchPosition(
      handleSuccess,
      handleError,
      geoOptions,
    );

    return () => navigator.geolocation.clearWatch(watcher);
  }, [options.enableHighAccuracy, options.timeout, options.maximumAge]);

  return location;
};


export const QrScanner = (props: any) => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const delivery = urlParams.get('delivery');

  const { permissions } = props;

  const [scanActive, setScanActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastDelivery, setLastDelivery] = useState<string | null>(null)

  const notify = useNotify();

  const location = useLocation()

  const [imgSrc, setImgSrc] = useState<any>(null);

  const resetView = () => {
    window.location.href = "/qr_scanner/"
  }

  const sendImage = useCallback(() => {

    if (imgSrc === null) {
      notify("Selezionare una immagine", "error")
      return
    }
    if (delivery === null) {
      notify("Selezionare una missione", { type: "error" })
      return
    }
    if (!location.coords.latitude || !location.coords.longitude) {
      notify("Attendere posizone GPS", { type: "error" })
      return
    }
    setLoading(true)
    dataprovider("/api").create("notes", {
      data: {
        file: imgSrc.toString(),
        config_name: "",
        final: true,
        delivery: parseInt(delivery.replace("S.", "")),
        origin: "D",
        lat_lon: location.coords.latitude + "," + location.coords.longitude
      }
    })
      .then((ret: any) => {
        notify("Immagine inviata", { type: "success" })
        setLastDelivery(delivery)
        resetView()
      })
      .catch((e: any) => {
        console.log(e)
      }).finally(() => setLoading(false))
  }, [imgSrc, delivery, location.coords.latitude, location.coords.longitude]);


  return (
    <>
      <div style={{
        visibility: loading ? "visible" : "hidden",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: 1000,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}>
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
          <Typography variant="h3" style={{ color: "white" }}>Caricamento...</Typography>
        </div>
      </div>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Grid item spacing={2} alignItems="center" justifyContent="center">
            <Typography
              variant="h5"
              align="center">
              Qr Scanner <button><ReplayIcon onClick={() => resetView()} /></button>
            </Typography>
          </Grid>
          {lastDelivery && <Grid item spacing={2} alignItems="center" justifyContent="center">
            <Typography
              variant="body1"
              align="center">
              Ultima missione fatta : {lastDelivery}
            </Typography>
          </Grid>
          }
          <Grid container item spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={6}>
              <Typography
                variant="h6"
                align="center"
                color={location.coords.latitude && location.coords.longitude ? "initial" : "error"} >
                {location.coords.latitude && location.coords.longitude ? "GPS OK" : "ATTESA GPS"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                align="center"
                color={delivery ? "initial" : "error"} >
                {delivery ? "Missione: " + delivery : "NO missione"}
              </Typography>
            </Grid>

          </Grid>
          <div>
            <div style={{ transform: "translate(50%, 0%)", height: "200px", width: "200px", margin: "0px 20px 0px 20px", textAlign: "center" }}>

              {
                delivery === null ? (<QrReader
                  onResult={(result: any, error: any) => {
                    if (scanActive === false)
                      return
                    if (result && result.text) {
                      setLoading(true)
                      client(`./deliveries/qrscanner/?qr=${result.text}`, {
                        method: "GET",
                      })
                        .then(() => {
                          window.location.href = "/qr_scanner/?delivery=" + result.text
                          /*
                          setDelivery(result.text)
                          setScanActive(false)
                          setShowQrScanner(false)
                          setImgSrc(null)
                          */
                        })
                        .catch((e: any) => {
                          notify("Errore", { type: "error" });
                        })
                        .finally(() => setLoading(false));
                    }
                  }}
                  constraints={{ facingMode: 'environment' }}

                />) : (<WebcamCapture imgSrc={imgSrc} setImgSrc={setImgSrc} />)
              }
              {
                imgSrc &&
                <><button onClick={sendImage} style={{ marginLeft: "20px" }}><SendIcon fontSize={"large"} /></button></>
              }

            </div>

          </div>

        </Grid>

      </Grid>
    </>

  );
};
