import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { CreateInvoiceForm } from "./CreateInvoiceForm";

export const InvoiceCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props}>
      <CreateInvoiceForm />
    </CustomCreate>
  );
};
