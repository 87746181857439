import { Box, Card, makeStyles } from "@material-ui/core";
import {
  DeleteWithConfirmButton,
  EditProps,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useEditContext,
  useRecordContext,
} from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { DownloadXmlButton } from "../components/DownloadXmlButton";
import { InvoiceForm } from "./InvoiceForm";
const useStyles = makeStyles((theme) => ({
  toolbar: { display: "flex", justifyContent: "flex-end" },
}));

const CustomToolbar = (props: ToolbarProps) => {
  const classes = useStyles();
  const record = useRecordContext();
  const { refetch } = useEditContext();
  return record ? (
    <Toolbar {...props} classes={classes}>
      <>
        <Box display="flex" flex="1">
          <DownloadXmlButton onSuccess={refetch!} />
        </Box>
      </>
      {record && record.status === "C" ? null : <SaveButton />}
      {record && record.status === "C" ? null : (
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      )}
    </Toolbar>
  ) : null;
};

export const InvoiceEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} component={Card}>
      <InvoiceForm toolbar={<CustomToolbar />} />
    </DefaultEdit>
  );
};
