import {
  AutocompleteInput,
  DateInput,
  ListProps,
  ReferenceInput,
  SelectInput,
} from "ra-ui-materialui";
import { DateTimeInput } from "react-admin";
import { GroupedList } from "../../components/GroupedList/GroupedList";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../shared/hooks/useOptions";
import { getDefaultListProps, initField } from "../../shared/utils";
import { GroupedDatagrid } from "./GroupedDatagrid";
import { InvoiceChartAccountsGroupedDatagrid } from "./InvoiceChartAccountsGroupedDatagrid";
import { TariffType } from "../../shared/types";


export const InvoiceChartAccountsGroupedList = (props: ListProps) => {

  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const { data: options } = useOptions("invoicechartaccounts", "GET");

  const filters = options && optionsTariff && [
    <DateInput
      {...initField("invoice_date_after", options)}
      label="Data fattura dal"
    />,
    <DateInput
      {...initField("invoice_date_before", options)}
      label="Data fattura fino al"
    />,
  ];

  return (
    <GroupedList
      defaultGroupedBy="chart_accounts"
      possibleGroupings={[
        {
          label: "Piano dei conti",
          source: "chart_accounts",
          component: <GroupedDatagrid />,
        },
        {
          label: "Piano dei conti principale",
          source: "main_chart_accounts",
          component: <GroupedDatagrid />,
        },
        {
          label: "Piano dei conti per sede",
          source: "site_main_chart_accounts",
          component: <GroupedDatagrid />,
        }
      ]}
      sort={{ field: "chart_accounts", order: "DESC" }}
      filters={filters}
      filter={{ "invoice_type": TariffType.PURCHASE }}
      {...getDefaultListProps(props)}
      topElement={<></>}
    >
      <InvoiceChartAccountsGroupedDatagrid />
    </GroupedList>
  );
};
