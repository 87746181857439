import { ListProps, SelectInput, TextInput } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";
import { ChartAccountsDatagrid } from "./ChartAccountsDatagrid";

export const ChartAccountsList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput {...initField("code", options)} />,
      <TextInput {...initField("name", options)} />,
    ]
    : [];
  return options ? (
    <CustomList
      filters={filters}
      filterDefaultValues={{ active: true }}
      {...getDefaultListProps(props)}>
      <ChartAccountsDatagrid options={options} />
    </CustomList>
  ) : null;
};
