import { Box, Dialog, DialogTitle, TableContainer } from "@material-ui/core";
import { ListBase, Record, useListContext } from "ra-core";
import {
  Datagrid,
  FilterButton,
  ListToolbar,
  Pagination,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { useOptions } from "../../shared/hooks/useOptions";
import { initField } from "../../shared/utils";

interface DriverDialogProps {
  onClose?: () => void;
  open: boolean;
  onSelect: (value: Record | undefined) => void;
}

const DatagridDriver = (props: {
  onSelect: (value: Record | undefined) => void;
  onClose?: () => void;
  options: any;
}) => {
  const { data } = useListContext();
  return (
    <Datagrid
      onToggleItem={(id) => {
        props.onSelect(data[id]);
        props.onClose && props.onClose();
      }}
      rowClick="toggleSelection"
    >
      <TextField {...initField("surname", props.options)} />
      <TextField {...initField("name", props.options)} />
      <TextField {...initField("license", props.options)} />
      <TextField {...initField("device", props.options)} />
      <TextField {...initField("fuel_card_number", props.options)} />
      <SelectField
        {...initField("status", props.options, "array")}
        choices={props.options.status.choices}
      />
    </Datagrid>
  );
};

export const DriverDialog = (props: DriverDialogProps) => {
  const { data: options } = useOptions("employees", "GET");

  return options ? (
    <Dialog onClose={props.onClose} open={props.open} fullWidth maxWidth="xl">
      <DialogTitle>Seleziona autista</DialogTitle>
      {options && (
        <ListBase
          resource="employees"
          syncWithLocation={false}
          basePath="/employees"
          perPage={100}
          sort={{ field: "surname", order: "ASC" }}
          filterDefaultValues={{ status: "D" }}
          filter={{ departments: "Autista" }}
        >
          <Box padding={2}>
            <ListToolbar
              filters={[
                <TextInput alwaysOn {...initField("name", options)} />,
                <TextInput alwaysOn {...initField("surname", options)} />,
                <SelectInput
                  choices={options.status.choices}
                  {...initField("status", options, "array")}
                  alwaysOn
                />,
              ]}
              actions={
                <TopToolbar>
                  <FilterButton />
                </TopToolbar>
              }
            />
          </Box>
          <TableContainer>
            <DatagridDriver
              onClose={props.onClose}
              onSelect={props.onSelect}
              options={options}
            />
            <Pagination />
          </TableContainer>
        </ListBase>
      )}
    </Dialog>
  ) : null;
};
