import React from "react";
import { Button } from "react-admin";
import IconCancel from "@material-ui/icons/Cancel";

export const CancelButton = (props: {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
}) => {
  return (
    <Button
      label="ra.action.cancel"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <IconCancel />
    </Button>
  );
};
