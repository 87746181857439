import {
  TextField,
  ReferenceField,
  DeleteButton,
  FunctionField,
  Record,
  NumberField,
  ArrayField,
  Datagrid,
  SelectField,
  useNotify,
} from "react-admin";
import {
  EditableDatagrid,
  EditableNumberField,
} from "../../components/EditableDatagrid";
import { DatagridPropsWithOptions } from "../../shared/types";
import { generateError, initField } from "../../shared/utils";
import { FormApi } from "final-form";
import { makeStyles, Typography } from "@material-ui/core";
import { SaveButtonDatagrid } from "../../Distribution/Cachet/SaveButtonDatagrid";

const useStyles = makeStyles((theme) => ({
  headerCell: {
    backgroundColor: "white",
    color: theme.palette.secondary.dark,
    borderBottom: "1px solid rgba(224, 224, 224, 1)!important",
  },
  row: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
}));
export const
  CustomerOrderItemDatagrid = ({
    options,
    updateRevenues,
    ...rest
  }: DatagridPropsWithOptions & { updateRevenues: () => void }) => {
    const classes = useStyles();
    const notify = useNotify();
    return (
      <EditableDatagrid rowClick={undefined} {...rest}>

        <FunctionField
          label={"Tariffa"}
          source="tariff_complete"
          render={(record: any) => {
            return record ? `${record.tariff_complete.short_label}` : null
          }

          }
        />

        <EditableNumberField
          alwaysEditable
          getInputProps={(
            record: Record,
            form: FormApi<
              globalThis.Record<string, any>,
              Partial<globalThis.Record<string, any>>
            >
          ) => ({
            style: { minWidth: "4em" },
            endAdornment: (
              <>
                <SaveButtonDatagrid callback={updateRevenues} />
              </>
            ),
          })}
          {...initField("quantity", options)}
        />
        {/* 
        <FunctionField
          label="Prezzo"
          render={(record: any) => {
            if (record.tariff_complete.pricerange_set.length <= 0)
              return <NumberField source="tariff_complete.price" />;
            return (
              <ArrayField source="tariff_complete.pricerange_set">
                <Datagrid classes={classes}>
                  <NumberField source="limit" label="A partirte da" />
                  <SelectField
                    label="Tipo"
                    source="type"
                    choices={[
                      { name: "Moltiplicatore", id: "M" },
                      { name: "Forfettario", id: "F" },
                    ]}
                  />
                  <NumberField source="grouping" label="Raggruppa per" />
                </Datagrid>
              </ArrayField>
            );
          }}
        />
        */}

        <FunctionField
          label={"Elimina"}
          render={(record: any) => (
            <DeleteButton
              record={record}
              resource="customer_order_items"
              redirect={`/customer_orders/${record.order}`}
              mutationMode="pessimistic"
              onFailure={(error: any) => {
                generateError(error, notify)
              }}
            />
          )}
        />
      </EditableDatagrid>
    );
  };
