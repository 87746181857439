// botton crea riconsegna
import { Dialog, DialogContent, DialogActions, Typography } from "@material-ui/core";
import { useState } from "react";
import {
  useRecordContext,
  Button,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  useNotify,
  useRedirect,
} from "react-admin";
import { useToggler } from "../../shared/hooks/useToggler";
import IconCancel from "@material-ui/icons/Cancel";
import { useOptions } from "../../shared/hooks/useOptions";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { client } from "../../dataProvider";
import { generateError } from "../../shared/utils";


export const UndoStockSuspendDeliveryStatusButton = () => {
  const { value, setTrue, setFalse } = useToggler();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const onClick = (values: any) => {
    setLoading(true);

    client(`deliveries/${record.id}/undo_stock_and_suspend`, {
      method: "POST",
      data: values,
    })
      .then((data) => {
        window.location.reload();
        setFalse();
      })
      .catch((error) => {
        setLoading(false);
        generateError(error, notify);
      });
  };
  const [loading, setLoading] = useState(false);
  const { data: optionsTariffs } = useOptions("tariffs", "GET");
  const { data: options } = useOptions("deliveries", "GET");

  return options && record ? (
    <>
      {
        record.status && (record.status === "S" || record.status === "SU")
        &&
        <Button
          disabled={!!!record?.client}
          onClick={(e) => {
            e.stopPropagation();
            setTrue();
          }}
          label={"Annulla (In Giacenza/Sospesa)"}
          title="Usare SOLO se si è messa in giacenza o in sospesa per sbaglio! Riporta la missione da In giacenza o da Sopspesa nello stato precedente, NON modifica il giro! Quindi resta nel vecchio giro!"
        ><RotateLeftIcon /></Button>
      }

      <Dialog
        open={value}
        onClick={(e) => e.stopPropagation()}
        fullWidth
        maxWidth="sm"
      >
        <FormWithRedirect
          resource="project-collaborators"
          initialValues={{}}
          save={onClick}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <Typography>Sicuro di voler annullare gli ultimi stati di giacenza/sospensione della missione?</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={setFalse}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};
