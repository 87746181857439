import {
  Button,
  EditProps,
  ToolbarProps,
  TopToolbar,
  useEditContext,
} from "react-admin";
import { DefaultEdit } from "../../components/DefaultEdit";
import { RemittanceForm } from "./RemittanceForm";
import SendIcon from "@material-ui/icons/Send";
import { DownloadRemittanceExcleButton } from "./DownloadRemittanceExcelButton";
import { DownloadRemittancePdfButton } from "./DownloadRemittancePdfButton";
const ChangeStatusButton = (props: ToolbarProps) => {
  const { save, record } = useEditContext();
  const onSubmit = () => {
    save && save({ status: "B" }, "/remittances/" + record!.id)
  };
  return (
    <TopToolbar {...props}>
      {record && record.status !== "B" ? (
        <Button variant="contained" label="Emetti" onClick={onSubmit}>
          <SendIcon />
        </Button>
      ) : null}
      <DownloadRemittanceExcleButton />
      <DownloadRemittancePdfButton />
    </TopToolbar>
  );
};
export const RemittanceEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} actions={<ChangeStatusButton />}>
      <RemittanceForm />
    </DefaultEdit>
  );
};
