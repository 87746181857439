import { CustomList } from "../components/CustomList";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../shared/utils";

import { Tabs, Divider, Tab } from "@material-ui/core";
import { useState, useEffect, useCallback, Fragment } from "react";
import {
  Datagrid,
  TextField,
  ListProps,
  TextInput,
  DatagridProps,
  Identifier,
  ListContextProvider,
  useListContext,
} from "react-admin";
import { IdentifierField } from "../components/IdentifierField";
import { getUser } from "../authProvider";

const tabs = [
  { id: "Autista", name: "Autisti" },
  { id: "Ufficio", name: "Ufficio" },
  { id: "Magazzino", name: "Magazzino" },
  { id: "Manutenzione", name: "Manutenzione" },
  { id: "Smistamento", name: "Smistamento" },
];

const TabbedDatagrid = (props: DatagridProps) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [drivers, setDrivers] = useState<Identifier[]>([] as Identifier[]);
  const [office, setOffice] = useState<Identifier[]>([] as Identifier[]);
  const [mainteneance, setMaintenance] = useState<Identifier[]>(
    [] as Identifier[]
  );
  const [store, setStore] = useState<Identifier[]>([] as Identifier[]);
  const [sorting, setSorting] = useState<Identifier[]>([] as Identifier[]);

  useEffect(() => {
    if (ids && ids !== filterValues.departments) {
      switch (filterValues.departments) {
        case "Autista":
          setDrivers(ids);
          break;
        case "Ufficio":
          setOffice(ids);
          break;
        case "Magazzino":
          setStore(ids);
          break;
        case "Manutenzione":
          setMaintenance(ids);
          break;
        case "Smistamento":
          setSorting(ids);
          break;
      }
    }
  }, [ids, filterValues.departments]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, departments: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );
  const { data: options } = useOptionsResource("GET");

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.departments}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.name} value={choice.id} />
        ))}
      </Tabs>
      <Divider />
      {options ? (
        <div>
          {filterValues.departments === "Autista" && (
            <ListContextProvider value={{ ...listContext, ids: drivers }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("license", options)} />
                <TextField {...initField("device", options)} />
                <TextField {...initField("fuel_card_number", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.departments === "Ufficio" && (
            <ListContextProvider value={{ ...listContext, ids: office }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("phone_number", options)} />
                <TextField {...initField("email", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.departments === "Magazzino" && (
            <ListContextProvider value={{ ...listContext, ids: store }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("phone_number", options)} />
                <TextField {...initField("email", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.departments === "Manutenzione" && (
            <ListContextProvider value={{ ...listContext, ids: mainteneance }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("phone_number", options)} />
                <TextField {...initField("email", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.departments === "Smistamento" && (
            <ListContextProvider value={{ ...listContext, ids: sorting }}>
              <Datagrid {...props}>
                <IdentifierField {...initField("id", options)} />
                <TextField {...initField("name", options)} />
                <TextField {...initField("surname", options)} />
                <TextField {...initField("phone_number", options)} />
                <TextField {...initField("email", options)} />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
      ) : null}
    </Fragment>
  );
};

export const EmployeeList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput {...initField("name", options)} />,
      <TextInput {...initField("surname", options)} />,
    ]
    : [];
  return options && getUser().permissions.includes('employees.change_employee') ? (
    <CustomList
      filterDefaultValues={{ departments: "Autista" }}
      filters={filters}
      {...getDefaultListProps(props)}
    >
      <TabbedDatagrid />
    </CustomList>
  ) : null;
};
