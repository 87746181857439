

import {
    Datagrid,
    TextField,
    DateField,
    NumberField,
    SelectField,
    FunctionField,
    Record,
    ArrayField,
} from "react-admin";
import { DatagridPropsWithOptions } from "../shared/types";
import { initField } from "../shared/utils";
import { SubjectField } from "./SubjectField";

export const TariffClientDatagrid = ({
    options,
    ...rest
}: DatagridPropsWithOptions) => {
    const optionsTariffs = options;
    return (
        <Datagrid rowClick="toggleSelection" {...rest}>
            <TextField {...initField("id", optionsTariffs)} />
            <SubjectField {...initField("tariff.client", optionsTariffs)}
                label="Cliente" />
            <SelectField
                choices={optionsTariffs.charge_type.choices}
                {...initField("charge_type", optionsTariffs, "array")}
            />
            <SelectField
                choices={optionsTariffs.quantity_type.choices}
                {...initField("quantity_type", optionsTariffs, "array")}
            />
            <DateField {...initField("start_date", optionsTariffs)} />
            <NumberField {...initField("price", optionsTariffs)}
                label="Prezzo" />
            <FunctionField
                label="Articolo"
                source="item"
                render={(tableRecord: Record | undefined) => {
                    if (tableRecord && tableRecord.item) {
                        return `${tableRecord.item.code}: ${tableRecord.item.description}`;
                    }
                    return null;
                }}
            />
            <ArrayField source="pricerange_set" label="Fasce">
                <Datagrid rowClick={"expand"}>
                    <NumberField source="limit" label="A partire da" />
                </Datagrid>
            </ArrayField>
        </Datagrid>
    );
};











