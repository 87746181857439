import { Button } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { FieldProps, useNotify, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

export const DeliveryField = (
  props: FieldProps & { deliverySource: string, noTitle?: boolean }
) => {
  const record = useRecordContext(props);
  const delivery_id = _.get(record, props.deliverySource);
  const notify = useNotify()
  return delivery_id ? (
    <span onClick={(e) => e.stopPropagation()}>
      <Button
        style={{ lineHeight: 0.75 }}
        color="primary"
        component={Link}
        target={"_blank"}
        to={"/deliveries/" + delivery_id}
        onContextMenu={(e: any) => {
          if (e.getModifierState('Control')) {
            e.preventDefault()
            notify("Copiato: " + delivery_id, "info")
            navigator.clipboard.writeText(delivery_id)

          }
        }}
        onClick={(e: any) => {
          if (e.getModifierState('Alt')) {
            e.preventDefault()
            window.open(`/deliveries?displayedFilters={}&filter={"main_delivery"%3A"${delivery_id}"}`, "_blank");
          }
          else {
            if (e.getModifierState('Control')) {
              e.preventDefault()
              notify("Copiato: " + delivery_id, "info")
              navigator.clipboard.writeText(delivery_id)
            }
          }
        }}
      >
        {props.noTitle ? "" : "Missione ID:"} {delivery_id}
      </Button>
    </span>
  ) : null;
};
