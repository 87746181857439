import { FilledInputProps, OutlinedInputProps } from "@material-ui/core";
import {
  FieldProps,
  FunctionField,
  InputProps,
  NumberField,
  Record,
} from "react-admin";
import { useStore } from "./store";
import { useForm } from "react-final-form";
import { FormApi } from "final-form";
import { NumberInput } from "../NumberInput";
export const EditableNumberField = (
  props: FieldProps & {
    source: string;
    alwaysEditable?: boolean;
    getInputProps?: (
      record: Record,
      form: FormApi<
        globalThis.Record<string, any>,
        Partial<globalThis.Record<string, any>>
      >
    ) =>
      | Partial<InputProps>
      | Partial<FilledInputProps>
      | Partial<OutlinedInputProps>
      | undefined;
  }
) => {
  const { editable } = useStore();
  const form = useForm();
  const { getInputProps, alwaysEditable, ...rest } = props;
  return editable || alwaysEditable ? (
    <FunctionField
      {...rest}
      render={(record: Record | undefined) => (
        <NumberInput
          size="small"
          variant="standard"
          label=""
          source={rest.source}
          helperText={false}
          onClick={(e) => e.stopPropagation()}
          InputProps={
            getInputProps && record ? getInputProps(record, form) : undefined
          }
        />
      )}
      onWheel={(e: any) => { e.target.blur() }}
    />
  ) : (
    <NumberField {...rest} />
  );
};
