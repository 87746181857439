import {
  ListProps,
  TextInput,
  DateInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  NullableBooleanInput,
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../../shared/utils";
import { AdditionalActions } from "./AdditionalActions";
import { NoteDatagrid } from "./NoteDatagrid";

export const NoteList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput {...initField("id", options)} />,
      <NullableBooleanInput {...initField("sent", options)} />,
      <ReferenceInput
        {...initField("delivery", options)}
        reference="autocomplete/delivery"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <DateInput
        {...initField("created_date", options)}
        label="Data di creazione dal"
      />,
    ]
    : [];
  return options ? (
    <CustomList
      sort={{ field: "id", order: "DESC" }}
      filters={filters}
      {...getDefaultListProps(props)}
      additionalActions={<AdditionalActions />}
    >
      <NoteDatagrid options={options} />
    </CustomList>
  ) : null;
};
