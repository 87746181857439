import {
  SimpleForm,
  SelectInput,
  DateInput,
  TextInput,
} from "ra-ui-materialui";
import { ReferenceArrayInput, ReferenceInput, SelectArrayInput } from "react-admin";
import { ReferenceInputAccountCompany } from "../../components/reference-inputs/ReferenceInputAccountCompany";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { ReferenceInputBank } from "../../components/reference-inputs/ReferenceInputBank";

export const InvoiceForm = (props: FormProps) => {
  return (
    <SimpleForm {...props}>
      <TextInput
        defaultValue="Servizi svolti per vostro conto"
        multiline
        {...initInput("causal", props.fieldOptions, props.canEdit)}
      />

      <ReferenceInputAccountCompany
        {...initInput("company", props.fieldOptions, props.canEdit)}
        required
      />

      <ReferenceInputBank {...initInput("bank_id", props.fieldOptions, props.canEdit)} />

      <DateInput
        {...initInput("invoice_date", props.fieldOptions, props.canEdit)}
      />

      <ReferenceInput
        {...initInput("terms_payment", props.fieldOptions, props.canEdit)}
        reference="payment-terms"
        required
        perPage={10000}
      >
        <SelectInput optionText="label" />
      </ReferenceInput>

      <DateInput
        {...initInput("expiration_date", props.fieldOptions, props.canEdit)}
      />
      <SelectInput
        choices={props.fieldOptions.status.choices}
        {...initInput("status", props.fieldOptions, props.canEdit, "array")}
      />

      <TextInput
        multiline
        {...initInput("notes", props.fieldOptions, props.canEdit)}
      />
    </SimpleForm>
  );
};
