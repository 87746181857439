import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
} from "ra-ui-materialui";
import React from "react";

type ReferenceInputBankProps = Omit<
  ReferenceInputProps,
  "children" | "reference" | "sort"
>;

export const ReferenceInputBank = (props: ReferenceInputBankProps) => {
  return (
    <ReferenceInput
      {...(props as ReferenceInputProps)}
      reference="autocomplete/bank"
      sort={{ field: "name", order: "ASC" }}
      allowEmpty={true}
    >
      <AutocompleteInput optionText="label" />
    </ReferenceInput>
  );
};
