import { Button, ButtonProps, useListContext, useNotify } from "react-admin";
import DescriptionIcon from "@material-ui/icons/Description";
import { client, clientNoJson } from "../../dataProvider";
import { useState } from "react";
import { url as URL } from "../../constants";
import { CircularProgress } from "@material-ui/core";
import { getFileNameFromContentDisposition } from "../../shared/utils";

export const LoadRibaBulkButton = (props: ButtonProps) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { selectedIds, refetch } = useListContext();

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;

    try {
      res = await clientNoJson(`${URL}/invoices/set_invoices_loaded/`, {
        method: "POST",
        data: { ids: selectedIds }
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );
    refetch();

  };

  return (
    <Button
      {...props}
      disabled={loading || selectedIds.length <= 0}
      label="Riba caricate"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <DescriptionIcon />}
    </Button>
  );
};
