import {
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  FunctionField,
} from "react-admin";
import { DatagridPropsWithOptions } from "../../shared/types";
import { initField } from "../../shared/utils";
import { DeliveryField } from "../../components/DeliveryField";
import { IdentifierField } from "../../components/IdentifierField";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";
import { NoteOriginField } from "./NoteOriginField";

export const NoteDatagrid = ({
  options,
  ...rest
}: DatagridPropsWithOptions) => {
  return (
    <Datagrid {...rest}>
      <IdentifierField {...initField("id", options)} />
      <BooleanField source="final" label="Scansione finale"></BooleanField>
      <DeliveryField
        deliverySource={"delivery"}
        {...initField("delivery", options)}
      />
      <DateField {...initField("sent", options)} />

      <FunctionField
        {...initField("file_info", options)}
        render={(tableRecord: any) => {
          if (tableRecord) {
            return (
              <DownloadNoteFileButton
                title={tableRecord.file_info.file}
                noteId={tableRecord.id}
                final={tableRecord.final}
              />
            );
          }
          return null;
        }}
      />

      <TextField {...initField("config_name", options)} />
      <TextField {...initField("document_number", options)} />
      <TextField {...initField("notes", options)} />
      <NoteOriginField source="origin" options={options} label={"Origine"} />
      <TextField {...initField("lat_lon", options)} />
    </Datagrid>
  );
};
